import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  get_salary_deductions,
  get_salary_earnings,
  human_resource_get_staff,
} from "../../../redux/actions/staff";
import { START_LOADING } from "../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import StaffSalary from "./StaffSalaryEarning/StaffSalary";
import StaffDeduction from "./StaffSalaryDeduction/StaffDeduction";

const AllStaff = (props) => {
  const { userId, loading, allStaff } = props;
  const { startLoading, getAllStaff, getSalaryDeductions, getSalaryEarnings } =
    props;

  const [currentStaff, setCurrentStaff] = useState({});
  const [openStaffSalary, setOpenStaffSalary] = useState(false);
  const [openStaffDeduction, setOpenStaffDeduction] = useState(false);

  // useeffect to get all staff
  useEffect(() => {
    if (userId) {
      startLoading();
      getAllStaff(userId);
    }
  }, [startLoading, userId, getAllStaff]);

  // useeffect to get all salary deductions
  useEffect(() => {
    if (userId) {
      startLoading();
      getSalaryDeductions(userId);
    }
  }, [getSalaryDeductions, startLoading, userId]);

  // useeffect to get salary earnings types
  useEffect(() => {
    if (userId) {
      startLoading();
      getSalaryEarnings(userId);
    }
  }, [getSalaryEarnings, userId, startLoading]);

  const handleOpenViewStaffSalaries = (staff) => {
    setCurrentStaff(staff);
    setOpenStaffSalary(true);
  };

  const handleOpenViewStaffDeductions = (staff) => {
    setCurrentStaff(staff);
    setOpenStaffDeduction(true);
  };
  return (
    <>
      <div className="table__parent" id={loading ? "formSubmitting" : ""}>
        <div class="table__parentHeader">
          <h3>Maintain staff salaries/deductions</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {allStaff?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Staff Name</th>
                <th>Payroll Number</th>
                <th>Status</th>
                <th>Salary</th>
                <th>Deductions</th>
              </tr>
            </thead>
            <tbody>
              {allStaff?.map((staff) => (
                <tr class="table__listingItem" key={staff?.id}>
                  <td>{staff?.name}</td>
                  <td>{staff?.payroll_number}</td>
                  <td>
                    {staff?.is_active ? (
                      <span className="green bd">Active</span>
                    ) : (
                      <span className="red bd">Inactive</span>
                    )}
                  </td>
                  <td
                    className="dodgerblue button__sp bd"
                    onClick={() => handleOpenViewStaffSalaries(staff)}
                  >
                    view
                  </td>
                  <td
                    className="dodgerblue button__sp bd"
                    onClick={() => handleOpenViewStaffDeductions(staff)}
                  >
                    view
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No staffs available</h4>
        )}
      </div>
      {/* child components */}
      {openStaffSalary && (
        <StaffSalary
          openStaffSalary={openStaffSalary}
          setOpenStaffSalary={setOpenStaffSalary}
          currentStaff={currentStaff}
          setCurrentStaff={setCurrentStaff}
        />
      )}
      {openStaffDeduction && (
        <StaffDeduction
          openStaffDeduction={openStaffDeduction}
          setOpenStaffDeduction={setOpenStaffDeduction}
          currentStaff={currentStaff}
          setCurrentStaff={setCurrentStaff}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allStaff: state?.staff?.allStaff,
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllStaff: (userId) => dispatch(human_resource_get_staff(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
    getSalaryDeductions: (userId) => dispatch(get_salary_deductions(userId)),
    getSalaryEarnings: (userId) => dispatch(get_salary_earnings(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllStaff);
