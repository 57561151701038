import React from "react";
import moment from "moment";

const SingleStaffReport = ({ staffData }) => {
  return (
    <tbody>
      <tr>
        <th colSpan="100%" className="report__title">
          {staffData?.staff_name} check in/out from{" "}
          {moment(staffData?.start_date).format("LL")} to{" "}
          {moment(staffData?.end_date).format("LL")}
        </th>
      </tr>
      <tr className="table__listingHeader">
        <th>Date</th>
        <th>Time In</th>
        <th>Time Out</th>
      </tr>
      {staffData?.check_in_data?.map((item) => (
        <tr className="table__listingItem" key={item?.id}>
          <td>{moment(item?.date_value).format("LL")}</td>
          <td>{moment(item?.time_in).format("LT")}</td>
          <td>{item?.time_out ? moment(item?.time_out).format("LT") : "-"}</td>
        </tr>
      ))}
    </tbody>
  );
};

export default SingleStaffReport;
