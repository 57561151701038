import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { edit_school_term } from "../../../../../redux/actions/course";
import { START_LOADING } from "../../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";

const EditTerm = (props) => {
  const { loading, userId, openEditTerm, currentTerm } = props;
  const { setOpenEditTerm, setCurrentTerm, editSchoolTerm, startLoading } =
    props;

  const { name, starting_date, ending_date } = currentTerm;

  const handleChange = (e) => {
    setCurrentTerm({ ...currentTerm, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    const body = {
      ...currentTerm,
    };
    editSchoolTerm(userId, body);
  };

  return (
    <MinDialog isOpen={openEditTerm}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit term details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Name</label>
          <input type="text" name="name" onChange={handleChange} value={name} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Start Date</label>
          <input
            type="date"
            name="starting_date"
            onChange={handleChange}
            value={starting_date}
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">End Date</label>
          <input
            type="date"
            name="ending_date"
            onChange={handleChange}
            value={ending_date}
          />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditTerm(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editSchoolTerm: (userId, body) => dispatch(edit_school_term(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTerm);
