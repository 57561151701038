import React, { useRef } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import globals from "../../../../shared/globals";
import "./PayrollReport.scss";
import MaxDialog from "../../../../components/common/MaxDialog";
import { formatWithCommas } from "../../../../shared/sharedFunctions";

const PayrollReport = (props) => {
  const { openPayrollReport, currentPayroll } = props;
  const { setOpenPayrollReport } = props;
  const {
    schoolName,
    schoolAddress,
    schoolEmail,
    schoolMobile1,
    schoolMobile2,
  } = globals;

  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: `Payroll Report`,
  });

  return (
    <MaxDialog isOpen={openPayrollReport} maxWidth="1600px" maxHeight="85vh">
      <div class="dialog">
        <table
          className="table__listing payroll__report"
          border="1"
          rules="rows"
          ref={printArea}
          id="payroll-report"
        >
          <tbody className="payroll__reportHeader">
            <tr>
              <th className="school__name" colSpan={"100%"}>
                {schoolName}
              </th>
            </tr>
            <tr>
              <th className="school__details" colSpan={"100%"}>
                {schoolAddress}
              </th>
            </tr>
            <tr>
              <th className="school__details" colSpan={"100%"}>
                <strong>Mobile:</strong> {schoolMobile1}, {schoolMobile2}
              </th>
            </tr>
            <tr>
              <th className="school__details" colSpan={"100%"}>
                <strong>Email:</strong> {schoolEmail}
              </th>
            </tr>
            <tr>
              <th colSpan={"100%"}>
                Month: {currentPayroll?.month_year} Payroll
              </th>
            </tr>
          </tbody>
          <tbody>
            <tr className="table__listingHeader">
              {/* we show indexing first */}
              <th>#</th>
              <th>Staff Name</th>
              <th>Payroll No:</th>
              {/* we show the earning names */}
              {currentPayroll?.earnings_types?.map((earning_item) => (
                <th key={earning_item?.id}>{earning_item?.name}</th>
              ))}
              {/* we display total earnings */}
              <th className="totals__item">Total Earnings</th>
              {/* we show the deduction names */}
              {currentPayroll?.deduction_types?.map((deduction_item) => (
                <th key={deduction_item?.id}>{deduction_item?.name}</th>
              ))}
              <th className="totals__item">Total Deductions</th>
              {/* we then show net pay */}
              <th>Net Pay</th>
            </tr>
          </tbody>
          <tbody>
            {currentPayroll?.staff_pay_slips?.map((pay_slip, index) => (
              <tr key={pay_slip?.id} className="table__listingItem">
                {/* index */}
                <td>{index + 1}</td>
                {/* show staff name */}
                <td>{pay_slip?.staff_name}</td>
                {/* staff payroll number */}
                <td>{pay_slip?.staff_payroll_number}</td>
                {/* we then show the earning amounts */}
                {pay_slip?.staff_pay_slip_earnings?.map(
                  (pay_slip_earning_amount, index) => (
                    <td key={index}>
                      {formatWithCommas(pay_slip_earning_amount)}
                    </td>
                  )
                )}
                {/* we then show the total payslip earnings */}
                <td className="totals__item">
                  {formatWithCommas(pay_slip?.staff_pay_slip_total_earnings)}
                </td>
                {/* we then show the deduction amounts */}
                {pay_slip?.staff_pay_slip_deductions?.map(
                  (pay_slip_deduction_amount, index) => (
                    <td key={index}>
                      {formatWithCommas(pay_slip_deduction_amount)}
                    </td>
                  )
                )}
                {/* we then show the total payslip deductions */}
                <td className="totals__item">
                  {formatWithCommas(pay_slip?.staff_pay_slip_total_deductions)}
                </td>
                {/* we who the net pay amount */}
                <td>{formatWithCommas(pay_slip?.net_pay_due)}</td>
              </tr>
            ))}
            {/* we then show the totals below */}
            <tr className="table__listingFooter">
              {/* we create a th for overall totals and create 2 blank th to abide with formatting */}
              <th>Overall Totals</th>
              <th></th>
              <th></th>
              {/* we then loop through the overall totals */}
              {currentPayroll?.overall_payroll_totals?.map((total_type) => (
                // first we loop through the total earnings
                <>
                  {total_type?.overall_payroll_earnings_items_totals?.map(
                    (item_earning_total, index) => (
                      <th key={index}>
                        {formatWithCommas(item_earning_total)}
                      </th>
                    )
                  )}
                  {/* we then show the total earnings totals */}
                  <th>
                    {formatWithCommas(
                      total_type?.overall_payroll_earnings_totals
                    )}
                  </th>
                  {/* we then loop through the total deductions */}
                  {total_type?.overall_payroll_deductions_items_totals?.map(
                    (item_deduction_total, index) => (
                      <th key={index}>
                        {formatWithCommas(item_deduction_total)}
                      </th>
                    )
                  )}
                  {/* we then show the total deductions totals */}
                  <th>
                    {formatWithCommas(
                      total_type?.overall_payroll_deductions_totals
                    )}
                  </th>
                  {/* then we show the total net pay totals */}
                  <th>
                    {formatWithCommas(
                      total_type?.overall_payroll_net_pay_totals
                    )}
                  </th>
                </>
              ))}
            </tr>
          </tbody>

          {/* section for showing who created the payroll and who approved it and it's status */}
          <tbody className="approval__details">
            <tr>
              <td colSpan={"100%"}>
                <strong>Prepared By:</strong> {currentPayroll?.prepared_by}
                <span>
                  <strong>Signature:</strong>{" "}
                  .............................................
                </span>
                <span>
                  <strong>Prepared On:</strong>{" "}
                  {moment(currentPayroll?.created_on).format("LLL")}
                </span>
              </td>
            </tr>
            <tr>
              <td colSpan={"100%"}>
                <strong>Payroll Status:</strong>{" "}
                {currentPayroll?.payroll_status}
              </td>
            </tr>
            <tr>
              <td colSpan={"100%"}>
                <strong>Approved By:</strong> {currentPayroll?.approved_by}
                <span>
                  <strong>Signature:</strong>{" "}
                  .............................................
                </span>
                <span>
                  <strong>Approved On:</strong>{" "}
                  {currentPayroll?.payroll_status === "approved" && (
                    <>{moment(currentPayroll?.approved_on).format("LLL")}</>
                  )}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenPayrollReport(false)}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print/Save
          </button>
        </div>
        <div class="export_to_excel_button">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button export_to_excel_button"
            table="payroll-report"
            filename="Payroll_Report"
            sheet="payroll_report"
            buttonText="Export To Excel"
          />
        </div>
      </div>
    </MaxDialog>
  );
};

export default PayrollReport;
