import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../components/common/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import CircularProgress from "@mui/material/CircularProgress";
import PrintPaySlip from "./PrintPaySlip";

const PayrollPaySlips = (props) => {
  const { openPayrollPayslips, currentPayroll, userId, loading } = props;
  const { setOpenPayrollPayslips, startLoading, stopLoading } = props;

  const [payslipsData, setPayslipsData] = useState([]);
  const [currentPaySlip, setCurrentPaySlip] = useState({});
  const [openPrintPaySlip, setOpenPrintPaySlip] = useState(false);

  useEffect(() => {
    const fetchPayslips = async () => {
      startLoading();
      await API.get(
        `/api/staff/staff/get-payroll-payslips/${userId}/${currentPayroll?.id}/`
      )
        .then((res) => {
          setPayslipsData(res?.data?.payroll_payslips);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    };
    fetchPayslips();
  }, [startLoading, stopLoading, userId, currentPayroll?.id]);

  const handleOpenPrintPaySlip = (payslip) => {
    setCurrentPaySlip(payslip);
    setOpenPrintPaySlip(true);
  };

  return (
    <>
      <MaxDialog
        isOpen={openPayrollPayslips}
        maxWidth="1200px"
        maxHeight="85vh"
      >
        <div class="dialog" id={loading ? "formSubmitting" : ""}>
          <h3>Payslips for {currentPayroll?.month_year} payroll</h3>
          <table className="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Staff Name</th>
                <th>View</th>
              </tr>
            </thead>
            {loading && (
              <CircularProgress
                style={{ position: "absolute", marginLeft: "45%" }}
              />
            )}
            <tbody>
              {payslipsData?.length > 0 ? (
                <>
                  {payslipsData?.map((payslip) => (
                    <tr key={payslip?.id}>
                      <td>{payslip?.staff_name}</td>
                      <td
                        className="dodgerblue bd button__sp"
                        onClick={() => handleOpenPrintPaySlip(payslip)}
                      >
                        View
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td className="red">
                    No payslips available for this payroll
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div class="form__Buttons">
            <button type="button" onClick={() => setOpenPayrollPayslips(false)}>
              Close
            </button>
          </div>
        </div>
      </MaxDialog>
      {openPrintPaySlip && (
        <PrintPaySlip
          openPrintPaySlip={openPrintPaySlip}
          setOpenPrintPaySlip={setOpenPrintPaySlip}
          currentPaySlip={currentPaySlip}
          payslipMonth={currentPayroll?.month_year}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollPaySlips);
