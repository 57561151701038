import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import MediumDialog from "../../../../components/common/MediumDialog";

import moment from "moment";

const AttendanceReport = (props) => {
  const { openAttendanceReport, attendanceReportData } = props;
  const { setOpenAttendanceReport } = props;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Attendance report",
  });

  return (
    <MediumDialog isOpen={openAttendanceReport}>
      <div className="dialog">
        <div ref={printArea}>
          <h3 className="dodgerblue tc">
            Attendance report for class {attendanceReportData?.class_stream} on{" "}
            {moment(attendanceReportData?.date).format("LL")}
          </h3>
          <table className="table__listing">
            <tr className="table__listingHeader">
              <th>Admission Number</th>
              <th>Student Name</th>
              <th className="tc">Present</th>
            </tr>
            {attendanceReportData?.stream_students?.map((student) => (
              <tr className="table__listingItem" key={student?.id}>
                <td>{student?.admission_number}</td>
                <td>{student?.name}</td>
                <td className="tc">
                  <input
                    type="checkbox"
                    name=""
                    checked={student?.is_present}
                  />
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenAttendanceReport(false)}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

export default AttendanceReport;
