import React, { useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import MaxDialog from "../../../../components/common/MaxDialog";
import AllStaffReport from "./AllStaffReport";
import SingleStaffReport from "./SingleStaffReport";
import ReportHeader from "../../../../shared/ReportHeader/ReportHeader";

const CheckInOutReport = (props) => {
  const { openCheckInOutReport, staffData, checkDate, checkInType } = props;
  const { setOpenCheckInOutReport } = props;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Staff check in/out report",
  });
  return (
    <MaxDialog isOpen={openCheckInOutReport} maxWidth="1200px" maxHeight="85vh">
      <div className="dialog">
        <div ref={printArea}>
          {staffData?.check_in_data?.length > 0 ? (
            <table className="report__statement" border="1" rules="rows">
              <ReportHeader />
              {checkInType === "all_staff" && (
                <AllStaffReport checkDate={checkDate} staffData={staffData} />
              )}
              {checkInType === "single_staff" && (
                <SingleStaffReport staffData={staffData} />
              )}
            </table>
          ) : (
            <h4 className="not__available">
              No check in and out data available for{" "}
              {moment(checkDate).format("LL")}
            </h4>
          )}
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenCheckInOutReport(false)}>
            Close
          </button>
          {staffData?.check_in_data?.length > 0 && (
            <button type="button" onClick={handlePrint}>
              Print
            </button>
          )}
        </div>
      </div>
    </MaxDialog>
  );
};

export default CheckInOutReport;
