import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../components/common/MaxDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { showError } from "../../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import { formatWithCommas, ifEmpty } from "../../../../shared/sharedFunctions";

const UpdateSalaryPayments = (props) => {
  const { openUpdateSalaryPayment, userId, loading, bankLedgers } = props;
  const { setOpenUpdateSalaryPayment, startLoading, stopLoading } = props;

  const [payrolls, setPayrolls] = useState([]);
  const [currentPayroll, setCurrentPayroll] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({
    paying_ledger: "",
    transaction_date: "",
  });

  const { paying_ledger, transaction_date } = paymentDetails;

  // useeffect to get payrolls outstanding payrolls
  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        startLoading();
        await API.get(`/api/staff/finance/finance-payroll-payments/${userId}/`)
          .then((res) => {
            setPayrolls(res?.data?.payrolls_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [startLoading, stopLoading, userId]);

  const handleChange = (e) => {
    setPaymentDetails({ ...paymentDetails, [e.target.name]: e.target.value });
  };

  const handlePayrollChange = (e) => {
    setCurrentPayroll(
      payrolls?.find((payroll) => payroll?.id === e.target.value)
    );
  };

  const handlePaySlipChange = (e, payslipIndex) => {
    const arrayToMutate = currentPayroll?.payslips;
    arrayToMutate[payslipIndex] = {
      ...arrayToMutate[payslipIndex],
      amount_to_pay: e.target.value,
    };
    setCurrentPayroll({ ...currentPayroll, payslips: arrayToMutate });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (ifEmpty(paymentDetails)) {
      return window.alert(
        "Please select the paying bank/cash and indicate the transaction date"
      );
    }
    if (!currentPayroll?.id) {
      return window.alert("You need to select the payroll to update");
    }

    await API.patch(`/api/staff/finance/finance-payroll-payments/${userId}/`, {
      ...currentPayroll,
      ...paymentDetails,
    })
      .then((res) => {
        // we map through the payrolls to update the updated payroll with the new values
        setPayrolls(
          payrolls?.map((payroll) =>
            payroll?.id === res?.data?.updated_payroll?.id
              ? res?.data?.updated_payroll
              : payroll
          )
        );
        // to show the updated changes on the currently selected payroll, we setCurrentPayroll using the res?.data?.updated_payroll
        setCurrentPayroll(res?.data?.updated_payroll);

        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MaxDialog
      isOpen={openUpdateSalaryPayment}
      maxWidth="1200px"
      maxHeight="85vh"
    >
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Update salary payments</h3>
        <div class="dialog__row">
          <span>
            <label for="">Select payroll</label>
            <select name="" onChange={handlePayrollChange}>
              <option value="">------</option>
              {payrolls?.map((payroll) => (
                <option value={payroll?.id} key={payroll?.id}>
                  {payroll?.month_year}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label for="">Select paying bank/cash</label>
            <select
              onChange={handleChange}
              value={paying_ledger}
              name="paying_ledger"
            >
              <option value="">------</option>
              {bankLedgers?.map((ledger) => (
                <option value={ledger?.id} key={ledger?.id}>
                  {ledger?.name}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label for="">Transaction Date</label>
            <input
              type="date"
              name="transaction_date"
              onChange={handleChange}
              value={transaction_date}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Current payroll outstanding payments</label>
          {currentPayroll?.payslips?.length > 0 ? (
            <table className="table__listing">
              <thead>
                <tr class="table__listingHeader">
                  <th>Staff</th>
                  <th>Balance Due</th>
                  <th>Amount Paid</th>
                </tr>
              </thead>
              <tbody>
                {currentPayroll?.payslips?.map((payslip, index) => (
                  <tr className="table__listingItem" key={payslip?.id}>
                    <td>{payslip?.staff_name}</td>
                    <td>{formatWithCommas(payslip?.net_pay_balance, 2)}</td>
                    <td>
                      <input
                        type="number"
                        name={payslip?.id}
                        value={payslip?.amount_to_pay}
                        onChange={(e) => handlePaySlipChange(e, index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="red">
              No outstanding payslip payments for selected payroll
            </h4>
          )}
        </div>
        <div class="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenUpdateSalaryPayment(false)}
          >
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Update Payments
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    bankLedgers: state?.finance?.ledgers?.filter(
      (ledger) => ledger.is_bank_or_cash
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSalaryPayments);
