import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { START_LOADING, STOP_LOADING } from "../../redux/actions/types";
import { admin_get_stock_items } from "../../redux/actions/stores";
import AddStockItem from "./components/AddStockItem";
import API from "../../shared/axios";
import { showError } from "../../redux/actions/shared";
import EditStockItem from "./components/EditStockItem";
import StockInOut from "./components/StockInOut";
import StockReports from "./components/StockReports/StockReports";

const Stocks = (props) => {
  const { userId, stock_items } = props;
  const { startLoading, adminGetStockItems, stopLoading } = props;

  const [openAddStockItem, setOpenAddStockItem] = useState(false);
  const [openEditStockItem, setOpenEditStockItem] = useState(false);
  const [currentStockItem, setCurrentStockItem] = useState({});
  const [expensesLedgers, setExpensesLedgers] = useState([]);
  const [openStockInOut, setOpenStockInOut] = useState(false);
  const [currentStockEntryType, setCurrentStockEntryType] = useState("");
  const [openStockReports, setOpenStockReports] = useState(false);

  useEffect(() => {
    if (userId) {
      startLoading();
      adminGetStockItems(userId);
    }
  }, [adminGetStockItems, userId, startLoading]);

  // useEffect to get ledgers/groups
  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        startLoading();
        await API.get(`/api/finance/get-expenses-ledgers/${userId}/`).then(
          (res) => {
            setExpensesLedgers(res?.data?.expenses_ledgers_data);
          }
        );
      };
      fetchData()
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  }, [userId, startLoading, stopLoading]);

  const handleOpenEdit = (stock_item) => {
    setCurrentStockItem(stock_item);
    setOpenEditStockItem(true);
  };

  const handleOpenStockInOut = (stock_entry_type) => {
    setOpenStockInOut(true);
    setCurrentStockEntryType(stock_entry_type);
  };

  return (
    <>
      <div className="table__parent">
        <div class="table__parentHeader">
          <button
            className="add__button"
            onClick={() => setOpenAddStockItem(true)}
          >
            New Stock Item
          </button>
          <button
            className="add__button"
            onClick={() => handleOpenStockInOut("Stock In")}
          >
            Stock In
          </button>
          <button
            className="add__button"
            onClick={() => handleOpenStockInOut("Stock Out")}
          >
            Stock Out
          </button>
          <button type="button" onClick={() => setOpenStockReports(true)}>
            Stock Reports
          </button>
          <h3>Maintain Stocks</h3>
        </div>
        {stock_items?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr className="table__listingHeader">
                <th>Item Name</th>
                <th>Status</th>
                <th>Unit of Measure</th>
                <th>Balance</th>
                <th>Reorder Level</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {stock_items?.map((item) => (
                <tr class="table__listingItem" key={item?.id}>
                  <td>{item?.name}</td>
                  <td>
                    {item?.is_active ? (
                      <span className="green bd">Active</span>
                    ) : (
                      <span className="red bd">Inactive</span>
                    )}
                  </td>
                  <td>{item?.unit_of_measure}</td>
                  <td>{item?.balance}</td>
                  <td>{item?.reorder_level}</td>
                  <td
                    className="dodgerblue bd button__sp"
                    onClick={() => handleOpenEdit(item)}
                  >
                    Edit
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No stock items available</h4>
        )}
      </div>
      {/* child components */}
      {openAddStockItem && (
        <AddStockItem
          openAddStockItem={openAddStockItem}
          setOpenAddStockItem={setOpenAddStockItem}
          expensesLedgers={expensesLedgers}
        />
      )}
      {openEditStockItem && (
        <EditStockItem
          openEditStockItem={openEditStockItem}
          setOpenEditStockItem={setOpenEditStockItem}
          currentStockItem={currentStockItem}
          setCurrentStockItem={setCurrentStockItem}
          expensesLedgers={expensesLedgers}
        />
      )}
      {openStockInOut && (
        <StockInOut
          openStockInOut={openStockInOut}
          setOpenStockInOut={setOpenStockInOut}
          currentStockEntryType={currentStockEntryType}
        />
      )}
      {
        <StockReports
          openStockReports={openStockReports}
          setOpenStockReports={setOpenStockReports}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    stock_items: state?.stores?.stock_items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    adminGetStockItems: (userId) => dispatch(admin_get_stock_items(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stocks);
