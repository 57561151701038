import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { get_users } from "../../../redux/actions/auth";
import { get_books, get_book_borrows } from "../../../redux/actions/library";
import { START_LOADING } from "../../../redux/actions/types";
import NewBookBorrow from "./components/NewBookBorrow";
import UpdateBookBorrow from "./components/UpdateBookBorrow";

const BookBorrows = (props) => {
  const { otherstaff, userId, teachers, students, all_books, borrowed_books } =
    props;
  const {
    startLoading,
    getOtherStaff,
    getTeachers,
    getStudents,
    getBooks,
    getBookBorrows,
  } = props;

  const [openNewBookBorrow, setOpenNewBookBorrow] = useState(false);
  const [openUpdateBookBorrow, setOpenUpdateBookBorrow] = useState(false);
  const [currentBookBorrow, setCurrentBookBorrow] = useState({});

  // useEffect to get other staff
  useEffect(() => {
    if (userId) {
      startLoading();
      getOtherStaff(userId, "Other_Staff");
    }
  }, [getOtherStaff, userId, startLoading]);

  // useEffect to get teachers
  useEffect(() => {
    if (userId) {
      startLoading();
      getTeachers(userId, "Teacher");
    }
  }, [getTeachers, userId, startLoading]);

  // useEffect to get students
  useEffect(() => {
    if (userId) {
      startLoading();
      getStudents(userId, "Student");
    }
  }, [getStudents, userId, startLoading]);

  // useEffect to get all books
  useEffect(() => {
    if (userId) {
      startLoading();
      getBooks(userId);
    }
  }, [userId, getBooks, startLoading]);

  // useEffect to get book borrows
  useEffect(() => {
    if (userId) {
      startLoading();
      getBookBorrows(userId);
    }
  }, [userId, startLoading, getBookBorrows]);

  const handleOpenUpdateBorrow = (book_borrow) => {
    setCurrentBookBorrow(book_borrow);
    setOpenUpdateBookBorrow(true);
  };

  return (
    <>
      <div className="table__parent">
        <div class="table__parentHeader">
          <button type="button" onClick={() => setOpenNewBookBorrow(true)}>
            New Borrow
          </button>
          <h3>Maintain books currently borrowed</h3>
        </div>
        {borrowed_books?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr className="table__listingHeader">
                <th>Book</th>
                <th>Book Code</th>
                <th>User</th>
                <th>Due Date</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {borrowed_books?.map((borrowing) => (
                <tr class="table__listingItem" key={borrowing?.id}>
                  <td>{borrowing?.book_name}</td>
                  <td>{borrowing?.book_code}</td>
                  <td>{borrowing?.user}</td>
                  <td>{moment(borrowing?.due_date).format("LLL")}</td>
                  <td
                    className="button__sp dodgerblue bd"
                    onClick={() => handleOpenUpdateBorrow(borrowing)}
                  >
                    Edit
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No borrowed books</h4>
        )}
      </div>
      {/* child components */}
      {openNewBookBorrow && (
        <NewBookBorrow
          openNewBookBorrow={openNewBookBorrow}
          setOpenNewBookBorrow={setOpenNewBookBorrow}
        />
      )}
      {openUpdateBookBorrow && (
        <UpdateBookBorrow
          openUpdateBookBorrow={openUpdateBookBorrow}
          setOpenUpdateBookBorrow={setOpenUpdateBookBorrow}
          currentBookBorrow={currentBookBorrow}
          setCurrentBookBorrow={setCurrentBookBorrow}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    teachers: state.auth?.teachers,
    students: state?.auth?.students,
    otherstaff: state?.auth?.otherstaff,
    all_books: state?.library?.all_books,
    borrowed_books: state?.library?.borrowed_books,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getOtherStaff: (userId, userType) => dispatch(get_users(userId, userType)),
    getStudents: (userId, userType) => dispatch(get_users(userId, userType)),
    getTeachers: (userId, userType) => dispatch(get_users(userId, userType)),
    getBooks: (userId) => dispatch(get_books(userId)),
    getBookBorrows: (userId) => dispatch(get_book_borrows(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookBorrows);
