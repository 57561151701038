import React, { useState } from "react";
import { connect } from "react-redux";
import SelectStudents from "./components/SelectStudents";

const UpgradeStudents = (props) => {
  const { teacher_class_streams } = props;
  const [openSelectStudents, setOpenSelectStudents] = useState(false);
  const [currentStream, setCurrentStream] = useState({});

  const handleOpen = (stream) => {
    setCurrentStream(stream);
    setOpenSelectStudents(true);
  };

  return (
    <>
      <div className="table__parent">
        <div class="table__parentHeader">
          <h3>Classes in which you are the class teacher</h3>
        </div>
        {teacher_class_streams?.length > 0 ? (
          <table
            class="table__listing"
            style={{ maxWidth: "500px", margin: "auto" }}
          >
            <thead>
              <tr className="table__listingHeader">
                <th>Class Stream</th>
                <th>Upgrade Students</th>
              </tr>
            </thead>
            <tbody>
              {teacher_class_streams?.map((stream) => (
                <tr class="table__listingItem" key={stream?.name}>
                  <td>{stream?.name}</td>
                  <td
                    className="dodgerblue button__sp bd"
                    onClick={() => handleOpen(stream)}
                  >
                    Upgrade Students
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">
            You have not appointed as a class teacher yet
          </h4>
        )}
      </div>
      {/* child components */}
      <SelectStudents
        currentStream={currentStream}
        openSelectStudents={openSelectStudents}
        setOpenSelectStudents={setOpenSelectStudents}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    teacher_class_streams: state?.auth?.user?.class_streams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeStudents);
