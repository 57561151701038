import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  dorms: [],
  active_dorms: [],
};

const createNewDorm = (state, payload) => {
  return updateObject(state, {
    dorms: [...state?.dorms, payload],
  });
};

const setDorms = (state, payload) => {
  return updateObject(state, {
    dorms: payload,
  });
};

const setActiveDorms = (state, payload) => {
  return updateObject(state, {
    active_dorms: payload,
  });
};

const editDorm = (state, payload) => {
  return updateObject(state, {
    dorms: state?.dorms?.map((dorm) =>
      dorm?.id === payload?.id ? payload : dorm
    ),
  });
};

const accomodationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_DORMS:
      return setDorms(state, payload);
    case actionTypes.NEW_DORM:
      return createNewDorm(state, payload);
    case actionTypes.EDIT_DORM:
      return editDorm(state, payload);
    case actionTypes.SET_ACTIVE_DORMS:
      return setActiveDorms(state, payload);
    default:
      return state;
  }
};

export default accomodationReducer;
