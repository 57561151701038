import React from "react";

import "./LicenseActivation.scss";

const LicenseActivation = () => {
  return (
    <div className="license">
      <h3>Your license has expired</h3>
      <a href={`https://azwgroup.com/renew-saas-license/`} target="__blank">
        <button type="button">Renew License</button>
      </a>
    </div>
  );
};

export default LicenseActivation;
