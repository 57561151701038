import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { edit_class_stream } from "../../../../../redux/actions/course";
import { START_LOADING } from "../../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";

const EditClassStream = (props) => {
  const {
    openEditClassStream,
    currentClassStream,
    class_levels,
    teachers,
    loading,
    userId,
  } = props;
  const {
    setOpenEditClassStream,
    setCurrentClassStream,
    startLoading,
    editClassStream,
  } = props;

  const { name, level, class_teacher } = currentClassStream;

  const handleChange = (e) => {
    setCurrentClassStream({
      ...currentClassStream,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    const body = {
      ...currentClassStream,
    };
    editClassStream(userId, body);
  };

  return (
    <MinDialog isOpen={openEditClassStream}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit class stream details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Class Stream Name</label>
          <input type="text" name="name" onChange={handleChange} value={name} />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Class Level</label>
          <select name="level" onChange={handleChange} value={level}>
            {class_levels?.map((level) => (
              <option value={level?.id} key={level?.id}>
                {level?.name}
              </option>
            ))}
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Class Teacher</label>
          <select
            name="class_teacher"
            onChange={handleChange}
            value={class_teacher}
          >
            <option value="" disabled>
              --select class teacher--
            </option>
            {teachers?.map((teacher) => (
              <option value={teacher?.id}>
                {teacher?.first_name} {teacher?.last_name} {teacher?.surname}
              </option>
            ))}
          </select>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditClassStream(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    class_levels: state.course?.class_levels,
    teachers: state.course?.teachers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editClassStream: (userId, body) =>
      dispatch(edit_class_stream(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditClassStream);
