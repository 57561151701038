import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { showError } from "../../../redux/actions/shared";
import API from "../../../shared/axios";

import "./Footer.scss";

const Footer = () => {
  const [licenseDetails, setLicenseDetails] = useState({});

  // useEffect(() => {
  //   const fetchLicenseDetails = async () => {
  //     await API.get(`/api/core/get-footer-license-details/`).then((res) => {
  //       setLicenseDetails(res?.data?.license_details);
  //     });
  //   };
  //   fetchLicenseDetails()
  //     .catch((err) => showError(err))
  //     .finally(() => {});
  // }, []);

  return (
    <div className="footer">
      {/* <h4>
        <span>
          License Expiry Date:
          <span className="license__expiry">
            {" "}
            {moment(licenseDetails?.expiry_date).format("LLL")}{" "}
          </span>
        </span>
      </h4> */}
      {/* <p>
        Grace period for renewal after expiry:{" "}
        <span className="license__expiry">5 days</span>
      </p> */}
      <p>
        System offered by: <a href="https://azwgroup.com">AZW Group</a>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
