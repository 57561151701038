import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import StockReportTemplate from "./components/StockReportTemplate";
import { ifEmpty } from "../../../../shared/sharedFunctions";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";

const StockReports = (props) => {
  const { openStockReports, userId, loading } = props;
  const { setOpenStockReports, startLoading, stopLoading } = props;

  const [reportFilters, setReportFilters] = useState({
    report_type: "",
    report_date: "",
  });

  const { report_type, report_date } = reportFilters;
  const [openStockReportTemplate, setOpenStockReportTemplate] = useState(false);
  const [reportData, setReportData] = useState({});

  const handleChange = (e) => {
    setReportFilters({ ...reportFilters, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (ifEmpty(reportFilters)) {
      return window.alert("Indicate the report type and date");
    }
    startLoading();
    await API.post(
      `/api/store/admin/generate-stock-report/${userId}/`,
      reportFilters
    )
      .then((res) => {
        setReportData(res?.data);
        setOpenStockReportTemplate(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog isOpen={openStockReports}>
        <div class="dialog" id={loading ? "formSubmitting" : ""}>
          <h3>Generate stock report</h3>
          <div class="dialog__rowSingleItem">
            <label for="">Report Type</label>
            <select
              name="report_type"
              onChange={handleChange}
              value={report_type}
            >
              <option value="" disabled selected>
                --select--
              </option>
              <option value="stock_in">Stock In Report</option>
              <option value="stock_out">Stock Out Report</option>
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div class="dialog__rowSingleItem">
            <label for="">Report Date</label>
            <input
              type="date"
              name="report_date"
              onChange={handleChange}
              value={report_date}
            />
          </div>
          <div class="form__Buttons">
            <button type="button" onClick={() => setOpenStockReports(false)}>
              Close
            </button>
            <button type="button" onClick={handleSubmit}>
              Generate
            </button>
          </div>
        </div>
      </MinDialog>
      {/* child components */}
      {openStockReportTemplate && (
        <StockReportTemplate
          openStockReportTemplate={openStockReportTemplate}
          setOpenStockReportTemplate={setOpenStockReportTemplate}
          report_type={
            report_type === "stock_in" ? "Stock In Report" : "Stock Out Report"
          }
          report_date={report_date}
          reportData={reportData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockReports);
