import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_offline_exams } from "../../../../redux/actions/exam";
import {
  START_LOADING,
  STOP_LOADING,
  UPDATE_OFFLINE_EXAM,
} from "../../../../redux/actions/types";

import { get_school_terms } from "../../../../redux/actions/course";
import { showError } from "../../../../redux/actions/shared";
import API from "../../../../shared/axios";
import AddOfflineExam from "./components/AddOfflineExam";
import EditOfflineExam from "./components/EditOfflineExam";
import MeritListAction from "./components/MeritListAction";

const OfflineExams = (props) => {
  const { offlineExams, loading, userId, school_terms } = props;
  const {
    startLoading,
    getOfflineExams,
    getSchoolTerms,
    stopLoading,
    updateOfflineExam,
  } = props;
  const [openAddOfflineExam, setOpenAddOfflineExam] = useState(false);
  const [openEditOfflineExam, setOpenEditOfflineExam] = useState(false);
  const [currentOfflineExam, setCurrentOfflineExam] = useState({});
  const [openMeritListAction, setOpenMeritListAction] = useState(false);

  useEffect(() => {
    if (userId && offlineExams?.length === 0) {
      startLoading();
      getOfflineExams(userId);
    }
  }, [startLoading, userId, getOfflineExams, offlineExams?.length]);

  // useEffect to get created terms
  useEffect(() => {
    if (school_terms?.length === 0 && userId) {
      startLoading();
      getSchoolTerms(userId);
    }
  }, [getSchoolTerms, userId, school_terms?.length, startLoading]);

  const handleOpenEditOfflineExam = (offline_exam) => {
    setCurrentOfflineExam(offline_exam);
    setOpenEditOfflineExam(true);
  };

  const handleOpenMeritListAction = (offline_exam) => {
    setCurrentOfflineExam(offline_exam);
    setOpenMeritListAction(true);
  };

  const handleFinalizeResults = async (e, examId) => {
    e.preventDefault();
    if (
      window.confirm(
        "This will compute the overall grades for all students. The process will only work if all the subject results have been updated. This will take a few minutes to complete. Press ok to continue"
      )
    ) {
      startLoading();
      await API.get(
        `/api/exam/finalize-offline-results-grading/${userId}/${examId}/`
      )
        .then((res) => {
          updateOfflineExam(res?.data?.updated_offline_exam);
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__parentHeader">
          <button type="button" onClick={() => setOpenAddOfflineExam(true)}>
            Add Offline Exam
          </button>
          <h3>Maintain offline exams</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        {offlineExams?.length > 0 ? (
          <table className="table__listing">
            <tr className="table__listingHeader">
              <th>Exam Name</th>
              <th>School Term</th>
              <th>Exam Type</th>
              <th>Results Status</th>
              <th>Edit</th>
              <th>Merit List</th>
            </tr>
            {offlineExams?.map((exam) => (
              <tr className="table__listingItem" key={exam?.id}>
                <td>{exam?.name}</td>
                <td>{exam?.school_term_name}</td>
                <td>
                  {exam?.exam_type === "end_year" && <span>End year exam</span>}
                  {exam?.exam_type === "intermediate_exam" && (
                    <span>Intermediate exam</span>
                  )}
                </td>
                <td>
                  {exam?.update_complete ? (
                    <span className="purple bd">Completed</span>
                  ) : (
                    <span
                      className="dodgerblue bd button__sp"
                      onClick={(e) => handleFinalizeResults(e, exam?.id)}
                    >
                      Click To Finalize
                    </span>
                  )}
                </td>
                <td
                  className="dodgerblue bd button__sp"
                  onClick={() => handleOpenEditOfflineExam(exam)}
                >
                  Edit
                </td>
                <td
                  className="dodgerblue bd button__sp"
                  onClick={() => handleOpenMeritListAction(exam)}
                >
                  view
                </td>
              </tr>
            ))}
          </table>
        ) : (
          <h4 className="not__available">No offline exams</h4>
        )}
      </div>
      {/* child components */}

      {openAddOfflineExam && (
        <AddOfflineExam
          openAddOfflineExam={openAddOfflineExam}
          setOpenAddOfflineExam={setOpenAddOfflineExam}
        />
      )}
      {openEditOfflineExam && (
        <EditOfflineExam
          openEditOfflineExam={openEditOfflineExam}
          setOpenEditOfflineExam={setOpenEditOfflineExam}
          currentOfflineExam={currentOfflineExam}
          setCurrentOfflineExam={setCurrentOfflineExam}
        />
      )}
      {openMeritListAction && (
        <MeritListAction
          openMeritListAction={openMeritListAction}
          setOpenMeritListAction={setOpenMeritListAction}
          currentOfflineExam={currentOfflineExam}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    offlineExams: state?.exam?.offlineExams,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    school_terms: state.course?.school_terms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOfflineExams: (userId) => dispatch(get_offline_exams(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    getSchoolTerms: (userId) => dispatch(get_school_terms(userId)),
    updateOfflineExam: (updated_offline_exam) =>
      dispatch({ type: UPDATE_OFFLINE_EXAM, payload: updated_offline_exam }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfflineExams);
