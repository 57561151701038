import { useRef } from "react";
import { formatWithCommas } from "../../../../../../shared/sharedFunctions";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import MediumDialog from "../../../../../../components/common/MediumDialog";

import "./StatementTemplate.scss";
import ReportHeader from "../../../../../../shared/ReportHeader/ReportHeader";

// student template here
const StatementTemplate = (props) => {
  const {
    openStatementTemplate,
    setOpenStatementTemplate,
    start_date,
    end_date,
  } = props;
  const {
    transactions,
    full_names,
    admission_number,
    opening_balance,
    closing_balance,
  } = props?.statementDetails;

  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: `Fees statement`,
  });

  return (
    <MediumDialog isOpen={openStatementTemplate}>
      <div className="dialog">
        <table
          ref={printArea}
          className="report__statement"
          border="1"
          rules="rows"
          id="student_statement"
        >
          <ReportHeader />
          <tbody>
            <tr>
              <td className="report__title" colSpan="100%">
                {admission_number} - {full_names} Fees Statement (
                {moment(start_date).format("LL")} to{" "}
                {moment(end_date).format("LL")})
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th className="bbottom purple tl">Date</th>
              <th className="bbottom purple tl">Details</th>
              <th className="bbottom purple">Debit</th>
              <th className="bbottom purple">Credit</th>
            </tr>
          </tbody>
          <tbody>
            <tr style={{ border: "none" }}>
              <th>Opening balance as at {moment(start_date).format("LL")}</th>
              <th></th>
              <th>
                {opening_balance?.type === "Debit" && (
                  <span>{formatWithCommas(opening_balance?.amount)}</span>
                )}
              </th>
              <th>
                {opening_balance?.type === "Credit" && (
                  <span>{formatWithCommas(opening_balance?.amount)}</span>
                )}
              </th>
            </tr>
            {transactions?.map((item) => (
              <tr key={item?.id}>
                <td>{moment(item?.created_on).format("LLL")}</td>
                <td>{item?.details}</td>
                <td>
                  {item?.entry_type === "Debit" &&
                    formatWithCommas(item?.amount)}
                </td>
                <td>
                  {item?.entry_type === "Credit" &&
                    item?.amount?.toLocaleString()}
                </td>
              </tr>
            ))}
            <br />

            {/* <tr style={{ border: "none" }}>
                <th>Totals</th>
                <th></th>
                <th>{formatWithCommas(total_debits)}</th>
                <th>{formatWithCommas(total_credits)}</th>
              </tr> */}
            <tr style={{ border: "none" }}>
              <th>Closing Balance as at {moment(end_date).format("LL")}</th>
              <th></th>
              <th>
                {closing_balance?.type === "Debit" && (
                  <span>{formatWithCommas(closing_balance?.amount)}</span>
                )}
              </th>
              <th>
                {closing_balance?.type === "Credit" && (
                  <span>{formatWithCommas(closing_balance?.amount)}</span>
                )}
              </th>
            </tr>
          </tbody>
        </table>
        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenStatementTemplate(false)}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print/Save
          </button>
        </div>
        <div class="export_to_excel_button">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="student_statement"
            filename="student_statement"
            sheet="student_statement"
            buttonText="Export To Excel"
          />
        </div>
      </div>
    </MediumDialog>
  );
};

export default StatementTemplate;
