import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../components/common/MinDialog";
import { get_offline_exams } from "../../../redux/actions/exam";
import { showError } from "../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";
import API from "../../../shared/axios";

import CircularProgress from "@mui/material/CircularProgress";

import StudentResultsTemplate from "../../../components/shared/StudentResultsTemplate/StudentResultsTemplate";

const OfflineExamResultsReport = (props) => {
  const {
    openOfflineExamResultsReport,
    parentsChildren,
    offlineExams,
    userId,
    loading,
  } = props;
  const {
    setOpenOfflineExamResultsReport,
    startLoading,
    getOfflineExams,
    stopLoading,
  } = props;

  const [openStudentResultsTemplate, setOpenStudentResultsTemplate] =
    useState(false);

  const [studentExamFilters, setStudentExamFilters] = useState({
    admission_number: "",
    offline_exam: "",
  });
  const [resultsData, setResultsData] = useState({});

  const { admission_number, offline_exam } = studentExamFilters;

  useEffect(() => {
    if (userId && offlineExams?.length === 0) {
      startLoading();
      getOfflineExams(userId);
    }
  }, [userId, getOfflineExams, startLoading, offlineExams?.length]);

  const handleChange = (e) => {
    setStudentExamFilters({
      ...studentExamFilters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(`/api/exam/get-student-offline-exam-results/${userId}/`, {
      ...studentExamFilters,
    })
      .then((res) => {
        setResultsData(res?.data?.student_exam_results_data);
        setOpenStudentResultsTemplate(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openOfflineExamResultsReport}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Generate student's report card</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Select student</label>
          <select
            name="admission_number"
            onChange={handleChange}
            value={admission_number}
          >
            <option value="" disabled selected>
              --select--
            </option>
            {parentsChildren?.map((child) => (
              <option value={child?.admission_number} key={child?.id}>
                {child?.name}
              </option>
            ))}
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Select Exam</label>
          <select
            name="offline_exam"
            onChange={handleChange}
            value={offline_exam}
          >
            <option value="" disabled selected>
              --select--
            </option>
            {offlineExams?.map((exam) => (
              <option value={exam?.id} key={exam?.id}>
                {exam?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenOfflineExamResultsReport(false)}
          >
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Generate
          </button>
        </div>
      </form>
      {/* child components */}
      {openStudentResultsTemplate && (
        <StudentResultsTemplate
          openStudentResultsTemplate={openStudentResultsTemplate}
          setOpenStudentResultsTemplate={setOpenStudentResultsTemplate}
          resultsData={resultsData}
        />
      )}
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    parentsChildren: state?.auth?.parentsChildren,
    offlineExams: state?.exam?.offlineExams,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOfflineExams: (userId) => dispatch(get_offline_exams(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfflineExamResultsReport);
