import React, { useEffect, useState } from "react";
import ExamPageContent from "./ExamPageContent";
import ExamPageHeader from "./ExamPageHeader";

import { connect } from "react-redux";

import "./StudentExamPage.scss";
import {
  SET_EXAM_PAGE,
  START_LOADING,
  STOP_LOADING,
} from "../../../redux/actions/types";
import API from "../../../shared/axios";
import { useParams } from "react-router-dom";
import { showError } from "../../../redux/actions/shared";

const StudentExamPage = (props) => {
  const { userId } = props;
  const { startLoading, stopLoading, setIsExamPage } = props;
  const { examId } = useParams();

  const [examDetails, setExamDetails] = useState({});
  const [studentAnswers, setStudentAnswers] = useState([]);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchExamData = async () => {
        const sessionTime = localStorage.getItem("sessionTime")
          ? localStorage.getItem("sessionTime")
          : null;
        const body = { examId, sessionTime };
        await API.post(
          `/api/exam/student/student-get-current-exam/${userId}/`,
          body
        ).then((res) => {
          setExamDetails(res?.data?.exam_details_data);
          setStudentAnswers(res.data?.exam_details_data?.student_questions);
        });
      };
      fetchExamData()
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  }, [examId, startLoading, stopLoading, userId]);

  useEffect(() => {
    setIsExamPage(true);
    // clean up function to set exam page to false so that the header and sidebar can be displayed
    return () => {
      setIsExamPage(false);
    };
  }, [setIsExamPage]);
  return (
    <div className="student__examPage">
      <ExamPageHeader
        examTitle={examDetails?.title}
        examRemainingTime={examDetails?.examRemainingTime}
        examSubject={examDetails?.subject}
      />
      <ExamPageContent
        examId={examId}
        examDetails={examDetails}
        studentAnswers={studentAnswers}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    setIsExamPage: (payload) => dispatch({ type: SET_EXAM_PAGE, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentExamPage);
