// import installed packages
import { useEffect, useState } from "react";
import { connect } from "react-redux";

// import styles
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
// import components/pages

// import redux API
import { admin_get_class_streams } from "../../../../redux/actions/course";
import { START_LOADING } from "../../../../redux/actions/types";

import AddClassLevel from "./components/AddClassLevel";

import AddClassStream from "./components/AddClassStream";
import EditClassLevel from "./components/EditClassLevel";
import EditClassStream from "./components/EditClassStream";

const ClassStreams = (props) => {
  const { class_levels, class_streams, loading, userId } = props; // get state from props
  const { startLoading, adminGetClassStreams } = props; // get dispatch from props

  const [openAddClassLevel, setOpenAddClassLevel] = useState(false);
  const [openAddClassStream, setOpenAddClassStream] = useState(false);
  const [openEditClassLevel, setOpenEditClassLevel] = useState(false);
  const [openEditClassStream, setOpenEditClassStream] = useState(false);
  const [currentClassLevel, setCurrentClassLevel] = useState({});
  const [currentClassStream, setCurrentClassStream] = useState({});

  // useEffect to get class levels and class streams
  useEffect(() => {
    if (userId && class_levels?.length === 0) {
      startLoading();
      adminGetClassStreams(userId);
    }
  }, [userId, startLoading, adminGetClassStreams, class_levels?.length]);

  const handleOpenEditClassLevel = (class_level) => {
    setCurrentClassLevel(class_level);
    setOpenEditClassLevel(true);
  };

  const handleOpenEditClassStream = (class_stream) => {
    setCurrentClassStream(class_stream);
    setOpenEditClassStream(true);
  };

  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <button
            type="button"
            className="add__button white"
            onClick={() => setOpenAddClassLevel(true)}
          >
            Add Class Level
          </button>
          <button
            type="button"
            className="add__button white"
            onClick={() => setOpenAddClassStream(true)}
          >
            Add Class Stream
          </button>

          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "0%" }}
            />
          )}
        </div>
        <div class="table__parentHeader">
          <h3>Existing Class streams</h3>
        </div>
        <table class="table__listing">
          <thead>
            <tr class="table__listingHeader">
              <th>Level</th>
              <th>Class Stream</th>
              <th>Class Teacher</th>
              <th>Students</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {class_streams?.map((item, index) => (
              <tr className="table__listingItem">
                <td>{item?.level_name}</td>
                <td>{item?.name}</td>
                <td>{item?.class_teacher_name}</td>
                <td>{item?.students_number}</td>
                <td
                  class="dodgerblue bd button__sp"
                  onClick={() => handleOpenEditClassStream(item)}
                >
                  Edit
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div class="table__parentHeader">
          <h3>Existing Class levels</h3>
        </div>
        {class_streams?.length > 0 ? (
          <table className="table__listing">
            <thead>
              <tr className="table__listingHeader">
                <th>Level Name/Number</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {class_levels?.map((level) => (
                <tr class="table__listingItem" key={level?.id}>
                  <td>{level?.name}</td>
                  <td
                    className="dodgerblue bd button__sp"
                    onClick={() => handleOpenEditClassLevel(level)}
                  >
                    Edit
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 className="not__available">No class streams available</h4>
        )}
      </div>
      {/* child components */}

      {openAddClassStream && (
        <AddClassStream
          openAddClassStream={openAddClassStream}
          setOpenAddClassStream={setOpenAddClassStream}
        />
      )}
      {openAddClassLevel && (
        <AddClassLevel
          openAddClassLevel={openAddClassLevel}
          setOpenAddClassLevel={setOpenAddClassLevel}
        />
      )}
      {openEditClassLevel && (
        <EditClassLevel
          openEditClassLevel={openEditClassLevel}
          setOpenEditClassLevel={setOpenEditClassLevel}
          currentClassLevel={currentClassLevel}
          setCurrentClassLevel={setCurrentClassLevel}
        />
      )}
      {openEditClassStream && (
        <EditClassStream
          openEditClassStream={openEditClassStream}
          setOpenEditClassStream={setOpenEditClassStream}
          currentClassStream={currentClassStream}
          setCurrentClassStream={setCurrentClassStream}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    class_streams: state.course?.class_streams,
    class_levels: state.course?.class_levels,
    userId: state.auth.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    adminGetClassStreams: (userId) => dispatch(admin_get_class_streams(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassStreams);
