// import installed packages
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
// import styles
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import API from "../../shared/axios";
import {
  ifEmpty,
  resetFormValues,
  resetCheckedValues,
} from "../../shared/sharedFunctions";
// import components/pages
import MinDialog from "../common/MinDialog";
// import redux API
import { SET_PERMISSIONS, START_LOADING } from "../../redux/actions/types";
import { create_group } from "../../redux/actions/auth";

const AddGroup = (props) => {
  const dispatch = useDispatch();
  const { openAddGroup, available_permissions, loading, userId } = props; // get state from props
  const { setOpenAddGroup, createGroup, startLoading } = props; // get dispatch actions from props

  const [newGroup, setNewGroup] = useState({
    name: "",
    about: "",
  });
  const [groupPermissions, setGroupPermissions] = useState({});

  // destructuring
  const { name, about } = newGroup;

  // useEffect to get permissions available and store in redux for reuse
  useEffect(() => {
    if (available_permissions?.length === 0 && userId && openAddGroup) {
      const fetchPermissions = async () => {
        const res = await API.get(`/api/permission/get-permissions/${userId}/`);
        dispatch({ type: SET_PERMISSIONS, payload: res.data?.permissions });
      };
      fetchPermissions().catch((err) => {
        console.log(err.response);
      });
    }
  }, [available_permissions?.length, userId, dispatch, openAddGroup]);

  const closeAddGroup = () => {
    setOpenAddGroup(false);
    resetForm();
  };

  const handleChange = (e) => {
    setNewGroup({ ...newGroup, [e.target.name]: e.target.value });
  };

  // function to handle permissions change
  const handlePermissionsChange = (e) => {
    setGroupPermissions({
      ...groupPermissions,
      [e.target.name]: e.target.checked,
    });
  };

  // functioj to clear form details
  const resetForm = () => {
    resetFormValues(newGroup);
    resetCheckedValues(groupPermissions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(newGroup)) {
      return alert("Group name and about fields are required");
    }
    startLoading();

    createGroup({ ...newGroup, groupPermissions }, userId, resetForm);
  };

  return (
    <MinDialog isOpen={openAddGroup}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter group details</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Name</label>
          <input type="text" name="name" value={name} onChange={handleChange} />
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">About</label>
          <textarea name="about" value={about} onChange={handleChange} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "43%" }}
          />
        )}
        {/* to ensure permissions are only shown when name is not empty and also permissions reset when a group is created */}
        {name.trim() !== "" && (
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Group Permissions</label>
            <div className="dialog__checkBoxListing">
              {available_permissions?.map((permission) => (
                <div
                  className="dialog__checkBoxListingItem"
                  key={permission?.codename}
                >
                  <input
                    type="checkbox"
                    name={permission?.codename}
                    checked={groupPermissions?.codename}
                    onChange={handlePermissionsChange}
                  />
                  <label htmlFor="">{permission?.name}</label>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="form__Buttons">
          <button onClick={closeAddGroup} type="button">
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared.loading,
    available_permissions: state.auth.permissions,
    userId: state.auth.user.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createGroup: (newGroup, userId, resetForm) =>
      dispatch(create_group(newGroup, userId, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);
