// import installed packages
import { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";

// import styles
import "./App.scss";
// import material ui items
// import redux API
import { get_user, get_valid_license } from "./redux/actions/auth";

import Footer from "./components/common/Footer/Footer";
import Header from "./components/common/Header/Header";
import Sidebar from "./components/common/Sidebar/Sidebar";
import ForgotPassword from "./components/users/ForgotPassword";
import Login from "./components/users/Login";
import ReactivateAccount from "./components/users/ReactivateAccount";
import Dorms from "./pages/Accomodation/Dorms";
import Transactions from "./pages/Finance/Transactions/Transactions";
import LicenseActivation from "./pages/Home/LicenseActivation";
import AllStaff from "./pages/Human Resource/AllStaff/AllStaff";
import PAYEBrackets from "./pages/Human Resource/PAYEBrackets/PAYEBrackets";
import SalaryDeductions from "./pages/Human Resource/SalaryDeductions/SalaryDeductions";
import SalaryEarnings from "./pages/Human Resource/SalaryEarnings/SalaryEarnings";
import BookBorrows from "./pages/Librarian/BookBorrows/BookBorrows";
import Books from "./pages/Librarian/Books/Books";
import BooksLost from "./pages/Librarian/BooksLost/BooksLost";
import LostBookCharges from "./pages/Librarian/LostBookCharges/LostBookCharges";
import Stocks from "./pages/Store/Stocks";
import StudentLiveClasses from "./pages/Student/StudentLiveClasses/StudentLiveClasses";
import UpgradeStudents from "./pages/Teacher/Exams/UpgradeStudents/UpgradeStudents";

// import shared/global items
import StaffAttendance from "./pages/Human Resource/StaffAttendance/StaffAttendance";
import AdminOfflineExams from "./pages/SchoolAdmin/Academic/AdminOfflineExams/AdminOfflineExams";
import StudentAssignments from "./pages/Student/StudentAssignments/StudentAssignments";
import Assignments from "./pages/Teacher/Exams/Assignments/Assignments";
import OfflineExams from "./pages/Teacher/Exams/OfflineExams/OfflineExams";
import PrivateRoute from "./shared/PrivateRoute";

// import components/pages
import ActivateAccount from "./pages/ActivateAccount/ActivateAccount";
import LiveMeeting from "./pages/common/LiveMeeting/LiveMeeting";
import Accounting from "./pages/Finance/Accounting/Accounting";
import Reports from "./pages/Finance/Reports/Reports";
import SchoolFees from "./pages/Finance/SchoolFees/SchoolFees";
import Home from "./pages/Home/Home";
import Payrolls from "./pages/Human Resource/Payrolls/Payrolls";
import PendingLeaveApplications from "./pages/Human Resource/PendingLeaveApplications/PendingLeaveApplications";
import NotFound from "./pages/NotFound/NotFound";
import ParentReports from "./pages/Parent/ParentReports";
import Profile from "./pages/Profile/Profile";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import ClassStreams from "./pages/SchoolAdmin/Academic/ClassStreams/ClassStreams";
import SchoolTerms from "./pages/SchoolAdmin/Academic/SchoolTerms/SchoolTerms";
import Subjects from "./pages/SchoolAdmin/Academic/Subjects/Subjects";
import TimeTables from "./pages/SchoolAdmin/Academic/TimeTables/TimeTables";
import SMS from "./pages/SchoolAdmin/Communication/SMS";
import DeactivatedUsers from "./pages/SchoolAdmin/Users/DeactivatedUsers/DeactivatedUsers";
import OtherStaff from "./pages/SchoolAdmin/Users/OtherStaff/OtherStaff";
import Parents from "./pages/SchoolAdmin/Users/Parents/Parents";
import Students from "./pages/SchoolAdmin/Users/Students/Students";
import Teachers from "./pages/SchoolAdmin/Users/Teachers/Teachers";
import Dashboard from "./pages/Staff/Dashboard";
import StudentExamPage from "./pages/Student/StudentExamPage/StudentExamPage";
import StudentExams from "./pages/Student/StudentExams/StudentExams";
import StudentReports from "./pages/Student/StudentReports/StudentReports";
import UserGroups from "./pages/SystemAdmin/UserGroups";
import Users from "./pages/SystemAdmin/Users/Users";
import Attendance from "./pages/Teacher/Attendance/Attendance";
import OnlineExams from "./pages/Teacher/Exams/OnlineExams/OnlineExams";
import TeacherLiveClasses from "./pages/Teacher/TeacherLiveClasses/TeacherLiveClasses";
import TeacherSubjects from "./pages/Teacher/TeacherSubjects/TeacherSubjects";

function App(props) {
  const session_cookie = localStorage.getItem("session_cookie");

  const navigate = useNavigate();

  const {
    loggedIn,
    isExamPage,
    is_staff,
    users_groups,
    loginForm,
    forgotPasswordForm,
    resendActivationForm,
    userId,
    licenseDetails,
  } = props; //get state from props
  const { getUserData, getValidLicense } = props;

  useEffect(() => {
    // get user on page refresh
    if (session_cookie) {
      getUserData();
    }
  }, [getUserData, session_cookie]);

  // get the license details
  // useEffect(() => {
  //   if (userId) {
  //     getValidLicense(userId);
  //   }
  // }, [getValidLicense, userId]);

  // verify license details
  // useEffect(() => {
  //   if (userId) {
  //     if (licenseDetails?.checked && !licenseDetails?.is_valid) {
  //       navigate("/home/license-activation/", { replace: true });
  //     }
  //   }
  // }, [userId, licenseDetails?.checked, licenseDetails, navigate]);

  return (
    <div id="body-pd" className={loggedIn && "body-pd"}>
      {!isExamPage && (
        <>
          <Header />
          {loggedIn && <Sidebar />}
        </>
      )}
      <div className="body-section" id="body-section">
        <Routes>
          {/* unauthenticated routes */}
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/user/password-reset/:password_token/"
            element={<ResetPasswordConfirm />}
          />
          <Route
            exact
            path="/user/activate/:activation_token/"
            element={<ActivateAccount />}
          />
          {/* authenticated routes */}
          {/* system admin/school admin links */}
          <Route exact path="/" element={<PrivateRoute />}>
            <Route
              exact
              path="/system-admin/user-groups/"
              element={<UserGroups />}
            />
            <Route exact path="/system-admin/users/" element={<Users />} />

            {["School Admin"]?.some((allowed_group) =>
              users_groups?.includes(allowed_group)
            ) && (
              <>
                <Route
                  exact
                  path="/school-admin/subjects/"
                  element={<Subjects />}
                />
                <Route
                  exact
                  path="/school-admin/class-streams/"
                  element={<ClassStreams />}
                />
                <Route
                  exact
                  path="/school-admin/school-terms/"
                  element={<SchoolTerms />}
                />
                <Route
                  exact
                  path="/school-admin/teachers/"
                  element={<Teachers />}
                />
                <Route
                  exact
                  path="/school-admin/students/"
                  element={<Students />}
                />
                <Route
                  exact
                  path="/school-admin/parents/"
                  element={<Parents />}
                />
                <Route
                  exact
                  path="/school-admin/other-staff/"
                  element={<OtherStaff />}
                />
                <Route
                  exact
                  path="/school-admin/deactivated-users/"
                  element={<DeactivatedUsers />}
                />
                <Route
                  exact
                  path="/school-admin/offline-exams/"
                  element={<AdminOfflineExams />}
                />
                <Route
                  exact
                  path="/school-admin/timetables/"
                  element={<TimeTables />}
                />
                <Route exact path="/communication/sms/" element={<SMS />} />
              </>
            )}
            {users_groups?.includes("Teacher") && (
              <>
                <Route
                  exact
                  path="/teacher/assignments/"
                  element={<Assignments />}
                />
                <Route
                  exact
                  path="/teacher/online-exams/"
                  element={<OnlineExams />}
                />
                <Route
                  exact
                  path="/teacher/offline-exams/"
                  element={<OfflineExams />}
                />
                <Route
                  exact
                  path="/teacher/teacher-live-classes/"
                  element={<TeacherLiveClasses />}
                />
                <Route
                  exact
                  path="/teacher/attendance/"
                  element={<Attendance />}
                />
                <Route
                  exact
                  path="/teacher/upgrade-students/"
                  element={<UpgradeStudents />}
                />
                <Route
                  exact
                  path="/teacher/teacher-subjects/"
                  element={<TeacherSubjects />}
                />
              </>
            )}
            {users_groups?.includes("Student") && (
              <>
                <Route
                  exact
                  path="/student/exams/"
                  element={<StudentExams />}
                />
                <Route
                  exact
                  path="/student/online-exam/:examId/"
                  element={<StudentExamPage />}
                />
                <Route
                  exact
                  path="/student/reports/"
                  element={<StudentReports />}
                />
                <Route
                  exact
                  path="/student/assignments/"
                  element={<StudentAssignments />}
                />
                <Route
                  exact
                  path="/student/live-classes/"
                  element={<StudentLiveClasses />}
                />
              </>
            )}
            {users_groups?.includes("Parent") && (
              <Route
                exact
                path="/parent/reports/"
                element={<ParentReports />}
              />
            )}
            {["School Admin", "Accountant"]?.some((allowed_group) =>
              users_groups?.includes(allowed_group)
            ) && (
              <>
                <Route
                  exact
                  path="/finance/school-fees/"
                  element={<SchoolFees />}
                />
                <Route
                  exact
                  path="/finance/accounting/"
                  element={<Accounting />}
                />
                <Route exact path="/finance/reports/" element={<Reports />} />
                <Route
                  exact
                  path="/finance/transactions/"
                  element={<Transactions />}
                />
              </>
            )}
            {/* routes accessible only by staff */}
            {is_staff && (
              <>
                <Route exact path="/staff/dashboard/" element={<Dashboard />} />
              </>
            )}
            {/* routes accessible only by HR group */}
            {["Human Resource", "School Admin"]?.some((allowed_group) =>
              users_groups?.includes(allowed_group)
            ) && (
              <>
                <Route
                  exact
                  path="/human-resource/all-staff/"
                  element={<AllStaff />}
                />
                <Route
                  exact
                  path="/human-resource/salary-earnings/"
                  element={<SalaryEarnings />}
                />
                <Route
                  exact
                  path="/human-resource/salary-deductions/"
                  element={<SalaryDeductions />}
                />
                <Route
                  exact
                  path="/human-resource/leave-applications/"
                  element={<PendingLeaveApplications />}
                />
                <Route
                  exact
                  path="/human-resource/paye-brackets/"
                  element={<PAYEBrackets />}
                />
                <Route
                  exact
                  path="/human-resource/payrolls/"
                  element={<Payrolls />}
                />
              </>
            )}
            {/* routes accessible by either HR or receptionist */}
            {["Human Resource", "Receptionist", "School Admin"]?.some(
              (allowed_group) => users_groups?.includes(allowed_group)
            ) && (
              <>
                <Route
                  exact
                  path="/human-resource/staff-attendance/"
                  element={<StaffAttendance />}
                />
              </>
            )}
            {["Accomodation", "School Admin"]?.some((allowed_group) =>
              users_groups?.includes(allowed_group)
            ) && (
              <>
                <Route exact path="/accomodation/dorms/" element={<Dorms />} />
              </>
            )}
            {["Kitchen", "School Admin"]?.some((allowed_group) =>
              users_groups?.includes(allowed_group)
            ) && (
              <>
                <Route exact path="/store/stocks/" element={<Stocks />} />
              </>
            )}
            {["Librarian", "School Admin"]?.some((allowed_group) =>
              users_groups?.includes(allowed_group)
            ) && (
              <>
                <Route exact path="/library/books/" element={<Books />} />
                <Route
                  exact
                  path="/library/book-borrows/"
                  element={<BookBorrows />}
                />
                <Route
                  exact
                  path="/library/books-lost/"
                  element={<BooksLost />}
                />
                <Route
                  exact
                  path="/library/lost-book-charges/"
                  element={<LostBookCharges />}
                />
              </>
            )}

            <Route
              exact
              path="/live-class/:liveClassId/"
              element={<LiveMeeting />}
            />
            <Route exact path="/profile" element={<Profile />} />
            <Route
              exact
              path="/home/license-activation/"
              element={<LicenseActivation />}
            />
          </Route>

          <Route element={<NotFound />} path="*" />
        </Routes>
      </div>
      {!isExamPage && (
        <>
          <Footer />
        </>
      )}
      {loginForm && <Login />}
      {forgotPasswordForm && <ForgotPassword />}
      {resendActivationForm && <ReactivateAccount />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile_type: state.auth.user.profile_type,
    is_staff: state.auth.user.is_staff,
    users_groups: state.auth.user.users_groups,
    loggedIn: state.auth?.loggedIn,
    isExamPage: state?.exam?.isExamPage,
    loginForm: state.auth?.loginForm,
    forgotPasswordForm: state.auth?.forgotPasswordForm,
    resendActivationForm: state.auth?.resendActivationForm,
    userId: state?.auth?.user?.id,
    licenseDetails: state?.auth?.licenseDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: () => dispatch(get_user()),
    // getValidLicense: (userId) => dispatch(get_valid_license(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
