import React, { useState } from "react";
import MaxDialog from "../../../../components/common/MaxDialog";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import { connect } from "react-redux";
import { ifEmpty, maxDate } from "../../../../shared/sharedFunctions";
import { showError } from "../../../../redux/actions/shared";
import API from "../../../../shared/axios";
import { teacher_mark_attendance } from "../../../../redux/actions/course";

const EditAttendance = (props) => {
  const { openEditAttendance, class_streams, userId } = props;
  const {
    startLoading,
    stopLoading,
    teacherMarkAttendance,
    setOpenEditAttendance,
  } = props;

  const [reportFilters, setReportFilters] = useState({
    class_stream: "",
    report_date: "",
  });
  const { class_stream, report_date } = reportFilters;

  const [streamStudents, setStreamStudents] = useState([]);
  const [attendanceId, setAttendanceId] = useState("");

  const handleChange = (e) => {
    setReportFilters({ ...reportFilters, [e.target.name]: e.target.value });
  };

  const getAttendanceDetails = (e) => {
    e.preventDefault();
    if (ifEmpty(reportFilters)) {
      return window.alert("Please indicate class stream and report date");
    }
    startLoading();
    const fetchAttendanceData = async () => {
      const body = {
        ...reportFilters,
      };
      await API.post(
        `/api/course/teacher/generate-attendance-report/${userId}/`,
        body
      ).then((res) => {
        setStreamStudents(res?.data?.attendance_report_data?.stream_students);
        setAttendanceId(res?.data?.attendance_report_data?.id);
      });
    };
    fetchAttendanceData()
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleStudentsTicking = (e, studentIndex) => {
    const arrayToMutate = [...streamStudents];

    arrayToMutate[studentIndex] = {
      ...arrayToMutate[studentIndex],
      is_present: e.target.checked,
    };

    setStreamStudents(arrayToMutate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    teacherMarkAttendance(userId, {
      streamStudents,
      attendanceType: "editAttendance",
      attendanceId,
    });
  };

  return (
    <MaxDialog isOpen={openEditAttendance} maxWidth="1200px" maxHeight="90vh">
      <div className="dialog">
        <h3>Choose class stream and date to view and edit attendance</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Class Stream</label>
            <select
              name="class_stream"
              onChange={handleChange}
              value={class_stream}
            >
              <option value="" selected disabled>
                --select--
              </option>
              {class_streams?.map((stream) => (
                <option value={stream?.id} key={stream?.id}>
                  {stream?.name}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label htmlFor="">Date</label>
            <input
              type="date"
              name="report_date"
              onChange={handleChange}
              value={report_date}
              max={maxDate()}
            />
          </span>
        </div>
        <button
          type="button"
          className="add__button"
          style={{
            maxWidth: "200px",
            marginLeft: "20px",
            justifySelf: "center",
          }}
          onClick={getAttendanceDetails}
        >
          Get Attendance
        </button>
        {streamStudents?.length > 0 && (
          <table className="table__listing">
            <tr className="table__listingHeader">
              <th>Admission Number</th>
              <th>Student Name</th>
              <th className="tc">Present</th>
            </tr>
            {streamStudents?.map((student, index) => (
              <tr className="table__listingItem" key={student?.id}>
                <td>{student?.admission_number}</td>
                <td>{student?.name}</td>
                <td className="tc">
                  <input
                    type="checkbox"
                    name=""
                    onClick={(e) => handleStudentsTicking(e, index)}
                    value={student?.id}
                    checked={student?.is_present}
                  />
                </td>
              </tr>
            ))}
          </table>
        )}
        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenEditAttendance(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    class_streams: state?.auth?.user?.class_streams,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    teacherMarkAttendance: (userId, body) =>
      dispatch(teacher_mark_attendance(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAttendance);
