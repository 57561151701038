// import installed packages
import { useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import styles
// import material ui items
// import shared/global items
// import components/pages
import MaxDialog from "../../../../../components/common/MaxDialog";

// import redux API

const ProfitLoss = (props) => {
  const { schoolName, openReportTemplate, start_date, end_date, reportData } =
    props; // get state from props
  const { setOpenReportTemplate } = props; // get dispatch actions from props

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Profit and Loss Report",
  });

  const handleClose = () => {
    setOpenReportTemplate(false);
  };

  return (
    <MaxDialog isOpen={openReportTemplate} maxWidth="1200px" maxHeight="90vh">
      <div className="dialog">
        <table
          className="report__statement"
          border="1"
          rules="rows"
          ref={printArea}
          id="profit_loss"
        >
          <thead>
            <tr>
              <th colSpan="8" className="dodgerblue">
                {schoolName}
              </th>
            </tr>
            <tr>
              <th colSpan="8" className="dodgerblue">
                Profit and Loss
              </th>
            </tr>
            <tr>
              <th colSpan="8" className="dodgerblue">
                From {moment(start_date).format("LL")} to{" "}
                {moment(end_date).format("LL")}
              </th>
            </tr>
            <tr>
              <th className="bbottom purple tl">Details</th>
              <th className="bbottom purple">Amount (Kshs)</th>
            </tr>
          </thead>
          <tbody>
            <br />
            {/* we show the income ledgers first using the LedgersLooping component */}
            <LedgersLooping
              parentLedgersListing={reportData?.report_data_income}
            />
            <br />
            {/* we show the income totals here */}
            <tr className="parent_ledger_group">
              <th>Total Income</th>
              <th className="amount">
                {reportData?.overall_totals?.overallIncomeTotals?.toLocaleString()}
              </th>
            </tr>
            <br />

            {/* we show the expenses ledgers using the LedgersLooping component */}
            <LedgersLooping
              parentLedgersListing={reportData?.report_data_expense}
            />
            <br />
            {/* we show the expense totals here */}
            <tr>
              <th className="">Total Expenses</th>
              <th className="amount">
                {reportData?.overall_totals?.overallExpenseTotals?.toLocaleString()}
              </th>
            </tr>
            <br />
            {/* finally we show the net profit here */}
            <tr className="parent_ledger_groups_totals">
              <th>Net Profit/Loss</th>
              <th className="amount">
                {reportData?.overall_totals?.profitOrLoss?.toLocaleString()}
              </th>
            </tr>
          </tbody>
        </table>
        <div className="form__Buttons">
          <button onClick={handleClose} type="button">
            Close
          </button>
          <button type="submit" onClick={handlePrint}>
            Print
          </button>
        </div>
        <div class="export_to_excel_button">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="profit_loss"
            filename="profit_loss"
            sheet="profit_loss"
            buttonText="Export To Excel"
          />
        </div>
      </div>
    </MaxDialog>
  );
};

export default ProfitLoss;

// we reuse this to loop for incomes and expenses

const LedgersLooping = ({ parentLedgersListing }) => {
  return (
    <>
      {parentLedgersListing?.map((parentLedgerGroup) => (
        <>
          <tr className="parent_ledger_group">
            <th className="" colSpan="2">
              {parentLedgerGroup?.name}
            </th>
          </tr>
          {parentLedgerGroup?.ledgerGroupsListing?.map((ledgerGroup) => (
            <>
              <tr className="ledger_group">
                <th>{ledgerGroup?.name}</th>
              </tr>
              {ledgerGroup?.ledgersListing?.map((ledger) => (
                <>
                  <tr className="ledger">
                    <td>{ledger?.name}</td>
                    <td className="amount">
                      {ledger?.netAmount?.toLocaleString()}
                    </td>
                  </tr>
                </>
              ))}
            </>
          ))}
        </>
      ))}
    </>
  );
};
