// import installed packages
import { useState, useEffect } from "react";
import { connect } from "react-redux";

// import styles
import "./SchoolFees.scss";
// import material ui items
// import shared/global items
// import components/pages

// import redux API
import { get_class_streams } from "../../../redux/actions/course";
import { START_LOADING } from "../../../redux/actions/types";
import {
  delete_school_service,
  get_ledgers,
  get_school_services,
} from "../../../redux/actions/finance";
import ReceiveFees from "./components/ReceiveFees";
import StudentStatement from "./components/StudentStatement/StudentStatement";
import InvoiceStudents from "./components/InvoiceStudents";
import AddSchoolService from "./components/AddSchoolService";
import EditSchoolService from "./components/EditSchoolService";

const SchoolFees = (props) => {
  const { school_services, userId, loading } = props; // get state from props
  const {
    getSchoolServices,
    startLoading,
    getClassStreams,
    getLedgers,
    deleteSchoolService,
  } = props; // get dispatch actions from props

  const [openInvoiceStudents, setOpenInvoiceStudents] = useState(false);
  const [openAddSchoolService, setOpenAddSchoolService] = useState(false);
  const [openReceiveFees, setOpenReceiveFees] = useState(false);
  const [openStudentStatement, setOpenStudentStatement] = useState(false);
  const [openEditSchoolService, setOpenEditSchoolService] = useState(false);
  const [currentSchoolService, setCurrentSchoolService] = useState({});

  // useEffect to get school services if they are not in state
  useEffect(() => {
    if (userId) {
      startLoading();
      getSchoolServices(userId);
    }
  }, [userId, startLoading, getSchoolServices]);

  // useEffect to get classes and class streams in case they are not in the redux state
  useEffect(() => {
    if (userId) {
      startLoading();
      getClassStreams(userId);
    }
  }, [userId, startLoading, getClassStreams]);

  // useEffect to get ledgers/groups for use when receiving student fees etc
  useEffect(() => {
    if (userId) {
      startLoading();
      getLedgers(userId);
    }
  }, [userId, startLoading, getLedgers]);

  const handleOpenEditSchoolService = (school_service) => {
    setCurrentSchoolService(school_service);
    setOpenEditSchoolService(true);
  };

  const handleDeleteSchoolService = (schoolServiceId) => {
    if (
      window.confirm(
        "Delete action is irreversible, are you sure you want to delete this school service?"
      )
    ) {
      startLoading();
      deleteSchoolService(userId, schoolServiceId);
    }
  };

  return (
    <>
      <div className="school__fees" id={loading ? "pageSubmitting" : ""}>
        <div className="school__feesActions">
          <span
            className="button"
            onClick={() => setOpenAddSchoolService(true)}
          >
            New Service
          </span>
          <span className="button" onClick={() => setOpenInvoiceStudents(true)}>
            Invoice Students
          </span>
          <span className="button" onClick={() => setOpenReceiveFees(true)}>
            Receive Fees
          </span>
          <span
            className="button"
            onClick={() => setOpenStudentStatement(true)}
          >
            Student Statement
          </span>
        </div>
        <div className="table__parent">
          <h3 className="tc dodgerblue ud">Existing school services</h3>
          {school_services?.length > 0 ? (
            <table className="table__listing">
              <tr className="table__listingHeader">
                <th>No:</th>
                <th>Name</th>
                <th>Status</th>
                <th>Price</th>
                <th>Edit</th>
                <th className="tc">Delete</th>
              </tr>

              {school_services?.map((item, index) => (
                <tr className="table__listingItem" key={item?.id}>
                  <td>{index + 1}</td>
                  <td>{item?.name}</td>
                  <td>
                    {item?.is_active ? (
                      <span className="green bd">Active</span>
                    ) : (
                      <span className="red bd">Inactive</span>
                    )}
                  </td>
                  <td>{item?.price?.toLocaleString()}</td>
                  <td
                    className="dodgerblue bd button__sp"
                    onClick={() => handleOpenEditSchoolService(item)}
                  >
                    Edit
                  </td>
                  <td className="tc">
                    <i
                      className="bx bxs-trash-alt red button__sp"
                      onClick={() => handleDeleteSchoolService(item?.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </table>
          ) : (
            <h4 className="not__available">No services available</h4>
          )}
        </div>
      </div>
      {/* child components */}

      {openInvoiceStudents && (
        <InvoiceStudents
          openInvoiceStudents={openInvoiceStudents}
          setOpenInvoiceStudents={setOpenInvoiceStudents}
        />
      )}
      {openAddSchoolService && (
        <AddSchoolService
          setOpenAddSchoolService={setOpenAddSchoolService}
          openAddSchoolService={openAddSchoolService}
        />
      )}
      {openReceiveFees && (
        <ReceiveFees
          openReceiveFees={openReceiveFees}
          setOpenReceiveFees={setOpenReceiveFees}
        />
      )}
      {openStudentStatement && (
        <StudentStatement
          openStudentStatement={openStudentStatement}
          setOpenStudentStatement={setOpenStudentStatement}
        />
      )}
      {openEditSchoolService && (
        <EditSchoolService
          openEditSchoolService={openEditSchoolService}
          setOpenEditSchoolService={setOpenEditSchoolService}
          currentSchoolService={currentSchoolService}
          setCurrentSchoolService={setCurrentSchoolService}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    school_services: state.finance?.school_services,
    userId: state.auth.user?.id,

    ledgers: state.finance?.ledgers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getSchoolServices: (userId) => dispatch(get_school_services(userId)),
    getClassStreams: (userId) => dispatch(get_class_streams(userId)),
    getLedgers: (userId) => dispatch(get_ledgers(userId)),
    deleteSchoolService: (userId, schoolServiceId) =>
      dispatch(delete_school_service(userId, schoolServiceId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolFees);
