import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../components/common/MinDialog";
import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";
import API from "../../../shared/axios";
import { ifEmpty, maxDate } from "../../../shared/sharedFunctions";
import { showError } from "../../../redux/actions/shared";
import StatementTemplate from "../../Finance/SchoolFees/components/StudentStatement/components/StatementTemplate";
import CircularProgress from "@mui/material/CircularProgress";

const FeesReport = (props) => {
  const { openFeesReport, userId, loading, parentsChildren } = props;
  const { setOpenFeesReport, startLoading, stopLoading } = props;

  const [statementFilters, setStatementFilters] = useState({
    admission_number: "",
    start_date: "",
    end_date: "",
  });

  const { admission_number, start_date, end_date } = statementFilters;

  const [statementDetails, setStatementDetails] = useState({});
  const [openStatementTemplate, setOpenStatementTemplate] = useState(false);

  const handleClose = () => {
    setOpenFeesReport(false);
  };

  const handleChange = (e) => {
    setStatementFilters({
      ...statementFilters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(statementFilters)) {
      return alert("All fields are required");
    }
    if (end_date < start_date) {
      return alert("End date must be greater than start date.");
    }
    startLoading();
    const fetchData = async () => {
      const res = await API.post(
        `/api/finance/get-student-fees-statement/${userId}/`,
        { ...statementFilters }
      );
      setStatementDetails(res.data?.statement_data);
      setOpenStatementTemplate(true);
    };
    fetchData()
      .catch((err) => showError(err))
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <>
      <MinDialog isOpen={openFeesReport}>
        <form className="dialog" id={loading ? "formSubmitting" : ""}>
          <h3>Enter the details below to generate report</h3>
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Select student</label>
            <select
              name="admission_number"
              onChange={handleChange}
              value={admission_number}
            >
              <option value="" disabled selected>
                --select--
              </option>
              {parentsChildren?.map((child) => (
                <option value={child?.admission_number} key={child?.id}>
                  {child?.name}
                </option>
              ))}
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Start Date</label>
            <input
              type="date"
              name="start_date"
              onChange={handleChange}
              value={start_date}
              max={maxDate()}
            />
          </div>
          <div className="dialog__rowSingleItem">
            <label htmlFor="">End date</label>
            <input
              type="date"
              name="end_date"
              onChange={handleChange}
              value={end_date}
              max={maxDate()}
            />
          </div>
          <div className="form__Buttons">
            <button type="button" onClick={handleClose}>
              Close
            </button>
            <button type="submit" onClick={handleSubmit}>
              Generate
            </button>
          </div>
        </form>
      </MinDialog>
      {openStatementTemplate && (
        <StatementTemplate
          openStatementTemplate={openStatementTemplate}
          setOpenStatementTemplate={setOpenStatementTemplate}
          statementDetails={statementDetails}
          start_date={start_date}
          end_date={end_date}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    parentsChildren: state?.auth?.parentsChildren,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeesReport);
