const globals = {
  success: "success",
  error: "error",
  fillFields: "Please fill all fields",
  unknown_error: "An unknown error occurred, please try again later",
  schoolName: "Funzo Letu",
  schoolEmail: "info@school.azwgroup.com",
  schoolAddress: "13331, Tom Mboya Street, Nairobi",
  schoolMobile1: "+254 700 22 22 22",
  schoolMobile2: "+254 700 11 11 11",
  liveProduction: true,
  devHome: "http://localhost:8000", // for local development
  productionHome: "https://funzoletu.azwgroup.com", // for live production
  accountTypes: [
    {
      name: "accountant",
      id: 1,
    },
    {
      name: "human resource",
      id: 2,
    },
    {
      name: "librarian",
      id: 3,
    },
    {
      name: "receptionist",
      id: 4,
    },
    {
      name: "other staff",
      id: 5,
    },
  ],
};
export default globals;
