import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import { showError } from "../../../../../redux/actions/shared";
import {
  START_LOADING,
  STOP_LOADING,
} from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import MaxDialog from "../../.././../../components/common/MaxDialog";

const UpdateSubjectResults = (props) => {
  const {
    userId,
    currentExam,
    loading,
    openUpdateSubjectResults,
    studentsSubjectResults,
    currentSubjectStream,
  } = props;
  const {
    setOpenUpdateSubjectResults,
    startLoading,
    stopLoading,
    setStudentsSubjectResults,
  } = props;

  const handleMarksChanged = (e, studentIndex) => {
    const arrayToMutate = [...studentsSubjectResults];
    const studentsMarks = arrayToMutate[studentIndex];
    // target name is either marks scored or comments
    studentsMarks[e.target.name] = e.target.value;
    setStudentsSubjectResults(arrayToMutate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();

    await API.post(
      `/api/exam/teacher/maintain-offline-exam-subject-results/${userId}/${currentSubjectStream?.id}/${currentExam?.id}/`,
      { studentsSubjectResults }
    )
      .then((res) => {
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MaxDialog
      isOpen={openUpdateSubjectResults}
      maxWidth="1200px"
      maxHeight="85vh"
    >
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>
          {currentExam?.name} - update results for{" "}
          {currentSubjectStream?.subject_stream}
        </h3>

        {studentsSubjectResults?.length > 0 ? (
          <table
            className="table__listing report__statement"
            border="1"
            rules="all"
          >
            <tr className="table__listingHeader">
              <th>Admission</th>
              <th>Student Name</th>
              <th>Marks Scored</th>
              <th>Comments</th>
            </tr>
            {loading && (
              <CircularProgress
                style={{ position: "absolute", marginLeft: "45%" }}
              />
            )}
            {studentsSubjectResults?.map((student, index) => (
              <tr className="table__listingItem" key={student?.id}>
                <td>{student?.admission_number}</td>
                <td>{student?.name}</td>
                <td>
                  <input
                    type="number"
                    name="marks_scored"
                    value={student?.marks_scored}
                    onChange={(e) => handleMarksChanged(e, index)}
                    style={{
                      borderRadius: "5px",
                      border: "1px solid dodgerblue",
                      maxWidth: "100px",
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="comments"
                    value={student?.comments}
                    onChange={(e) => handleMarksChanged(e, index)}
                    style={{
                      borderRadius: "5px",
                      border: "1px solid dodgerblue",
                      width: "100%",
                    }}
                  />
                </td>
              </tr>
            ))}
          </table>
        ) : (
          <h4 class="not__available">
            No students for this class stream and subject
          </h4>
        )}
        <div className="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenUpdateSubjectResults(false)}
          >
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    teacher_subjects: state.course?.teacher_subjects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSubjectResults);
