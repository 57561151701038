import React, { useState } from "react";
import MediumDialog from "../../../../components/common/MediumDialog";
import { formatWithCommas } from "../../../../shared/sharedFunctions";
import AddStaffDeduction from "./AddStaffDeduction";
import EditStaffDeduction from "./EditStaffDeduction";

const StaffDeduction = (props) => {
  const { openStaffDeduction, currentStaff } = props;
  const { setOpenStaffDeduction, setCurrentStaff } = props;

  const [openAddStaffDeduction, setOpenAddStaffDeduction] = useState(false);
  const [currentStaffDeduction, setCurrentStaffDeduction] = useState({});
  const [openEditStaffDeduction, setOpenEditStaffDeduction] = useState(false);

  const handleOpenEditStaffDeduction = (staff_deduction) => {
    setCurrentStaffDeduction(staff_deduction);
    setOpenEditStaffDeduction(true);
  };

  return (
    <>
      <MediumDialog isOpen={openStaffDeduction}>
        <div class="dialog">
          <h3>Salary deductions for {currentStaff?.name}</h3>
          {currentStaff?.staff_salary_deductions?.length > 0 ? (
            <table class="table__listing">
              <thead>
                <tr class="table__listingHeader">
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th className="tc">Edit</th>
                </tr>
              </thead>
              <tbody>
                {currentStaff?.staff_salary_deductions?.map((deduction) => (
                  <tr class="table__listingItem" key={deduction?.id}>
                    <td>{deduction?.deduction_name}</td>
                    <td>{formatWithCommas(deduction?.amount)}</td>
                    <td>
                      {deduction?.is_active ? (
                        <span className="green bd">Active</span>
                      ) : (
                        <span className="red bd">Inactive</span>
                      )}
                    </td>
                    {/* here we disable editing of system items such as PAYE and personal relief */}
                    <td className="tc bd">
                      {deduction?.is_system_item ? (
                        <span class="red">N/A</span>
                      ) : (
                        <span
                          className="dodgerblue bd button__sp"
                          onClick={() =>
                            handleOpenEditStaffDeduction(deduction)
                          }
                        >
                          Edit
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No salary deductions added yet</h4>
          )}
          <div class="form__Buttons">
            <button type="button" onClick={() => setOpenStaffDeduction(false)}>
              Close
            </button>
            <button
              type="button"
              onClick={() => setOpenAddStaffDeduction(true)}
            >
              Add Deduction
            </button>
          </div>
        </div>
      </MediumDialog>
      {/* child components */}
      {openAddStaffDeduction && (
        <AddStaffDeduction
          openAddStaffDeduction={openAddStaffDeduction}
          setOpenAddStaffDeduction={setOpenAddStaffDeduction}
          currentStaff={currentStaff}
          setCurrentStaff={setCurrentStaff}
        />
      )}
      {openEditStaffDeduction && (
        <EditStaffDeduction
          openEditStaffDeduction={openEditStaffDeduction}
          setOpenEditStaffDeduction={setOpenEditStaffDeduction}
          currentStaff={currentStaff}
          setCurrentStaff={setCurrentStaff}
          currentStaffDeduction={currentStaffDeduction}
          setCurrentStaffDeduction={setCurrentStaffDeduction}
        />
      )}
    </>
  );
};

export default StaffDeduction;
