import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import MediumDialog from "../../../components/common/MediumDialog";
import {
  EDIT_DORM,
  START_LOADING,
  STOP_LOADING,
} from "../../../redux/actions/types";
import API from "../../../shared/axios";
import CircularProgress from "@mui/material/CircularProgress";
import { showError } from "../../../redux/actions/shared";

const ChooseDormLeadership = (props) => {
  const {
    openChooseDormLeadership,
    currentDorm,
    leaderOption,
    userId,
    loading,
  } = props;
  const { setOpenChooseDormLeadership, startLoading, stopLoading } = props;
  const dispatch = useDispatch();
  const [validDetails, setValidDetails] = useState(false);
  const [leaderDetails, setLeaderDetails] = useState({});
  const [admissionOrPhone, setAdmissionOrPhone] = useState("");

  const resetDetails = () => {
    setValidDetails(false);
    setLeaderDetails({});
    setAdmissionOrPhone("");
  };

  const validateDetails = async (e) => {
    if (admissionOrPhone.trim() === "") {
      return window.alert("Enter valid values");
    }
    startLoading();
    const body = {
      admissionOrPhone,
      leaderOption,
    };
    await API.post(
      `/api/accomodation/admin/validate-leader-details/${userId}/`,
      body
    )
      .then((res) => {
        setValidDetails(true);
        setLeaderDetails(res?.data?.leader_details);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      dormId: currentDorm?.id,
      admissionOrPhone,
      leaderOption,
    };
    await API.post(
      `/api/accomodation/admin/appoint-dorm-leaders/${userId}/`,
      body
    )
      .then((res) => {
        dispatch({ type: EDIT_DORM, payload: res?.data?.updated_dorm });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MediumDialog isOpen={openChooseDormLeadership} maxWidth="900px">
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>
          Choose {leaderOption} for {currentDorm?.name}
        </h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {leaderOption === "Patron" && (
          <>
            <div class="dialog__row">
              <span>
                <label for="">Enter teacher phone number to validate</label>
                <input
                  type="text"
                  value={admissionOrPhone}
                  onChange={(e) => setAdmissionOrPhone(e.target.value)}
                />
              </span>
              {validDetails && (
                <span>
                  <label for="">Teacher Name</label>
                  <input type="text" value={leaderDetails?.name} disabled />
                </span>
              )}
            </div>
            {validDetails && (
              <>
                <div class="dialog__row">
                  <span>
                    <label for="">Email</label>
                    <input type="text" value={leaderDetails?.email} disabled />
                  </span>
                  <span>
                    <label for="">Gender</label>
                    <input type="text" value={leaderDetails?.gender} disabled />
                  </span>
                </div>
                <div class="dialog__row">
                  <span>
                    <label for="">Reset Details</label>
                    <button class="add__button" onClick={resetDetails}>
                      Reset Details
                    </button>
                  </span>
                  <span></span>
                  <span></span>
                </div>
              </>
            )}
          </>
        )}
        {(leaderOption === "Captain" || leaderOption === "Deputy Captain") && (
          <>
            <div class="dialog__row">
              <span>
                <label for="">Enter admission number to validate student</label>
                <input
                  type="text"
                  value={admissionOrPhone}
                  onChange={(e) => setAdmissionOrPhone(e.target.value)}
                />
              </span>
              {validDetails && (
                <span>
                  <label for="">Student Name</label>
                  <input type="text" value={leaderDetails?.name} disabled />
                </span>
              )}
            </div>
            {validDetails && (
              <>
                <div class="dialog__row">
                  <span>
                    <label for="">Class Stream</label>
                    <input
                      type="text"
                      value={leaderDetails?.class_stream}
                      disabled
                    />
                  </span>
                  <span>
                    <label for="">Gender</label>
                    <input type="text" value={leaderDetails?.gender} disabled />
                  </span>
                </div>
                <div class="dialog__row">
                  <span>
                    <label for="">Reset Details</label>
                    <button class="add__button" onClick={resetDetails}>
                      Reset Details
                    </button>
                  </span>
                  <span></span>
                  <span></span>
                </div>
              </>
            )}
          </>
        )}
        <div class="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenChooseDormLeadership(false)}
          >
            Close
          </button>
          {validDetails ? (
            <button type="button" onClick={handleSubmit}>
              Submit
            </button>
          ) : (
            <button type="button" onClick={validateDetails}>
              Validate
            </button>
          )}
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseDormLeadership);
