// import installed packages
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const AccomodationLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to="#"
        className="nav__link"
        onClick={() => toggleSubmenu("Accomodation")}
      >
        <i className="bx bxs-bed"></i>
        <span className="nav__name">Accomodation</span>
      </Link>
      {openSubMenu === "Accomodation" && (
        <>
          <Link
            to="/accomodation/dorms/"
            className={
              `${pathname}` === "/accomodation/dorms/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Dorms</span>
          </Link>
        </>
      )}
    </>
  );
};

export default AccomodationLinks;
