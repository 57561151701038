import React, { useState } from "react";
import OfflineExamsReports from "./components/OfflineExamsReports";
import OnlineExamsReport from "./components/OnlineExamsReport";

const StudentReports = () => {
  const [openExamsReport, setOpenExamsReport] = useState(false);
  const [openOfflineExamsReport, setOpenOfflineExamsReport] = useState(false);

  const handleOpenExamsReport = () => {
    setOpenExamsReport(true);
  };
  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <h3>Access your reports here</h3>
        </div>
        <table className="table__listing">
          <tr className="table__listingHeader">
            <th>Report Type</th>
            <th>Action</th>
          </tr>
          <tr className="table__listingItem">
            <td>Online Exams</td>

            <td
              className="dodgerblue bd button__sp"
              onClick={handleOpenExamsReport}
            >
              View
            </td>
          </tr>
          <tr className="table__listingItem">
            <td>Offline Exams</td>
            <td
              className="dodgerblue bd button__sp"
              onClick={() => setOpenOfflineExamsReport(true)}
            >
              View
            </td>
          </tr>
        </table>
      </div>
      {/* child components */}

      {openExamsReport && (
        <OnlineExamsReport
          openExamsReport={openExamsReport}
          setOpenExamsReport={setOpenExamsReport}
        />
      )}
      {openOfflineExamsReport && (
        <OfflineExamsReports
          openOfflineExamsReport={openOfflineExamsReport}
          setOpenOfflineExamsReport={setOpenOfflineExamsReport}
        />
      )}
    </>
  );
};

export default StudentReports;
