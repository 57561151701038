import { Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import MaxDialog from "../../../components/common/MaxDialog";
import ReportHeader from "../../../shared/ReportHeader/ReportHeader";
import { formatWithCommas } from "../../../shared/sharedFunctions";

const OverallResultsMeritList = (props) => {
  const {
    openOverallResultsMeritList,
    studentsOverallResults,
    currentClassStream,
    currentExam,
    classStreamSubjects,
  } = props;
  const { setOpenOverallResultsMeritList } = props;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: `${currentClassStream?.name} Overall Results Report`,
  });

  return (
    <MaxDialog
      isOpen={openOverallResultsMeritList}
      maxWidth="1200px"
      maxHeight="90vh"
    >
      <div class="dialog">
        <table class="report__statement" border="1" rules="all" ref={printArea}>
          <ReportHeader />
          <tbody>
            <tr>
              <th
                colSpan="100%"
                style={{ padding: "5px", color: "purple" }}
                className="tc"
              >
                {currentClassStream?.name} - {currentExam?.name} overall results
                merit list
              </th>
            </tr>
            <tr className="table__listingHeader">
              <th className="tc">Class Position</th>
              <th className="tc">Overall Position</th>
              <th>Adm Number:</th>
              <th>Student</th>
              {/* we loop through the various subjects for this class stream */}
              {classStreamSubjects?.map((subject) => (
                <th key={subject?.subject__id} className="tc">
                  {subject?.subject__name}
                </th>
              ))}
              <th>{/* separation column */}</th>
              <th className="tc">Grade</th>
              <th className="tc">Points</th>
              <th className="tc">Marks</th>
            </tr>
            {studentsOverallResults?.map((student_result) => (
              <tr className="table__listingItem">
                <td className="tc">{student_result?.class_stream_position}</td>
                <td className="tc">{student_result?.class_level_position}</td>
                <td>{student_result?.admission_number}</td>
                <td>{student_result?.student}</td>
                {/* we need to loop through the student subject results comparing them to the subjects and when the id's match we show the marks scored
                    on that td
                */}
                {classStreamSubjects?.map((subject) => (
                  <td className="tc">
                    {/* here we loop through the subject results of the student */}
                    {student_result?.student_subject_results?.map(
                      (student_subject_result) => (
                        <Fragment>
                          {student_subject_result?.subject__id ===
                            subject?.subject__id && (
                            <span>{student_subject_result?.marks_scored}</span>
                          )}
                        </Fragment>
                      )
                    )}
                    {/* here we loop through the subjects that the student is not taking to show n/a in these areas */}
                    {student_result?.subjects_not_being_taken?.map(
                      (subject_item_id) => (
                        <Fragment>
                          {subject_item_id === subject?.subject__id && (
                            <span className="red">n/a</span>
                          )}
                        </Fragment>
                      )
                    )}
                  </td>
                ))}
                <td>{/* separation column */}</td>
                <td className="tc">{student_result?.grade}</td>
                <td className="tc">
                  {formatWithCommas(student_result?.points)}
                </td>
                <td className="tc">{student_result?.marks_scored}</td>
              </tr>
            ))}
            <tr>
              <td colSpan="100%">
                Results Status:{" "}
                {currentExam?.update_complete ? (
                  <span className="green bd">Completed</span>
                ) : (
                  <span className="red bd">Update ongoing</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenOverallResultsMeritList(false)}
          >
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print/Save
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

export default OverallResultsMeritList;
