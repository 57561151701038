// import installed packages

// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const CommonLinks = ({
  Link,
  pathname,
  logoutUser,
  toggleSubmenu,
  openSubMenu,
  loggedIn,
  is_staff,
}) => {
  return (
    <>
      <Link
        to="/"
        className={`${pathname}` === "/" ? "nav__link active" : "nav__link"}
      >
        <i className="bx bxs-home"></i>
        <span className="nav__name">Home</span>
      </Link>
      {/* staff links */}
      {is_staff && (
        <>
          <Link
            to="/staff/dashboard/"
            className={
              `${pathname}` === "/staff/dashboard/"
                ? "nav__link active"
                : "nav__link"
            }
          >
            <i className="bx bxs-briefcase-alt-2"></i>
            <span className="nav__name">Dashboard</span>
          </Link>
        </>
      )}
      <Link
        to="/profile/"
        className={
          `${pathname}` === "/profile/" ? "nav__link active" : "nav__link"
        }
      >
        <i className="bx bxs-user"></i>
        <span className="nav__name">Profile</span>
      </Link>
    </>
  );
};

export default CommonLinks;
