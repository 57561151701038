import { CircularProgress } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { update_student_school_services } from "../../../../../redux/actions/finance";
import { START_LOADING } from "../../../../../redux/actions/types";

const StudentServices = (props) => {
  const { openStudentServices, currentStudent, loading, userId } = props;
  const {
    setOpenStudentServices,
    setCurrentStudent,
    startLoading,
    updateStudentSchoolServices,
  } = props;

  const handleServicesChange = (e, serviceIndex) => {
    const arrayToMutate = [...currentStudent?.school_services];

    arrayToMutate[serviceIndex] = {
      ...arrayToMutate[serviceIndex],
      student_consumes: e.target.checked,
    };

    setCurrentStudent({ ...currentStudent, school_services: arrayToMutate });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    const body = {
      studentUserId: currentStudent?.id,
      schoolServices: currentStudent?.school_services,
    };
    updateStudentSchoolServices(userId, body);
  };

  return (
    <MediumDialog isOpen={openStudentServices} maxWidth="800px">
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>
          {currentStudent?.admission_number} - {currentStudent?.first_name}{" "}
          {currentStudent?.last_name} - School services
        </h3>
        <table class="table__listing">
          <thead>
            <tr class="table__listingHeader">
              <th>Service</th>
              <th>Price</th>
              <th className="tc">Student Allocated</th>
            </tr>
          </thead>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <tbody>
            {currentStudent?.school_services?.map((service, index) => (
              <tr class="table__listingItem" key={service?.id}>
                <td>{service?.name}</td>
                <td>{service?.price}</td>
                <td className="tc">
                  <input
                    type="checkbox"
                    name=""
                    onChange={(e) => handleServicesChange(e, index)}
                    checked={service?.student_consumes}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenStudentServices(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    updateStudentSchoolServices: (userId, body) =>
      dispatch(update_student_school_services(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentServices);
