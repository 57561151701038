import { useEffect, useState } from "react";
import { connect } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import MinDialog from "../../../../../components/common/MinDialog";
import { admin_get_class_streams } from "../../../../../redux/actions/course";
import { showError } from "../../../../../redux/actions/shared";
import {
  START_LOADING,
  STOP_LOADING,
} from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import OverallResultsMeritList from "../../../../common/Exams/OverallResultsMeritList";

const MeritListAction = (props) => {
  const {
    openMeritListAction,
    userId,
    loading,
    class_streams,
    currentOfflineExam,
  } = props;
  const {
    setOpenMeritListAction,
    startLoading,
    adminGetClassStreams,
    stopLoading,
  } = props;

  const [studentsOverallResults, setStudentsOverallResults] = useState([]);
  const [classStreamSubjects, setClassStreamSubjects] = useState([]);
  const [openOverallResultsMeritList, setOpenOverallResultsMeritList] =
    useState(false);
  const [currentClassStreamId, setCurrentClassStreamId] = useState("");

  // useEffect to get class levels and class streams
  useEffect(() => {
    if (userId && class_streams?.length === 0) {
      startLoading();
      adminGetClassStreams(userId);
    }
  }, [userId, startLoading, adminGetClassStreams, class_streams?.length]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.get(
      `/api/exam/teacher/maintain-offline-exam-results/${userId}/${currentClassStreamId}/${currentOfflineExam?.id}/`
    )
      .then((res) => {
        setClassStreamSubjects(res?.data?.class_stream_subjects);
        setStudentsOverallResults(res?.data?.students_overall_results_data);
        setOpenOverallResultsMeritList(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog isOpen={openMeritListAction}>
        <form
          class="dialog"
          id={loading ? "formSubmitting" : ""}
          onSubmit={handleSubmit}
        >
          <h3>Select to generate merit list</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div class="dialog__rowSingleItem">
            <label for="">Class stream</label>
            <select
              onChange={(e) => setCurrentClassStreamId(e.target.value)}
              value={currentClassStreamId}
              required
            >
              <option value="">--select--</option>
              {class_streams?.map((stream) => (
                <option value={stream?.id} key={stream?.id}>
                  {stream?.name}
                </option>
              ))}
            </select>
          </div>

          <div class="form__Buttons">
            <button type="button" onClick={() => setOpenMeritListAction(false)}>
              Close
            </button>
            <button type="submit">Generate</button>
          </div>
        </form>
      </MinDialog>
      {/* child components */}
      {openOverallResultsMeritList && (
        <OverallResultsMeritList
          openOverallResultsMeritList={openOverallResultsMeritList}
          setOpenOverallResultsMeritList={setOpenOverallResultsMeritList}
          studentsOverallResults={studentsOverallResults}
          currentClassStream={class_streams?.find(
            (class_stream) => class_stream?.id === currentClassStreamId
          )}
          currentExam={currentOfflineExam}
          classStreamSubjects={classStreamSubjects}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    class_streams: state.course?.class_streams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    adminGetClassStreams: (userId) => dispatch(admin_get_class_streams(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeritListAction);
