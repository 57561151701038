import * as actionTypes from "./types";
import * as api from "../../api";
import { showError, stopLoading } from "./shared";

// action to add new book
export const add_new_book = (userId, body, resetForm) => async (dispatch) => {
  await api
    .addNewBook(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.ADD_NEW_BOOK,
        payload: res?.data?.new_book,
      });
      resetForm();
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to edit book
export const edit_book = (userId, body) => async (dispatch) => {
  await api
    .editBook(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_BOOK,
        payload: res?.data?.edited_book,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to get books, note that we will paginate later on
export const get_books = (userId) => async (dispatch) => {
  await api
    .getBooks(userId)
    .then((res) => {
      dispatch({ type: actionTypes.SET_BOOKS, payload: res?.data?.books_data });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to stock existing book
export const stock_existing_book =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .stockExistingBook(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.STOCK_EXISTING_BOOK,
          payload: res?.data?.updated_book_stock,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to record book borrow
export const record_book_borrow =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .recordBookBorrow(userId, body)
      .then((res) => {
        // at the moment we get all borrowed books as we figure out how to get the borrowed books from request when many=True for serializer save
        dispatch({
          type: actionTypes.NEW_BOOK_BORROW,
          payload: res?.data?.text_book_borrows_data,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to get book borrows
export const get_book_borrows = (userId) => async (dispatch) => {
  await api
    .getBookBorrows(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_BOOK_BORROWS,
        payload: res?.data?.text_book_borrows_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to update book borrow
export const update_book_borrow =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .updateBookBorrow(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_BOOK_BORROW,
          payload: res?.data?.book_borrow_id,
        });
        window.alert(res?.data?.detail);
        resetForm();
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to get lost books
export const get_lost_books = (userId) => async (dispatch) => {
  await api
    .getLostBooks(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_LOST_BOOKS,
        payload: res?.data?.lost_text_books_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to charge lost book
export const charge_lost_book =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .chargeLostBook(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.CHARGE_LOST_BOOK,
          payload: res?.data?.lost_book_borrow_id,
        });
        window.alert(res?.data?.detail);
        resetForm();
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to get lost book charges
export const get_lost_book_charges = (userId) => async (dispatch) => {
  await api
    .getLostBookCharges(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_LOST_BOOK_CHARGES,
        payload: res?.data?.lost_book_charges_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};
