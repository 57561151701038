import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import AddManualTimeTable from "./components/AddManualTimeTable";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";
import EditTimeTableTemplate from "./components/EditManualTimeTable";

const TimeTables = (props) => {
  const { userId, loading } = props;
  const { startLoading, stopLoading } = props;

  const [openAddManualTimeTable, setOpenAddManualTimeTable] = useState(false);
  const [timeTables, setTimeTables] = useState([]);
  const [currentTimeTable, setCurrentTimeTable] = useState({});
  const [openEditManualTimeTable, setOpenEditManualTimeTable] = useState(false);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchTimeTables = async () => {
        await API.get(`/api/timetable/maintain-manual-timetable/${userId}/`)
          .then((res) => {
            setTimeTables(res?.data?.timetables_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchTimeTables();
    }
  }, [userId, startLoading, stopLoading]);

  const handleOpenAddManualTimeTable = () => {
    setOpenAddManualTimeTable(true);
  };

  const handleOpenEditManualTimeTable = (timetable) => {
    setCurrentTimeTable(timetable);
    setOpenEditManualTimeTable(true);
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <button type="button" onClick={handleOpenAddManualTimeTable}>
            TimeTable Template
          </button>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {timeTables?.length > 0 ? (
          <table className="table__listing">
            <thead>
              <tr className="table__listingHeader">
                <th>Name</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {timeTables?.map((timetable) => (
                <tr key={timetable?.id} className="table__listingItem">
                  <td>{timetable?.name}</td>
                  <td
                    className="dodgerblue bd button__sp"
                    onClick={() => handleOpenEditManualTimeTable(timetable)}
                  >
                    view
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No timetables saved yet</h4>
        )}
      </div>
      {/* child components */}
      {openAddManualTimeTable && (
        <AddManualTimeTable
          openAddManualTimeTable={openAddManualTimeTable}
          setOpenAddManualTimeTable={setOpenAddManualTimeTable}
          setTimeTables={setTimeTables}
          timeTables={timeTables}
        />
      )}
      {openEditManualTimeTable && (
        <EditTimeTableTemplate
          openEditManualTimeTable={openEditManualTimeTable}
          setOpenEditManualTimeTable={setOpenEditManualTimeTable}
          setTimeTables={setTimeTables}
          timeTables={timeTables}
          currentTimeTable={currentTimeTable}
          setCurrentTimeTable={setCurrentTimeTable}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeTables);
