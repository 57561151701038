import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../../components/common/MaxDialog";
import { get_teacher_subjects } from "../../../../../redux/actions/course";
import { create_exam } from "../../../../../redux/actions/exam";
import { START_LOADING } from "../../../../../redux/actions/types";
import Markdown from "../../../../../shared/Markdown/Markdown";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const AddExam = (props) => {
  const { openAddExam, userId, loading, teacher_subjects } = props;
  const { setOpenAddExam, startLoading, createExam, getTeacherSubjects } =
    props;

  const [examDetails, setExamDetails] = useState({
    title: "",
    duration: "",
    pass_mark: "",
    deadline_date: "",
    deadline_time: "",
    alloted_subject: "",
  });

  const {
    title,
    duration,
    pass_mark,
    deadline_date,
    alloted_subject,
    deadline_time,
  } = examDetails;

  const [instructions, setInstructions] = useState("");

  useEffect(() => {
    if (teacher_subjects?.length === 0 && userId) {
      startLoading();
      getTeacherSubjects(userId);
    }
  }, [teacher_subjects?.length, userId, getTeacherSubjects, startLoading]);

  const handleChange = (e) => {
    setExamDetails({ ...examDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    resetFormValues(examDetails);
    setInstructions("");
  };
  const handleClose = () => {
    resetForm();
    setOpenAddExam(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(examDetails) || instructions.trim() === "") {
      return window.alert("Fill in all fields");
    }
    startLoading();
    createExam(userId, { ...examDetails, instructions }, resetForm);
  };
  return (
    <MaxDialog isOpen={openAddExam} maxWidth="1000px" maxHeight="80vh">
      <form
        className="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Enter exam details</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Title</label>
            <input
              type="text"
              name="title"
              onChange={handleChange}
              value={title}
            />
          </span>
          <span>
            <label htmlFor="">Subject &amp; Stream</label>
            <select
              name="alloted_subject"
              onChange={handleChange}
              value={alloted_subject}
            >
              <option value="" selected disabled>
                Select subject
              </option>
              {/* value to display is subject concatenated with stream */}
              {teacher_subjects?.map((subject) => (
                <option value={subject?.id} key={subject?.id}>
                  {subject?.subject_stream}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Pass Mark (%)</label>
            <input
              type="number"
              name="pass_mark"
              onChange={handleChange}
              value={pass_mark}
              min="1"
            />
          </span>
          <span>
            <label htmlFor="">Deadline Date</label>
            <input
              type="date"
              name="deadline_date"
              onChange={handleChange}
              value={deadline_date}
              id=""
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Deadline Time</label>
            <input
              type="time"
              name="deadline_time"
              onChange={handleChange}
              value={deadline_time}
              id=""
            />
          </span>
          <span>
            <label htmlFor="">Duration (minutes)</label>
            <input
              type="number"
              name="duration"
              onChange={handleChange}
              value={duration}
              min="1"
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Exam Instructions</label>
          <Markdown content={instructions} setContent={setInstructions} />
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth?.user?.id,
    loading: state.shared?.loading,
    teacher_subjects: state.course?.teacher_subjects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createExam: (userId, body, resetForm) =>
      dispatch(create_exam(userId, body, resetForm)),
    getTeacherSubjects: (userId) => dispatch(get_teacher_subjects(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddExam);
