import { useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { delete_overall_grading } from "../../../../../redux/actions/course";
import { START_LOADING } from "../../../../../redux/actions/types";
import { formatWithCommas } from "../../../../../shared/sharedFunctions";
import AddOverallGrade from "./AddOverallGrade";
import EditOverallGrade from "./EditOverallGrade";

const OverallGrading = (props) => {
  const { openOverallGrading, overall_grading, loading, userId } = props;
  const { setOpenOverallGrading, deleteOverallGrade, startLoading } = props;

  const [openAddOverallGrade, setOpenAddOverallGrade] = useState(false);
  const [currentOverallGrade, setCurrentOverallGrade] = useState({});
  const [openEditOverallGrade, setOpenEditOverallGrade] = useState(false);

  const handleOpenEditOverallGrade = (overallGrade) => {
    setCurrentOverallGrade(overallGrade);
    setOpenEditOverallGrade(true);
  };

  const handleDeleteOverallGrade = (overallGradeId) => {
    if (window.confirm("Are you sure you want to delete this overall grade")) {
      startLoading();
      deleteOverallGrade(userId, overallGradeId);
    }
  };

  return (
    <>
      <MediumDialog isOpen={openOverallGrading}>
        <div class="dialog" id={loading ? "formSubmitting" : ""}>
          <h3>Overall Grading System</h3>
          {overall_grading?.length > 0 ? (
            <table class="table__listing">
              <thead>
                <tr class="table__listingHeader">
                  <th>Grade</th>
                  <th>Points</th>
                  <th>Edit</th>
                  <th className="tc">Delete</th>
                </tr>
              </thead>
              <tbody>
                {overall_grading?.map((grade) => (
                  <tr class="table__listingItem" key={grade?.id}>
                    <td>{grade?.grade}</td>
                    <td>{formatWithCommas(grade?.points)}</td>
                    <td
                      className="dodgerblue button__sp bd"
                      onClick={() => handleOpenEditOverallGrade(grade)}
                    >
                      Edit
                    </td>
                    <td className="tc">
                      <i
                        className="bx bxs-trash-alt red button__sp"
                        onClick={() => handleDeleteOverallGrade(grade?.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No overall grades set yet</h4>
          )}
          <div class="form__Buttons">
            <button type="button" onClick={() => setOpenOverallGrading(false)}>
              Close
            </button>
            <button type="button" onClick={() => setOpenAddOverallGrade(true)}>
              Add Grade
            </button>
          </div>
        </div>
      </MediumDialog>
      {/* child components */}
      {openAddOverallGrade && (
        <AddOverallGrade
          openAddOverallGrade={openAddOverallGrade}
          setOpenAddOverallGrade={setOpenAddOverallGrade}
        />
      )}
      {openEditOverallGrade && (
        <EditOverallGrade
          openEditOverallGrade={openEditOverallGrade}
          setOpenEditOverallGrade={setOpenEditOverallGrade}
          currentOverallGrade={currentOverallGrade}
          setCurrentOverallGrade={setCurrentOverallGrade}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    overall_grading: state?.course?.overall_grading,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteOverallGrade: (userId, overallGradeId) =>
      dispatch(delete_overall_grading(userId, overallGradeId)),
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverallGrading);
