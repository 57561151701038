// import installed packages
import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import styles
import "./Accounting.scss";
// import material ui items
// import shared/global items
// import components/pages

// import redux API
import { START_LOADING } from "../../../redux/actions/types";
import { get_ledgers } from "../../../redux/actions/finance";

import ShowLedgers from "./components/ShowLedgers";
import AddLedgerGroup from "./components/AddLedgerGroup";
import AddLedger from "./components/AddLedger";
import PostEntry from "./components/PostEntry";
import PostStockInvoice from "./components/PostStockInvoice";
import UpdateSalaryPayments from "./components/UpdateSalaryPayments";

const Accounting = (props) => {
  const { ledger_groups, ledgers, userId } = props; //get state from props
  const { startLoading, getLedgers } = props; //get dispatch actions from props
  const [openAddLedgerGroup, setOpenAddLedgerGroup] = useState(false);
  const [openAddLedger, setOpenAddLedger] = useState(false);
  const [openShowLedgers, setOpenShowLedgers] = useState(false);
  const [ledgerType, setLedgerType] = useState("");

  const [openPostEntry, setOpenPostEntry] = useState(false);
  const [openPostStockInvoice, setOpenPostStockInvoice] = useState(false);
  const [openUpdateSalaryPayment, setOpenUpdateSalaryPayment] = useState(false);

  // useEffect to get ledgers/groups
  useEffect(() => {
    if (userId) {
      startLoading();
      getLedgers(userId);
    }
  }, [userId, startLoading, getLedgers]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showAllLedgers = (ledgerType) => {
    setLedgerType(ledgerType);
    setOpenShowLedgers(true);
  };

  return (
    <>
      <div className="accounting">
        <div className="accounting__actions">
          <span className="button" onClick={() => setOpenAddLedgerGroup(true)}>
            Add Ledger Group
          </span>
          <span className="button" onClick={() => setOpenAddLedger(true)}>
            Add Ledger
          </span>
          <span
            className="button"
            onClick={() => setOpenPostStockInvoice(true)}
          >
            Post Stock Invoice
          </span>
          <span
            className="button"
            onClick={() => setOpenUpdateSalaryPayment(true)}
          >
            Salary Payments
          </span>
          <span className="button" onClick={() => setOpenPostEntry(true)}>
            Post Entry
          </span>
        </div>
        <div className="account__ledgersListing">
          <div>
            <table>
              <thead>
                <tr>
                  <th>Ledger Groups</th>
                </tr>
              </thead>
              <tbody>
                {/* we use slice to show only a few ledgers */}
                {ledger_groups?.slice(0, 5)?.map((ledger_group, index) => (
                  <tr key={ledger_group?.id}>
                    <td>
                      {index + 1}. {ledger_group?.name}
                    </td>
                  </tr>
                ))}
                <br />
                <tr className="show__all">
                  <td onClick={() => showAllLedgers("Ledger Groups")}>
                    Show all
                  </td>
                </tr>
              </tbody>
            </table>

            {/* SHOW OTHER LEDGERS, I.E NOT STUDENT OR STAFF */}
            <table>
              <thead>
                <tr>
                  <th>Ledgers</th>
                </tr>
              </thead>
              <tbody>
                {ledgers?.slice(0, 5)?.map((ledger, index) => (
                  <tr key={ledger?.id}>
                    <td>
                      {index + 1}. {ledger?.name}
                    </td>
                  </tr>
                ))}
                <br />
                <tr className="show__all">
                  <td onClick={() => showAllLedgers("Ledgers")}>Show all</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div></div>
        </div>
      </div>
      {/* child components */}

      {openAddLedgerGroup && (
        <AddLedgerGroup
          openAddLedgerGroup={openAddLedgerGroup}
          setOpenAddLedgerGroup={setOpenAddLedgerGroup}
        />
      )}
      {openAddLedger && (
        <AddLedger
          openAddLedger={openAddLedger}
          setOpenAddLedger={setOpenAddLedger}
        />
      )}
      {openShowLedgers && (
        <ShowLedgers
          openShowLedgers={openShowLedgers}
          setOpenShowLedgers={setOpenShowLedgers}
          ledgerType={ledgerType}
          setLedgerType={setLedgerType}
        />
      )}
      {openPostEntry && (
        <PostEntry
          openPostEntry={openPostEntry}
          setOpenPostEntry={setOpenPostEntry}
        />
      )}
      {openPostStockInvoice && (
        <PostStockInvoice
          openPostStockInvoice={openPostStockInvoice}
          setOpenPostStockInvoice={setOpenPostStockInvoice}
        />
      )}
      {openUpdateSalaryPayment && (
        <UpdateSalaryPayments
          openUpdateSalaryPayment={openUpdateSalaryPayment}
          setOpenUpdateSalaryPayment={setOpenUpdateSalaryPayment}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user?.id,
    loading: state.shared?.loading,
    ledger_groups: state.finance?.ledger_groups,
    ledgers: state.finance?.ledgers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getLedgers: (userId) => dispatch(get_ledgers(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounting);
