import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { get_dorms } from "../../redux/actions/accomodation";
import {
  EDIT_DORM,
  START_LOADING,
  STOP_LOADING,
} from "../../redux/actions/types";
import API from "../../shared/axios";
import AddDorm from "./components/AddDorm";
import ChooseDormLeadership from "./components/ChooseDormLeadership";
import EditDorm from "./components/EditDorm";
import CircularProgress from "@mui/material/CircularProgress";
import { showError } from "../../redux/actions/shared";

const Dorms = (props) => {
  const { loading, dorms, userId } = props;
  const { startLoading, getDorms, stopLoading } = props;

  const dispatch = useDispatch();

  const [openAddDorm, setOpenAddDorm] = useState(false);
  const [currentDorm, setCurrentDorm] = useState({});
  const [openEditDorm, setOpenEditDorm] = useState(false);
  const [openChooseDormLeadership, setOpenChooseDormLeadership] =
    useState(false);
  const [leaderOption, setLeaderOption] = useState("");

  useEffect(() => {
    if (userId) {
      startLoading();
      getDorms(userId);
    }
  }, [userId, startLoading, getDorms]);

  const handleOpenEditDorm = (dorm) => {
    setCurrentDorm(dorm);
    setOpenEditDorm(true);
  };

  const handleOpenDormLeadership = (dorm, leader) => {
    setCurrentDorm(dorm);
    setLeaderOption(leader);
    setOpenChooseDormLeadership(true);
  };

  const handleRemoveDormLeader = async (e, leaderType, dormId) => {
    e.preventDefault();
    if (
      window.confirm(
        `Are you sure you want to remove the ${leaderType} from this dorm`
      )
    ) {
      const body = {
        leaderType,
        dormId,
      };
      await API.post(
        `/api/accomodation/admin/remove-dorm-leaders/${userId}/`,
        body
      )
        .then((res) => {
          dispatch({ type: EDIT_DORM, payload: res?.data?.updated_dorm });
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <h3>Maintain Dorms</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <button class="add__buttons" onClick={() => setOpenAddDorm(true)}>
            Add Dorm
          </button>
        </div>
        {dorms?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Name</th>
                <th>Capacity</th>
                <th>Occupied</th>
                <th>Vacant</th>
                <th>Edit</th>
                <th>Patron</th>
                <th>Captain</th>
                <th>Deputy Captain</th>
              </tr>
            </thead>
            <tbody>
              {dorms?.map((dorm) => (
                <tr class="table__listingItem" key={dorm?.id}>
                  <td>{dorm?.name}</td>
                  <td>{dorm?.capacity}</td>
                  <td>{dorm?.occupied}</td>
                  <td>{dorm?.vacant}</td>
                  <td
                    class="dodgerblue bd button__sp"
                    onClick={() => handleOpenEditDorm(dorm)}
                  >
                    Edit
                  </td>
                  <td>
                    {dorm?.patron ? (
                      <span
                        className="hover__delete"
                        onClick={(e) =>
                          handleRemoveDormLeader(e, "Patron", dorm?.id)
                        }
                      >
                        {dorm?.patron}
                      </span>
                    ) : (
                      <span
                        className="dodgerblue bd button__sp"
                        onClick={() => handleOpenDormLeadership(dorm, "Patron")}
                      >
                        Choose
                      </span>
                    )}
                  </td>
                  <td>
                    {dorm?.captain ? (
                      <span
                        className="hover__delete"
                        onClick={(e) =>
                          handleRemoveDormLeader(e, "Captain", dorm?.id)
                        }
                      >
                        {dorm?.captain}
                      </span>
                    ) : (
                      <span
                        className="dodgerblue bd button__sp"
                        onClick={() =>
                          handleOpenDormLeadership(dorm, "Captain")
                        }
                      >
                        Choose
                      </span>
                    )}
                  </td>
                  <td>
                    {dorm?.deputy_captain ? (
                      <span
                        className="hover__delete"
                        onClick={(e) =>
                          handleRemoveDormLeader(e, "Deputy Captain", dorm?.id)
                        }
                      >
                        {dorm?.deputy_captain}
                      </span>
                    ) : (
                      <span
                        className="dodgerblue bd button__sp"
                        onClick={() =>
                          handleOpenDormLeadership(dorm, "Deputy Captain")
                        }
                      >
                        Choose
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No dorms available</h4>
        )}
      </div>
      {/* child components */}
      {openAddDorm && (
        <AddDorm openAddDorm={openAddDorm} setOpenAddDorm={setOpenAddDorm} />
      )}
      {openEditDorm && (
        <EditDorm
          openEditDorm={openEditDorm}
          setOpenEditDorm={setOpenEditDorm}
          currentDorm={currentDorm}
          setCurrentDorm={setCurrentDorm}
        />
      )}
      {openChooseDormLeadership && (
        <ChooseDormLeadership
          openChooseDormLeadership={openChooseDormLeadership}
          setOpenChooseDormLeadership={setOpenChooseDormLeadership}
          currentDorm={currentDorm}
          leaderOption={leaderOption}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    dorms: state?.accomodation?.dorms,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    getDorms: (userId) => dispatch(get_dorms(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dorms);
