import { connect } from "react-redux";

import MaxDialog from "../../../../components/common/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";

const ClassStreamSubjectStudents = (props) => {
  const {
    openSubjectStudents,
    currentClassStreamSubject,
    loading,
    userId,
    subjectStudentsList,
  } = props;
  const {
    setOpenSubjectStudents,
    startLoading,
    stopLoading,
    setSubjectStudentsList,
  } = props;

  const handleChangeStudentsSubjectList = (e, studentIndex) => {
    const arrayToMutate = [...subjectStudentsList];
    arrayToMutate[studentIndex] = {
      ...arrayToMutate[studentIndex],
      is_student_taking_subject: e.target.checked,
    };
    setSubjectStudentsList(arrayToMutate);
  };

  const handleUpdateSubjectStudents = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(
      `/api/course/teacher/maintain-subject-students/${userId}/${currentClassStreamSubject?.id}/`,
      subjectStudentsList
    )
      .then((res) => {
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MaxDialog isOpen={openSubjectStudents} maxWidth="1200px" maxHeight="85vh">
      <form class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>{currentClassStreamSubject?.subject_stream} students</h3>
        <table class="table__listing">
          <thead>
            <tr class="table__listingHeader">
              <th>Adm Number</th>
              <th>Name</th>
              <th className="tc">Is Active</th>
            </tr>
          </thead>
          <tbody>
            {subjectStudentsList?.map((student, index) => (
              <tr class="table__listingItem" key={student?.id}>
                <td>{student?.admission_number}</td>
                <td>{student?.student_name}</td>
                <td className="tc">
                  <input
                    type="checkbox"
                    name=""
                    checked={student?.is_student_taking_subject}
                    onChange={(e) => handleChangeStudentsSubjectList(e, index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenSubjectStudents(false)}>
            Close
          </button>
          <button type="submit" onClick={handleUpdateSubjectStudents}>
            Update
          </button>
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClassStreamSubjectStudents);
