// import installed packages
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { START_LOADING } from "../../redux/actions/types";
// import styles
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import { ifEmpty, resetFormValues } from "../../shared/sharedFunctions";
import API from "../../shared/axios";
import globals from "../../shared/globals";
// import components/pages
import MediumDialog from "../common/MediumDialog";
// import redux API
import { create_user, get_groups } from "../../redux/actions/auth";
import { SET_PERMISSIONS } from "../../redux/actions/types";
import { admin_get_class_streams } from "../../redux/actions/course";

const AddUser = (props) => {
  const {
    openAddUser,
    loading,
    available_permissions,
    userId,
    groups,
    subjects,
    class_streams,
  } = props; // get state from props
  const {
    setOpenAddUser,
    startLoading,
    createUser,
    getGroups,
    adminGetClassStreams,
  } = props; // get dispatch actions from props
  const dispatch = useDispatch();
  const { fillFields, account_types } = globals;
  const [userPermissions, setUserPermissions] = useState({});
  const [userSubjects, setUserSubjects] = useState({});
  const [account_type, setAccountType] = useState("");
  const [studentStream, setStudentStream] = useState({
    stream_name: "",
    admission_number: "",
  });
  const [newUser, setNewUser] = useState({
    first_name: "",
    last_name: "",
    surname: "",
    email: "",
    group: "",
  });

  // destructure
  const { first_name, last_name, surname, email, group } = newUser;
  const { stream_name, admission_number } = studentStream;

  // useEffect to get permissions available and store in redux for reuse
  useEffect(() => {
    if (available_permissions?.length === 0 && userId && openAddUser) {
      const fetchPermissions = async () => {
        const res = await API.get(`/api/permission/get-permissions/${userId}/`);
        dispatch({ type: SET_PERMISSIONS, payload: res.data?.permissions });
      };
      fetchPermissions().catch((err) => {
        console.log(err.response);
      });
    }
  }, [available_permissions?.length, userId, dispatch, openAddUser]);

  // useEffect to get groups if they do not exist
  useEffect(() => {
    if (groups?.length === 0 && userId && openAddUser) {
      getGroups(userId);
    }
  }, [getGroups, groups?.length, userId, openAddUser]);

  // useeffect to get classes and class streams if they do not exist
  useEffect(() => {
    if (class_streams?.length === 0 && userId && openAddUser) {
      startLoading();
      adminGetClassStreams(userId);
    }
  }, [
    class_streams?.length,
    userId,
    startLoading,
    adminGetClassStreams,
    openAddUser,
  ]);

  // function to reset form
  const resetForm = () => {
    resetFormValues(newUser);
    resetFormValues(studentStream);
    setAccountType("");
  };

  // function to close add user form
  const closeUserForm = () => {
    setOpenAddUser(false);
    resetForm();
  };

  // function to handle change
  const handleChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  // function to handle stream change
  const handleStreamChange = (e) => {
    setStudentStream({ ...studentStream, [e.target.name]: e.target.value });
  };

  // function to handle permissions change
  const handlePermissionsChange = (e) => {
    setUserPermissions({
      ...userPermissions,
      [e.target.name]: e.target.checked,
    });
  };
  // function to handle subjects change
  const handleSubjectsChange = (e) => {
    setUserSubjects({
      ...userPermissions,
      [e.target.name]: e.target.checked,
    });
  };

  // function to handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      ifEmpty(newUser) ||
      (account_type === "student" && ifEmpty(studentStream))
    ) {
      return alert(fillFields);
    }
    startLoading();
    createUser(
      { ...newUser, account_type, userPermissions, ...studentStream },
      userId,
      resetForm
    );
  };

  return (
    <MediumDialog isOpen={openAddUser}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter user details</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Account Type</label>
            <select
              name=""
              onChange={(e) => setAccountType(e.target.value)}
              value={account_type}
            >
              <option value="" selected disabled>
                Select account type
              </option>
              {account_types?.map((item) => (
                <option value={item?.slug} key={item.slug}>
                  {item?.name}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label htmlFor="">Group</label>
            <select name="group" value={group} onChange={handleChange}>
              <option value="" selected disabled>
                Select group
              </option>
              {groups?.map((group, index) => (
                <option value={group?.name} key={index}>
                  {group?.name}
                </option>
              ))}
            </select>
          </span>
        </div>
        {/* a section to show class and class stream selection if user to be created is student */}
        {account_type === "student" && (
          <div className="dialog__row">
            <span>
              <label htmlFor="">Class</label>
              <select
                name="stream_name"
                onChange={handleStreamChange}
                value={stream_name}
              >
                <option value="" disabled selected>
                  Select class
                </option>
                {class_streams?.map((item, index) => (
                  <option value={item?.name} key={index}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <label htmlFor="">Admission Number</label>
              <input
                type="text"
                name="admission_number"
                value={admission_number}
                onChange={handleStreamChange}
              />
            </span>
          </div>
        )}

        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}

        <div className="dialog__row">
          <span>
            <label htmlFor="">First Name</label>
            <input
              type="text"
              name="first_name"
              value={first_name}
              onChange={handleChange}
            />
          </span>
          <span>
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={last_name}
              onChange={handleChange}
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Surname</label>
            <input
              type="text"
              name="surname"
              value={surname}
              onChange={handleChange}
            />
          </span>
          <span>
            <label htmlFor="">Email</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
            />
          </span>
        </div>

        {(account_type === "teacher" || account_type === "management") && (
          <div className="dialog__twoColumn">
            <div className="dialog__rowSingleItem">
              <label htmlFor="">User Permissions</label>
              <div className="dialog__checkBoxListing">
                {available_permissions?.map((permission) => (
                  <div
                    className="dialog__checkBoxListingItem"
                    key={permission?.codename}
                  >
                    <input
                      type="checkbox"
                      name={permission?.codename}
                      checked={userPermissions?.codename}
                      onChange={handlePermissionsChange}
                    />
                    <label htmlFor="">{permission?.name}</label>
                  </div>
                ))}
              </div>
            </div>
            {/* show subjects if teacher is being created */}
            {account_type === "teacher" && (
              <div className="dialog__rowSingleItem">
                <label htmlFor="">Subjects</label>
                {subjects?.map((subject) => (
                  <div
                    className="dialog__checkBoxListingItem"
                    key={subject?.name}
                  >
                    <input
                      type="checkbox"
                      name={subject?.name}
                      checked={userSubjects?.codename}
                      onChange={handleSubjectsChange}
                    />
                    <label htmlFor="">{subject?.name}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        <div className="form__Buttons">
          <button type="button" onClick={closeUserForm}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared.loading,
    available_permissions: state.auth.permissions,
    userId: state.auth.user.id,
    groups: state.auth.groups,
    subjects: state.course?.subjects,
    class_streams: state.course?.class_streams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createUser: (newUser, userId, resetForm) =>
      dispatch(create_user(newUser, userId, resetForm)),
    getGroups: (userId) => dispatch(get_groups(userId)),
    adminGetClassStreams: (userId) => dispatch(admin_get_class_streams(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
