// import installed packages
import { useState } from "react";
import { connect } from "react-redux";
// import styles
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import globals from "../../../../../shared/globals";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
// import components/pages
import MinDialog from "../../../../../components/common/MinDialog";
// import redux API
import { START_LOADING } from "../../../../../redux/actions/types";
import { create_new_class_stream } from "../../../../../redux/actions/course";

const AddClassStream = (props) => {
  const { openAddClassStream, loading, userId, class_levels, teachers } = props; // get state from props
  const { setOpenAddClassStream, startLoading, createNewClassStream } = props; // get dispatch actions from props
  const { fillFields } = globals;
  const [newClassStream, setNewClassStream] = useState({
    name: "",
    class_teacher: "",
    level: "",
  });

  const { name, class_teacher, level } = newClassStream;

  const resetForm = () => {
    resetFormValues(newClassStream);
  };
  const handleClose = () => {
    resetForm();
    setOpenAddClassStream(false);
  };

  const handleChange = (e) => {
    setNewClassStream({ ...newClassStream, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(newClassStream)) {
      return alert(fillFields);
    }
    startLoading();
    createNewClassStream(newClassStream, userId, resetForm);
  };

  return (
    <MinDialog isOpen={openAddClassStream}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter class stream details</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Class Stream Name</label>
          <input type="text" name="name" value={name} onChange={handleChange} />
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Class Level</label>
          <select name="level" onChange={handleChange} value={level}>
            <option value="" selected disabled>
              Select level
            </option>
            {class_levels?.length > 0 ? (
              <>
                {class_levels?.map((level) => (
                  <option value={level?.id} key={level?.id}>
                    {level?.name}
                  </option>
                ))}
              </>
            ) : (
              <>
                <span>No class levels created yet</span>
              </>
            )}
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "41%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Class Teacher</label>
          <select
            name="class_teacher"
            value={class_teacher}
            onChange={handleChange}
          >
            <option value="">Select class teacher</option>
            {teachers?.length > 0 ? (
              <>
                {teachers?.map((teacher) => (
                  <option value={teacher?.id} key={teacher?.id}>
                    {teacher?.first_name} {teacher?.last_name}{" "}
                    {teacher?.surname}
                  </option>
                ))}
              </>
            ) : (
              <span>No teachers added yet</span>
            )}
          </select>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    userId: state.auth.user?.id,
    class_levels: state.course?.class_levels,
    teachers: state.course?.teachers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createNewClassStream: (newClassStream, userId, resetForm) =>
      dispatch(create_new_class_stream(newClassStream, userId, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClassStream);
