import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../components/common/MediumDialog";
import { change_staffs_groups } from "../../../../redux/actions/auth";
import { START_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";

const GroupsAllocated = (props) => {
  const { openGroupsAllocated, currentStaff, userType, userId, loading } =
    props;
  const { setOpenGroupsAllocated, changeStaffGroups, startLoading } = props;
  const [currentStaffGroups, setCurrentStaffGroups] = useState([]);

  useEffect(() => {
    setCurrentStaffGroups(currentStaff?.staff_groups);
  }, [currentStaff?.staff_groups]);

  const handleGroupChange = (e, groupIndex) => {
    const arrayToMutate = [...currentStaffGroups];

    arrayToMutate[groupIndex] = {
      ...arrayToMutate[groupIndex],
      is_member: e.target.checked,
    };

    setCurrentStaffGroups(arrayToMutate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      currentStaffGroups,
      currentStaffUserId: currentStaff?.id,
      userType,
    };
    startLoading();
    changeStaffGroups(userId, body);
  };

  return (
    <MediumDialog isOpen={openGroupsAllocated} maxWidth="600px">
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>
          Staff groups in which {currentStaff?.first_name}{" "}
          {currentStaff?.last_name} is authorized
        </h3>
        <br />
        <table className="table__listing">
          <tr className="table__listingHeader">
            <th>Group Name</th>
            <th className="tc">Has Rights</th>
          </tr>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {currentStaffGroups?.map((group, index) => (
            <tr key={group?.name} className="table__listingItem">
              <td>{group?.name} Group</td>
              <td className="tc">
                <input
                  type="checkbox"
                  name=""
                  onChange={(e) => handleGroupChange(e, index)}
                  checked={group?.is_member}
                />
              </td>
            </tr>
          ))}
        </table>
        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenGroupsAllocated(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    changeStaffGroups: (userId, body, userType) =>
      dispatch(change_staffs_groups(userId, body, userType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsAllocated);
