import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  allStaff: [],
  leaveApplications: [],
  pendingLeaveApplications: [],
  salaryDeductions: [],
  taxReliefs: [],
  salaryEarnings: [],
  PAYEBrackets: [],
  payrolls: [],
};

const setLeaveApplications = (state, payload) => {
  return updateObject(state, {
    leaveApplications: payload?.leave_applications_data,
    pendingLeaveApplications: payload?.pending_leave_applications_data,
  });
};

const setNewLeaveApplication = (state, payload) => {
  return updateObject(state, {
    leaveApplications: [...state.leaveApplications, payload],
  });
};

const approveOrRejectLeaveApplication = (state, payload) => {
  return updateObject(state, {
    pendingLeaveApplications: state?.pendingLeaveApplications?.filter(
      (leave_application) => leave_application?.id !== payload
    ),
  });
};

const setStaff = (state, payload) => {
  return updateObject(state, {
    allStaff: payload,
  });
};

const setSalaryDeductions = (state, payload) => {
  return updateObject(state, {
    salaryDeductions: payload?.salary_deductions_data,
    taxReliefs: payload?.tax_reliefs_data,
  });
};

const createSalaryDeduction = (state, payload) => {
  return updateObject(state, {
    salaryDeductions: [...state?.salaryDeductions, payload],
  });
};

const editSalaryDeduction = (state, payload) => {
  return updateObject(state, {
    salaryDeductions: state?.salaryDeductions?.map((deduction) =>
      deduction?.id === payload?.id ? payload : deduction
    ),
  });
};

const createSalaryEarning = (state, payload) => {
  return updateObject(state, {
    salaryEarnings: [...state?.salaryEarnings, payload],
  });
};

const editSalaryEarning = (state, payload) => {
  return updateObject(state, {
    salaryEarnings: state?.salaryEarnings?.map((earning) =>
      earning?.id === payload?.id ? payload : earning
    ),
  });
};

const setSalaryEarnings = (state, payload) => {
  return updateObject(state, {
    salaryEarnings: payload,
  });
};

const updateStaffDetails = (state, payload) => {
  return updateObject(state, {
    allStaff: state?.allStaff?.map((staff) =>
      staff?.id === payload?.id ? payload : staff
    ),
  });
};

const deleteSalaryDeduction = (state, payload) => {
  return updateObject(state, {
    salaryDeductions: state?.salaryDeductions?.filter(
      (deduction) => deduction?.id !== payload
    ),
  });
};
const deleteSalaryEarning = (state, payload) => {
  return updateObject(state, {
    salaryEarnings: state?.salaryEarnings?.filter(
      (earning) => earning?.id !== payload
    ),
  });
};

const createPAYEBracket = (state, payload) => {
  return updateObject(state, {
    PAYEBrackets: [...state?.PAYEBrackets, payload],
  });
};

const setPAYEBrackets = (state, payload) => {
  return updateObject(state, {
    PAYEBrackets: payload,
  });
};

const deletePAYEBracket = (state, payload) => {
  return updateObject(state, {
    PAYEBrackets: state?.PAYEBrackets?.filter(
      (bracket) => bracket?.id !== payload
    ),
  });
};

const createPayroll = (state, payload) => {
  return updateObject(state, {
    payrolls: [...state?.payrolls, payload],
  });
};
const editPayroll = (state, payload) => {
  return updateObject(state, {
    payrolls: state?.payrolls?.map((payroll) =>
      payroll?.id === payload?.id ? payload : payroll
    ),
  });
};

const setPayrolls = (state, payload) => {
  return updateObject(state, {
    payrolls: payload,
  });
};

const createTaxRelief = (state, payload) => {
  return updateObject(state, {
    taxReliefs: [...state?.taxReliefs, payload],
  });
};

const editTaxRelief = (state, payload) => {
  return updateObject(state, {
    taxReliefs: state?.taxReliefs?.map((relief) =>
      relief?.id === payload?.id ? payload : relief
    ),
  });
};

const deleteTaxRelief = (state, payload) => {
  return updateObject(state, {
    taxReliefs: state?.taxReliefs?.filter((relief) => relief?.id !== payload),
  });
};

const staffReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.NEW_LEAVE_APPLICATION:
      return setNewLeaveApplication(state, payload);
    case actionTypes.SET_LEAVE_APPLICATIONS:
      return setLeaveApplications(state, payload);
    case actionTypes.APPROVE_OR_REJECT_LEAVE:
      return approveOrRejectLeaveApplication(state, payload);
    case actionTypes.SET_STAFF:
      return setStaff(state, payload);
    case actionTypes.SET_SALARY_DEDUCTIONS:
      return setSalaryDeductions(state, payload);
    case actionTypes.NEW_SALARY_DEDUCTION:
      return createSalaryDeduction(state, payload);
    case actionTypes.EDIT_SALARY_DEDUCTION:
      return editSalaryDeduction(state, payload);
    case actionTypes.NEW_SALARY_EARNING:
      return createSalaryEarning(state, payload);
    case actionTypes.EDIT_SALARY_EARNING:
      return editSalaryEarning(state, payload);
    case actionTypes.SET_SALARY_EARNINGS:
      return setSalaryEarnings(state, payload);
    case actionTypes.UPDATE_STAFF_DETAILS:
      return updateStaffDetails(state, payload);
    case actionTypes.DELETE_SALARY_DEDUCTION:
      return deleteSalaryDeduction(state, payload);
    case actionTypes.DELETE_SALARY_EARNING:
      return deleteSalaryEarning(state, payload);
    case actionTypes.NEW_PAYE_BRACKET:
      return createPAYEBracket(state, payload);
    case actionTypes.SET_PAYE_BRACKETS:
      return setPAYEBrackets(state, payload);
    case actionTypes.DELETE_PAYE_BRACKET:
      return deletePAYEBracket(state, payload);
    case actionTypes.NEW_PAYROLL:
      return createPayroll(state, payload);
    case actionTypes.EDIT_PAYROLL:
      return editPayroll(state, payload);
    case actionTypes.SET_PAYROLLS:
      return setPayrolls(state, payload);
    case actionTypes.NEW_TAX_RELIEF:
      return createTaxRelief(state, payload);
    case actionTypes.EDIT_TAX_RELIEF:
      return editTaxRelief(state, payload);
    case actionTypes.DELETE_TAX_RELIEF:
      return deleteTaxRelief(state, payload);
    default:
      return state;
  }
};

export default staffReducer;
