import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  teacher_delete_assignment,
  teacher_get_assignments,
} from "../../../../redux/actions/exam";
import { START_LOADING } from "../../../../redux/actions/types";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";

import AssignmentSubmissions from "./components/AssignmentSubmissions";
import AddAssignment from "./components/AddAssignment";
import EditAssignment from "./components/EditAssignment";

const Assignments = (props) => {
  const { userId, teacherAssignments, loading } = props;
  const { teacherGetAssignments, startLoading, teacherDeleteAssignment } =
    props;

  const [openAddAssignment, setOpenAddAssignment] = useState(false);
  const [openEditAssignment, setOpenEditAssignment] = useState(false);
  const [openAssignmentSubmissions, setOpenAssignmentSubmissions] =
    useState(false);
  const [currentAssignment, setCurrentAssignment] = useState({});

  useEffect(() => {
    if (userId && teacherAssignments?.length === 0) {
      startLoading();
      teacherGetAssignments(userId);
    }
  }, [teacherAssignments?.length, userId, teacherGetAssignments, startLoading]);

  const handleOpenAssignmentSubmissions = (assignment) => {
    setOpenAssignmentSubmissions(true);
    setCurrentAssignment(assignment);
  };

  const deleteAssignment = (assignmentId) => {
    if (window.confirm(`Are you sure you want to delete this assignment?`)) {
      startLoading();
      teacherDeleteAssignment(userId, assignmentId);
    }
  };

  const handleEditAssignment = (assignment) => {
    setCurrentAssignment(assignment);
    setOpenEditAssignment(true);
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__parentHeader">
          <h3>Maintain student assignments</h3>
          <button type="button" onClick={() => setOpenAddAssignment(true)}>
            Add Assignment
          </button>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {teacherAssignments?.length > 0 ? (
          <table className="table__listing">
            <tr className="table__listingHeader">
              <th>Subject</th>
              <th>Title</th>
              <th>Due Date</th>
              <th>Edit</th>
              <th>Submissions</th>
              <th>Deactivate</th>
              <th>Delete</th>
            </tr>
            {teacherAssignments?.map((assignment) => (
              <tr className="table__listingItem" key={assignment?.id}>
                <td>{assignment?.subject}</td>
                <td>{assignment?.title}</td>
                <td>{moment(assignment?.due_date).format("LLL")}</td>
                <td
                  className="green bd button__sp"
                  onClick={() => handleEditAssignment(assignment)}
                >
                  Edit
                </td>
                <td
                  className="dodgerblue bd button__sp"
                  onClick={() => handleOpenAssignmentSubmissions(assignment)}
                >
                  View Submissions
                </td>
                <td className="bd button__sp">
                  {assignment?.is_active ? (
                    <span
                      className="red"
                      onClick={() => deleteAssignment(assignment?.id)}
                    >
                      Deactivate
                    </span>
                  ) : (
                    <span
                      className="green"
                      onClick={() => deleteAssignment(assignment?.id)}
                    >
                      Activate
                    </span>
                  )}
                </td>
                <td
                  className="red bd button__sp"
                  onClick={() => deleteAssignment(assignment?.id)}
                >
                  Delete
                </td>
              </tr>
            ))}
          </table>
        ) : (
          <h4 className="not__available">
            You have not added any assignments yet
          </h4>
        )}
      </div>
      {/* child components */}

      {openAddAssignment && (
        <AddAssignment
          openAddAssignment={openAddAssignment}
          setOpenAddAssignment={setOpenAddAssignment}
        />
      )}
      {openAssignmentSubmissions && (
        <AssignmentSubmissions
          setOpenAssignmentSubmissions={setOpenAssignmentSubmissions}
          openAssignmentSubmissions={openAssignmentSubmissions}
          currentAssignment={currentAssignment}
        />
      )}
      {openEditAssignment && (
        <EditAssignment
          openEditAssignment={openEditAssignment}
          setOpenEditAssignment={setOpenEditAssignment}
          currentAssignment={currentAssignment}
          setCurrentAssignment={setCurrentAssignment}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    teacherAssignments: state?.exam?.teacherAssignments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    teacherGetAssignments: (userId) =>
      dispatch(teacher_get_assignments(userId)),
    teacherDeleteAssignment: (userId, assignmentId) =>
      dispatch(teacher_delete_assignment(userId, assignmentId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assignments);
