import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { teacher_create_assignment } from "../../../../../redux/actions/exam";
import { START_LOADING } from "../../../../../redux/actions/types";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import { get_teacher_subjects } from "../../../../../redux/actions/course";

const AddAssignment = (props) => {
  const { userId, loading, openAddAssignment, teacher_subjects } = props;
  const {
    setOpenAddAssignment,
    startLoading,
    teacherCreateAssignment,
    getTeacherSubjects,
  } = props;

  const [assignmentDetails, setAssignmentDetails] = useState({
    title: "",
    due_date: "",
    due_time: "",
    pass_mark: "",
    subject_alloted: "",
  });

  const { title, due_date, pass_mark, subject_alloted, due_time } =
    assignmentDetails;
  const [attachment, setAttachment] = useState(null);

  useEffect(() => {
    if (teacher_subjects?.length === 0 && userId) {
      startLoading();
      getTeacherSubjects(userId);
    }
  }, [startLoading, getTeacherSubjects, teacher_subjects?.length, userId]);

  const handleChange = (e) => {
    setAssignmentDetails({
      ...assignmentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setAttachment(e.target.files[0]);
  };

  const resetForm = () => {
    resetFormValues(assignmentDetails);
  };

  const handleClose = () => {
    resetForm();
    setOpenAddAssignment(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(assignmentDetails) || !attachment) {
      return window.alert("Please fill in all fields");
    }
    startLoading();

    let body = new FormData();
    body.append("title", title);
    body.append("pass_mark", pass_mark);
    body.append("due_date", due_date);
    body.append("due_time", due_time);
    body.append("subject_alloted", subject_alloted);
    body.append("attachment", attachment, attachment.name);

    teacherCreateAssignment(userId, body, resetForm);
  };
  return (
    <MediumDialog isOpen={openAddAssignment}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter assignment details</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Class stream &amp; subject</label>
            <select
              name="subject_alloted"
              onChange={handleChange}
              value={subject_alloted}
            >
              <option value="">--select--</option>
              {teacher_subjects?.map((subject) => (
                <option value={subject?.id} key={subject?.id}>
                  {subject?.subject_stream}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label htmlFor="">Title</label>
            <input
              type="text"
              name="title"
              onChange={handleChange}
              value={title}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <span>
            <label htmlFor="">Due date</label>
            <input
              type="date"
              name="due_date"
              onChange={handleChange}
              value={due_date}
            />
          </span>
          <span>
            <label htmlFor="">Due Time</label>
            <input
              type="time"
              name="due_time"
              onChange={handleChange}
              value={due_time}
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Pass Mark (%)</label>
            <input
              type="number"
              name="pass_mark"
              onChange={handleChange}
              value={pass_mark}
            />
          </span>
          <span>
            <label htmlFor="">Attachment</label>
            <input type="file" name="" onChange={handleFileChange} />
          </span>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    teacher_subjects: state.course?.teacher_subjects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    teacherCreateAssignment: (userId, body, resetForm) =>
      dispatch(teacher_create_assignment(userId, body, resetForm)),
    getTeacherSubjects: (userId) => dispatch(get_teacher_subjects(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAssignment);
