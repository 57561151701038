import React, { useState } from "react";
import { v4 as uuid4 } from "uuid";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import {
  formatWithCommas,
  ifEmpty,
  maxDate,
} from "../../../../shared/sharedFunctions";
// import components/pages
import MaxDialog from "../../../../components/common/MaxDialog";
// import redux API
import { START_LOADING } from "../../../../redux/actions/types";
import { post_new_transaction } from "../../../../redux/actions/finance";

const PostPayment = (props) => {
  const { openPostEntry, loading, userId, bank_cash_ledgers, ledgers } = props;
  const { setOpenPostEntry, startLoading, postNewTransaction } = props;

  const [transaction_type, set_transaction_type] = useState("");

  const [payingOrReceivingLedger, setPayingOrReceivingLedger] = useState("");
  const [transaction_date, set_transaction_date] = useState("");
  const [memo, setMemo] = useState("");

  const [currentSubentry, setCurrentSubentry] = useState({
    ledger: "",
    amount: "",
    entry_type: "",
  });

  const [subEntries, setSubEntries] = useState([]);

  const resetForm = () => {
    set_transaction_type("");
    setPayingOrReceivingLedger("");
    set_transaction_date("");
    setMemo("");
    setCurrentSubentry({
      ledger: "",
      amount: "",
      entry_type: "",
      entry_id: "", // used to enable removal of entries
    });

    setSubEntries([]);
  };

  const handleClose = () => {
    resetForm();
    setOpenPostEntry(false);
  };

  // to avoid potential bugs when a user changes between payment/journal/receipt, we reset subentries when transaction type changes
  const handleTransactionTypeChange = (e) => {
    set_transaction_type(e.target.value);
    setSubEntries([]);
  };

  const handleChange = (e) => {
    if (transaction_type === "receipt") {
      setCurrentSubentry({
        ...currentSubentry,
        [e.target.name]: e.target.value,
        entry_type: "Credit",
        entry_id: uuid4(),
      });
    } else if (transaction_type === "payment") {
      setCurrentSubentry({
        ...currentSubentry,
        [e.target.name]: e.target.value,
        entry_type: "Debit",
        entry_id: uuid4(),
      });
    } else {
      setCurrentSubentry({
        ...currentSubentry,
        [e.target.name]: e.target.value,
        entry_id: uuid4(),
      });
    }
  };

  // function to removw sub entry
  const removeEntry = (entryId) => {
    setSubEntries(subEntries?.filter((entry) => entry.entry_id !== entryId));
  };

  const handleAddSubentries = () => {
    if (ifEmpty(currentSubentry)) {
      return window.alert("Enter values in all the indicated fields");
    }
    setSubEntries([...subEntries, currentSubentry]);
    setCurrentSubentry({
      ledger: "",
      amount: "",
      entry_type: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (transaction_type?.trim() === "" || transaction_date.trim() === "") {
      return window.alert("Please fill in all required details");
    }
    if (
      (transaction_type?.trim() === "receipt" ||
        transaction_type?.trim() === "payment") &&
      payingOrReceivingLedger.trim() === ""
    ) {
      return window.alert("You must fill in all fields");
    }
    if (subEntries?.length === 0) {
      return window.alert(
        "You must enter allocate this transaction to the respective ledgers"
      );
    }
    startLoading();
    const body = {
      transaction_type,
      transaction_date,
      memo,
      payingOrReceivingLedger,
      subEntries: [...subEntries],
    };
    postNewTransaction(userId, body, resetForm);
  };

  return (
    <MaxDialog isOpen={openPostEntry} maxWidth="1100px" maxHeight="80vh">
      <form
        className="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Main entry details</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Transaction Type*</label>
            <select
              name=""
              onChange={handleTransactionTypeChange}
              value={transaction_type}
            >
              <option value="" disabled selected>
                Select type
              </option>
              <option value="journal">Journal</option>
              <option value="payment">Payment</option>
              <option value="receipt">Receipt</option>
            </select>
          </span>
          <span>
            <label htmlFor="">Date*</label>
            <input
              type="date"
              onChange={(e) => set_transaction_date(e.target.value)}
              value={transaction_date}
              max={maxDate()}
            />
          </span>
          {/* if transaction is a payment */}
          {transaction_type === "payment" && (
            <span>
              <label htmlFor="">Select paying bank/cash*</label>
              <select
                onChange={(e) => setPayingOrReceivingLedger(e.target.value)}
                value={payingOrReceivingLedger}
              >
                {bank_cash_ledgers?.length > 0 ? (
                  <>
                    <option value="" disabled selected>
                      ---select----
                    </option>
                    {bank_cash_ledgers?.map((bank_ledger) => (
                      <option value={bank_ledger?.name}>
                        {bank_ledger?.name}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="">No bank or cash ledgers created yet</option>
                )}
              </select>
            </span>
          )}
          {/* if transaction is a receipt */}
          {transaction_type === "receipt" && (
            <span>
              <label htmlFor="">Select receiving bank/cash</label>
              <select
                onChange={(e) => setPayingOrReceivingLedger(e.target.value)}
                value={payingOrReceivingLedger}
              >
                {bank_cash_ledgers?.length > 0 ? (
                  <>
                    <option value="" disabled selected>
                      ---select----
                    </option>
                    {bank_cash_ledgers?.map((bank_ledger) => (
                      <option value={bank_ledger?.name}>
                        {bank_ledger?.name}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="">No bank or cash ledgers created yet</option>
                )}
              </select>
            </span>
          )}
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Narration (optional)</label>
            <input
              type="text"
              onChange={(e) => setMemo(e.target.value)}
              value={memo}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {/* section to add entries */}
        {transaction_type.trim() !== "" && (
          <>
            <h3>Allocate Transactions</h3>
            <p
              style={{
                textAlign: "center",
                fontSize: "0.9rem",
                color: "green",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Enter a transaction details below and click allocate transaction
              button
            </p>
            <div className="dialog__row">
              <span>
                <label htmlFor="">Ledger*</label>
                <select
                  name="ledger"
                  onChange={handleChange}
                  value={currentSubentry?.ledger}
                >
                  <option value="">---select---</option>
                  {ledgers?.map((ledger) => (
                    // we can use ledger name since it is unique, plus we need the name to display the subentries
                    <option value={ledger?.name}>{ledger?.name}</option>
                  ))}
                </select>
              </span>
              <span>
                <label htmlFor="">Amount*</label>
                <input
                  type="number"
                  name="amount"
                  onChange={handleChange}
                  value={currentSubentry?.amount}
                  min="1"
                />
              </span>
              {/* if it's a journal select if entry is debit or credit */}
              {transaction_type === "journal" && (
                <span>
                  <label htmlFor="">Entry type*</label>
                  <select
                    name="entry_type"
                    onChange={handleChange}
                    value={currentSubentry?.entry_type}
                  >
                    <option value="" selected disabled>
                      ---select---
                    </option>
                    <option value="Debit">Debit</option>
                    <option value="Credit">Credit</option>
                  </select>
                </span>
              )}
            </div>
            <br />
            <div className="dialog__row">
              <button
                className="add__button"
                onClick={handleAddSubentries}
                type="button"
              >
                Allocate Transaction
              </button>
            </div>

            <h3>Added subentries</h3>
            {subEntries?.length > 0 ? (
              <>
                <table className="table__listing">
                  <tr className="table__listingHeader">
                    <th>Ledger</th>
                    <th className="tc">Debit</th>
                    <th className="tc">Credit</th>
                    <th className="tc">Delete</th>
                  </tr>

                  {subEntries?.map((item, index) => (
                    <tr className="table__listingItem" key={index}>
                      <td>{item?.ledger}</td>
                      <td className="tc">
                        {item?.entry_type === "Debit" && (
                          <span>{formatWithCommas(item?.amount)}</span>
                        )}
                      </td>
                      <td className="tc">
                        {item?.entry_type === "Credit" && (
                          <span>{formatWithCommas(item?.amount)}</span>
                        )}
                      </td>
                      <td className="tc red" title="click to remove entry">
                        <i
                          className="bx bxs-trash-alt button__sp"
                          onClick={() => removeEntry(item?.entry_id)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </table>
              </>
            ) : (
              <h4 className="not__available">No subentries added yet</h4>
            )}
          </>
        )}

        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Post</button>
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    bank_cash_ledgers: state.finance.ledgers.filter(
      (ledger) => ledger.is_bank_or_cash === true
    ),
    ledgers: state?.finance?.ledgers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    postNewTransaction: (userId, body, resetForm) =>
      dispatch(post_new_transaction(userId, body, resetForm)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PostPayment);
