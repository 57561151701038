import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import {
  START_LOADING,
  STOP_LOADING,
  UPDATE_SUBJECT,
} from "../../../../../redux/actions/types";
import { ifEmpty } from "../../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../../shared/axios";
import { showError } from "../../../../../redux/actions/shared";

const AddSubjectGrade = (props) => {
  const dispatch = useDispatch();
  const { openAddSubjectGrade, currentSubject, loading, userId } = props;
  const {
    setOpenAddSubjectGrade,
    startLoading,
    stopLoading,
    setCurrentSubject,
  } = props;

  const [gradingDetails, setGradingDetails] = useState({
    start_marks: "",
    end_marks: "",
    points: "",
    grade: "",
  });
  const { start_marks, end_marks, points, grade } = gradingDetails;

  const handleChange = (e) => {
    setGradingDetails({ ...gradingDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setGradingDetails({
      start_marks: "",
      end_marks: "",
      points: "",
      grade: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Number(end_marks) < Number(start_marks)) {
      return window.alert("End marks must be greater than start marks");
    }
    const body = {
      ...gradingDetails,
      subject: currentSubject?.id,
      grade: grade?.toUpperCase(),
    };
    startLoading();
    await API.post(`/api/course/maintain-subject-grade/${userId}/`, body)
      .then((res) => {
        resetForm();
        dispatch({
          type: UPDATE_SUBJECT,
          payload: res?.data?.updated_subject,
        });
        setCurrentSubject(res?.data?.updated_subject);
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MediumDialog isOpen={openAddSubjectGrade} maxWidth="600px">
      <form
        class="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>Add new Grade for {currentSubject?.name}</h3>
        <div class="dialog__row">
          <span>
            <label for="">Start Marks</label>
            <input
              type="number"
              name="start_marks"
              min="0"
              max="100"
              onChange={handleChange}
              value={start_marks}
              required
            />
          </span>
          <span>
            <label for="">End Marks</label>
            <input
              type="number"
              name="end_marks"
              min="0"
              max="100"
              onChange={handleChange}
              value={end_marks}
              required
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__row">
          <span>
            <label for="">Points (0 - 12)</label>
            <input
              type="number"
              name="points"
              min="0"
              max="12"
              value={points}
              onChange={handleChange}
              required
            />
          </span>
          <span>
            <label for="">Grade</label>
            <input
              type="text"
              name="grade"
              value={grade}
              onChange={handleChange}
              required
            />
          </span>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenAddSubjectGrade(false)}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSubjectGrade);
