import React, { useRef } from "react";
import moment from "moment";
import MaxDialog from "../../../../../components/common/MaxDialog";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { formatWithCommas } from "../../../../../shared/sharedFunctions";

const StaffNetPayBalances = (props) => {
  const { openReportTemplate, reportData, end_date, schoolName } = props;
  const { setOpenReportTemplate } = props;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Students Balances Report",
  });

  return (
    <MaxDialog isOpen={openReportTemplate} maxWidth="1200px" maxHeight="85vh">
      <div class="dialog">
        <table
          className="report__statement"
          border="1"
          rules="rows"
          ref={printArea}
          id="staff_net_pay_balances"
        >
          <thead>
            <tr>
              <th colSpan="8" className="dodgerblue">
                {schoolName}
              </th>
            </tr>
            <tr>
              <th colSpan="8" className="dodgerblue">
                Staff Net Pay Balances
              </th>
            </tr>
            <tr>
              <th colSpan="8" className="dodgerblue">
                As at {moment(end_date).format("LL")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="bbottom purple tl">Staff Name</th>
              <th className="bbottom purple tc">Balance (Kshs)</th>
            </tr>
            {reportData
              ?.filter((staff) => staff !== null)
              ?.map((staff) => (
                <tr key={staff?.id}>
                  <td>{staff?.name}</td>
                  <td className="tc">
                    {formatWithCommas(staff?.closing_balance)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenReportTemplate(false)}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print/Save
          </button>
        </div>
        <div class="export_to_excel_button">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="staff_net_pay_balances"
            filename="staff_net_pay_balances"
            sheet="staff_net_pay_balances"
            buttonText="Export To Excel"
          />
        </div>
      </div>
    </MaxDialog>
  );
};

export default StaffNetPayBalances;
