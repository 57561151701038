import React from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../components/common/MediumDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { START_LOADING } from "../../../../redux/actions/types";
import { edit_book } from "../../../../redux/actions/library";

const EditBook = (props) => {
  const { openEditBook, currentBook, loading, userId, subjects, class_levels } =
    props;
  const { setOpenEditBook, setCurrentBook, startLoading, editBook } = props;

  const {
    name,
    author,
    book_code,
    year_published,
    category,
    subject,
    class_level,
  } = currentBook;

  const handleChange = (e) => {
    setCurrentBook({ ...currentBook, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpenEditBook(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    const body = {
      ...currentBook,
    };
    editBook(userId, body);
  };

  return (
    <MediumDialog isOpen={openEditBook}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit book details</h3>
        <div class="dialog__row">
          <span>
            <label for="">Name</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={name}
            />
          </span>
          <span>
            <label for="">Author</label>
            <input
              type="text"
              name="author"
              onChange={handleChange}
              value={author}
            />
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Book Code</label>
            <input
              type="text"
              name="book_code"
              onChange={handleChange}
              value={book_code}
            />
          </span>
          <span>
            <label for="">Year Published</label>
            <input
              type="number"
              name="year_published"
              onChange={handleChange}
              value={year_published}
              min="1"
            />
          </span>
          <span>
            <label for="">Category</label>
            <select name="category" onChange={handleChange} value={category}>
              <option value="" selected disabled>
                --select--
              </option>
              <option value="academic">Academic</option>
              <option value="inspirational">Inspirational</option>
              <option value="novel">Novel</option>
            </select>
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {category === "academic" && (
          <div class="dialog__row">
            <span>
              <label for="">Subject</label>
              <select name="subject" onChange={handleChange} value={subject}>
                <option value="" disabled selected>
                  --select subject--
                </option>
                {subjects?.map((subject) => (
                  <option value={subject?.id} key={subject?.id}>
                    {subject?.name}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <label for="">Class Level</label>
              <select
                name="class_level"
                onChange={handleChange}
                value={class_level}
              >
                <option value="" disabled selected>
                  --select level--
                </option>
                {class_levels?.map((level) => (
                  <option value={level?.id} key={level?.id}>
                    {level?.name}
                  </option>
                ))}
              </select>
            </span>
          </div>
        )}
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    subjects: state.course?.subjects,
    class_levels: state.course?.class_levels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editBook: (userId, body) => dispatch(edit_book(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBook);
