import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { get_teacher_subjects } from "../../../../../redux/actions/course";
import { START_LOADING } from "../../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import { teacher_edit_assignment } from "../../../../../redux/actions/exam";

const EditAssignment = (props) => {
  const {
    currentAssignment,
    openEditAssignment,
    loading,
    userId,
    teacher_subjects,
  } = props;
  const {
    startLoading,
    getTeacherSubjects,
    setCurrentAssignment,
    setOpenEditAssignment,
    teacherEditAssignment,
  } = props;

  const { subject_alloted, title, due_date_value, due_time_value, pass_mark } =
    currentAssignment;

  const [attachment, setAttachment] = useState("");

  useEffect(() => {
    if (teacher_subjects?.length === 0 && userId) {
      startLoading();
      getTeacherSubjects(userId);
    }
  }, [startLoading, getTeacherSubjects, teacher_subjects?.length, userId]);

  const handleChange = (e) => {
    setCurrentAssignment({
      ...currentAssignment,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    let body = new FormData();
    body.append("id", currentAssignment?.id);
    body.append("title", title);
    body.append("pass_mark", pass_mark);
    body.append("due_date_value", due_date_value);
    body.append("due_time_value", due_time_value);
    body.append("subject_alloted", subject_alloted);
    if (attachment !== "") {
      body.append("attachment", attachment, attachment.name);
    } else {
      body.append("attachment", "");
    }

    teacherEditAssignment(userId, body);
  };

  return (
    <MediumDialog isOpen={openEditAssignment}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter assignment details</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Class stream &amp; subject</label>
            <select
              name="subject_alloted"
              onChange={handleChange}
              value={subject_alloted}
            >
              <option value="">--select--</option>
              {teacher_subjects?.map((subject) => (
                <option value={subject?.id} key={subject?.id}>
                  {subject?.subject_stream}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label htmlFor="">Title</label>
            <input
              type="text"
              name="title"
              onChange={handleChange}
              value={title}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <span>
            <label htmlFor="">Due date</label>
            <input
              type="date"
              name="due_date_value"
              onChange={handleChange}
              value={due_date_value}
            />
          </span>
          <span>
            <label htmlFor="">Due Time</label>
            <input
              type="time"
              name="due_time_value"
              onChange={handleChange}
              value={due_time_value}
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Pass Mark (%)</label>
            <input
              type="number"
              name="pass_mark"
              onChange={handleChange}
              value={pass_mark}
            />
          </span>
          <span>
            <label htmlFor="">
              Attachment{" "}
              <span style={{ color: "red", fontSize: "0.8rem" }}>
                (Leave blank to maintain current attachment)
              </span>{" "}
            </label>
            <input
              type="file"
              name=""
              onChange={(e) => setAttachment(e.target.files[0])}
            />
          </span>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenEditAssignment(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    teacher_subjects: state.course?.teacher_subjects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeacherSubjects: (userId) => dispatch(get_teacher_subjects(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
    teacherEditAssignment: (userId, body) =>
      dispatch(teacher_edit_assignment(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAssignment);
