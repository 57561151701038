// axios items
import * as api from "../../api/index";

// redux API items

import * as actionTypes from "../actions/types";
import { showError, stopLoading } from "./shared";

// get valid license
export const get_valid_license = (userId) => async (dispatch) => {
  await api
    .getValidLicense(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_LICENSE,
        payload: res?.data?.license_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// create user group
export const create_group =
  (newGroup, userId, resetForm) => async (dispatch) => {
    await api
      .createGroup(newGroup, userId)
      .then((res) => {
        dispatch({ type: actionTypes.NEW_GROUP, payload: res.data?.new_group });
        window.alert(res.data.detail);
        resetForm();
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// get existing groups
export const get_groups = (userId) => async (dispatch) => {
  await api
    .getGroups(userId)
    .then((res) => {
      dispatch({ type: actionTypes.SET_GROUPS, payload: res.data.groups });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// function to create new user
export const create_user = (newUser, userId, resetForm) => async (dispatch) => {
  await api
    .createUser(newUser, userId)
    .then((res) => {
      resetForm();
      window.alert(res.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// get existing users
export const get_users = (userId, userType) => async (dispatch) => {
  await api
    .getUsers(userId, userType)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_USERS,
        payload: { users: res.data?.users, userType },
      });
      if (userType === "Teacher") {
        dispatch({
          type: actionTypes.SET_SUBJECT_ALLOTMENTS,
          payload: res.data?.alloted_subjects,
        });
      }
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// sign up user
export const signup_user = (newUser, resetForm) => async (dispatch) => {
  await api
    .signupUser(newUser)
    .then((res) => {
      // dispatch({ type: AUTH_SUCCESS, payload: data?.user });
      window.alert(res.data?.detail);
      resetForm();
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// activate user account
export const activate_account = (body) => async (dispatch) => {
  const { activation_token, navigate } = body;
  await api
    .activateAccount(activation_token)
    .then((res) => {
      alert(res.data?.detail);
      dispatch({ type: actionTypes.OPEN_LOGIN });
    })
    .catch((err) => showError(err))
    .finally(() => {
      stopLoading(dispatch);
      navigate("/", { replace: true });
    });
};

// resend user account activation link
export const resend_activation = (email, resetForm) => async (dispatch) => {
  await api
    .resendActivation(email)
    .then((res) => {
      window.alert(res.data?.detail);
      resetForm();
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// login user
export const login = (loginData, resetForm) => async (dispatch) => {
  await api
    .signIn(loginData)
    .then((res) => {
      localStorage.setItem("session_cookie", res.data?.access);
      resetForm();
      // dispatch success message
      dispatch({ type: actionTypes.CLOSE_LOGIN });
      // get user details
      dispatch(get_user());
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// reset user password by sending an email with a reset link
export const reset_password = (email, resetForm) => async (dispatch) => {
  await api
    .resetPassword(email)
    .then((res) => {
      window.alert(res.data?.detail);
      resetForm();
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// set new user password
export const set_password =
  (newPasswords, password_token, navigate) => async (dispatch) => {
    await api
      .setPassword(newPasswords, password_token)
      .then((res) => {
        window.alert(res.data?.detail);
        dispatch({ type: actionTypes.CLOSE_PASSWORD_RESET_CONFIRM });
        navigate("/", { replace: true });
        dispatch({ type: actionTypes.OPEN_LOGIN });
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// patch/update user data for user to update their own details such as name
export const update_user = (updatedUser, userId) => async (dispatch) => {
  await api
    .updateUser(updatedUser, userId)
    .then((res) => {
      dispatch({ type: actionTypes.EDIT_PROFILE, payload: res.data?.user });
      window.alert(res.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// user change password
export const change_password =
  (passwords, userId, navigate) => async (dispatch) => {
    await api
      .changePassword(passwords, userId)
      .then((res) => {
        window.alert(res.data?.detail);
        dispatch({ type: actionTypes.CLOSE_CHANGE_PASSWORD });
        dispatch(logout(navigate));
        dispatch({ type: actionTypes.OPEN_LOGIN });
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// get user data
export const get_user = () => async (dispatch) => {
  await api
    .getUser()
    .then((res) => {
      dispatch({ type: actionTypes.AUTH_SUCCESS, payload: res.data?.user });
    })
    .catch((err) => {
      dispatch({ type: actionTypes.LOGOUT });
      localStorage.clear();
    });
};

// POST REQUEST TO create new student/parent/teacher/other staff
export const create_new_user =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createNewUser(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_USER,
          payload: { new_user: res.data?.new_user, userType: body["userType"] },
        });
        resetForm();
        window.alert(res.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action for parent to get their children
export const parent_get_children = (userId) => async (dispatch) => {
  await api
    .parentGetChildren(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_CHILDREN,
        payload: res?.data?.parents_children,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action for school admin to change staffs groups
export const change_staffs_groups = (userId, body) => async (dispatch) => {
  await api
    .changeStaffsGroups(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.CHANGE_STAFF_GROUPS,
        payload: {
          userType: body["userType"],
          updated_staff: res?.data?.updated_staff,
        },
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action for admin to update user
export const admin_update_user = (userId, body) => async (dispatch) => {
  await api
    .adminUpdateUser(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        payload: {
          userType: body["userType"],
          user_data: res?.data?.updated_user,
        },
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// logout user
export const logout = () => async (dispatch) => {
  localStorage.clear();
  dispatch({ type: actionTypes.LOGOUT });
  window.location.replace("/"); // we use this to ensure page reloads, thus all state is cleared
};
