import React, { useState } from "react";
import MediumDialog from "../../../../components/common/MediumDialog";
import { formatWithCommas } from "../../../../shared/sharedFunctions";
import AddStaffSalary from "./AddStaffSalary";
import EditStaffSalary from "./EditStaffSalary";

const StaffSalary = (props) => {
  const { openStaffSalary, currentStaff } = props;
  const { setOpenStaffSalary, setCurrentStaff } = props;

  const [openAddStaffSalary, setOpenAddStaffSalary] = useState(false);
  const [currentSalaryItem, setCurrentSalaryItem] = useState({});
  const [openEditStaffSalary, setOpenEditStaffSalary] = useState(false);

  const handleOpenEditSalaryItem = (salary_item) => {
    setCurrentSalaryItem(salary_item);
    setOpenEditStaffSalary(true);
  };

  return (
    <>
      <MediumDialog isOpen={openStaffSalary}>
        <div class="dialog">
          <h3>Salary breakdown for {currentStaff?.name}</h3>
          {currentStaff?.staff_salary_earnings?.length > 0 ? (
            <table class="table__listing">
              <thead>
                <tr className="table__listingHeader">
                  <th>Salary Name</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th className="tc">Edit</th>
                </tr>
              </thead>
              <tbody>
                {currentStaff?.staff_salary_earnings?.map((item) => (
                  <tr class="table__listingItem" key={item?.id}>
                    <td>{item?.earning_name}</td>
                    <td>{formatWithCommas(item?.amount)}</td>
                    <td>
                      {item?.is_active ? (
                        <span className="green bd">Active</span>
                      ) : (
                        <span className="red bd">Inactive</span>
                      )}
                    </td>
                    <td
                      class="dodgerblue button__sp bd tc"
                      onClick={() => handleOpenEditSalaryItem(item)}
                    >
                      Edit
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No breakdown available</h4>
          )}
          <div class="form__Buttons">
            <button type="button" onClick={() => setOpenStaffSalary(false)}>
              Close
            </button>
            <button type="button" onClick={() => setOpenAddStaffSalary(true)}>
              Add Salary
            </button>
          </div>
        </div>
      </MediumDialog>
      {/* child components */}
      {openAddStaffSalary && (
        <AddStaffSalary
          openAddStaffSalary={openAddStaffSalary}
          setOpenAddStaffSalary={setOpenAddStaffSalary}
          setCurrentStaff={setCurrentStaff}
          currentStaff={currentStaff}
        />
      )}
      {openEditStaffSalary && (
        <EditStaffSalary
          openEditStaffSalary={openEditStaffSalary}
          setOpenEditStaffSalary={setOpenEditStaffSalary}
          currentSalaryItem={currentSalaryItem}
          setCurrentSalaryItem={setCurrentSalaryItem}
          currentStaff={currentStaff}
          setCurrentStaff={setCurrentStaff}
        />
      )}
    </>
  );
};

export default StaffSalary;
