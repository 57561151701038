import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { edit_class_level } from "../../../../../redux/actions/course";
import { START_LOADING } from "../../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";

const EditClassLevel = (props) => {
  const { currentClassLevel, openEditClassLevel, userId, loading } = props;
  const {
    setOpenEditClassLevel,
    setCurrentClassLevel,
    startLoading,
    editClassLevel,
  } = props;

  const { name } = currentClassLevel;

  const handleChange = (e) => {
    setCurrentClassLevel({
      ...currentClassLevel,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    const body = {
      ...currentClassLevel,
    };
    editClassLevel(userId, body);
  };

  return (
    <MinDialog isOpen={openEditClassLevel}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit class level</h3>
        <div class="dialog__row">
          <span>
            <label for="">Class level name/number</label>
            <input
              type="text"
              onChange={handleChange}
              name="name"
              value={name}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditClassLevel(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editClassLevel: (userId, body) => dispatch(edit_class_level(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditClassLevel);
