// import installed packages
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import styles

import "./Header.scss";
// import material ui items
// import shared/global items
import { showNavbar } from "../../../shared/scripts";

// import redux API
import { logout } from "../../../redux/actions/auth";
import { OPEN_LOGIN } from "../../../redux/actions/types";

// import components/pages

const Header = (props) => {
  const { first_name, loggedIn } = props;
  const { openLogin } = props;
  const session_cookie = localStorage.getItem("session_cookie");

  return (
    <header className="header" id="header">
      <div className="header__toggle">
        {loggedIn && (
          <>
            <i
              className="bx bx-menu showBtn dodgerblue"
              id="mobile__menuBtnShow"
              onClick={showNavbar}
            ></i>
            <i
              className="bx bx-window-close hideBtn red"
              onClick={showNavbar}
              id="mobile__menuBtnHide"
            ></i>
          </>
        )}
        <h1>
          <Link to="/" className="dodgerblue">
            Funzo Letu
          </Link>
        </h1>
      </div>

      {session_cookie ? (
        <div className="header__right authenticated">
          <>
            <div>
              <h6>
                <span className="green bd">&bull;</span> {first_name}
              </h6>
            </div>
          </>
        </div>
      ) : (
        <div className="header__right">
          <span className="button" onClick={openLogin}>
            Login
          </span>
        </div>
      )}
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    first_name: state.auth.user?.first_name,
    userImage: state.auth?.userImage,
    loggedIn: state.auth?.loggedIn,
    profile_type: state?.auth?.user?.profile_type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: (navigate) => dispatch(logout(navigate)),
    openLogin: () => dispatch({ type: OPEN_LOGIN }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
