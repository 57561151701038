import { useEffect, useState } from "react";

import { connect } from "react-redux";
import API from "../../../../../shared/axios";
import CircularProgress from "@mui/material/CircularProgress";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { showError } from "../../../../../redux/actions/shared";
import { get_class_streams } from "../../../../../redux/actions/course";
import {
  START_LOADING,
  STOP_LOADING,
} from "../../../../../redux/actions/types";

const SelectStudents = (props) => {
  const { currentStream, openSelectStudents, userId, loading, class_streams } =
    props;
  const { startLoading, stopLoading, setOpenSelectStudents, getClassStreams } =
    props;
  const [streamStudents, setStreamStudents] = useState([]);

  const [streamToUpgradeId, setStreamToUpgradeId] = useState("");

  useEffect(() => {
    if (userId && currentStream?.id) {
      const fetchStreamStudents = async () => {
        startLoading();
        await API.get(
          `/api/course/teacher/get-class-stream-students/${userId}/${currentStream?.id}/`
        ).then((res) => {
          setStreamStudents(res.data?.stream_students);
        });
      };
      fetchStreamStudents()
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  }, [currentStream?.id, startLoading, stopLoading, userId]);

  useEffect(() => {
    if (class_streams?.length === 0 && userId) {
      startLoading();
      getClassStreams(userId);
    }
  }, [class_streams?.length, getClassStreams, userId, startLoading]);

  const handleStudentsTicking = (e, studentIndex) => {
    const arrayToMutate = [...streamStudents];

    arrayToMutate[studentIndex] = {
      ...arrayToMutate[studentIndex],
      is_present: e.target.checked,
    };

    setStreamStudents(arrayToMutate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (streamToUpgradeId === "") {
      return window.alert("Select the class stream to allocate students to");
    }
    const body = {
      streamStudents,
      currentStreamId: currentStream?.id,
      streamToUpgradeId,
    };

    await API.post(
      `/api/course/teacher/upgrade-students-stream/${userId}/`,
      body
    )
      .then((res) => {
        setStreamStudents(
          streamStudents?.filter((student) => student?.is_present === false)
        );
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MediumDialog isOpen={openSelectStudents}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>
          Choose the students in {currentStream?.name} to upgrade to next stream
        </h3>
        {streamStudents?.length > 0 ? (
          <>
            <table class="table__listing">
              <thead>
                <tr className="table__listingHeader">
                  <th>Admission Number</th>
                  <th>Student Name</th>
                  <th className="tc">Select</th>
                </tr>
              </thead>
              {loading && (
                <CircularProgress
                  style={{ position: "absolute", marginLeft: "45%" }}
                />
              )}
              <tbody>
                {streamStudents?.map((student, index) => (
                  <tr class="table__listingItem" key={student?.id}>
                    <td>{student?.admission_number}</td>
                    <td>{student?.name}</td>
                    <td className="tc">
                      <input
                        type="checkbox"
                        name=""
                        onClick={(e) => handleStudentsTicking(e, index)}
                        value={student?.id}
                        checked={student?.is_present}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div class="dialog__row">
              <span>
                <label for="">
                  Choose the stream to allocate the above students
                </label>
                <select
                  name=""
                  onChange={(e) => setStreamToUpgradeId(e.target.value)}
                >
                  <option value="" selected disabled>
                    --select--
                  </option>
                  {class_streams?.map((stream) => (
                    <option value={stream?.id} key={stream?.id}>
                      {stream?.name}
                    </option>
                  ))}
                </select>
              </span>
              <span></span>
            </div>
          </>
        ) : (
          <h4 class="not__available">No students in this stream</h4>
        )}

        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenSelectStudents(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Upgrade
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    class_streams: state.course?.class_streams,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClassStreams: (userId) => dispatch(get_class_streams(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectStudents);
