import React from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { admin_update_user } from "../../../../../redux/actions/auth";
import { START_LOADING } from "../../../../../redux/actions/types";

import CircularProgress from "@mui/material/CircularProgress";

const EditTeacher = (props) => {
  const { openEditTeacher, currentTeacher, loading, userId } = props;
  const {
    setOpenEditTeacher,
    setCurrentTeacher,
    startLoading,
    adminUpdateUser,
  } = props;

  const {
    first_name,
    last_name,
    surname,
    email,
    phone_number,
    gender,
    payroll_number,
    joining_date,
    is_suspended,
    is_deactivated,
    kra_pin,
  } = currentTeacher;

  const handleChange = (e) => {
    setCurrentTeacher({ ...currentTeacher, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setCurrentTeacher({
      ...currentTeacher,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    startLoading();
    const body = {
      ...currentTeacher,
      userType: "Teacher",
    };
    adminUpdateUser(userId, body);
  };

  return (
    <MediumDialog isOpen={openEditTeacher}>
      <form class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit Teacher details</h3>
        <div class="dialog__row">
          <span>
            <label for="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={first_name}
            />
          </span>
          <span>
            <label for="">Last Name</label>
            <input
              type="text"
              onChange={handleChange}
              value={last_name}
              name="last_name"
            />
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Surname</label>
            <input
              type="text"
              name="surname"
              onChange={handleChange}
              value={surname}
            />
          </span>
          <span>
            <label for="">Email</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={email}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__row">
          <span>
            <label for="">Phone Number</label>
            <input
              type="text"
              onChange={handleChange}
              name="phone_number"
              value={phone_number}
            />
          </span>
          <span>
            <label for="">Gender</label>
            <select name="gender" onChange={handleChange} value={gender}>
              <option value="" disabled></option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Payroll Number</label>
            <input
              type="text"
              onChange={handleChange}
              name="payroll_number"
              value={payroll_number}
            />
          </span>
          <span>
            <label for="">Joining Date</label>
            <input
              type="date"
              name="joining_date"
              onChange={handleChange}
              value={joining_date}
            />
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">KRA PIN (optional)</label>
            <input
              type="text"
              name="kra_pin"
              onChange={handleChange}
              value={kra_pin}
            />
          </span>
          <span></span>
        </div>
        <div class="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_suspended"
            checked={is_suspended}
            onChange={handleCheckboxChange}
          />
          <label for="" style={{ color: "red" }}>
            Tick this to disable the teacher account login/access
          </label>
        </div>
        <div class="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_deactivated"
            checked={is_deactivated}
            onChange={handleCheckboxChange}
          />
          <label for="" style={{ color: "red" }}>
            Tick this to deactivate the teacher account PERMANENTLY
          </label>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditTeacher(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    adminUpdateUser: (userId, body) =>
      dispatch(admin_update_user(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTeacher);
