export const SET_LICENSE = "SET_LICENSE";

// user types
export const OPEN_SIGNUP = "OPEN_SIGNUP";
export const CLOSE_SIGNUP = "CLOSE_SIGNUP";
export const OPEN_LOGIN = "OPEN_LOGIN";
export const CLOSE_LOGIN = "CLOSE_LOGIN";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const LOGOUT = "LOGOUT";
export const OPEN_FORGOT_PASSWORD = "OPEN_FORGOT_PASSWORD";
export const CLOSE_FORGOT_PASSWORD = "CLOSE_FORGOT_PASSWORD";
export const OPEN_RESEND_ACTIVATION = "OPEN_RESEND_ACTIVATION";
export const CLOSE_RESEND_ACTIVATION = "CLOSE_RESEND_ACTIVATION";
export const OPEN_PASSWORD_RESET_CONFIRM = "OPEN_PASSWORD_RESET_CONFIRM";
export const CLOSE_PASSWORD_RESET_CONFIRM = "CLOSE_PASSWORD_RESET_CONFIRM";
export const OPEN_CHANGE_PASSWORD = "OPEN_CHANGE_PASSWORD";
export const CLOSE_CHANGE_PASSWORD = "CLOSE_CHANGE_PASSWORD";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const NEW_GROUP = "NEW_GROUP";
export const SET_GROUPS = "SET_GROUPS";
export const SET_USERS = "SET_TEACHERS";
export const NEW_USER = "NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_CHILDREN = "SET_CHILDREN";
export const CHANGE_STAFF_GROUPS = "CHANGE_STAFF_GROUPS";

// shared types
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const TOGGLE_LOADING = "TOGGLE_LOADING";

// course types
export const SET_SCHOOL_TERMS = "SET_TERMS";
export const NEW_SCHOOL_TERM = "NEW_SCHOOL_TERM";
export const EDIT_SCHOOL_TERM = "EDIT_SCHOOL_TERM";
export const NEW_CLASS_LEVEL = "NEW_CLASS_LEVEL";
export const EDIT_CLASS_LEVEL = "EDIT_CLASS_LEVEL";
export const NEW_CLASS_STREAM = "NEW_CLASS_STREAM";
export const EDIT_CLASS_STREAM = "EDIT_CLASS_STREAM";
export const ADMIN_SET_CLASS_STREAMS = "ADMIN_SET_CLASS_STREAMS";
export const SET_CLASS_STREAMS = "SET_CLASS_STREAMS";
export const SET_SUBJECTS = "SET_SUBJECTS";
export const NEW_SUBJECT = "NEW_SUBJECT";
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const ALLOT_SUBJECT = "ALLOT_SUBJECT";
export const REMOVE_SUBJECT_ALLOTMENT = "REMOVE_SUBJECT_ALLOTMENT";
export const SET_SUBJECT_ALLOTMENTS = "SET_SUBJECT_ALLOTMENTS";
export const SET_TEACHER_SUBJECTS = "SET_TEACHER_SUBJECTS";
export const SET_TEACHER_LIVE_CLASSES = "SET_TEACHER_LIVE_CLASSES";
export const NEW_LIVE_CLASS = "NEW_LIVE_CLASS";
export const SET_STUDENT_LIVE_CLASSES = "SET_STUDENT_LIVE_CLASSES";
export const ADD_OVERALL_GRADE = "ADD_OVERALL_GRADE";
export const EDIT_OVERALL_GRADE = "EDIT_OVERALL_GRADE";
export const DELETE_OVERALL_GRADE = "DELETE_OVERALL_GRADE";

// finance types
export const NEW_SCHOOL_SERVICE = "NEW_SCHOOL_SERVICE";
export const EDIT_SCHOOL_SERVICE = "EDIT_SCHOOL_SERVICE";
export const SET_SCHOOL_SERVICES = "SET_SCHOOL_SERVICES";
export const NEW_LEDGER = "NEW_LEDGER";
export const EDIT_LEDGER = "EDIT_LEDGER";
export const SET_LEDGERS = "SET_LEDGERS";
export const NEW_LEDGER_GROUP = "NEW_LEDGER_GROUP";
export const EDIT_LEDGER_GROUP = "EDIT_LEDGER_GROUP";
export const SET_REPORTS = "SET_REPORTS";
export const DELETE_LEDGER_OR_GROUP = "DELETE_LEDGER_OR_GROUP";
export const DELETE_SCHOOL_SERVICE = "DELETE_SCHOOL_SERVICE";

// exam types
export const NEW_EXAM = "NEW_EXAM";
export const SET_EXAMS = "SET_EXAMS";
export const UPDATE_EXAM = "UPDATE_EXAM";
export const NEW_EXAM_QUESTION = "NEW_EXAM_QUESTION";
export const UPDATE_EXAM_QUESTION = "UPDATE_EXAM_QUESTION";
export const DELETE_EXAM = "DELETE_EXAM";
export const DELETE_EXAM_QUESTION = "DELETE_EXAM_QUESTION";
export const SET_STUDENT_EXAMS = "SET_STUDENT_EXAMS";
export const SET_EXAM_PAGE = "SET_EXAM_PAGE";
export const SET_LEAVE_APPLICATIONS = "SET_LEAVE_APPLICATIONS";
export const NEW_LEAVE_APPLICATION = "NEW_LEAVE_APPLICATION";
export const APPROVE_OR_REJECT_LEAVE = "APPROVE_OR_REJECT_LEAVE";
export const SET_STAFF = "SET_STAFF";
export const SET_OFFLINE_EXAMS = "SET_OFFLINE_EXAMS";
export const NEW_OFFLINE_EXAM = "NEW_OFFLINE_EXAM";
export const UPDATE_OFFLINE_EXAM = "UPDATE_OFFLINE_EXAM";
export const SET_ASSIGNMENTS = "SET_ASSIGNMENTS";
export const NEW_ASSIGNMENT = "NEW_ASSIGNMENT";
export const EDIT_ASSIGNMENT = "EDIT_ASSIGNMENT";
export const DELETE_ASSIGNMENT = "DELETE_ASSIGNMENT";

// staff types
export const SET_SALARY_DEDUCTIONS = "SET_SALARY_DEDUCTIONS";
export const NEW_SALARY_DEDUCTION = "NEW_SALARY_DEDUCTION";
export const EDIT_SALARY_DEDUCTION = "EDIT_SALARY_DEDUCTION";
export const NEW_SALARY_EARNING = "NEW_SALARY_EARNING";
export const EDIT_SALARY_EARNING = "EDIT_SALARY_EARNING";
export const SET_SALARY_EARNINGS = "SET_SALARY_EARNINGS";
export const UPDATE_STAFF_DETAILS = "UPDATE_STAFF_DETAILS";
export const DELETE_SALARY_DEDUCTION = "DELETE_SALARY_DEDUCTION";
export const DELETE_SALARY_EARNING = "DELETE_SALARY_EARNING";
export const NEW_PAYE_BRACKET = "NEW_PAYE_BRACKET";
export const SET_PAYE_BRACKETS = "SET_PAYE_BRACKETS";
export const DELETE_PAYE_BRACKET = "DELETE_PAYE_BRACKET";
export const NEW_PAYROLL = "NEW_PAYROLL";
export const EDIT_PAYROLL = "EDIT_PAYROLL";
export const SET_PAYROLLS = "SET_PAYROLLS";
export const NEW_TAX_RELIEF = "NEW_TAX_RELIEF";
export const EDIT_TAX_RELIEF = "EDIT_TAX_RELIEF";
export const DELETE_TAX_RELIEF = "DELETE_TAX_RELIEF";

// accomodation types
export const NEW_DORM = "NEW_DORM";
export const EDIT_DORM = "EDIT_DORM";
export const SET_DORMS = "SET_DORMS";
export const SET_ACTIVE_DORMS = "SET_ACTIVE_DORMS";

// kitchen types
export const NEW_STOCK_ITEM = "NEW_STOCK_ITEM";
export const EDIT_STOCK_ITEM = "EDIT_STOCK_ITEM";
export const SET_STOCK_ITEMS = "SET_STOCK_ITEMS";

// library types
export const ADD_NEW_BOOK = "ADD_NEW_BOOK";
export const EDIT_BOOK = "EDIT_BOOK";
export const SET_BOOKS = "SET_BOOKS";
export const STOCK_EXISTING_BOOK = "STOCK_EXISTING_BOOK";
export const NEW_BOOK_BORROW = "NEW_BOOK_BORROW";
export const SET_BOOK_BORROWS = "SET_BOOK_BORROWS";
export const UPDATE_BOOK_BORROW = "UPDATE_BOOK_BORROW";
export const SET_LOST_BOOKS = "SET_LOST_BOOKS";
export const CHARGE_LOST_BOOK = "CHARGE_LOST_BOOK";
export const SET_LOST_BOOK_CHARGES = "SET_LOST_BOOK_CHARGES";

// communication types
export const SET_PHONE_NUMBERS = "SET_PHONE_NUMBERS";
