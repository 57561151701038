// import installed packages
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const HumanResourceLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to="#"
        onClick={() => toggleSubmenu("Human Resource")}
        className={`${pathname}` === "" ? "nav__link active" : "nav__link"}
      >
        <i className="bx bxs-group"></i>
        <span className="nav__name">Human Resource</span>
      </Link>
      {openSubMenu === "Human Resource" && (
        <>
          <Link
            to="/human-resource/all-staff/"
            className={
              `${pathname}` === "/human-resource/all-staff/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; All Staff</span>
          </Link>
          <Link
            to="/human-resource/staff-attendance/"
            className={
              `${pathname}` === "/human-resource/staff-attendance/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Staff Attendance</span>
          </Link>
          <Link
            to="/human-resource/salary-earnings/"
            className={
              `${pathname}` === "/human-resource/salary-earnings/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Salary Earnings</span>
          </Link>
          <Link
            to="/human-resource/salary-deductions/"
            className={
              `${pathname}` === "/human-resource/salary-deductions/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Salary Deductions</span>
          </Link>
          <Link
            to="/human-resource/leave-applications/"
            className={
              `${pathname}` === "/human-resource/leave-applications/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Leave Applications</span>
          </Link>
          <Link
            to="/human-resource/paye-brackets/"
            className={
              `${pathname}` === "/human-resource/paye-brackets/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; PAYE Brackets</span>
          </Link>
          <Link
            to="/human-resource/payrolls/"
            className={
              `${pathname}` === "/human-resource/payrolls/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Payrolls</span>
          </Link>
        </>
      )}
    </>
  );
};

export default HumanResourceLinks;
