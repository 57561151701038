import React, { useState } from "react";
import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";
import { ifEmpty, maxDate } from "../../../shared/sharedFunctions";
import { connect } from "react-redux";
import AttendanceReport from "./components/AttendanceReport";
import API from "../../../shared/axios";
import { showError } from "../../../redux/actions/shared";
import CircularProgress from "@mui/material/CircularProgress";
import EditAttendance from "./components/EditAttendance";
import MarkAttendance from "./components/MarkAttendance";

const Attendance = (props) => {
  const { userId, loading, class_streams } = props;
  const { startLoading, stopLoading } = props;

  const [reportFilters, setReportFilters] = useState({
    class_stream: "",
    report_date: "",
  });
  const { class_stream, report_date } = reportFilters;

  const [openAttendanceReport, setOpenAttendanceReport] = useState(false);
  const [attendanceReportData, setAttendanceReportData] = useState({});

  const [openMarkAttendance, setOpenMarkAttendance] = useState(false);
  const [openEditAttendance, setOpenEditAttendance] = useState(false);

  const handleChange = (e) => {
    setReportFilters({ ...reportFilters, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(reportFilters)) {
      return window.alert("Please indicate class stream and report date");
    }
    startLoading();
    const fetchAttendanceData = async () => {
      const body = {
        ...reportFilters,
      };
      await API.post(
        `/api/course/teacher/generate-attendance-report/${userId}/`,
        body
      ).then((res) => {
        setAttendanceReportData(res?.data?.attendance_report_data);
        setOpenAttendanceReport(true);
      });
    };
    fetchAttendanceData()
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };
  return (
    <div className="table__parent" id={loading ? "formSubmitting" : ""}>
      <div className="table__parentHeader">
        <button type="button" onClick={() => setOpenMarkAttendance(true)}>
          Mark Attendance
        </button>
        <button type="button" onClick={() => setOpenEditAttendance(true)}>
          Edit Attendance
        </button>
        <h3>Generate attendance report</h3>
      </div>
      {loading && (
        <CircularProgress style={{ position: "absolute", marginLeft: "40%" }} />
      )}
      <div
        className="dialog__row"
        style={{ maxWidth: "500px", margin: "auto" }}
      >
        <span>
          <label htmlFor="">Class Stream</label>
          <select
            name="class_stream"
            onChange={handleChange}
            value={class_stream}
          >
            <option value="" selected disabled>
              --select--
            </option>
            {class_streams?.map((stream) => (
              <option value={stream?.id} key={stream?.id}>
                {stream?.name}
              </option>
            ))}
          </select>
        </span>
        <span>
          <label htmlFor="">Report Date</label>
          <input
            type="date"
            name="report_date"
            onChange={handleChange}
            value={report_date}
            max={maxDate()}
          />
          <br />
        </span>
      </div>
      <div
        className="dialog__row"
        style={{ maxWidth: "200px", margin: "auto" }}
      >
        <span>
          <button type="button" className="add__button" onClick={handleSubmit}>
            Generate
          </button>
        </span>
      </div>

      {/* child components */}

      {openMarkAttendance && (
        <MarkAttendance
          openMarkAttendance={openMarkAttendance}
          setOpenMarkAttendance={setOpenMarkAttendance}
        />
      )}
      {openAttendanceReport && (
        <AttendanceReport
          openAttendanceReport={openAttendanceReport}
          setOpenAttendanceReport={setOpenAttendanceReport}
          attendanceReportData={attendanceReportData}
        />
      )}
      {openEditAttendance && (
        <EditAttendance
          openEditAttendance={openEditAttendance}
          setOpenEditAttendance={setOpenEditAttendance}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    class_streams: state?.auth?.user?.class_streams,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
