// import installed packages
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { connect } from "react-redux";

// import styles
import "./Sidebar.scss";
// import material ui items

// import shared/global items

// import components/pages
import SchoolAdminLinks from "./Links/SchoolAdminLinks";

// import redux API
import { logout } from "../../../redux/actions/auth";
import CommonLinks from "./Links/CommonLinks";
import AccountantLinks from "./Links/AccountantLinks";
import LibrarianLinks from "./Links/LibrarianLinks";
import ReceptionistLinks from "./Links/ReceptionistLinks";
import StudentLinks from "./Links/StudentLinks";
import ParentLinks from "./Links/ParentLinks";
import TeacherLinks from "./Links/TeacherLinks";
import HumanResourceLinks from "./Links/HumanResourceLinks";
import AccomodationLinks from "./Links/AccomodationLinks";
import StoreLinks from "./Links/StoreLinks";
import CommunicationLinks from "./Links/CommunicationLinks";

const Sidebar = (props) => {
  const { pathname } = useLocation();

  const { loggedIn, is_staff, users_groups } = props; // get state from props
  const { logoutUser } = props; // get dispatch from props
  const [openSubMenu, setOpenSubMenu] = useState("");

  // function to toggle sidebar submenu
  const toggleSubmenu = (link_name) => {
    if (openSubMenu === link_name) {
      setOpenSubMenu("");
    } else {
      setOpenSubMenu(link_name);
    }
  };

  return (
    <div className="left-navbar show" id="nav-bar">
      <nav className="nav">
        <div className="nav__list">
          {/* unprotected links */}
          <CommonLinks
            pathname={pathname}
            Link={Link}
            logoutUser={logoutUser}
            toggleSubmenu={toggleSubmenu}
            openSubMenu={openSubMenu}
            loggedIn={loggedIn}
            is_staff={is_staff}
          />
          {/* protected links */}

          {/* system admin/school admin links */}
          {users_groups?.includes("School Admin") && (
            <SchoolAdminLinks
              pathname={pathname}
              Link={Link}
              toggleSubmenu={toggleSubmenu}
              openSubMenu={openSubMenu}
            />
          )}
          {/* communication links */}
          <CommunicationLinks
            pathname={pathname}
            Link={Link}
            toggleSubmenu={toggleSubmenu}
            openSubMenu={openSubMenu}
            users_groups={users_groups}
          />
          {["Accountant", "School Admin"]?.some((allowed_group) =>
            users_groups?.includes(allowed_group)
          ) && (
            <AccountantLinks
              pathname={pathname}
              Link={Link}
              toggleSubmenu={toggleSubmenu}
              openSubMenu={openSubMenu}
            />
          )}
          {["Librarian", "School Admin"]?.some((allowed_group) =>
            users_groups?.includes(allowed_group)
          ) && (
            <LibrarianLinks
              pathname={pathname}
              Link={Link}
              toggleSubmenu={toggleSubmenu}
              openSubMenu={openSubMenu}
            />
          )}
          {users_groups?.includes("receptionist") && (
            <ReceptionistLinks
              pathname={pathname}
              Link={Link}
              toggleSubmenu={toggleSubmenu}
              openSubMenu={openSubMenu}
            />
          )}
          {["Human Resource", "School Admin"]?.some((allowed_group) =>
            users_groups?.includes(allowed_group)
          ) && (
            <HumanResourceLinks
              pathname={pathname}
              Link={Link}
              toggleSubmenu={toggleSubmenu}
              openSubMenu={openSubMenu}
            />
          )}
          {["Accomodation", "School Admin"]?.some((allowed_group) =>
            users_groups?.includes(allowed_group)
          ) && (
            <AccomodationLinks
              pathname={pathname}
              Link={Link}
              toggleSubmenu={toggleSubmenu}
              openSubMenu={openSubMenu}
            />
          )}
          {["Store", "School Admin"]?.some((allowed_group) =>
            users_groups?.includes(allowed_group)
          ) && (
            <StoreLinks
              pathname={pathname}
              Link={Link}
              toggleSubmenu={toggleSubmenu}
              openSubMenu={openSubMenu}
            />
          )}
          {users_groups?.includes("Student") && (
            <StudentLinks
              pathname={pathname}
              Link={Link}
              toggleSubmenu={toggleSubmenu}
              openSubMenu={openSubMenu}
            />
          )}
          {users_groups?.includes("Parent") && (
            <ParentLinks
              pathname={pathname}
              Link={Link}
              toggleSubmenu={toggleSubmenu}
              openSubMenu={openSubMenu}
            />
          )}
          {users_groups?.includes("Teacher") && (
            <TeacherLinks
              pathname={pathname}
              Link={Link}
              toggleSubmenu={toggleSubmenu}
              openSubMenu={openSubMenu}
            />
          )}

          <Link to="" className="nav__link" onClick={logoutUser}>
            <i className="bx bx-log-out-circle"></i>
            <span className="nav__name">Logout</span>
          </Link>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    is_staff: state.auth?.user?.is_staff,
    users_groups: state?.auth?.user?.users_groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: (navigate) => dispatch(logout(navigate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
