import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { get_offline_exams } from "../../../../redux/actions/exam";
import { showError } from "../../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import CircularProgress from "@mui/material/CircularProgress";
import StudentResultsTemplate from "../../../../components/shared/StudentResultsTemplate/StudentResultsTemplate";

const OfflineExamsReports = (props) => {
  const {
    openOfflineExamsReport,
    userId,
    offlineExams,
    loading,
    admission_number,
  } = props;
  const {
    setOpenOfflineExamsReport,
    startLoading,
    getOfflineExams,
    stopLoading,
  } = props;

  const [offline_exam, setOfflineExam] = useState("");
  const [resultsData, setResultsData] = useState({});
  const [openStudentResultsTemplate, setOpenStudentResultsTemplate] =
    useState(false);

  useEffect(() => {
    if (userId && offlineExams?.length === 0) {
      startLoading();
      getOfflineExams(userId);
    }
  }, [startLoading, userId, getOfflineExams, offlineExams?.length]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(`/api/exam/get-student-offline-exam-results/${userId}/`, {
      offline_exam,
      admission_number,
    })
      .then((res) => {
        setResultsData(res?.data?.student_exam_results_data);
        setOpenStudentResultsTemplate(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };
  return (
    <MinDialog isOpen={openOfflineExamsReport}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Choose the exam to view results</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Choose Exam</label>
          <select
            name=""
            onChange={(e) => setOfflineExam(e.target.value)}
            value={offline_exam}
          >
            <option value="" disabled selected>
              --select--
            </option>
            {offlineExams?.map((offline_exam) => (
              <option value={offline_exam?.id} key={offline_exam?.id}>
                {offline_exam?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenOfflineExamsReport(false)}
          >
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            View
          </button>
        </div>
      </form>
      {/* child components */}

      <StudentResultsTemplate
        openStudentResultsTemplate={openStudentResultsTemplate}
        setOpenStudentResultsTemplate={setOpenStudentResultsTemplate}
        resultsData={resultsData}
      />
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    offlineExams: state?.exam?.offlineExams,
    admission_number: state?.auth?.user?.admission_number,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    getOfflineExams: (userId) => dispatch(get_offline_exams(userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfflineExamsReports);
