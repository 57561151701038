import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { showError } from "../../../redux/actions/shared";

import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";
import API from "../../../shared/axios";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import globals from "../../../shared/globals";

const StudentAssignments = (props) => {
  const { userId, loading } = props;
  const { startLoading, stopLoading } = props;

  const { liveProduction, devHome } = globals;

  const [assignments, setAssignments] = useState([]);
  const [mySubmission, setMySubmission] = useState(null);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchAssignments = async () => {
        await API.get(`/api/exam/student/maintain-assignments/${userId}/`).then(
          (res) => {
            setAssignments(res?.data?.assignments_data);
          }
        );
      };
      fetchAssignments()
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  }, [startLoading, stopLoading, userId]);

  const attachmentUpload = (e) => {
    setMySubmission(e.target.files[0]);
  };

  // function to update assignment upon submission or deletion
  const updateAssignments = (payload) => {
    setAssignments(
      assignments?.map((assignment) =>
        assignment.id === payload.id ? payload : assignment
      )
    );
  };

  // function for student to submit course assignment
  const studentSubmitAssignment = (assignmentId) => {
    if (mySubmission === null) {
      return window.alert("You must attach a document");
    }
    // check attachment size is not more than 5MB
    // size is in bytes so we divide to get kilobytes then megabytes
    if (mySubmission?.size / (1024 * 1024) > 5) {
      return window.alert("Attachment file size should be at most 5MB.");
    }
    startLoading();

    let body = new FormData();
    body.append("assignment", assignmentId);
    body.append("attachment", mySubmission, mySubmission.name);
    body.append("assignmentId", assignmentId);

    const postAssignmentData = async () => {
      await API.post(`/api/exam/student/maintain-assignments/${userId}/`, body)

        // update assignments upon submission of assignment
        .then((res) => {
          updateAssignments(res.data?.updated_assignment);
          window.alert(res.data?.detail);
        });
    };
    postAssignmentData()
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <div className="table__parent">
      <div className="table__parentHeader">
        <h3>Pending assignments</h3>
      </div>
      {loading && (
        <CircularProgress style={{ position: "absolute", marginLeft: "40%" }} />
      )}
      {assignments?.length > 0 ? (
        <table className="table__listing">
          <tr className="table__listingHeader">
            <th>Subject</th>
            <th>Class Stream</th>
            <th>Due date</th>
            <th>Open</th>
            <th>Status</th>
            <th>Marks Scored</th>
          </tr>
          {assignments?.map((assignment) => (
            <tr className="table__listingItem" key={assignment?.id}>
              <td>{assignment?.subject}</td>
              <td>{assignment?.class_stream}</td>
              <td>{moment(assignment?.due_date).format("LLL")}</td>
              <td>
                {liveProduction ? (
                  <Link
                    className="dodgerblue bd tc"
                    to={`${assignment?.attachment}`}
                    target="__blank"
                  >
                    Open
                  </Link>
                ) : (
                  <a
                    className="dodgerblue bd button__sp"
                    href={`${devHome}${assignment?.attachment}`}
                    target="__blank"
                  >
                    Open
                  </a>
                )}
              </td>
              <td>
                {assignment?.already_submitted ? (
                  <span>Submitted</span>
                ) : (
                  <form style={{ display: "flex" }}>
                    <input type="file" onChange={attachmentUpload} />
                    <input
                      type="button"
                      className="button__sp add__button"
                      value="submit"
                      onClick={() => studentSubmitAssignment(assignment?.id)}
                    />
                  </form>
                )}
              </td>
              <td>{assignment?.marks_scored}</td>
            </tr>
          ))}
        </table>
      ) : (
        <h4 className="not__available">No assignments pending</h4>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentAssignments);
