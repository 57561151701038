import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";

import { ifEmpty, resetFormValues } from "../../../../shared/sharedFunctions";

const StaffCheckInOut = (props) => {
  const { openStaffCheckinOut, allStaff, userId, loading } = props;
  const { setOpenStaffCheckinOut, startLoading, stopLoading } = props;

  const [checkinDetails, setCheckinDetails] = useState({
    staff: "",
    time: "",
    check_type: "",
  });

  const { time, staff, check_type } = checkinDetails;

  const resetForm = () => {
    resetFormValues(checkinDetails);
  };

  const handleClose = () => {
    setOpenStaffCheckinOut(false);
    resetForm();
  };

  const handleChange = (e) => {
    setCheckinDetails({ ...checkinDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (ifEmpty(checkinDetails)) {
      return window.alert("You must fill in all fields");
    }
    startLoading();
    const body = {
      ...checkinDetails,
    };
    await API.post(
      `/api/staff/staff/maintain-staff-check-in-out/${userId}/`,
      body
    )
      .then((res) => {
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openStaffCheckinOut}>
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>Staff check in/out details</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Choose action</label>
          <select name="check_type" onChange={handleChange} value={check_type}>
            <option value="" selected disabled>
              --select--
            </option>
            <option value="check_in">Check In</option>
            <option value="check_out">Check Out</option>
          </select>
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Select staff</label>
          <select name="staff" onChange={handleChange} value={staff}>
            <option value="" disabled selected>
              --select--
            </option>
            {allStaff?.map((staff) => (
              <option value={staff?.id} key={staff?.id}>
                {staff?.name}
              </option>
            ))}
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Time In/Out</label>
          <input type="time" name="time" onChange={handleChange} value={time} />
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Check In</button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffCheckInOut);
