import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import MaxDialog from "../../../components/common/MaxDialog";
import CircularProgress from "@mui/material/CircularProgress";
import {
  SET_STOCK_ITEMS,
  START_LOADING,
  STOP_LOADING,
} from "../../../redux/actions/types";
import API from "../../../shared/axios";
import { showError } from "../../../redux/actions/shared";
import { formatWithCommas, maxDate } from "../../../shared/sharedFunctions";

const StockInOut = (props) => {
  const {
    openStockInOut,
    stock_items,
    loading,
    userId,
    currentStockEntryType,
  } = props;
  const { setOpenStockInOut, startLoading, stopLoading } = props;

  const dispatch = useDispatch();

  const [activeStockItems, setActiveStockItems] = useState([]);
  const [transactionDate, setTransactionDate] = useState("");

  const handleAddStockItem = (e) => {
    if (!activeStockItems?.find((item) => item?.id === e.target.value)) {
      const stockItem = stock_items?.find(
        (item) => item?.id === e.target.value
      );
      setActiveStockItems([...activeStockItems, stockItem]);
    }
  };

  const removeStockItem = (stockItemId) => {
    setActiveStockItems(
      activeStockItems?.filter((item) => item?.id !== stockItemId)
    );
  };

  const handleChange = (e, itemIndex) => {
    const arrayToMutate = [...activeStockItems];
    arrayToMutate[itemIndex] = {
      ...arrayToMutate[itemIndex],
      [e.target.name]: Number(e.target.value),
    };
    setActiveStockItems(arrayToMutate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (transactionDate === "") {
      return window.alert("Indicate the stock in date");
    }
    let url = "";
    if (currentStockEntryType === "Stock In") {
      for (const i in activeStockItems) {
        if (
          activeStockItems[i]["quantity"] === 0 ||
          activeStockItems[i]["unit_cost"] === 0
        ) {
          return window.alert(
            "Quantity and unit cost for selected stock items must be greater than zero"
          );
        }
      }
      url = `/api/store/admin/update-stock-in/${userId}/`;
    } else if (currentStockEntryType === "Stock Out") {
      for (const i in activeStockItems) {
        if (activeStockItems[i]["quantity"] > activeStockItems[i]["balance"]) {
          return window.alert(
            "Stock out quantity cannot be more than the outstanding stock balance"
          );
        }
      }
      url = `/api/store/admin/update-stock-out/${userId}/`;
    }
    startLoading();
    const body = {
      activeStockItems,
      transactionDate,
    };

    await API.post(url, body)
      .then((res) => {
        setActiveStockItems(
          res?.data?.stock_items_data?.filter((item) => item?.is_active)
        );
        dispatch({
          type: SET_STOCK_ITEMS,
          payload: res?.data?.stock_items_data,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MaxDialog isOpen={openStockInOut} maxWidth="1200px" maxHeight="85vh">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Record {currentStockEntryType}</h3>
        <div class="dialog__row">
          <span>
            <label for="">{currentStockEntryType} Date</label>
            <input
              type="date"
              onChange={(e) => setTransactionDate(e.target.value)}
              value={transactionDate}
              max={maxDate()}
            />
          </span>
          <span>
            <label for="">Choose the stock items to update</label>
            <select name="" onChange={handleAddStockItem}>
              <option value="" selected disabled>
                --select to add--
              </option>
              {stock_items?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </span>
        </div>
        {activeStockItems?.length > 0 && (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Stock Item</th>
                <th>Balance</th>
                <th>Reorder Level</th>
                <th>Unit of Measure</th>
                <th className="tc"> Quantity</th>
                {currentStockEntryType === "Stock In" && (
                  <>
                    <th className="tc">Unit Cost</th>
                    <th>Total Cost</th>
                    <th className="tc">Remove</th>
                  </>
                )}
              </tr>
            </thead>
            {loading && (
              <CircularProgress
                style={{ position: "absolute", marginLeft: "45%" }}
              />
            )}
            <tbody>
              {activeStockItems?.map((item, index) => (
                <tr class="table__listingItem" key={item?.id}>
                  <td>{item?.name}</td>
                  <td>{item?.balance}</td>
                  <td>{item?.reorder_level}</td>
                  <td>{item?.unit_of_measure}</td>
                  <td className="tc">
                    <input
                      type="number"
                      min="0"
                      name="quantity"
                      value={item?.quantity}
                      onChange={(e) => handleChange(e, index)}
                      style={{ borderRadius: "5px", maxWidth: "150px" }}
                      required
                    />
                  </td>
                  {currentStockEntryType === "Stock In" && (
                    <>
                      <td className="tc">
                        <input
                          type="number"
                          min="0"
                          name="unit_cost"
                          value={item?.unit_cost}
                          onChange={(e) => handleChange(e, index)}
                          style={{ borderRadius: "5px", maxWidth: "150px" }}
                        />
                      </td>
                      <td>
                        {formatWithCommas(item?.unit_cost * item?.quantity)}
                      </td>
                      <td className="tc">
                        <i
                          className="bx bxs-trash-alt red bd button__sp"
                          onClick={() => removeStockItem(item?.id)}
                        ></i>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenStockInOut(false)}>
            Close
          </button>
          <button type="submit">Update</button>
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    stock_items: state?.stores?.stock_items?.filter((item) => item?.is_active),
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockInOut);
