import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  admin_get_class_streams,
  get_subjects,
} from "../../../redux/actions/course";
import { get_books } from "../../../redux/actions/library";
import { START_LOADING } from "../../../redux/actions/types";
import StockExistingBook from "./components/StockExistingBook";
import AddNewBook from "./components/AddNewBook";
import EditBook from "./components/EditBook";

const Books = (props) => {
  const { userId, all_books } = props;
  const { startLoading, getSubjects, adminGetClassStreams, getBooks } = props;

  const [openAddNewBook, setOpenAddNewBook] = useState(false);
  const [openEditBook, setOpenEditBook] = useState(false);
  const [currentBook, setCurrentBook] = useState({});
  const [openStockExistingBook, setOpenStockExistingBook] = useState(false);

  useEffect(() => {
    if (userId) {
      startLoading();
      getSubjects(userId);
    }
  }, [startLoading, userId, getSubjects]);

  useEffect(() => {
    if (userId) {
      startLoading();
      getBooks(userId);
    }
  }, [userId, getBooks, startLoading]);

  // useEffect to get classes and class streams
  useEffect(() => {
    if (userId) {
      startLoading();
      adminGetClassStreams(userId);
    }
  }, [userId, startLoading, adminGetClassStreams]);

  const handleOpenEditBook = (book) => {
    setCurrentBook(book);
    setOpenEditBook(true);
  };

  return (
    <>
      <div className="table__parent">
        <div class="table__parentHeader">
          <button type="button" onClick={() => setOpenAddNewBook(true)}>
            Add New Book
          </button>
          <button type="button" onClick={() => setOpenStockExistingBook(true)}>
            Stock Existing Book
          </button>
        </div>
        <br />
        {all_books?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Name</th>
                <th>Author</th>
                <th>Category</th>
                <th className="tc">Available</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {all_books?.map((book) => (
                <tr class="table__listingItem" key={book?.id}>
                  <td>{book?.name}</td>
                  <td>{book?.author}</td>
                  <td>{book?.category}</td>
                  <td className="tc">{book?.quantity}</td>
                  <td
                    className="dodgerblue bd button__sp"
                    onClick={() => handleOpenEditBook(book)}
                  >
                    Edit
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No books available</h4>
        )}
      </div>
      {/* child components */}
      {openAddNewBook && (
        <AddNewBook
          openAddNewBook={openAddNewBook}
          setOpenAddNewBook={setOpenAddNewBook}
        />
      )}
      {openEditBook && (
        <EditBook
          openEditBook={openEditBook}
          setOpenEditBook={setOpenEditBook}
          currentBook={currentBook}
          setCurrentBook={setCurrentBook}
        />
      )}
      {openStockExistingBook && (
        <StockExistingBook
          openStockExistingBook={openStockExistingBook}
          setOpenStockExistingBook={setOpenStockExistingBook}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subjects: state.course?.subjects,
    userId: state.auth.user?.id,
    class_levels: state.course?.class_levels,
    all_books: state?.library?.all_books,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubjects: (userId) => dispatch(get_subjects(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
    adminGetClassStreams: (userId) => dispatch(admin_get_class_streams(userId)),
    getBooks: (userId) => dispatch(get_books(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Books);
