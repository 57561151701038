import * as api from "../../api";
import * as actionTypes from "./types";

import { showError, stopLoading } from "./shared";

// function to create a new term
export const create_new_term =
  (newTerm, userId, resetForm) => async (dispatch) => {
    await api
      .createNewTerm(newTerm, userId)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_SCHOOL_TERM,
          payload: res.data.new_term,
        });
        resetForm();
        alert(res.data.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// function to edit term
export const edit_school_term = (userId, body) => async (dispatch) => {
  await api
    .editSchoolTerm(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_SCHOOL_TERM,
        payload: res?.data?.updated_school_term,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// function to get school terms
export const get_school_terms = (userId) => async (dispatch) => {
  await api
    .getSchoolTerms(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_SCHOOL_TERMS,
        payload: res.data?.school_terms,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// POST REQUEST to create new class level
export const create_class_level =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createClassLevel(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_CLASS_LEVEL,
          payload: res.data?.new_class_level,
        });
        resetForm();
        window.alert(res.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// PATCH REQUEST TO edit class level
export const edit_class_level = (userId, body) => async (dispatch) => {
  await api
    .editClassLevel(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_CLASS_LEVEL,
        payload: res?.data?.updated_class_level,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// POST REQUEST
export const create_subject = (userId, body, resetForm) => async (dispatch) => {
  await api
    .createSubject(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.NEW_SUBJECT,
        payload: res?.data?.new_subject,
      });
      resetForm();
      window.alert(res.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// PATCH REQUEST
export const edit_subject = (userId, body) => async (dispatch) => {
  await api
    .editSubject(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_SUBJECT,
        payload: res?.data?.updated_subject,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// POST REQUEST function to create new class stream
export const create_new_class_stream =
  (newClassStream, userId, resetForm) => async (dispatch) => {
    await api
      .createNewClassStream(newClassStream, userId)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_CLASS_STREAM,
          payload: res.data?.new_class_stream,
        });
        alert(res.data.detail);
        resetForm();
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// POST REQUEST function to edit class stream
export const edit_class_stream = (userId, body) => async (dispatch) => {
  await api
    .editClassStream(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_CLASS_STREAM,
        payload: res?.data?.updated_class_stream,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// GET REQUEST function to get classes and class streams
export const admin_get_class_streams = (userId) => async (dispatch) => {
  await api
    .adminGetClassStreams(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.ADMIN_SET_CLASS_STREAMS,
        payload: {
          class_levels: res.data?.class_levels,
          class_streams: res.data?.class_streams,
          teachers: res.data?.teachers,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// GET REQUEST to get subjects
export const get_subjects = (userId) => async (dispatch) => {
  await api
    .getSubjects(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_SUBJECTS,
        payload: {
          subjects: res?.data?.subjects,
          subject_groups: res?.data?.subject_groups,
          overall_grading: res?.data?.overall_grading,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// POST REQUEST to allot subject to teacher
export const allot_subject = (userId, body, resetForm) => async (dispatch) => {
  await api
    .allotSubject(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.ALLOT_SUBJECT,
        payload: res.data?.alloted_subject,
      });
      resetForm();
      window.alert(res.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// GET REQUEST to get a teacher's subjects and class streams
export const get_teacher_subjects = (userId) => async (dispatch) => {
  await api
    .getTeacherSubjects(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_TEACHER_SUBJECTS,
        payload: res.data?.teacher_subjects,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// DELETE REQUEST to remove classstreamsubject teacher allotment
export const remove_class_stream_subject_teacher =
  (userId, classStreamSubjectId) => async (dispatch) => {
    await api
      .removeClassStreamSubjectTeacher(userId, classStreamSubjectId)
      .then((res) => {
        dispatch({
          type: actionTypes.REMOVE_SUBJECT_ALLOTMENT,
          payload: classStreamSubjectId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to add new overall grade
export const add_overall_grade =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .addOverallGrade(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_OVERALL_GRADE,
          payload: res?.data?.new_overall_grade,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to edit overall grade
export const edit_overall_grade = (userId, body) => async (dispatch) => {
  await api
    .editOverallGrade(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_OVERALL_GRADE,
        payload: res?.data?.edited_overall_grade,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to delete overall grade
export const delete_overall_grading =
  (userId, overallGradeId) => async (dispatch) => {
    await api
      .deleteOverallGrade(userId, overallGradeId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_OVERALL_GRADE,
          payload: overallGradeId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// GET REQUEST for teacher to get live classes
export const teacher_get_live_classes = (userId) => async (dispatch) => {
  await api
    .teacherGetLiveClasses(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_TEACHER_LIVE_CLASSES,
        payload: res.data?.teacher_live_classes_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// POST REQUEST to schedule a live class
export const create_live_class =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createLiveClass(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_LIVE_CLASS,
          payload: res?.data?.new_live_class,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// ACTION for teacher to mark attendance
export const teacher_mark_attendance = (userId, body) => async (dispatch) => {
  await api
    .teacherMarkAttendance(userId, body)
    .then((res) => {
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action for users such as teachers to get stream classes
export const get_class_streams = (userId) => async (dispatch) => {
  await api
    .getClassStreams(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_CLASS_STREAMS,
        payload: res?.data?.class_streams_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action for student to get live classes
export const student_get_live_classes = (userId) => async (dispatch) => {
  await api
    .studentGetLiveClasses(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_STUDENT_LIVE_CLASSES,
        payload: res?.data?.student_live_classes,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};
