// import installed packages
import { useState, useEffect } from "react";
import { connect } from "react-redux";
// import styles
import "./Users.scss";
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
// import components/pages
// import redux API
import { START_LOADING } from "../../../redux/actions/types";
import { get_groups, get_users } from "../../../redux/actions/auth";

import AddUser from "../../../components/SystemAdmin/AddUser";

const Users = (props) => {
  const { loading, users, userId, groups } = props; // get state from props
  const { startLoading, getUsers, getGroups } = props; // get dispatch actions from props
  const [openAddUser, setOpenAddUser] = useState(false);
  const [filterValues, setFilterValues] = useState({
    group: "",
  });

  // destructuring
  const { group } = filterValues;

  useEffect(() => {
    if (groups?.length === 0 && userId) {
      startLoading();
      getGroups(userId);
    }
  }, [getGroups, groups?.length, startLoading, userId]);

  // function to get filtered users
  const getFilteredUsers = (e) => {
    e.preventDefault();
    startLoading();
    getUsers(filterValues, userId);
  };

  // function to handle change
  const handleChange = (e) => {
    setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__parentHeader">
          <button
            type="button"
            className="add__button white"
            onClick={() => setOpenAddUser(true)}
          >
            Add User
          </button>
        </div>
        <div className="filter__users">
          <h3>Filter users below</h3>
          <form className="filter__fields">
            <select name="group" value={group} onChange={handleChange}>
              <option value="" selected disabled>
                Select group
              </option>
              {groups?.map((group) => (
                <option value={group?.name} key={group?.name}>
                  {group?.name}
                </option>
              ))}
            </select>
            <button
              type="submit"
              onClick={getFilteredUsers}
              className="add__button"
            >
              Submit
            </button>
          </form>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <table className="table__listing">
          {users?.length > 0 ? (
            <>
              <tr className="table__listingHeader">
                <th>No:</th>
                {group === "Students" && <th>Admission</th>}

                <th>Name</th>
                <th>Group</th>
                <th>View</th>
              </tr>
              {users?.map((user, index) => (
                <tr className="table__listingItem">
                  <td>{index + 1}</td>
                  {group === "Students" && <td>{user?.admission_number}</td>}
                  <td>
                    {user?.first_name} {user?.last_name} {user?.surname}
                  </td>
                  <td>
                    <ul>
                      {user?.user_groups?.map((group) => (
                        <li key={group?.name}>{group?.name}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="button dodgerblue bd">View</td>
                </tr>
              ))}
            </>
          ) : (
            <h4 className="not__available">No users available</h4>
          )}
        </table>
      </div>
      {/* child components */}

      {openAddUser && (
        <AddUser openAddUser={openAddUser} setOpenAddUser={setOpenAddUser} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.auth?.users,
    userId: state.auth.user.id,
    loading: state.shared?.loading,
    groups: state.auth?.groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getUsers: (filterValues, userId, navigate) =>
      dispatch(get_users(filterValues, userId, navigate)),
    getGroups: (userId) => dispatch(get_groups(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
