import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { showError } from "../../../redux/actions/shared";
import { human_resource_get_staff } from "../../../redux/actions/staff";
import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";
import API from "../../../shared/axios";

import CircularProgress from "@mui/material/CircularProgress";

import CheckInOutReport from "./components/CheckInOutReport";
import StaffCheckInOut from "./components/StaffCheckInOut";
import { ifEmpty, maxDate } from "../../../shared/sharedFunctions";

const StaffAttendance = (props) => {
  const { userId, allStaff, loading } = props;
  const { startLoading, getAllStaff, stopLoading } = props;

  const [openStaffCheckinOut, setOpenStaffCheckinOut] = useState(false);
  const [openCheckInOutReport, setOpenCheckInOutReport] = useState(false);

  const [checkDate, setCheckDate] = useState("");
  const [checkInType, setCheckInType] = useState("");
  const [singleStaffFilters, setSingleStaffFilters] = useState({
    staff: "",
    start_date: "",
    end_date: "",
  });
  const { staff, start_date, end_date } = singleStaffFilters;
  const [staffData, setStaffData] = useState({});

  useEffect(() => {
    if (userId) {
      startLoading();
      getAllStaff(userId);
    }
  }, [startLoading, userId, getAllStaff, allStaff?.length]);

  const handleChange = (e) => {
    setSingleStaffFilters({
      ...singleStaffFilters,
      [e.target.name]: e.target.value,
    });
  };

  const generateCheckInOutReport = async (e) => {
    e.preventDefault();
    let body;
    if (checkInType?.trim() === "") {
      return window.alert("Indicate the report type");
    }
    if (checkInType === "all_staff") {
      if (checkDate?.trim() === "") {
        return window.alert("Please indicate the report date");
      }
      body = { checkDate, checkInType };
    } else if (checkInType === "single_staff") {
      if (ifEmpty(singleStaffFilters)) {
        return window.alert("Indicate staff, start date and end date");
      }
      if (start_date > end_date) {
        return window.alert("Start date must be less than end date");
      }
      body = { ...singleStaffFilters, checkInType };
    }

    await API.post(`/api/staff/staff/get-staffs-check-in-out/${userId}/`, {
      ...body,
    })
      .then((res) => {
        setStaffData(res?.data?.check_in_report_data);
        setOpenCheckInOutReport(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <button type="button" onClick={() => setOpenStaffCheckinOut(true)}>
            Staff Check In/Out
          </button>
          <br />
          <br />
          <h4 className="dodgerblue ud">Generate Check In/Out Report</h4>

          <div class="dialog">
            <div class="dialog__row">
              <span>
                <label for="">Report Type</label>
                <select
                  name=""
                  onChange={(e) => setCheckInType(e.target.value)}
                  value={checkInType}
                >
                  <option value="">--select check in type</option>
                  <option value="all_staff">All Staff</option>
                  <option value="single_staff">Single Staff</option>
                </select>
              </span>
              {checkInType === "all_staff" && (
                <span>
                  <label for="">Report Date</label>
                  <input
                    type="date"
                    name=""
                    onChange={(e) => setCheckDate(e.target.value)}
                    value={checkDate}
                  />
                </span>
              )}
              {checkInType === "single_staff" && (
                <>
                  <span>
                    <label for="">Select staff</label>
                    <select name="staff" onChange={handleChange} value={staff}>
                      <option value="">--select staff--</option>
                      {allStaff?.map((item) => (
                        <option value={item?.id} key={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </span>
                  <span>
                    <label for="">Start Date</label>
                    <input
                      type="date"
                      name="start_date"
                      onChange={handleChange}
                      value={start_date}
                      max={maxDate()}
                    />
                  </span>
                  <span>
                    <label for="">End Date</label>
                    <input
                      type="date"
                      name="end_date"
                      onChange={handleChange}
                      value={end_date}
                      max={maxDate()}
                    />
                  </span>
                </>
              )}
            </div>
          </div>
          <button type="button" onClick={generateCheckInOutReport}>
            Get Report
          </button>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
      </div>
      {/* child components */}

      {openStaffCheckinOut && (
        <StaffCheckInOut
          openStaffCheckinOut={openStaffCheckinOut}
          setOpenStaffCheckinOut={setOpenStaffCheckinOut}
          allStaff={allStaff}
        />
      )}
      {openCheckInOutReport && (
        <CheckInOutReport
          setOpenCheckInOutReport={setOpenCheckInOutReport}
          openCheckInOutReport={openCheckInOutReport}
          staffData={staffData}
          checkDate={checkDate}
          checkInType={checkInType}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    allStaff: state?.staff?.allStaff,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllStaff: (userId) => dispatch(human_resource_get_staff(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffAttendance);
