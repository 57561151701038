import React, { useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import globals from "../../../../../shared/globals";
import MediumDialog from "../../../../../components/common/MediumDialog";
import "./OfficialReceipt.scss";

const OfficialReceipt = (props) => {
  const { openOfficialReceipt, officialReceiptData } = props;
  const { setOpenOfficialReceipt } = props;

  const {
    receipt_date,
    student_name,
    admission_number,
    receipt_no,
    opening_balance,
    amount_received,
    closing_balance,
    notes,
    received_by,
  } = officialReceiptData;
  const {
    schoolName,
    schoolAddress,
    schoolMobile1,
    schoolMobile2,
    schoolEmail,
  } = globals;
  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Student fees receipt",
  });
  return (
    <MediumDialog isOpen={openOfficialReceipt}>
      <div class="dialog">
        <table
          className="report__statement"
          border="1"
          rules="rows"
          ref={printArea}
        >
          <thead>
            <tr>
              <th colSpan={2} className="school__name">
                {schoolName}
              </th>
            </tr>

            <tr>
              <td colSpan={2}>{schoolAddress}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <strong>Mobile:</strong> {schoolMobile1}, {schoolMobile2}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <strong>Email:</strong> {schoolEmail}
              </td>
            </tr>
            <tr>
              <th colSpan={2} className="fees__receipt">
                Fees Receipt
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="student__details">
              <td>
                <strong>Date:</strong> {moment(receipt_date).format("LL")}
              </td>
              <td className="tc">
                <strong>Receipt No:</strong> {receipt_no}
              </td>
            </tr>
            <tr className="student__details">
              <td>
                <strong>Student: </strong> {student_name}
              </td>
              <td className="tc">
                <strong>Admission No:</strong> {admission_number}
              </td>
            </tr>

            <tr>
              <th>Description</th>
              <th className="tc">Amount</th>
            </tr>
            <tr>
              <td>Balance before payment</td>
              <td className="tc">{opening_balance}</td>
            </tr>
            <tr>
              <td>Amount Received</td>
              <td className="tc">{amount_received}</td>
            </tr>
            <tr>
              <td>Outstanding Balance</td>
              <td className="tc">{closing_balance}</td>
            </tr>
          </tbody>
          <tbody class="receipt__footer">
            <tr>
              <td colSpan={2}>
                <strong>Notes:</strong> {notes}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Received By:</strong> {received_by}
              </td>
              <td className="tc">
                <strong>Sign:</strong>......................................
              </td>
            </tr>
          </tbody>
        </table>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenOfficialReceipt(false)}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

export default OfficialReceipt;
