import API from "../shared/axios";

// patch user data
export const updateUser = (updatedUser, userId) =>
  API.patch(`/api/user/update-user-details/${userId}/`, updatedUser);

// PATCH REQUEST to create exam question
export const updateExamQuestion = (userId, body) =>
  API.patch(`/api/exam/teacher/maintain-exam-questions/${userId}/`, body);

// PATCH REQUEST to update exam
export const updateExam = (userId, body) =>
  API.patch(`/api/exam/teacher/maintain-exams/${userId}/`, body);

// PATCH REQUEST to create the created offline exams
export const editOfflineExam = (userId, body) =>
  API.patch(`/api/exam/maintain-offline-exams/${userId}/`, body);

// PATCH REQUEST for school admin to approve or reject staff leave application
export const approveOrRejectStaffLeaveApplication = (userId, body) =>
  API.patch(
    `/api/staff/school-admin/approve-or-reject-staff-leave-application/${userId}/`,
    body
  );

// PATCH REQUEST for teacher to mark student assigment submission
export const teacherMarkAssignmentSubmission = (userId, body) =>
  API.patch(`/api/exam/teacher/mark-assignment-submission/${userId}/`, body);

// PATCH REQUEST for school admin to change staffs groups
export const changeStaffsGroups = (userId, body) =>
  API.patch(`/api/user/change-staffs-groups/${userId}/`, body);

// PATCH REQUEST FOR ADMIN TO UPDATE USER
export const adminUpdateUser = (userId, body) =>
  API.patch(`/api/user/admin-update-user/${userId}/`, body);

// PATCH REQUEST to create a school service
export const editSchoolService = (userId, body) =>
  API.patch(`/api/finance/maintain-school-services/${userId}/`, body);

// PATCH REQUEST for finance to define the services a student consumes
export const updateStudentSchoolServices = (userId, body) =>
  API.patch(`/api/finance/maintain-student-school-services/${userId}/`, body);

// PATCH REQUEST API to edit ledger group
export const editLedgerGroup = (userId, body) =>
  API.patch(`/api/finance/maintain-ledger-groups/${userId}/`, body);

// PATCH REQUEST API to edit ledger
export const editLedger = (userId, body) =>
  API.patch(`/api/finance/maintain-ledgers/${userId}/`, body);

// function to edit class level
export const editClassLevel = (userId, body) =>
  API.patch(`/api/course/maintain-class-levels/${userId}/`, body);

// PATCH REQUEST function to edit class stream
export const editClassStream = (userId, body) =>
  API.patch(`/api/course/maintain-class-stream/${userId}/`, body);

// function to edit term
export const editSchoolTerm = (userId, body) =>
  API.patch(`/api/course/maintain-school-terms/${userId}/`, body);

// function to edit subject
export const editSubject = (userId, body) =>
  API.patch(`/api/course/maintain-subjects/${userId}/`, body);

// PATCH request to edit overall grade
export const editOverallGrade = (userId, body) =>
  API.patch(`/api/course/maintain-overall-grade/${userId}/`, body);

// PATCH REQUEST to edit a dorm
export const editDorm = (userId, body) =>
  API.patch(`/api/accomodation/admin/maintain-dorms/${userId}/`, body);

// PATCH REQUEST to edit assignment
export const teacherEditAssignment = (userId, body) =>
  API.patch(`/api/exam/teacher/maintain-assignments/${userId}/`, body);

// PATCH REQUEST to edit a stock item
export const editStockItem = (userId, body) =>
  API.patch(`/api/store/admin/maintain-stock-items/${userId}/`, body);

// PATCH REQUEST to edit a book
export const editBook = (userId, body) =>
  API.patch(`/api/library/admin/maintain-books/${userId}/`, body);

// PATCH REQUEST to update book borrows
export const updateBookBorrow = (userId, body) =>
  API.patch(`/api/library/admin/maintain-book-borrows/${userId}/`, body);

// PATCH REQUEST to update a deduction
export const editSalaryDeduction = (userId, body) =>
  API.patch(`/api/staff/staff/maintain-salary-deductions/${userId}/`, body);

// PATCH REQUEST to edit an earning
export const editSalaryEarning = (userId, body) =>
  API.patch(`/api/staff/staff/maintain-salary-earnings/${userId}/`, body);

// PATCH REQUEST to edit staff salary earning for specific staff
export const editStaffSalaryEarning = (userId, body) =>
  API.patch(`/api/staff/staff/maintain-staff-salary-earning/${userId}/`, body);

// PATCH REQUEST to edit staff salary deduction for specific staff
export const editStaffSalaryDeduction = (userId, body) =>
  API.patch(
    `/api/staff/staff/maintain-staff-salary-deduction/${userId}/`,
    body
  );

// PATCH REQUEST to run a payroll
export const editPayroll = (userId, body) =>
  API.patch(`/api/staff/staff/maintain-payrolls/${userId}/`, body);

// PATCH REQUEST to edit a tax relief
export const editTaxRelief = (userId, body) =>
  API.patch(`/api/staff/staff/maintain-tax-relief/${userId}/`, body);

// PATCH REQUEST TO approve payroll
export const approvePayroll = (userId, body) =>
  API.patch(`/api/staff/staff/approve-payroll/${userId}/`, body);
