import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { update_exam_question } from "../../../../../redux/actions/exam";
import { START_LOADING } from "../../../../../redux/actions/types";
import Markdown from "../../../../../shared/Markdown/Markdown";
import { ifEmpty } from "../../../../../shared/sharedFunctions";

import CircularProgress from "@mui/material/CircularProgress";

const EditQuestion = (props) => {
  const {
    setOpenEditQuestion,
    setCurrentQuestion,
    startLoading,
    updateExamQuestion,
  } = props;
  const { openEditQuestion, currentQuestion, loading, userId } = props;

  const [description, setDescription] = useState("");
  // multiple answer state
  const [multipleAnswers, setMultipleAnswers] = useState({});

  // to hold the actual values for answers, use set to enforce uniqueness
  const answersList = new Set();

  const { optionA, optionB, optionC, optionD, marks, correct_answers, id } =
    currentQuestion;

  // useEffect to set multiple answers
  useEffect(() => {
    setMultipleAnswers({
      optionA: currentQuestion?.correct_answers?.AisAnswer,
      optionB: currentQuestion?.correct_answers?.BisAnswer,
      optionC: currentQuestion?.correct_answers?.CisAnswer,
      optionD: currentQuestion?.correct_answers?.DisAnswer,
    });
    setDescription(currentQuestion?.description);
  }, [currentQuestion]);

  // function to handle change
  const handleChange = (e) => {
    setCurrentQuestion({ ...currentQuestion, [e.target.name]: e.target.value });
  };

  // function to add multiple answers
  const chooseMultipleAnswers = (e) => {
    setMultipleAnswers({
      ...multipleAnswers,
      [e.target.name]: e.target.checked,
    });
  };

  // function to get correct answers for multiple answer questions
  const getMultipleAnswers = () => {
    for (const item in multipleAnswers) {
      if (multipleAnswers[item] === true) {
        answersList.add(currentQuestion[item]);
      }
    }
    return answersList;
  };

  // function to edit question
  const handleSubmit = (e) => {
    e.preventDefault();
    // run this function to add correct answers to answersList
    getMultipleAnswers();
    if (ifEmpty(currentQuestion)) {
      return window.alert("Fill in all fields");
    }
    let updated_answers;
    if (!currentQuestion?.has_multiple_answers) {
      updated_answers = currentQuestion?.correct_answers;
    } else if (currentQuestion?.has_multiple_answers) {
      updated_answers = Array.from(answersList);
    }
    const body = {
      description,
      optionA,
      optionB,
      optionC,
      optionD,
      id,
      marks,
      correct_answers: updated_answers,
      questionType: currentQuestion.has_multiple_answers
        ? "multiple_answer"
        : "single_answer",
    };
    startLoading();
    updateExamQuestion(userId, body);
  };

  return (
    <MediumDialog isOpen={openEditQuestion} maxWidth="1100px">
      <form
        className="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Edit question details</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Question description</label>
          <Markdown content={description} setContent={setDescription} />
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Marks</label>
            <input
              type="number"
              name="marks"
              min="1"
              onChange={handleChange}
              value={marks}
            />
          </span>
          <span>
            <label htmlFor="" className="label__right">
              Question Type
            </label>
            <input
              type="text"
              className="input__right"
              value={
                currentQuestion?.has_multiple_answers
                  ? "Multiple Answer"
                  : "Single Answer"
              }
              disabled
              readOnly
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option A</label>
          <input
            type="text"
            name="optionA"
            onChange={handleChange}
            value={optionA}
          />
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option B</label>
          <input
            type="text"
            name="optionB"
            onChange={handleChange}
            value={optionB}
          />
        </div>

        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option C</label>
          <input
            type="text"
            name="optionC"
            onChange={handleChange}
            value={optionC}
          />
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option D</label>
          <input
            type="text"
            name="optionD"
            onChange={handleChange}
            value={optionD}
          />
        </div>
        {currentQuestion?.has_multiple_answers ? (
          <>
            {/* show this section if questionType is multiple answer */}
            <div className="dialog__rowSingleItem">
              <label htmlFor="">Choose the correct answers</label>
            </div>
            <div className="dialog__sameLineItems">
              <span>
                <input
                  type="checkbox"
                  id={optionA}
                  onChange={chooseMultipleAnswers}
                  checked={multipleAnswers?.optionA}
                  name="optionA"
                />
                <label htmlFor={optionA}>Option A</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id={optionB}
                  onChange={chooseMultipleAnswers}
                  checked={multipleAnswers?.optionB}
                  name="optionB"
                />
                <label htmlFor={optionB}>Option B</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id={optionC}
                  onChange={chooseMultipleAnswers}
                  checked={multipleAnswers?.optionC}
                  name="optionC"
                />
                <label htmlFor={optionC}>Option C</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id={optionD}
                  onChange={chooseMultipleAnswers}
                  checked={multipleAnswers?.optionD}
                  name="optionD"
                />
                <label htmlFor={optionD}>Option D</label>
              </span>
            </div>
          </>
        ) : (
          <>
            {/* show this section if questionType is single answer */}
            <div className="dialog__rowSingleItem">
              <label htmlFor="">Choose right answer</label>
              <select
                onChange={handleChange}
                value={correct_answers}
                name="correct_answers"
              >
                <option value="" disabled selected>
                  Select one
                </option>
                <option value={optionA}>{optionA}</option>
                <option value={optionB}>{optionB}</option>
                <option value={optionC}>{optionC}</option>
                <option value={optionD}>{optionD}</option>
              </select>
            </div>
          </>
        )}

        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenEditQuestion(false)}>
            Close
          </button>
          <button type="submit">Update</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    updateExamQuestion: (userId, body) =>
      dispatch(update_exam_question(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditQuestion);
