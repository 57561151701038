import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_lost_books } from "../../../redux/actions/library";
import { START_LOADING } from "../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import LostBookChargeUser from "./components/LostBookChargeUser";

const BooksLost = (props) => {
  const { loading, userId, lost_books } = props;
  const { startLoading, getLostBooks } = props;

  const [currentLostBook, setCurrentLostBook] = useState({});
  const [openChargeUser, setOpenChargeUser] = useState(false);

  // useEffect to get lost books
  useEffect(() => {
    if (userId) {
      startLoading();
      getLostBooks(userId);
    }
  }, [getLostBooks, userId, startLoading]);

  const handleOpenChargeUser = (lost_book) => {
    setCurrentLostBook(lost_book);
    setOpenChargeUser(true);
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <h3>Currently lost books</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {lost_books?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Book Name</th>
                <th>User</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {lost_books?.map((item) => (
                <tr class="table__listingItem" key={item?.id}>
                  <td>{item?.book_name}</td>
                  <td>{item?.user}</td>
                  <td
                    className="button__sp dodgerblue bd"
                    onClick={() => handleOpenChargeUser(item)}
                  >
                    Charge User
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No lost books</h4>
        )}
      </div>
      {/* child components */}
      {openChargeUser && (
        <LostBookChargeUser
          openChargeUser={openChargeUser}
          setOpenChargeUser={setOpenChargeUser}
          currentLostBook={currentLostBook}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    lost_books: state?.library?.lost_books,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getLostBooks: (userId) => dispatch(get_lost_books(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BooksLost);
