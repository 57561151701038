import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  delete_paye_bracket,
  get_paye_brackets,
} from "../../../redux/actions/staff";
import { START_LOADING } from "../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import AddPAYEBracket from "./components/AddPAYEBracket";
import { formatWithCommas } from "../../../shared/sharedFunctions";
import PAYECalculator from "./components/PAYECalculator";

const PAYEBrackets = (props) => {
  const { loading, userId, PAYEBrackets } = props;
  const { startLoading, getPAYEBrackets, deletePAYEBracket } = props;

  const [openAddPAYEBracket, setOpenAddPAYEBracket] = useState(false);
  const [openPAYECalculator, setOpenPAYECalculator] = useState(false);

  // get paye brackets
  useEffect(() => {
    if (userId) {
      startLoading();
      getPAYEBrackets(userId);
    }
  }, [startLoading, userId, getPAYEBrackets, PAYEBrackets?.length]);

  const handleDeletePAYEBracket = (e, PAYEBracketId) => {
    e.preventDefault();
    if (
      window.confirm(
        "Delete action is not reversible, are you sure you want to continue"
      )
    ) {
      startLoading();
      deletePAYEBracket(userId, PAYEBracketId);
    }
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <button type="button" onClick={() => setOpenAddPAYEBracket(true)}>
            Add Bracket
          </button>
          <button type="button" onClick={() => setOpenPAYECalculator(true)}>
            PAYE Calculator
          </button>
          <p>
            <strong>Note:</strong>{" "}
            <span className="red">
              When creating PAYE brackets, you must create them in ascending
              order, failure to which you have to delete them and create them
              again.
            </span>
          </p>
          <h3>Currently configured PAYE brackets</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {PAYEBrackets?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Name</th>
                <th>Order</th>
                <th>Range</th>
                <th>Rate(%)</th>
                <th className="tc">Last Bracket</th>
                {/* PAYE brackets should not be editable to avoid complexities of overlapping amounts, if an error occurs, 
                you have to delete and create them again */}
                <th className="tc">Delete</th>
              </tr>
            </thead>
            <tbody>
              {PAYEBrackets?.map((bracket) => (
                <tr class="table__listingItem" key={bracket?.id}>
                  <td>{bracket?.name}</td>
                  <td>{bracket?.order}</td>
                  <td>
                    {bracket?.is_last_bracket ? (
                      <>{formatWithCommas(bracket?.start_amount)} and above</>
                    ) : (
                      <>
                        {formatWithCommas(bracket?.start_amount)} -{" "}
                        {formatWithCommas(bracket?.end_amount)}
                      </>
                    )}
                  </td>
                  <td>{bracket?.rate}</td>
                  <td className="tc">
                    {bracket?.is_last_bracket ? (
                      <i className="bx bx-check-square green"></i>
                    ) : (
                      <i className="bx bxs-x-square red"></i>
                    )}
                  </td>
                  <td className="tc">
                    <i
                      className="bx bxs-trash-alt red button__sp"
                      onClick={(e) => handleDeletePAYEBracket(e, bracket?.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No paye brackets configured yet.</h4>
        )}
      </div>
      {/* child components */}
      {openAddPAYEBracket && (
        <AddPAYEBracket
          openAddPAYEBracket={openAddPAYEBracket}
          setOpenAddPAYEBracket={setOpenAddPAYEBracket}
        />
      )}
      {openPAYECalculator && (
        <PAYECalculator
          openPAYECalculator={openPAYECalculator}
          setOpenPAYECalculator={setOpenPAYECalculator}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    PAYEBrackets: state?.staff?.PAYEBrackets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getPAYEBrackets: (userId) => dispatch(get_paye_brackets(userId)),
    deletePAYEBracket: (userId, PAYEBracketId) =>
      dispatch(delete_paye_bracket(userId, PAYEBracketId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PAYEBrackets);
