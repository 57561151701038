import React, { useEffect, useState, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { staff_get_leave_applications } from "../../redux/actions/staff";
import { START_LOADING } from "../../redux/actions/types";

import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
const GenerateP9 = lazy(() => import("./components/GenerateP9"));

const ApplyLeave = lazy(() => import("./components/ApplyLeave"));

const Dashboard = (props) => {
  const { leaveApplications, loading, userId } = props;
  const { startLoading, staffGetLeaveApplications } = props;

  const [openApplyLeave, setOpenLeaveApply] = useState(false);
  const [openGenerateP9, setOpenGenerateP9] = useState(false);

  useEffect(() => {
    if (userId) {
      startLoading();
      staffGetLeaveApplications(userId);
    }
  }, [staffGetLeaveApplications, userId, startLoading]);

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__parentHeader">
          <button type="button" onClick={() => setOpenLeaveApply(true)}>
            Apply New Leave
          </button>
          {/* <button type="button" onClick={() => setOpenGenerateP9(true)}>
            Generate P9
          </button> */}
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        {leaveApplications?.length > 0 ? (
          <table className="table__listing">
            <tr className="table__listingHeader">
              <th>Start Date</th>
              <th>End Date</th>
              <th>Approval Status</th>
            </tr>
            {leaveApplications?.map((leave) => (
              <tr className="table__listingItem">
                <td>{moment(leave?.start_date).format("LL")}</td>
                <td>{moment(leave?.end_date).format("LL")}</td>
                <td>{leave?.status}</td>
              </tr>
            ))}
          </table>
        ) : (
          <>
            <br />
            <h4 className="not__available">No leave applications done yet</h4>
          </>
        )}
      </div>
      {/* child components */}
      <Suspense
        fallback={<div style={{ textAlign: "center" }}>Loading...</div>}
      >
        {openApplyLeave && (
          <ApplyLeave
            openApplyLeave={openApplyLeave}
            setOpenLeaveApply={setOpenLeaveApply}
          />
        )}
        {openGenerateP9 && (
          <GenerateP9
            openGenerateP9={openGenerateP9}
            setOpenGenerateP9={setOpenGenerateP9}
          />
        )}
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leaveApplications: state?.staff?.leaveApplications,

    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    profile_type: state?.auth?.user?.profile_type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    staffGetLeaveApplications: (userId) =>
      dispatch(staff_get_leave_applications(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
