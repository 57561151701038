import React from "react";

const TimeSlots = () => {
  return (
    <tr className="time_slots">
      <th>Day</th>
      <th>Stream</th>
      <th>7.50 - 8.00</th>
      <th>8.00 - 8.40</th>
      <th>8.40 - 9.20</th>
      <th>9.20 - 9.30</th>
      <th>9.30 - 10.10</th>
      <th>10.10 - 10.50</th>
      <th>10.50 - 11.15</th>
      <th>11.15 - 11.55</th>
      <th>11.55 - 12.35</th>
      <th>12.35 - 13.10</th>
      <th>2.00 - 2.40</th>
      <th>2.40 - 3.20</th>
      <th>3.20 - 4.00</th>
      <th>4.00 - 5.00</th>
    </tr>
  );
};

export default TimeSlots;
