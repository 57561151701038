import React, { useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { create_new_user } from "../../../../../redux/actions/auth";
import { START_LOADING } from "../../../../../redux/actions/types";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const AddOtherStaff = (props) => {
  const { openAddOtherStaff, loading, userId } = props;
  const { setOpenAddOtherStaff, startLoading, createNewUser } = props;

  const [staffDetails, setStaffDetails] = useState({
    first_name: "",
    last_name: "",
    surname: "",
    email: "",
    gender: "",
    phone_number: "",
    joining_date: "",
    payroll_number: "",
  });
  const [kra_pin, set_kra_pin] = useState(null);

  const {
    first_name,
    last_name,
    surname,
    email,
    gender,
    phone_number,
    joining_date,
    payroll_number,
  } = staffDetails;

  const handleChange = (e) => {
    setStaffDetails({ ...staffDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    resetFormValues(staffDetails);
    set_kra_pin("");
  };

  const handleClose = () => {
    resetForm();
    setOpenAddOtherStaff(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(staffDetails)) {
      return window.alert("Fill in all fields");
    }
    startLoading();
    const body = {
      ...staffDetails,
      kra_pin,
      userType: "Other_Staff",
    };
    createNewUser(userId, body, resetForm);
  };

  return (
    <MediumDialog isOpen={openAddOtherStaff}>
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>Enter staff details</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={first_name}
            />
          </span>
          <span>
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={last_name}
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Surname</label>
            <input
              type="text"
              name="surname"
              onChange={handleChange}
              value={surname}
            />
          </span>
          <span>
            <label htmlFor="">Email</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={email}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <span>
            <label htmlFor="">Gender</label>
            <select name="gender" onChange={handleChange} value={gender}>
              <option value="" disabled selected>
                Select gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </span>
          <span>
            <label htmlFor="">Phone Number</label>
            <input
              type="text"
              name="phone_number"
              onChange={handleChange}
              value={phone_number}
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Joining Date</label>
            <input
              type="date"
              name="joining_date"
              onChange={handleChange}
              value={joining_date}
            />
          </span>
          <span>
            <label htmlFor="">Payroll Number</label>
            <input
              type="text"
              name="payroll_number"
              onChange={handleChange}
              value={payroll_number}
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">KRA PIN (optional)</label>
            <input
              type="text"
              name="kra_pin"
              onChange={(e) => set_kra_pin(e.target.value)}
              value={kra_pin}
            />
          </span>
          <span></span>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createNewUser: (userId, body, resetForm) =>
      dispatch(create_new_user(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOtherStaff);
