import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  subjects: [],
  subject_groups: [],
  overall_grading: [],
  school_terms: [],
  class_levels: [],
  class_streams: [],
  teacherExams: [],
  alloted_subjects: [],
  teacher_subjects: [],
  live_classes: [],
  student_live_classes: [],
  teachers: [], // used to enable selection of class teachers when creating class streams
};

const addNewClassLevel = (state, payload) => {
  return updateObject(state, {
    class_levels: [...state.class_levels, payload],
  });
};

const adminSetClassStreams = (state, payload) => {
  return updateObject(state, {
    class_levels: payload.class_levels,
    class_streams: payload.class_streams,
    teachers: payload?.teachers,
  });
};

const setSubjects = (state, payload) => {
  return updateObject(state, {
    subjects: payload?.subjects,
    subject_groups: payload?.subject_groups,
    overall_grading: payload?.overall_grading,
  });
};

const createSubject = (state, payload) => {
  return updateObject(state, {
    subjects: [...state.subjects, payload],
  });
};

const allotSubject = (state, payload) => {
  return updateObject(state, {
    alloted_subjects: [...state.alloted_subjects, payload],
  });
};

const removeClassStreamSubjectTeacher = (state, payload) => {
  return updateObject(state, {
    alloted_subjects: state?.alloted_subjects?.filter(
      (item) => item?.id !== payload
    ),
  });
};

const setSubjectAllotments = (state, payload) => {
  return updateObject(state, {
    alloted_subjects: payload,
  });
};

const setTeacherSubjects = (state, payload) => {
  return updateObject(state, {
    teacher_subjects: payload,
  });
};

const setTeacherLiveClasses = (state, payload) => {
  return updateObject(state, {
    live_classes: payload,
  });
};

const newLiveClass = (state, payload) => {
  return updateObject(state, {
    live_classes: [...state.live_classes, payload],
  });
};

// for users suchas teachers to get class streams
const setClassStreams = (state, payload) => {
  return updateObject(state, {
    class_streams: payload,
  });
};

const setStudentLiveClasses = (state, payload) => {
  return updateObject(state, {
    student_live_classes: payload,
  });
};

const editClassLevel = (state, payload) => {
  return updateObject(state, {
    class_levels: state?.class_levels?.map((level) =>
      level?.id === payload?.id ? payload : level
    ),
  });
};

const editClassStream = (state, payload) => {
  return updateObject(state, {
    class_streams: state?.class_streams?.map((stream) =>
      stream?.id === payload?.id ? payload : stream
    ),
  });
};

const editSchoolTerm = (state, payload) => {
  return updateObject(state, {
    school_terms: state?.school_terms?.map((term) =>
      term?.id === payload?.id ? payload : term
    ),
  });
};

const updateSubject = (state, payload) => {
  return updateObject(state, {
    subjects: state?.subjects?.map((subject) =>
      subject?.id === payload?.id ? payload : subject
    ),
  });
};

const addOverallGrade = (state, payload) => {
  return updateObject(state, {
    overall_grading: [...state.overall_grading, payload],
  });
};

const editOverallGrade = (state, payload) => {
  return updateObject(state, {
    overall_grading: state?.overall_grading?.map((grade) =>
      grade?.id === payload?.id ? payload : grade
    ),
  });
};

const deleteOverallGrade = (state, payload) => {
  return updateObject(state, {
    overall_grading: state?.overall_grading?.filter(
      (grade) => grade?.id !== payload
    ),
  });
};

const courseReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.NEW_SCHOOL_TERM:
      return {
        ...state,
        school_terms: [...state.school_terms, payload],
      };
    case actionTypes.SET_SCHOOL_TERMS:
      return {
        ...state,
        school_terms: payload,
      };
    case actionTypes.NEW_CLASS_LEVEL:
      return addNewClassLevel(state, payload);
    case actionTypes.NEW_CLASS_STREAM:
      return {
        ...state,
        class_streams: [...state.class_streams, payload],
      };
    case actionTypes.ADMIN_SET_CLASS_STREAMS:
      return adminSetClassStreams(state, payload);
    case actionTypes.SET_CLASS_STREAMS:
      return setClassStreams(state, payload);
    case actionTypes.SET_SUBJECTS:
      return setSubjects(state, payload);
    case actionTypes.NEW_SUBJECT:
      return createSubject(state, payload);
    case actionTypes.ALLOT_SUBJECT:
      return allotSubject(state, payload);
    case actionTypes.REMOVE_SUBJECT_ALLOTMENT:
      return removeClassStreamSubjectTeacher(state, payload);
    case actionTypes.SET_SUBJECT_ALLOTMENTS:
      return setSubjectAllotments(state, payload);
    case actionTypes.SET_TEACHER_SUBJECTS:
      return setTeacherSubjects(state, payload);
    case actionTypes.SET_TEACHER_LIVE_CLASSES:
      return setTeacherLiveClasses(state, payload);
    case actionTypes.NEW_LIVE_CLASS:
      return newLiveClass(state, payload);
    case actionTypes.SET_STUDENT_LIVE_CLASSES:
      return setStudentLiveClasses(state, payload);
    case actionTypes.EDIT_CLASS_LEVEL:
      return editClassLevel(state, payload);
    case actionTypes.EDIT_CLASS_STREAM:
      return editClassStream(state, payload);
    case actionTypes.EDIT_SCHOOL_TERM:
      return editSchoolTerm(state, payload);
    case actionTypes.UPDATE_SUBJECT:
      return updateSubject(state, payload);
    case actionTypes.ADD_OVERALL_GRADE:
      return addOverallGrade(state, payload);
    case actionTypes.EDIT_OVERALL_GRADE:
      return editOverallGrade(state, payload);
    case actionTypes.DELETE_OVERALL_GRADE:
      return deleteOverallGrade(state, payload);
    default:
      return state;
  }
};

export default courseReducer;
