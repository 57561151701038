import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import MediumDialog from "../../../../../components/common/MediumDialog";
import { START_LOADING } from "../../../../../redux/actions/types";
import {
  allot_subject,
  admin_get_class_streams,
  get_subjects,
} from "../../../../../redux/actions/course";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const AllotSubjects = (props) => {
  const {
    openAllotSubjects,
    subjects,
    userId,
    class_streams,
    loading,
    currentTeacherId,
  } = props;
  const {
    setOpenAllotSubjects,
    getSubjects,
    startLoading,
    adminGetClassStreams,
    allotSubject,
  } = props;

  const [allotmentDetails, setAllotmentDetails] = useState({
    subject: "",
    class_stream: "",
  });

  useEffect(() => {
    if (subjects?.length === 0 && userId) {
      startLoading();
      getSubjects(userId);
    }
  }, [subjects?.length, userId, startLoading, getSubjects]);

  useEffect(() => {
    if (class_streams?.length === 0 && userId) {
      startLoading();
      adminGetClassStreams(userId);
    }
  }, [class_streams?.length, userId, startLoading, adminGetClassStreams]);

  const handleChange = (e) => {
    setAllotmentDetails({
      ...allotmentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const { subject, class_stream } = allotmentDetails;

  const resetForm = () => {
    resetFormValues(allotmentDetails);
  };

  const handleClose = () => {
    resetForm();
    setOpenAllotSubjects(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(allotmentDetails)) {
      return window.alert("Please fill all fields");
    }
    startLoading();
    allotSubject(
      userId,
      { ...allotmentDetails, teacher: currentTeacherId },
      resetForm
    );
  };

  return (
    <MediumDialog isOpen={openAllotSubjects} maxWidth="800px">
      <form
        className="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Select subject to allot to teacher</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "43%" }}
          />
        )}
        <div className="dialog__row">
          <span>
            <label htmlFor="">Subject</label>
            <select name="subject" onChange={handleChange} value={subject}>
              <option value="" selected disabled>
                Select subject
              </option>
              {subjects?.map((subject) => (
                <option value={subject?.id} key={subject?.id}>
                  {subject?.name}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label htmlFor="">Class Stream</label>
            <select
              name="class_stream"
              onChange={handleChange}
              value={class_stream}
            >
              <option value="" selected disabled>
                Select class stream
              </option>
              {class_streams?.map((stream) => (
                <option value={stream?.id} key={stream?.id}>
                  {stream?.name}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Allot Subject</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    subjects: state.course?.subjects,
    userId: state.auth?.user?.id,
    class_streams: state.course?.class_streams,
    loading: state.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getSubjects: (userId) => dispatch(get_subjects(userId)),
    adminGetClassStreams: (userId) => dispatch(admin_get_class_streams(userId)),
    allotSubject: (userId, body, resetForm) =>
      dispatch(allot_subject(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllotSubjects);
