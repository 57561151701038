import React, { useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { create_exam_question } from "../../../../../redux/actions/exam";
import { START_LOADING } from "../../../../../redux/actions/types";
import Markdown from "../../../../../shared/Markdown/Markdown";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const AddQuestion = (props) => {
  const { openAddQuestion, userId, loading, currentExamId } = props;
  const { setOpenAddQuestion, startLoading, createExamQuestion } = props;
  const [description, setDescription] = useState("");

  const [newQuestion, setNewQuestion] = useState({
    marks: "",
    questionType: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
  });

  // single answer state
  const [answer, setAnswer] = useState("");

  // to hold the actual values for answers, use set to enforce uniqueness
  const answersList = new Set();

  // multiple answer state
  const [multipleAnswers, setMultipleAnswers] = useState({
    optionA: false,
    optionB: false,
    optionC: false,
    optionD: false,
  });

  const { marks, questionType, optionA, optionB, optionC, optionD } =
    newQuestion;

  const resetForm = () => {
    resetFormValues(newQuestion);
    resetFormValues(multipleAnswers);
    setDescription("");
  };

  const handleClose = () => {
    resetForm();
    setOpenAddQuestion(false);
  };

  // function to handle change
  const handleChange = (e) => {
    setNewQuestion({ ...newQuestion, [e.target.name]: e.target.value });
  };
  // function to add multiple answers
  const chooseMultipleAnswers = (e) => {
    setMultipleAnswers({
      ...multipleAnswers,
      [e.target.name]: e.target.checked,
    });
  };

  // function to get correct answers for multiple answer questions
  const getMultipleAnswers = () => {
    for (const item in multipleAnswers) {
      if (multipleAnswers[item] === true) {
        answersList.add(newQuestion[item]);
      }
    }
    return answersList;
  };

  // function to handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    // run this function to add correct answers to answersList
    getMultipleAnswers();
    if (ifEmpty(newQuestion)) {
      return window.alert("Fill in all fields");
    }
    if (questionType === "single_answer" && answer.trim() === "") {
      return window.alert("Fill in all fields");
    }
    if (questionType === "multiple_answer" && answersList.size === 0) {
      return window.alert("Fill in all fields");
    }
    let correct_answers;
    if (questionType === "single_answer") {
      correct_answers = [answer];
    } else if (questionType === "multiple_answer") {
      correct_answers = Array.from(answersList);
    }
    const body = {
      ...newQuestion,
      currentExamId,
      description,
      correct_answers,
    };
    startLoading();
    createExamQuestion(userId, body, resetForm);
  };

  return (
    <MediumDialog isOpen={openAddQuestion} maxWidth="1100px">
      <form
        className="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Enter question details</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Question description</label>
          <Markdown content={description} setContent={setDescription} />
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Marks</label>
            <input
              type="number"
              name="marks"
              min="1"
              onChange={handleChange}
              value={marks}
            />
          </span>
          <span>
            <label htmlFor="">Question type</label>
            <select
              name="questionType"
              onChange={handleChange}
              value={questionType}
            >
              <option value="" selected disabled>
                Select type
              </option>
              <option value="single_answer">Single answer</option>
              <option value="multiple_answer">Multiple answer</option>
            </select>
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option A</label>
          <input
            type="text"
            name="optionA"
            onChange={handleChange}
            value={optionA}
          />
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option B</label>
          <input
            type="text"
            name="optionB"
            onChange={handleChange}
            value={optionB}
          />
        </div>

        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option C</label>
          <input
            type="text"
            name="optionC"
            onChange={handleChange}
            value={optionC}
          />
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option D</label>
          <input
            type="text"
            name="optionD"
            onChange={handleChange}
            value={optionD}
          />
        </div>

        {/* show this section if questionType is single answer */}
        {questionType === "single_answer" && (
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Choose right answer</label>
            <select onChange={(e) => setAnswer(e.target.value)} value={answer}>
              <option value="" disabled selected>
                Select one
              </option>
              <option value={optionA}>{optionA}</option>
              <option value={optionB}>{optionB}</option>
              <option value={optionC}>{optionC}</option>
              <option value={optionD}>{optionD}</option>
            </select>
          </div>
        )}

        {/* show this section if questionType is multiple answer */}
        {questionType === "multiple_answer" && (
          <>
            <div className="dialog__rowSingleItem">
              <label htmlFor="">Choose the correct answers</label>
            </div>
            <div className="dialog__sameLineItems">
              <span>
                <input
                  type="checkbox"
                  id={optionA}
                  onChange={chooseMultipleAnswers}
                  checked={multipleAnswers?.optionA}
                  name="optionA"
                />
                <label htmlFor={optionA}>Option A</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id={optionB}
                  onChange={chooseMultipleAnswers}
                  checked={multipleAnswers?.optionB}
                  name="optionB"
                />
                <label htmlFor={optionB}>Option B</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id={optionC}
                  onChange={chooseMultipleAnswers}
                  checked={multipleAnswers?.optionC}
                  name="optionC"
                />
                <label htmlFor={optionC}>Option C</label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id={optionD}
                  onChange={chooseMultipleAnswers}
                  checked={multipleAnswers?.optionD}
                  name="optionD"
                />
                <label htmlFor={optionD}>Option D</label>
              </span>
            </div>
          </>
        )}
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createExamQuestion: (userId, body, resetForm) =>
      dispatch(create_exam_question(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
