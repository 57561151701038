import React, { useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../components/common/MediumDialog";
import { add_new_book } from "../../../../redux/actions/library";
import { START_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import { ifEmpty, resetFormValues } from "../../../../shared/sharedFunctions";

const AddNewBook = (props) => {
  const { openAddNewBook, subjects, class_levels, loading, userId } = props;
  const { setOpenAddNewBook, startLoading, addNewBook } = props;

  const [bookDetails, setBookDetails] = useState({
    name: "",
    book_code: "",
    year_published: "",
    author: "",
    quantity: "",
    average_cost: "",
    category: "",
  });
  const {
    name,
    book_code,
    year_published,
    author,
    quantity,
    average_cost,
    category,
  } = bookDetails;

  const [subjectAndClass, setSubjectAndClass] = useState({
    subject: null,
    class_level: null,
  });

  const { subject, class_level } = subjectAndClass;

  const handleChange = (e) => {
    setBookDetails({ ...bookDetails, [e.target.name]: e.target.value });
  };

  const handleSubjectClassChange = (e) => {
    setSubjectAndClass({ ...subjectAndClass, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    resetFormValues(bookDetails);
    setSubjectAndClass({
      subject: null,
      class_level: null,
    });
  };

  const handleClose = () => {
    setOpenAddNewBook(false);
    resetForm();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (category === "academic" && ifEmpty(subjectAndClass)) {
      return window.alert("Subject and class level are required");
    }
    if (ifEmpty(bookDetails)) {
      return window.alert("All fields are required");
    }
    startLoading();
    const body = {
      ...bookDetails,
      ...subjectAndClass,
    };
    addNewBook(userId, body, resetForm);
  };
  return (
    <MediumDialog isOpen={openAddNewBook}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter new book details</h3>
        <div class="dialog__row">
          <span>
            <label for="">Name</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={name}
            />
          </span>
          <span>
            <label for="">Author</label>
            <input
              type="text"
              name="author"
              onChange={handleChange}
              value={author}
            />
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Book Code</label>
            <input
              type="text"
              name="book_code"
              onChange={handleChange}
              value={book_code}
            />
          </span>
          <span>
            <label for="">Year Published</label>
            <input
              type="number"
              name="year_published"
              onChange={handleChange}
              value={year_published}
              min="1"
            />
          </span>
          <span>
            <label for="">Category</label>
            <select name="category" onChange={handleChange} value={category}>
              <option value="" selected disabled>
                --select--
              </option>
              <option value="academic">Academic</option>
              <option value="inspirational">Inspirational</option>
              <option value="novel">Novel</option>
            </select>
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {category === "academic" && (
          <div class="dialog__row">
            <span>
              <label for="">Subject</label>
              <select
                name="subject"
                onChange={handleSubjectClassChange}
                value={subject}
              >
                <option value="" disabled selected>
                  --select subject--
                </option>
                {subjects?.map((subject) => (
                  <option value={subject?.id} key={subject?.id}>
                    {subject?.name}
                  </option>
                ))}
              </select>
            </span>
            <span>
              <label for="">Class Level</label>
              <select
                name="class_level"
                onChange={handleSubjectClassChange}
                value={class_level}
              >
                <option value="" disabled selected>
                  --select level--
                </option>
                {class_levels?.map((level) => (
                  <option value={level?.id} key={level?.id}>
                    {level?.name}
                  </option>
                ))}
              </select>
            </span>
          </div>
        )}
        <div class="dialog__row">
          <span>
            <label for="">Quantity</label>
            <input
              type="number"
              name="quantity"
              onChange={handleChange}
              value={quantity}
              min="0"
            />
          </span>
          <span>
            <label for="">Unit Cost</label>
            <input
              type="number"
              name="average_cost"
              onChange={handleChange}
              value={average_cost}
              min="0"
            />
          </span>
        </div>

        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    subjects: state.course?.subjects,
    class_levels: state.course?.class_levels,
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    addNewBook: (userId, body, resetForm) =>
      dispatch(add_new_book(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewBook);
