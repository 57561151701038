// types import
import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  signupForm: false,
  loginForm: false,
  loggedIn: false,
  forgotPasswordForm: false,
  resendActivationForm: false,
  resetPasswordConfirmForm: false,
  changePasswordForm: false,
  user: {},
  teachers: [],
  parents: [],
  students: [],
  otherstaff: [],
  parentsChildren: [],
  licenseDetails: {
    checked: false, // if true means request to check if license exists has been made
    is_valid: false,
  },
};

const userEditProfile = (state, payload) => {
  return updateObject(state, {
    user: {
      ...state?.user,
      first_name: payload?.first_name,
      last_name: payload?.last_name,
      surname: payload?.surname,
      email: payload?.email,
    },
  });
};

const setLicense = (state, payload) => {
  return updateObject(state, {
    licenseDetails: payload,
  });
};

const setUsers = (state, payload) => {
  if (payload?.userType === "Teacher") {
    return updateObject(state, {
      teachers: payload?.users,
    });
  } else if (payload?.userType === "Parent") {
    return updateObject(state, {
      parents: payload?.users,
    });
  } else if (payload?.userType === "Student") {
    return updateObject(state, {
      students: payload?.users,
    });
  } else if (payload?.userType === "Other_Staff") {
    return updateObject(state, {
      otherstaff: payload?.users,
    });
  }
};

// create new student/parent/teacher/other staff
const createNewUser = (state, payload) => {
  if (payload?.userType === "Student") {
    return updateObject(state, {
      students: [...state.students, payload.new_user],
    });
  } else if (payload?.userType === "Teacher") {
    return updateObject(state, {
      teachers: [...state.teachers, payload.new_user],
    });
  }
  if (payload?.userType === "Other_Staff") {
    return updateObject(state, {
      otherstaff: [...state.otherstaff, payload.new_user],
    });
  }
  if (payload?.userType === "Parent") {
    return updateObject(state, {
      parents: [...state.parents, payload.new_user],
    });
  }
};

const parentGetChildren = (state, payload) => {
  return updateObject(state, {
    parentsChildren: payload,
  });
};

const changeStaffGroups = (state, payload) => {
  if (payload?.userType === "Teacher") {
    return updateObject(state, {
      teachers: state.teachers?.map((teacher) =>
        teacher?.id === payload?.updated_staff?.id
          ? payload?.updated_staff
          : teacher
      ),
    });
  } else if (payload?.userType === "Other_Staff") {
    return updateObject(state, {
      otherstaff: state.otherstaff?.map((staff) =>
        staff?.id === payload?.updated_staff?.id
          ? payload?.updated_staff
          : staff
      ),
    });
  }
};

const adminUpdateUser = (state, payload) => {
  // update teacher
  if (payload?.userType === "Teacher") {
    if (payload?.user_data?.is_deactivated) {
      return updateObject(state, {
        teachers: state?.teachers?.filter(
          (teacher) => teacher?.id !== payload?.user_data?.id
        ),
      });
    } else {
      return updateObject(state, {
        teachers: state?.teachers?.map((teacher) =>
          teacher?.id === payload?.user_data?.id ? payload?.user_data : teacher
        ),
      });
    }
  }
  // update other staff
  else if (payload?.userType === "Other_Staff") {
    if (payload?.user_data?.is_deactivated) {
      return updateObject(state, {
        otherstaff: state?.otherstaff?.filter(
          (staff) => staff?.id !== payload?.user_data?.id
        ),
      });
    } else {
      return updateObject(state, {
        otherstaff: state?.otherstaff?.map((staff) =>
          staff?.id === payload?.user_data?.id ? payload?.user_data : staff
        ),
      });
    }
  }
  // update student
  else if (payload?.userType === "Student") {
    if (payload?.user_data?.is_deactivated) {
      return updateObject(state, {
        students: state?.students?.filter(
          (student) => student?.id !== payload?.user_data?.id
        ),
      });
    } else {
      return updateObject(state, {
        students: state?.students?.map((student) =>
          student?.id === payload?.user_data?.id ? payload?.user_data : student
        ),
      });
    }
  }
  // update parent
  else if (payload?.userType === "Parent") {
    if (payload?.user_data?.is_deactivated) {
      return updateObject(state, {
        parents: state?.parents?.filter(
          (parent) => parent?.id !== payload?.user_data?.id
        ),
      });
    } else {
      return updateObject(state, {
        parents: state?.parents?.map((parent) =>
          parent?.id === payload?.user_data?.id ? payload?.user_data : parent
        ),
      });
    }
  }
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_LICENSE:
      return setLicense(state, payload);
    case actionTypes.OPEN_SIGNUP:
      return {
        ...state,
        signupForm: true,
      };
    case actionTypes.CLOSE_SIGNUP:
      return {
        ...state,
        signupForm: false,
      };
    case actionTypes.OPEN_LOGIN:
      return {
        ...state,
        loginForm: true,
      };
    case actionTypes.CLOSE_LOGIN:
      return {
        ...state,
        loginForm: false,
      };
    case actionTypes.AUTH_SUCCESS:
      return { ...state, user: payload, loggedIn: true };
    case actionTypes.EDIT_PROFILE:
      return userEditProfile(state, payload);
    case actionTypes.OPEN_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordForm: true,
      };
    case actionTypes.CLOSE_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordForm: false,
      };
    case actionTypes.OPEN_RESEND_ACTIVATION:
      return {
        ...state,
        resendActivationForm: true,
      };
    case actionTypes.CLOSE_RESEND_ACTIVATION:
      return {
        ...state,
        resendActivationForm: false,
      };
    case actionTypes.OPEN_PASSWORD_RESET_CONFIRM:
      return {
        ...state,
        resetPasswordConfirmForm: true,
      };
    case actionTypes.CLOSE_PASSWORD_RESET_CONFIRM:
      return {
        ...state,
        resetPasswordConfirmForm: false,
      };
    case actionTypes.OPEN_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordForm: true,
      };
    case actionTypes.CLOSE_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordForm: false,
      };
    case actionTypes.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      };
    case actionTypes.NEW_GROUP:
      return {
        ...state,
        groups: [...state.groups, payload],
      };
    case actionTypes.SET_GROUPS:
      return {
        ...state,
        groups: payload,
      };
    case actionTypes.SET_USERS:
      return setUsers(state, payload);
    case actionTypes.NEW_USER:
      return createNewUser(state, payload);
    case actionTypes.SET_CHILDREN:
      return parentGetChildren(state, payload);
    case actionTypes.CHANGE_STAFF_GROUPS:
      return changeStaffGroups(state, payload);
    case actionTypes.UPDATE_USER:
      return adminUpdateUser(state, payload);
    case actionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
