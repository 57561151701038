import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  all_books: [],
  borrowed_books: [],
  lost_books: [],
  lost_book_charges: [],
};

const addNewBook = (state, payload) => {
  return updateObject(state, {
    all_books: [...state.all_books, payload],
  });
};

const editBook = (state, payload) => {
  return updateObject(state, {
    all_books: state?.all_books?.map((book) =>
      book?.id === payload?.id ? payload : book
    ),
  });
};

const setBooks = (state, payload) => {
  return updateObject(state, {
    all_books: payload,
  });
};

const stockExistingBook = (state, payload) => {
  return updateObject(state, {
    all_books: state?.all_books?.map((book) =>
      book?.id === payload?.id ? payload : book
    ),
  });
};

const recordBookBorrow = (state, payload) => {
  return updateObject(state, {
    borrowed_books: payload,
  });
};

const getBookBorrows = (state, payload) => {
  return updateObject(state, {
    borrowed_books: payload,
  });
};

const updateBookBorrow = (state, payload) => {
  return updateObject(state, {
    borrowed_books: state?.borrowed_books?.filter(
      (book) => book?.id !== payload
    ),
  });
};
const getLostBooks = (state, payload) => {
  return updateObject(state, {
    lost_books: payload,
  });
};

const chargeLostBook = (state, payload) => {
  return updateObject(state, {
    lost_books: state?.lost_books?.filter(
      (lost_book) => lost_book?.id !== payload
    ),
  });
};

const setLostBookCharges = (state, payload) => {
  return updateObject(state, {
    lost_book_charges: payload,
  });
};

const libraryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.ADD_NEW_BOOK:
      return addNewBook(state, payload);
    case actionTypes.SET_BOOKS:
      return setBooks(state, payload);
    case actionTypes.EDIT_BOOK:
      return editBook(state, payload);
    case actionTypes.STOCK_EXISTING_BOOK:
      return stockExistingBook(state, payload);
    case actionTypes.NEW_BOOK_BORROW:
      return recordBookBorrow(state, payload);
    case actionTypes.SET_BOOK_BORROWS:
      return getBookBorrows(state, payload);
    case actionTypes.UPDATE_BOOK_BORROW:
      return updateBookBorrow(state, payload);
    case actionTypes.SET_LOST_BOOKS:
      return getLostBooks(state, payload);
    case actionTypes.CHARGE_LOST_BOOK:
      return chargeLostBook(state, payload);
    case actionTypes.SET_LOST_BOOK_CHARGES:
      return setLostBookCharges(state, payload);
    default:
      return state;
  }
};

export default libraryReducer;
