import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import { START_LOADING } from "../../../../redux/actions/types";
import { get_users } from "../../../../redux/actions/auth";
import GroupsAllocated from "../Shared/GroupsAllocated";
import EditOtherStaff from "./components/EditOtherStaff";
import AddOtherStaff from "./components/AddOtherStaff";

const OtherStaff = (props) => {
  const { loading, otherstaff, userId } = props;
  const { startLoading, getOtherStaff } = props;

  const [openAddOtherStaff, setOpenAddOtherStaff] = useState(false);
  const [openGroupsAllocated, setOpenGroupsAllocated] = useState(false);
  const [currentStaff, setCurrentStaff] = useState({});
  const [openEditOtherStaff, setOpenEditOtherStaff] = useState(false);
  const [filteredOtherStaff, setFilteredOtherStaff] = useState([]);
  const [filterBy, setFilterBy] = useState("");

  useEffect(() => {
    if (userId) {
      startLoading();
      getOtherStaff(userId, "Other_Staff");
    }
  }, [getOtherStaff, userId, startLoading]);

  useEffect(() => {
    setFilteredOtherStaff(otherstaff);
  }, [otherstaff]);

  const handleOpenGroups = (staff) => {
    setOpenGroupsAllocated(true);
    setCurrentStaff(staff);
  };

  const handleOpenEditOtherStaff = (teacher) => {
    setCurrentStaff(teacher);
    setOpenEditOtherStaff(true);
  };

  const handleFilterByChange = (e) => {
    if (e.target.value === "all") {
      setFilteredOtherStaff(otherstaff);
    }
    setFilterBy(e.target.value);
  };

  const onFilterValueChange = (e) => {
    if (filterBy === "phone_number") {
      setFilteredOtherStaff(
        otherstaff?.filter((staff) =>
          staff?.phone_number?.includes(e.target.value)
        )
      );
    } else if (filterBy === "first_name") {
      setFilteredOtherStaff(
        otherstaff?.filter((staff) =>
          staff?.first_name
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase())
        )
      );
    } else if (filterBy === "last_name") {
      setFilteredOtherStaff(
        otherstaff?.filter((staff) =>
          staff?.last_name
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase())
        )
      );
    } else if (filterBy === "surname") {
      setFilteredOtherStaff(
        otherstaff?.filter((staff) =>
          staff?.surname?.toLowerCase()?.includes(e.target.value?.toLowerCase())
        )
      );
    }
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__parentHeader">
          <button
            type="button"
            className="add__button white"
            onClick={() => setOpenAddOtherStaff(true)}
          >
            Add Staff
          </button>
          <h3 className="dodgerblue bd ud">Maintain Other Staff</h3>
          <div
            class="dialog__row"
            style={{ maxWidth: "800px", margin: "auto" }}
          >
            <span>
              <label for="">Select option to filter other staff</label>
              <select name="" onChange={handleFilterByChange}>
                <option value="all">--all other staff--</option>
                <option value="phone_number">Filter by Phone Number</option>
                <option value="first_name">Filter by First Name</option>
                <option value="last_name">Filter by Last Name</option>
                <option value="surname">Filter by Surname</option>
              </select>
            </span>
            {filterBy === "phone_number" && (
              <span>
                <label for="">Staff phone number</label>
                <input type="number" onChange={onFilterValueChange} min="0" />
              </span>
            )}
            {filterBy === "first_name" && (
              <span>
                <label for="">Staff First Name</label>
                <input type="text" onChange={onFilterValueChange} />
              </span>
            )}
            {filterBy === "last_name" && (
              <span>
                <label for="">Staff Last Name</label>
                <input type="text" onChange={onFilterValueChange} />
              </span>
            )}
            {filterBy === "surname" && (
              <span>
                <label for="">Staff Surname</label>
                <input type="text" onChange={onFilterValueChange} />
              </span>
            )}
          </div>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <table className="table__listing">
          {filteredOtherStaff?.length > 0 ? (
            <>
              <tr className="table__listingHeader">
                <th>Name</th>
                <th>Phone Number</th>
                <th>Login Status</th>
                <th>Groups</th>
                <th>Edit</th>
              </tr>
              {filteredOtherStaff?.map((other_staff) => (
                <tr className="table__listingItem" key={other_staff?.id}>
                  <td>
                    {other_staff?.first_name} {other_staff?.last_name}{" "}
                    {other_staff?.surname}
                  </td>
                  <td>{other_staff?.phone_number}</td>
                  <td>
                    {other_staff?.is_suspended ? (
                      <span className="red bd">Suspended</span>
                    ) : (
                      <span className="green bd">Enabled</span>
                    )}
                  </td>

                  <td
                    onClick={() => handleOpenGroups(other_staff)}
                    className="dodgerblue bd button__sp"
                  >
                    Groups
                  </td>
                  <td
                    className="green bd button__sp"
                    onClick={() => handleOpenEditOtherStaff(other_staff)}
                  >
                    Edit
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <h4 className="not__available">No other staff added yet</h4>
          )}
        </table>
      </div>
      {/* child components */}

      {openAddOtherStaff && (
        <AddOtherStaff
          openAddOtherStaff={openAddOtherStaff}
          setOpenAddOtherStaff={setOpenAddOtherStaff}
        />
      )}
      {openGroupsAllocated && (
        <GroupsAllocated
          openGroupsAllocated={openGroupsAllocated}
          setOpenGroupsAllocated={setOpenGroupsAllocated}
          currentStaff={currentStaff}
          userType="Other_Staff"
        />
      )}
      {openEditOtherStaff && (
        <EditOtherStaff
          openEditOtherStaff={openEditOtherStaff}
          setOpenEditOtherStaff={setOpenEditOtherStaff}
          currentStaff={currentStaff}
          setCurrentStaff={setCurrentStaff}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    otherstaff: state?.auth?.otherstaff,
    userId: state.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOtherStaff: (userId, userType) => dispatch(get_users(userId, userType)),
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherStaff);
