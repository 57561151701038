import React from "react";

const Wednesday = (props) => {
  const { timeTableClasses } = props;
  const { handleChange } = props;

  return (
    <>
      <tr className="days">
        <th rowSpan="16">Wed</th>
        {/* 1 NORTH */}
        <th>1N</th>
        <td></td>
        <td>
          {" "}
          <input
            type="text"
            name="wed__1N__8_00__8_40"
            value={timeTableClasses?.wed__1N__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          {" "}
          <input
            type="text"
            name="wed__1N__8_40__9_20"
            value={timeTableClasses?.wed__1N__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        {/* first break below */}

        <td>
          {" "}
          <input
            type="text"
            name="wed__1N__9_30__10_10"
            value={timeTableClasses?.wed__1N__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1N__10_10__10_50"
            value={timeTableClasses?.wed__1N__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        {/* second break */}

        <td>
          <input
            type="text"
            name="wed__1N__11_15__11_55"
            value={timeTableClasses?.wed__1N__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          {" "}
          <input
            type="text"
            name="wed__1N__11_55__12_35"
            value={timeTableClasses?.wed__1N__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        {/* lunch break */}

        <td>
          {" "}
          <input
            type="text"
            name="wed__1N__2_00__2_40"
            value={timeTableClasses?.wed__1N__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          {" "}
          <input
            type="text"
            name="wed__1N__2_40__3_20"
            value={timeTableClasses?.wed__1N__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1N__3_20__4_00"
            value={timeTableClasses?.wed__1N__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 1 EAST */}
        <th>1E</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__1E__8_00__8_40"
            value={timeTableClasses?.wed__1E__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1E__8_40__9_20"
            value={timeTableClasses?.wed__1E__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1E__9_30__10_10"
            value={timeTableClasses?.wed__1E__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1E__10_10__10_50"
            value={timeTableClasses?.wed__1E__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1E__11_15__11_55"
            value={timeTableClasses?.wed__1E__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1E__11_55__12_35"
            value={timeTableClasses?.wed__1E__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1E__2_00__2_40"
            value={timeTableClasses?.wed__1E__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1E__2_40__3_20"
            value={timeTableClasses?.wed__1E__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1E__3_20__4_00"
            value={timeTableClasses?.wed__1E__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 1 WEST */}
        <th>1W</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__1W__8_00__8_40"
            value={timeTableClasses?.wed__1W__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1W__8_40__9_20"
            value={timeTableClasses?.wed__1W__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1W__9_30__10_10"
            value={timeTableClasses?.wed__1W__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1W__10_10__10_50"
            value={timeTableClasses?.wed__1W__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1W__11_15__11_55"
            value={timeTableClasses?.wed__1W__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1W__11_55__12_35"
            value={timeTableClasses?.wed__1W__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1W__2_00__2_40"
            value={timeTableClasses?.wed__1W__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1W__2_40__3_20"
            value={timeTableClasses?.wed__1W__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1W__3_20__4_00"
            value={timeTableClasses?.wed__1W__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 1 SOUTH */}
        <th>1S</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__1S__8_00__8_40"
            value={timeTableClasses?.wed__1S__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1S__8_40__9_20"
            value={timeTableClasses?.wed__1S__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1S__9_30__10_10"
            value={timeTableClasses?.wed__1S__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1S__10_10__10_50"
            value={timeTableClasses?.wed__1S__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1S__11_15__11_55"
            value={timeTableClasses?.wed__1S__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1S__11_55__12_35"
            value={timeTableClasses?.wed__1S__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1S__2_00__2_40"
            value={timeTableClasses?.wed__1S__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1S__2_40__3_20"
            value={timeTableClasses?.wed__1S__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__1S__3_20__4_00"
            value={timeTableClasses?.wed__1S__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 2 NORTH */}
        <th>2N</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__2N__8_00__8_40"
            value={timeTableClasses?.wed__2N__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2N__8_40__9_20"
            value={timeTableClasses?.wed__2N__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2N__9_30__10_10"
            value={timeTableClasses?.wed__2N__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2N__10_10__10_50"
            value={timeTableClasses?.wed__2N__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2N__11_15__11_55"
            value={timeTableClasses?.wed__2N__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2N__11_55__12_35"
            value={timeTableClasses?.wed__2N__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2N__2_00__2_40"
            value={timeTableClasses?.wed__2N__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2N__2_40__3_20"
            value={timeTableClasses?.wed__2N__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2N__3_20__4_00"
            value={timeTableClasses?.wed__2N__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 2 EAST */}
        <th>2E</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__2E__8_00__8_40"
            value={timeTableClasses?.wed__2E__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2E__8_40__9_20"
            value={timeTableClasses?.wed__2E__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2E__9_30__10_10"
            value={timeTableClasses?.wed__2E__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2E__10_10__10_50"
            value={timeTableClasses?.wed__2E__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2E__11_15__11_55"
            value={timeTableClasses?.wed__2E__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2E__11_55__12_35"
            value={timeTableClasses?.wed__2E__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2E__2_00__2_40"
            value={timeTableClasses?.wed__2E__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2E__2_40__3_20"
            value={timeTableClasses?.wed__2E__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2E__3_20__4_00"
            value={timeTableClasses?.wed__2E__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 2 WEST */}
        <th>2W</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__2W__8_00__8_40"
            value={timeTableClasses?.wed__2W__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2W__8_40__9_20"
            value={timeTableClasses?.wed__2W__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2W__9_30__10_10"
            value={timeTableClasses?.wed__2W__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2W__10_10__10_50"
            value={timeTableClasses?.wed__2W__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2W__11_15__11_55"
            value={timeTableClasses?.wed__2W__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2W__11_55__12_35"
            value={timeTableClasses?.wed__2W__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2W__2_00__2_40"
            value={timeTableClasses?.wed__2W__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2W__2_40__3_20"
            value={timeTableClasses?.wed__2W__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2W__3_20__4_00"
            value={timeTableClasses?.wed__2W__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 2 SOUTH */}
        <th>2S</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__2S__8_00__8_40"
            value={timeTableClasses?.wed__2S__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2S__8_40__9_20"
            value={timeTableClasses?.wed__2S__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2S__9_30__10_10"
            value={timeTableClasses?.wed__2S__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2S__10_10__10_50"
            value={timeTableClasses?.wed__2S__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2S__11_15__11_55"
            value={timeTableClasses?.wed__2S__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2S__11_55__12_35"
            value={timeTableClasses?.wed__2S__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2S__2_00__2_40"
            value={timeTableClasses?.wed__2S__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2S__2_40__3_20"
            value={timeTableClasses?.wed__2S__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__2S__3_20__4_00"
            value={timeTableClasses?.wed__2S__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 3 NORTH */}
        <th>3N</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__3N__8_00__8_40"
            value={timeTableClasses?.wed__3N__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3N__8_40__9_20"
            value={timeTableClasses?.wed__3N__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3N__9_30__10_10"
            value={timeTableClasses?.wed__3N__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3N__10_10__10_50"
            value={timeTableClasses?.wed__3N__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3N__11_15__11_55"
            value={timeTableClasses?.wed__3N__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3N__11_55__12_35"
            value={timeTableClasses?.wed__3N__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3N__2_00__2_40"
            value={timeTableClasses?.wed__3N__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3N__2_40__3_20"
            value={timeTableClasses?.wed__3N__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3N__3_20__4_00"
            value={timeTableClasses?.wed__3N__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 3 EAST */}
        <th>3E</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__3E__8_00__8_40"
            value={timeTableClasses?.wed__3E__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3E__8_40__9_20"
            value={timeTableClasses?.wed__3E__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3E__9_30__10_10"
            value={timeTableClasses?.wed__3E__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3E__10_10__10_50"
            value={timeTableClasses?.wed__3E__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3E__11_15__11_55"
            value={timeTableClasses?.wed__3E__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3E__11_55__12_35"
            value={timeTableClasses?.wed__3E__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3E__2_00__2_40"
            value={timeTableClasses?.wed__3E__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3E__2_40__3_20"
            value={timeTableClasses?.wed__3E__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3E__3_20__4_00"
            value={timeTableClasses?.wed__3E__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 3 WEST */}
        <th>3W</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__3W__8_00__8_40"
            value={timeTableClasses?.wed__3W__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3W__8_40__9_20"
            value={timeTableClasses?.wed__3W__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3W__9_30__10_10"
            value={timeTableClasses?.wed__3W__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3W__10_10__10_50"
            value={timeTableClasses?.wed__3W__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3W__11_15__11_55"
            value={timeTableClasses?.wed__3W__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3W__11_55__12_35"
            value={timeTableClasses?.wed__3W__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3W__2_00__2_40"
            value={timeTableClasses?.wed__3W__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3W__2_40__3_20"
            value={timeTableClasses?.wed__3W__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3W__3_20__4_00"
            value={timeTableClasses?.wed__3W__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 3 SOUTH */}
        <th>3S</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__3S__8_00__8_40"
            value={timeTableClasses?.wed__3S__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3S__8_40__9_20"
            value={timeTableClasses?.wed__3S__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3S__9_30__10_10"
            value={timeTableClasses?.wed__3S__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3S__10_10__10_50"
            value={timeTableClasses?.wed__3S__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3S__11_15__11_55"
            value={timeTableClasses?.wed__3S__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3S__11_55__12_35"
            value={timeTableClasses?.wed__3S__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3S__2_00__2_40"
            value={timeTableClasses?.wed__3S__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3S__2_40__3_20"
            value={timeTableClasses?.wed__3S__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__3S__3_20__4_00"
            value={timeTableClasses?.wed__3S__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 4 NORTH */}
        <th>4N</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__4N__8_00__8_40"
            value={timeTableClasses?.wed__4N__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4N__8_40__9_20"
            value={timeTableClasses?.wed__4N__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4N__9_30__10_10"
            value={timeTableClasses?.wed__4N__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4N__10_10__10_50"
            value={timeTableClasses?.wed__4N__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4N__11_15__11_55"
            value={timeTableClasses?.wed__4N__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4N__11_55__12_35"
            value={timeTableClasses?.wed__4N__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4N__2_00__2_40"
            value={timeTableClasses?.wed__4N__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4N__2_40__3_20"
            value={timeTableClasses?.wed__4N__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4N__3_20__4_00"
            value={timeTableClasses?.wed__4N__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 4 EAST */}
        <th>4E</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__4E__8_00__8_40"
            value={timeTableClasses?.wed__4E__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4E__8_40__9_20"
            value={timeTableClasses?.wed__4E__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4E__9_30__10_10"
            value={timeTableClasses?.wed__4E__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4E__10_10__10_50"
            value={timeTableClasses?.wed__4E__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4E__11_15__11_55"
            value={timeTableClasses?.wed__4E__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4E__11_55__12_35"
            value={timeTableClasses?.wed__4E__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4E__2_00__2_40"
            value={timeTableClasses?.wed__4E__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4E__2_40__3_20"
            value={timeTableClasses?.wed__4E__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4E__3_20__4_00"
            value={timeTableClasses?.wed__4E__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 4 WEST */}
        <th>4W</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__4W__8_00__8_40"
            value={timeTableClasses?.wed__4W__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4W__8_40__9_20"
            value={timeTableClasses?.wed__4W__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4W__9_30__10_10"
            value={timeTableClasses?.wed__4W__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4W__10_10__10_50"
            value={timeTableClasses?.wed__4W__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4W__11_15__11_55"
            value={timeTableClasses?.wed__4W__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4W__11_55__12_35"
            value={timeTableClasses?.wed__4W__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4W__2_00__2_40"
            value={timeTableClasses?.wed__4W__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4W__2_40__3_20"
            value={timeTableClasses?.wed__4W__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4W__3_20__4_00"
            value={timeTableClasses?.wed__4W__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
      <tr className="days">
        {/* 4 SOUTH */}
        <th>4S</th>
        <td></td>
        <td>
          <input
            type="text"
            name="wed__4S__8_00__8_40"
            value={timeTableClasses?.wed__4S__8_00__8_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4S__8_40__9_20"
            value={timeTableClasses?.wed__4S__8_40__9_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4S__9_30__10_10"
            value={timeTableClasses?.wed__4S__9_30__10_10}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4S__10_10__10_50"
            value={timeTableClasses?.wed__4S__10_10__10_50}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4S__11_15__11_55"
            value={timeTableClasses?.wed__4S__11_15__11_55}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4S__11_55__12_35"
            value={timeTableClasses?.wed__4S__11_55__12_35}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4S__2_00__2_40"
            value={timeTableClasses?.wed__4S__2_00__2_40}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4S__2_40__3_20"
            value={timeTableClasses?.wed__4S__2_40__3_20}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="text"
            name="wed__4S__3_20__4_00"
            value={timeTableClasses?.wed__4S__3_20__4_00}
            onChange={handleChange}
          />
        </td>
        <td></td>
      </tr>
    </>
  );
};

export default Wednesday;
