import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get_subjects } from "../../../../redux/actions/course";
import { START_LOADING } from "../../../../redux/actions/types";
import AddSubject from "./components/AddSubject";
import EditSubject from "./components/EditSubject";
import OverallGrading from "./components/OverallGrading";
import SubjectGrading from "./components/SubjectGrading";

const Subjects = (props) => {
  const { subjects, userId } = props;
  const { startLoading, getSubjects } = props;

  const [openAddSubject, setOpenAddSubject] = useState(false);
  const [currentSubject, setCurrentSubject] = useState({});
  const [openEditSubject, setOpenEditSubject] = useState(false);
  const [openSubjectGrading, setOpenSubjectGrading] = useState(false);
  const [openOverallGrading, setOpenOverallGrading] = useState(false);

  useEffect(() => {
    if (userId) {
      startLoading();
      getSubjects(userId);
    }
  }, [startLoading, userId, getSubjects]);

  const handleOpenSubjectEdit = (subject) => {
    setCurrentSubject(subject);
    setOpenEditSubject(true);
  };

  const handleOpenSubjectGrading = (subject) => {
    setCurrentSubject(subject);
    setOpenSubjectGrading(true);
  };

  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <button
            className="add__button"
            type="button"
            onClick={() => setOpenAddSubject(true)}
          >
            Add Subject
          </button>
          <button type="button" onClick={() => setOpenOverallGrading(true)}>
            Overall Grading
          </button>
          <h3>Maintain school subjects</h3>
        </div>
        <table className="table__listing" style={{ maxWidth: "600px" }}>
          {subjects?.length > 0 ? (
            <>
              <tr className="table__listingHeader">
                <th>Title</th>
                <th>Edit</th>
                <th>Grading</th>
              </tr>
              {subjects?.map((subject) => (
                <tr className="table__listingItem">
                  <td>{subject?.name}</td>
                  <td
                    class="dodgerblue bd button__sp"
                    onClick={() => handleOpenSubjectEdit(subject)}
                  >
                    Edit
                  </td>
                  <td
                    onClick={() => handleOpenSubjectGrading(subject)}
                    className="dodgerblue bd button__sp"
                  >
                    View
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <h4 className="not__available">No subjects added yet</h4>
          )}
        </table>
      </div>
      {/* child components */}

      {openAddSubject && (
        <AddSubject
          openAddSubject={openAddSubject}
          setOpenAddSubject={setOpenAddSubject}
        />
      )}
      {openEditSubject && (
        <EditSubject
          openEditSubject={openEditSubject}
          setOpenEditSubject={setOpenEditSubject}
          currentSubject={currentSubject}
          setCurrentSubject={setCurrentSubject}
        />
      )}
      {openSubjectGrading && (
        <SubjectGrading
          openSubjectGrading={openSubjectGrading}
          setOpenSubjectGrading={setOpenSubjectGrading}
          currentSubject={currentSubject}
          setCurrentSubject={setCurrentSubject}
        />
      )}
      {openOverallGrading && (
        <OverallGrading
          openOverallGrading={openOverallGrading}
          setOpenOverallGrading={setOpenOverallGrading}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subjects: state.course?.subjects,
    userId: state.auth.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getSubjects: (userId) => dispatch(get_subjects(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subjects);
