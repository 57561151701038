// import installed packages
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const StudentLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to="/student/reports/"
        className={
          `${pathname}` === "/student/reports/"
            ? "nav__link active"
            : "nav__link"
        }
      >
        <i className="bx bxs-report"></i>
        <span className="nav__name">Reports</span>
      </Link>
      <Link to="#" className="nav__link" onClick={() => toggleSubmenu("Exams")}>
        <i className="bx bx-clipboard"></i>
        <span className="nav__name">Exams</span>
      </Link>
      {openSubMenu === "Exams" && (
        <>
          <Link
            to="/student/exams/"
            className={
              `${pathname}` === "/student/exams/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Exams</span>
          </Link>
          <Link
            to="/student/assignments/"
            className={
              `${pathname}` === "/student/assignments/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Assignments</span>
          </Link>
        </>
      )}

      <Link
        to="/student/live-classes/"
        className={
          `${pathname}` === "/student/live-classes/"
            ? "nav__link active"
            : "nav__link"
        }
      >
        <i className="bx bxs-video"></i>
        <span className="nav__name">Live Classes</span>
      </Link>
      <Link
        to="/student/library/"
        className={
          `${pathname}` === "/student/library/"
            ? "nav__link active"
            : "nav__link"
        }
      >
        <i className="bx bx-library"></i>
        <span className="nav__name">Library</span>
      </Link>
    </>
  );
};

export default StudentLinks;
