import { useRef } from "react";
import MaxDialog from "../../common/MaxDialog";

import globals from "../../../shared/globals";

import { useReactToPrint } from "react-to-print";
import "./StudentResultsTemplate.scss";

import ReportHeader from "../../../shared/ReportHeader/ReportHeader";

const StudentResultsTemplate = (props) => {
  const { openStudentResultsTemplate, resultsData } = props;
  const { setOpenStudentResultsTemplate } = props;

  const { schoolName } = globals;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: `${resultsData?.student_name} report card`,
  });

  return (
    <MaxDialog
      isOpen={openStudentResultsTemplate}
      maxWidth="1200px"
      maxHeight="85vh"
    >
      <table className="table__listing" border="1" rules="all" ref={printArea}>
        <ReportHeader />
        <tr className="student_name tl">
          <th colSpan="25%">
            Name: <span>{resultsData?.student_name}</span>
          </th>
          <th colSpan="25%">
            Exam: <span>{resultsData?.exam}</span>
          </th>
          <th colSpan="25%">
            Grade: <span>{resultsData?.grade}</span>
          </th>
          <th colSpan="25%">
            Marks: <span>{resultsData?.marks_scored}</span>
          </th>
        </tr>
        <tr className="tl">
          <th colSpan="50%">
            Overall Position: <span>{resultsData?.class_level_position}</span>
          </th>
          <th colSpan="50%">
            Class Position: <span>{resultsData?.class_stream_position}</span>
          </th>
        </tr>
        <tr className="tl">
          <th colSpan="100%">
            Comments: <span>{resultsData?.comments}</span>
          </th>
        </tr>

        <tr className="table__listingHeader">
          <th colSpan="16.66%">Subject</th>
          <th colSpan="16.66%" className="tc">
            Marks Scored
          </th>
          <th colSpan="16.66%" className="tc">
            Grade
          </th>
          <th colSpan="16.66%" className="tc">
            Class Position
          </th>
          <th colSpan="16.66%" className="tc">
            Overall Position
          </th>
          <th colSpan="16.33%">Comments</th>
        </tr>
        {resultsData?.subjects_results?.map((subject_result) => (
          <tr key={subject_result?.id} className="table__listingItem">
            <td colSpan="16.66%">{subject_result?.subject_name}</td>
            <td colSpan="16.66%" className="tc">
              {subject_result?.marks_scored}
            </td>
            <td colSpan="16.66%" className="tc">
              {subject_result?.grade}
            </td>
            <td colSpan="16.66%" className="tc">
              {subject_result?.class_level_position}
            </td>
            <td colSpan="16.66%" className="tc">
              {subject_result?.class_stream_position}
            </td>
            <td colSpan="16.66%">{subject_result?.comments}</td>
          </tr>
        ))}
      </table>
      <div
        className="form__Buttons"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <button
          type="button"
          onClick={() => setOpenStudentResultsTemplate(false)}
        >
          Close
        </button>
        <button type="button" onClick={handlePrint}>
          Print
        </button>
      </div>
    </MaxDialog>
  );
};

export default StudentResultsTemplate;
