import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { START_LOADING } from "../../../redux/actions/types";
import {
  approve_payroll,
  edit_payroll,
  get_payrolls,
} from "../../../redux/actions/staff";
import CreatePayroll from "./components/CreatePayroll";
import CircularProgress from "@mui/material/CircularProgress";
import PayrollReport from "./components/PayrollReport";
import PayrollPaySlips from "./components/PayrollPaySlips";

const Payrolls = (props) => {
  const { userId, payrolls, loading } = props;
  const { startLoading, getPayrolls, editPayroll, approvePayroll } = props;

  const [openCreatePayroll, setOpenCreatePayroll] = useState(false);
  const [openPayrollReport, setOpenPayrollReport] = useState(false);
  const [currentPayroll, setCurrentPayroll] = useState({});
  const [openPayrollPayslips, setOpenPayrollPayslips] = useState(false);

  useEffect(() => {
    if (userId) {
      startLoading();
      getPayrolls(userId);
    }
  }, [userId, startLoading, getPayrolls]);

  const handleReRunPayroll = (payrollId) => {
    if (
      window.confirm(
        "This will rerun the payroll for the selected month and recreate all staff payslips using the updated staff earnings/deductions. Are you sure you want to continue?"
      )
    ) {
      startLoading();
      editPayroll(userId, { payrollId });
    }
  };

  const handleOpenPayroll = (payroll) => {
    setCurrentPayroll(payroll);
    setOpenPayrollReport(true);
  };

  const handleOpenPayrollPayslips = (payroll) => {
    setCurrentPayroll(payroll);
    setOpenPayrollPayslips(true);
  };

  const handleApprovePayroll = (payrollId) => {
    if (
      window.confirm(
        "Note: Payroll approval may take a few minutes due to accounting transactions posting. Once payrolls are approved they cannot be channged. Are you sure you want approve this payroll?"
      )
    ) {
      startLoading();
      approvePayroll(userId, { payrollId });
    }
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__parentHeader">
          <button type="button" onClick={() => setOpenCreatePayroll(true)}>
            Create Payroll
          </button>
          <p>
            <strong>Note: </strong>
            <span class="red">
              Payrolls that have been approved cannot be re-run/updated
            </span>
          </p>
          <h3>Maintain Payrolls</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {payrolls?.length > 0 ? (
          <table className="table__listing">
            <thead>
              <tr className="table__listingHeader">
                <th>Month</th>
                <th>Status</th>
                <th>View</th>
                <th>Payslips</th>
                <th>ReRun Payroll</th>
                <th>Approve</th>
              </tr>
            </thead>
            <tbody>
              {payrolls?.map((payroll) => (
                <tr class="table__listingItem" key={payroll?.id}>
                  <td>{payroll?.month_year}</td>
                  <td>{payroll?.payroll_status}</td>
                  <td
                    class="dodgerblue button__sp bd"
                    onClick={() => handleOpenPayroll(payroll)}
                  >
                    View
                  </td>
                  <td>
                    {/* here we open the payroll payslips */}
                    {payroll?.payroll_status === "approved" ? (
                      <span
                        className="dodgerblue bd button__sp"
                        onClick={() => handleOpenPayrollPayslips(payroll)}
                      >
                        View
                      </span>
                    ) : (
                      <span className="red bd">N/A</span>
                    )}
                  </td>
                  <td>
                    {payroll?.payroll_status === "pending_approval" ? (
                      <span
                        className="dodgerblue bd button__sp"
                        onClick={() => handleReRunPayroll(payroll?.id)}
                      >
                        ReRun Payroll
                      </span>
                    ) : (
                      <span className="red bd">N/A</span>
                    )}
                  </td>
                  <td>
                    {payroll?.payroll_status === "pending_approval" ? (
                      <span
                        className="dodgerblue bd button__sp"
                        onClick={() => handleApprovePayroll(payroll?.id)}
                      >
                        Approve
                      </span>
                    ) : (
                      <span class="red bd">N/A</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No payrolls available</h4>
        )}
      </div>
      {/* child components */}
      {openCreatePayroll && (
        <CreatePayroll
          openCreatePayroll={openCreatePayroll}
          setOpenCreatePayroll={setOpenCreatePayroll}
        />
      )}
      {openPayrollReport && (
        <PayrollReport
          openPayrollReport={openPayrollReport}
          setOpenPayrollReport={setOpenPayrollReport}
          currentPayroll={currentPayroll}
        />
      )}
      {openPayrollPayslips && (
        <PayrollPaySlips
          openPayrollPayslips={openPayrollPayslips}
          setOpenPayrollPayslips={setOpenPayrollPayslips}
          currentPayroll={currentPayroll}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    payrolls: state?.staff?.payrolls,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getPayrolls: (userId) => dispatch(get_payrolls(userId)),
    editPayroll: (userId, payrollId) =>
      dispatch(edit_payroll(userId, payrollId)),
    approvePayroll: (userId, body) => dispatch(approve_payroll(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payrolls);
