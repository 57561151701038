import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  delete_salary_deduction,
  delete_tax_relief,
  get_salary_deductions,
} from "../../../redux/actions/staff";
import { START_LOADING } from "../../../redux/actions/types";
import { formatWithCommas } from "../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import AddSalaryDeduction from "./components/AddSalaryDeduction";
import EditSalaryDeduction from "./components/EditSalaryDeduction";
import AddTaxRelief from "./components/AddTaxRelief";
import EditTaxRelief from "./components/EditTaxRelief";

const SalaryDeductions = (props) => {
  const { userId, salaryDeductions, loading, taxReliefs } = props;
  const {
    startLoading,
    getSalaryDeductions,
    deleteSalaryDeduction,
    deleteTaxRelief,
  } = props;

  const [openEditSalaryDeduction, setOpenEditSalaryDeduction] = useState(false);
  const [currentSalaryDeduction, setCurrentSalaryDeduction] = useState({});
  const [openAddSalaryDeduction, setOpenAddSalaryDedution] = useState(false);
  const [openAddTaxRelief, setOpenAddTaxRelief] = useState(false);
  const [openEditTaxRelief, setOpenEditTaxRelief] = useState(false);
  const [currentTaxRelief, setCurrentTaxRelief] = useState({});

  useEffect(() => {
    if (userId) {
      startLoading();
      getSalaryDeductions(userId);
    }
  }, [getSalaryDeductions, startLoading, userId, salaryDeductions?.length]);

  const handleOpenEditSalarydeduction = (deduction) => {
    setCurrentSalaryDeduction(deduction);
    setOpenEditSalaryDeduction(true);
  };

  const handleDeleteSalaryDeduction = (e, salaryDeductionId) => {
    e.preventDefault();
    if (
      window.confirm(
        "This action is not reversible. Are you sure you want to delete this deduction"
      )
    ) {
      startLoading();
      deleteSalaryDeduction(userId, salaryDeductionId);
    }
  };

  const handleDeleteTaxRelief = (e, taxReliefId) => {
    e.preventDefault();
    if (
      window.confirm(
        "This action is not reversible. Are you sure you want to delete this tax relief"
      )
    ) {
      startLoading();
      deleteTaxRelief(userId, taxReliefId);
    }
  };

  const handleOpenEditTaxRelief = (tax_relief) => {
    setCurrentTaxRelief(tax_relief);
    setOpenEditTaxRelief(true);
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <h3>Maintain tax reliefs</h3>
          <button type="button" onClick={() => setOpenAddTaxRelief(true)}>
            Add Tax Relief
          </button>
        </div>
        {taxReliefs?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Relief Name</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Max Amount</th>
                <th>Percent(%)</th>
                <th>Edit</th>
                <th className="tc">Delete</th>
              </tr>
            </thead>
            <tbody>
              {taxReliefs?.map((relief) => (
                <tr class="table__listingItem" key={relief?.id}>
                  <td>{relief?.name}</td>
                  <td>{relief?.relief_type}</td>
                  <td>
                    {relief?.relief_type === "fixed" ? (
                      <span>{formatWithCommas(relief?.relief_amount, 0)}</span>
                    ) : (
                      <span className="red">N/A</span>
                    )}
                  </td>
                  <td>{formatWithCommas(relief?.max_relief_amount, 0)}</td>
                  <td>
                    {relief?.relief_type === "variable" ? (
                      <span>{relief?.relief_percent}</span>
                    ) : (
                      <span className="red">N/A</span>
                    )}
                  </td>
                  <td
                    className="dodgerblue bd button__sp"
                    onClick={() => handleOpenEditTaxRelief(relief)}
                  >
                    Edit
                  </td>

                  <td className="tc">
                    {relief?.is_system_item ? (
                      <span className="red bd">N/A</span>
                    ) : (
                      <i
                        className="bx bxs-trash-alt red button__sp"
                        onClick={(e) => handleDeleteTaxRelief(e, relief?.id)}
                      ></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No tax reliefs available</h4>
        )}

        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="table__parentHeader">
          <h3>Maintain salary deductions</h3>
          <button type="button" onClick={() => setOpenAddSalaryDedution(true)}>
            Add Deduction
          </button>
        </div>
        {salaryDeductions?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Name</th>
                <th>Type</th>
                <th className="tc">Amount</th>
                <th className="tc">Status</th>
                <th className="tc">Edit</th>
                <th className="tc">Delete</th>
              </tr>
            </thead>
            <tbody>
              {salaryDeductions?.map((deduction) => (
                <tr class="table__listingItem" key={deduction?.id}>
                  <td>{deduction?.name}</td>
                  <td>{deduction?.deduction_type}</td>
                  <td className="tc">
                    {deduction?.deduction_type === "fixed" ? (
                      <span>{formatWithCommas(deduction?.amount)}</span>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                  <td className="tc">
                    {deduction?.is_active ? (
                      <span className="green bd">Active</span>
                    ) : (
                      <span className="red bd">Inactive</span>
                    )}
                  </td>
                  <td className="bd tc">
                    {/* system items should not be editable or deletable */}
                    {deduction?.is_system_item ? (
                      <span className="red">N/A</span>
                    ) : (
                      <span
                        className="dodgerblue button__sp"
                        onClick={() => handleOpenEditSalarydeduction(deduction)}
                      >
                        Edit
                      </span>
                    )}
                  </td>
                  <td className="tc">
                    {deduction?.is_system_item ? (
                      <span className="red bd">N/A</span>
                    ) : (
                      <i
                        className="bx bxs-trash-alt red button__sp"
                        onClick={(e) =>
                          handleDeleteSalaryDeduction(e, deduction?.id)
                        }
                      ></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No deductions available</h4>
        )}
      </div>
      {/* child components */}
      {openEditSalaryDeduction && (
        <EditSalaryDeduction
          openEditSalaryDeduction={openEditSalaryDeduction}
          setOpenEditSalaryDeduction={setOpenEditSalaryDeduction}
          currentSalaryDeduction={currentSalaryDeduction}
          setCurrentSalaryDeduction={setCurrentSalaryDeduction}
        />
      )}
      {openAddSalaryDeduction && (
        <AddSalaryDeduction
          openAddSalaryDeduction={openAddSalaryDeduction}
          setOpenAddSalaryDedution={setOpenAddSalaryDedution}
        />
      )}
      {openAddTaxRelief && (
        <AddTaxRelief
          openAddTaxRelief={openAddTaxRelief}
          setOpenAddTaxRelief={setOpenAddTaxRelief}
        />
      )}
      {openEditTaxRelief && (
        <EditTaxRelief
          openEditTaxRelief={openEditTaxRelief}
          setOpenEditTaxRelief={setOpenEditTaxRelief}
          currentTaxRelief={currentTaxRelief}
          setCurrentTaxRelief={setCurrentTaxRelief}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    salaryDeductions: state?.staff?.salaryDeductions,
    taxReliefs: state?.staff?.taxReliefs,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSalaryDeductions: (userId) => dispatch(get_salary_deductions(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
    deleteSalaryDeduction: (userId, salaryDeductionId) =>
      dispatch(delete_salary_deduction(userId, salaryDeductionId)),
    deleteTaxRelief: (userId, taxReliefId) =>
      dispatch(delete_tax_relief(userId, taxReliefId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalaryDeductions);
