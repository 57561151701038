import * as api from "../../api";
import { showError, stopLoading } from "./shared";
import * as actionTypes from "./types";

// action to create a new dorm
export const create_new_dorm =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createNewDorm(userId, body)
      .then((res) => {
        dispatch({ type: actionTypes.NEW_DORM, payload: res?.data?.new_dorm });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to edit dorm
export const edit_dorm = (userId, body) => async (dispatch) => {
  await api
    .editDorm(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_DORM,
        payload: res?.data?.updated_dorm,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to get dorms
export const get_dorms = (userId) => async (dispatch) => {
  await api
    .getDorms(userId)
    .then((res) => {
      dispatch({ type: actionTypes.SET_DORMS, payload: res?.data?.dorms_data });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to get active dorms
export const get_active_dorms = (userId) => async (dispatch) => {
  await api
    .getActiveDorms(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_ACTIVE_DORMS,
        payload: res?.data?.active_dorms_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};
