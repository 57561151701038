import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../components/common/MinDialog";
import { create_new_dorm } from "../../../redux/actions/accomodation";
import { START_LOADING } from "../../../redux/actions/types";
import { ifEmpty, resetFormValues } from "../../../shared/sharedFunctions";

const AddDorm = (props) => {
  const { userId, loading, openAddDorm } = props;
  const { startLoading, createNewDorm, setOpenAddDorm } = props;

  const [dormDetails, setDormDetails] = useState({
    name: "",
    capacity: "",
  });

  const { name, capacity } = dormDetails;

  const handleChange = (e) => {
    setDormDetails({ ...dormDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    resetFormValues(dormDetails);
  };

  const handleClose = () => {
    resetForm();
    setOpenAddDorm(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(dormDetails)) {
      return window.alert("All fields are required");
    }
    startLoading();
    const body = {
      ...dormDetails,
    };
    createNewDorm(userId, body, resetForm);
  };

  return (
    <MinDialog isOpen={openAddDorm}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter dorm details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Dorm Name</label>
          <input type="text" onChange={handleChange} name="name" value={name} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Bed Capacity</label>
          <input
            type="number"
            name="capacity"
            min="1"
            onChange={handleChange}
            value={capacity}
          />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createNewDorm: (userId, body, resetForm) =>
      dispatch(create_new_dorm(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDorm);
