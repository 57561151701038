import React from "react";
import moment from "moment";

const AllStaffReport = ({ checkDate, staffData }) => {
  return (
    <tbody>
      <tr>
        <th className="report__title" colSpan="100%">
          Staff check in and out for {moment(checkDate).format("LL")}
        </th>
      </tr>

      <tr className="table__listingHeader">
        <th>Staff</th>
        <th>Time In</th>
        <th>Time Out</th>
      </tr>
      {staffData?.check_in_data?.map((staff) => (
        <tr className="table__listingItem" key={staff?.id}>
          <td>{staff?.name}</td>
          <td>{moment(staff?.time_in).format("LT")}</td>
          <td>
            {staff?.time_out ? moment(staff?.time_out).format("LT") : "-"}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default AllStaffReport;
