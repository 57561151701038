// import installed packages
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { connect } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";

// import styles
// import material ui items
// import shared/global items
import API from "../../../../shared/axios";
import {
  formatWithCommas,
  ifEmpty,
  maxDate,
  resetFormValues,
} from "../../../../shared/sharedFunctions";
import { showError } from "../../../../redux/actions/shared";
// import components/pages
import MediumDialog from "../../../../components/common/MediumDialog";
import OfficialReceipt from "./OfficialReceipt/OfficialReceipt";
// import redux API

const ReceiveFees = (props) => {
  const { openReceiveFees, bank_cash_ledgers, userId, loading } = props; // get state from props
  const { setOpenReceiveFees, startLoading, stopLoading } = props; // get dispatch action from props

  const [studentDetails, setStudentDetails] = useState({});
  const [validAdmission, setValidAdmission] = useState(false);

  const [feesReceipt, setFeesReceipt] = useState({
    admission_number: "",
    paid_through: "",
    amount: "",
    transaction_date: "",
    memo: "",
  });
  const [studentBalance, setStudentBalance] = useState("");

  const { admission_number, paid_through, amount, transaction_date, memo } =
    feesReceipt;
  const [openOfficialReceipt, setOpenOfficialReceipt] = useState(false);
  const [officialReceiptData, setOfficialReceiptData] = useState({});

  const handleChange = (e) => {
    setFeesReceipt({ ...feesReceipt, [e.target.name]: e.target.value });
  };

  // function to validate student details
  const validateAdmissionNumber = (e) => {
    e.preventDefault();
    if (admission_number.trim() === "") {
      return alert("Invalid admission number");
    }
    startLoading();
    const fetchData = async () => {
      const res = await API.post(
        `/api/user/validate-student-admission-number/${userId}/`,
        { admission_number }
      );
      setStudentDetails(res.data?.student_details);
      setStudentBalance(res?.data?.student_details?.student_balance);
      setValidAdmission(true);
    };
    fetchData()
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const resetForm = () => {
    resetFormValues(feesReceipt);
    setValidAdmission(false);
    setStudentDetails({});
  };
  const handleClose = () => {
    resetForm();
    setOpenReceiveFees(false);
  };

  // function to handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (ifEmpty(feesReceipt)) {
      return window.alert("All fields are required");
    }
    if (transaction_date > maxDate()) {
      return window.alert("Transaction date cannot be in the future");
    }

    startLoading();
    const postData = async () => {
      const res = await API.post(
        `/api/finance/receive-student-fees/${userId}/`,
        { ...feesReceipt }
      );
      setOfficialReceiptData(res?.data?.receipt_data);
      window?.alert(res.data?.detail);
      setOpenOfficialReceipt(true);
      resetForm();
    };
    postData()
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const resetStudentDetails = () => {
    setStudentDetails({});
    setValidAdmission(false);
    setStudentBalance("");
  };

  return (
    <>
      <MediumDialog isOpen={openReceiveFees} maxWidth="900px">
        <form className="dialog" id={loading ? "formSubmitting" : ""}>
          <h3>Enter receipt details</h3>
          <div className="dialog__row">
            <span>
              <label htmlFor="">Student Admission</label>
              <input
                type="text"
                name="admission_number"
                onChange={handleChange}
                value={admission_number}
                disabled={validAdmission ? true : false}
              />
            </span>
            {validAdmission && (
              <span>
                <label htmlFor="">Student Name</label>
                <input
                  type="text"
                  value={studentDetails?.full_names}
                  disabled
                />
              </span>
            )}
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "40%" }}
            />
          )}
          {validAdmission && (
            <>
              <div className="dialog__row">
                <span>
                  <label htmlFor="">Class Stream</label>
                  <input
                    type="text"
                    disabled
                    value={studentDetails?.class_stream}
                  />
                </span>
                <span>
                  <label for="" style={{ color: "red" }}>
                    Outstanding Student Balance
                  </label>
                  <input
                    type="text"
                    value={formatWithCommas(studentBalance)}
                    disabled
                  />
                </span>
              </div>

              <div className="dialog__row">
                <span>
                  <label htmlFor="">Payment mode</label>
                  <select
                    name="paid_through"
                    onChange={handleChange}
                    value={paid_through}
                  >
                    {bank_cash_ledgers?.length > 0 ? (
                      <>
                        <option value="" selected disabled>
                          Select ledger
                        </option>
                        {bank_cash_ledgers?.map((ledger) => (
                          <option value={ledger?.id} key={ledger?.id}>
                            {ledger?.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option value="" disabled selected>
                        First create bank or cash ledgers to receive fees
                      </option>
                    )}
                  </select>
                </span>
                <span>
                  <label htmlFor="">Amount</label>
                  <input
                    type="number"
                    name="amount"
                    min="1"
                    onChange={handleChange}
                    value={amount}
                  />
                </span>
              </div>
              <div className="dialog__row">
                <span>
                  <label htmlFor="">Transaction Date</label>
                  <input
                    type="date"
                    name="transaction_date"
                    onChange={handleChange}
                    value={transaction_date}
                    max={maxDate()}
                  />
                </span>
                <span>
                  <label htmlFor="">Memo</label>
                  <input
                    type="text"
                    name="memo"
                    onChange={handleChange}
                    value={memo}
                  />
                </span>
              </div>
              <div class="dialog__row">
                <span>
                  {validAdmission && (
                    <>
                      <label for="">Reset Student</label>
                      <button
                        type="button"
                        className="add__button"
                        onClick={resetStudentDetails}
                        style={{ maxWidth: "150px" }}
                      >
                        Clear Details
                      </button>
                    </>
                  )}
                </span>
                <span></span>
              </div>
            </>
          )}
          <div className="form__Buttons">
            <button type="button" onClick={handleClose}>
              Close
            </button>
            {validAdmission ? (
              <button type="submit" onClick={handleSubmit}>
                Submit
              </button>
            ) : (
              <button type="submit" onClick={validateAdmissionNumber}>
                Validate
              </button>
            )}
          </div>
        </form>
      </MediumDialog>
      {/* child components */}
      {openOfficialReceipt && (
        <OfficialReceipt
          openOfficialReceipt={openOfficialReceipt}
          setOpenOfficialReceipt={setOpenOfficialReceipt}
          officialReceiptData={officialReceiptData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bank_cash_ledgers: state.finance.ledgers.filter(
      (ledger) => ledger.is_bank_or_cash === true
    ),
    userId: state.auth.user.id,
    loading: state.shared.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReceiveFees);
