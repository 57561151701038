import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { showError } from "../../../../../redux/actions/shared";
import {
  START_LOADING,
  STOP_LOADING,
  UPDATE_SUBJECT,
} from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import AddSubjectGrade from "./AddSubjectGrade";
import EditSubjectGrade from "./EditSubjectGrade";
import CircularProgress from "@mui/material/CircularProgress";
import { formatWithCommas } from "../../../../../shared/sharedFunctions";

const SubjectGrading = (props) => {
  const dispatch = useDispatch();
  const { currentSubject, openSubjectGrading, userId, loading } = props;
  const {
    setOpenSubjectGrading,
    setCurrentSubject,
    startLoading,
    stopLoading,
  } = props;

  const { name, subject_grades } = currentSubject;

  const [openAddSubjectGrade, setOpenAddSubjectGrade] = useState(false);
  const [openEditSubjectGrade, setOpenEditSubjectGrade] = useState(false);
  const [currentSubjectGrade, setCurrentSubjectGrade] = useState({});

  const handleOpenEditSubjectGrade = (subject_grade) => {
    setCurrentSubjectGrade(subject_grade);
    setOpenEditSubjectGrade(true);
  };

  const handleSubjectGradeDelete = async (subjectGradeId) => {
    if (window.confirm(`Are you sure you want to delete this subject grade`)) {
      startLoading();
      await API.delete(
        `/api/course/delete-subject-grade/${userId}/${subjectGradeId}/`
      )
        .then((res) => {
          dispatch({
            type: UPDATE_SUBJECT,
            payload: res?.data?.updated_subject,
          });
          setCurrentSubject(res?.data?.updated_subject);
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <>
      <MediumDialog isOpen={openSubjectGrading}>
        <div class="dialog" id={loading && "formSubmitting"}>
          <h3>{name} grading system</h3>
          {subject_grades?.length > 0 ? (
            <table className="table__listing">
              <thead>
                <tr className="table__listingHeader">
                  <th>Grade</th>
                  <th>Marks</th>
                  <th>Points</th>
                  <th>Edit</th>
                  <th className="tc">Delete</th>
                </tr>
              </thead>
              <tbody>
                {subject_grades?.map((grade) => (
                  <tr key={grade?.id} className="table__listingItem">
                    <td>{grade?.grade}</td>
                    <td>
                      {formatWithCommas(grade?.start_marks)} -
                      {formatWithCommas(grade?.end_marks)}
                    </td>
                    <td>{formatWithCommas(grade?.points)}</td>
                    <td
                      onClick={() => handleOpenEditSubjectGrade(grade)}
                      className="button__sp bd dodgerblue"
                    >
                      Edit
                    </td>
                    <td className="tc">
                      <i
                        className="bx bxs-trash-alt button__sp red"
                        onClick={() => handleSubjectGradeDelete(grade?.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No grades set yet</h4>
          )}
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div class="form__Buttons">
            <button type="button" onClick={() => setOpenSubjectGrading(false)}>
              Close
            </button>
            <button type="button" onClick={() => setOpenAddSubjectGrade(true)}>
              Add Grade
            </button>
          </div>
        </div>
      </MediumDialog>
      {/* child components */}
      {openAddSubjectGrade && (
        <AddSubjectGrade
          openAddSubjectGrade={openAddSubjectGrade}
          setOpenAddSubjectGrade={setOpenAddSubjectGrade}
          currentSubject={currentSubject}
          setCurrentSubject={setCurrentSubject}
        />
      )}
      {openEditSubjectGrade && (
        <EditSubjectGrade
          openEditSubjectGrade={openEditSubjectGrade}
          setOpenEditSubjectGrade={setOpenEditSubjectGrade}
          currentSubject={currentSubject}
          setCurrentSubject={setCurrentSubject}
          currentSubjectGrade={currentSubjectGrade}
          setCurrentSubjectGrade={setCurrentSubjectGrade}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubjectGrading);
