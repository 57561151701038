import React, { useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import MinDialog from "../../../components/common/MinDialog";
import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";
import { ifEmpty, maxDate } from "../../../shared/sharedFunctions";
import StudentAttendanceReport from "./StudentAttendanceReport";
import API from "../../../shared/axios";
import { showError } from "../../../redux/actions/shared";

const StudentAttendance = (props) => {
  const { openStudentAttendance, userId, loading, parentsChildren } = props;
  const { setOpenStudentAttendance, startLoading, stopLoading } = props;

  const [attendanceFilters, setAttendanceFilters] = useState({
    admission_number: "",
    start_date: "",
    end_date: "",
  });
  const { admission_number, start_date, end_date } = attendanceFilters;

  const [openAttendanceReport, setOpenAttendanceReport] = useState(false);
  const [attendanceData, setAttendanceData] = useState({});

  const handleChange = (e) => {
    setAttendanceFilters({
      ...attendanceFilters,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setOpenStudentAttendance(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (start_date > end_date) {
      return window.alert("Start date must be less than end date");
    }
    if (ifEmpty(attendanceFilters)) {
      return window.alert("Fill in all fields");
    }
    startLoading();
    await API.post(
      `/api/course/parent/parent-get-student-attendance/${userId}/`,
      {
        ...attendanceFilters,
      }
    )
      .then((res) => {
        setAttendanceData(res?.data?.attendance_data);
        setOpenAttendanceReport(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog isOpen={openStudentAttendance}>
        <form
          className="dialog"
          onSubmit={handleSubmit}
          id={loading ? "formSubmitting" : ""}
        >
          <h3>Select student and dates</h3>
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Select student</label>
            <select
              name="admission_number"
              onChange={handleChange}
              value={admission_number}
            >
              <option value="" disabled selected>
                --select--
              </option>
              {parentsChildren?.map((child) => (
                <option value={child?.admission_number} key={child?.id}>
                  {child?.name}
                </option>
              ))}
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "40%" }}
            />
          )}
          <div class="dialog__rowSingleItem">
            <label for="">Start Date</label>
            <input
              type="date"
              name="start_date"
              onChange={handleChange}
              value={start_date}
              max={maxDate()}
            />
          </div>
          <div class="dialog__rowSingleItem">
            <label for="">End Date</label>
            <input
              type="date"
              name="end_date"
              onChange={handleChange}
              value={end_date}
              max={maxDate()}
            />
          </div>
          <div class="form__Buttons">
            <button type="button" onClick={handleClose}>
              Close
            </button>
            <button type="submit">View</button>
          </div>
        </form>
      </MinDialog>
      {/* child components */}
      {openAttendanceReport && (
        <StudentAttendanceReport
          openAttendanceReport={openAttendanceReport}
          setOpenAttendanceReport={setOpenAttendanceReport}
          attendanceData={attendanceData}
          start_date={start_date}
          end_date={end_date}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    parentsChildren: state?.auth?.parentsChildren,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentAttendance);
