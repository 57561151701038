// import installed packages
import { useState } from "react";
import { connect } from "react-redux";

// import styles

// import material ui items
// import shared/global items
import { ifEmpty, maxDate } from "../../../../../shared/sharedFunctions";
import API from "../../../../../shared/axios";

// import components/pages
// import redux API

import MinDialog from "../../../../../components/common/MinDialog";

import {
  START_LOADING,
  STOP_LOADING,
} from "../../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import StatementTemplate from "./components/StatementTemplate";
import { showError } from "../../../../../redux/actions/shared";

const StudentStatement = (props) => {
  const { openStudentStatement, userId, loading } = props; // get state from props
  const { setOpenStudentStatement, startLoading, stopLoading } = props; // get dispatch from props
  const [openStatementTemplate, setOpenStatementTemplate] = useState(false);
  const [statementFilters, setStatementFilters] = useState({
    admission_number: "",
    start_date: "",
    end_date: "",
  });
  const [statementDetails, setStatementDetails] = useState({});

  const { admission_number, start_date, end_date } = statementFilters;

  const handleChange = (e) => {
    setStatementFilters({
      ...statementFilters,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setOpenStudentStatement(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(statementFilters)) {
      return alert("All fields are required");
    }
    if (start_date > end_date) {
      return alert("Start date must be less than end date");
    }
    startLoading();
    const fetchData = async () => {
      const res = await API.post(
        `/api/finance/get-student-fees-statement/${userId}/`,
        { ...statementFilters }
      );
      setStatementDetails(res.data?.statement_data);
      setOpenStatementTemplate(true);
    };
    fetchData()
      .catch((err) => showError(err))
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <>
      <MinDialog isOpen={openStudentStatement}>
        <form
          className="dialog"
          id={loading ? "formSubmitting" : ""}
          onSubmit={handleSubmit}
        >
          <h3>Generate student statement</h3>
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Admission Number</label>
            <input
              type="text"
              name="admission_number"
              onChange={handleChange}
              value={admission_number}
              required
            />
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "40%" }}
            />
          )}
          <div className="dialog__row">
            <span>
              <label htmlFor="">Start Date</label>
              <input
                type="date"
                name="start_date"
                onChange={handleChange}
                value={start_date}
                max={maxDate()}
                required
              />
            </span>
            <span>
              <label htmlFor="">End Date</label>
              <input
                type="date"
                name="end_date"
                onChange={handleChange}
                value={end_date}
                max={maxDate()}
                required
              />
            </span>
          </div>

          <div className="form__Buttons">
            <button type="button" onClick={handleClose}>
              Close
            </button>
            <button type="submit">Generate</button>
          </div>
        </form>
      </MinDialog>
      {openStatementTemplate && (
        <StatementTemplate
          openStatementTemplate={openStatementTemplate}
          setOpenStatementTemplate={setOpenStatementTemplate}
          statementDetails={statementDetails}
          start_date={start_date}
          end_date={end_date}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user.id,
    loading: state.shared.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentStatement);
