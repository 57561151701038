import * as api from "../../api";
import * as actionTypes from "./types";
import { showError, stopLoading } from "./shared";

export const admin_get_staff_parent_phone_numbers =
  (userId) => async (dispatch) => {
    await api
      .adminGetStaffParentPhoneNumbers(userId)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_PHONE_NUMBERS,
          payload: res?.data?.phone_numbers,
        });
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };
