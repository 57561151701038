// import installed packages
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const TeacherLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link to="/teacher/teacher-subjects/" className="nav__link">
        <i className="bx bxs-book-content"></i>
        <span className="nav__name">Subjects</span>
      </Link>
      <Link to="#" className="nav__link" onClick={() => toggleSubmenu("Exams")}>
        <i className="bx bxs-file"></i>
        <span className="nav__name">Exams...</span>
      </Link>
      {openSubMenu === "Exams" && (
        <>
          <Link
            to="/teacher/assignments/"
            className={
              `${pathname}` === "/teacher/assignments/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Assignments</span>
          </Link>
          <Link
            to="/teacher/offline-exams/"
            className={
              `${pathname}` === "/teacher/offline-exams/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Offline Exams</span>
          </Link>
          <Link
            to="/teacher/online-exams/"
            className={
              `${pathname}` === "/teacher/online-exams/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Online Exams</span>
          </Link>
          <Link
            to="/teacher/upgrade-students/"
            className={
              `${pathname}` === "/teacher/upgrade-students/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Upgrade Students</span>
          </Link>
        </>
      )}
      <Link
        to="/teacher/attendance/"
        className={
          `${pathname}` === "/teacher/attendance/"
            ? "nav__link active"
            : "nav__link"
        }
      >
        <i className="bx bx-clipboard"></i>
        <span className="nav__name">Attendance</span>
      </Link>
      <Link
        to="/teacher/teacher-live-classes/"
        className={
          `${pathname}` === "/teacher/teacher-live-classes/"
            ? "nav__link active"
            : "nav__link"
        }
      >
        <i className="bx bxs-video"></i>
        <span className="nav__name">Live Classes</span>
      </Link>
    </>
  );
};

export default TeacherLinks;
