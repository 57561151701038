import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { charge_lost_book } from "../../../../redux/actions/library";
import { START_LOADING } from "../../../../redux/actions/types";

const LostBookChargeUser = (props) => {
  const { openChargeUser, currentLostBook, userId, loading } = props;
  const { setOpenChargeUser, chargeLostBook, startLoading } = props;

  const { book_name, user, book_cost } = currentLostBook;

  const [chargeAmount, setChargeAmount] = useState("");

  const resetForm = () => {
    setChargeAmount("");
    setOpenChargeUser(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (chargeAmount === "") {
      return window.alert("Please indicate the charge amount");
    }
    startLoading();
    const body = {
      ...currentLostBook,
      chargeAmount,
    };
    chargeLostBook(userId, body, resetForm);
  };

  return (
    <MinDialog isOpen={openChargeUser}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Charge user for lost book</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Book Name</label>
          <input type="text" value={book_name} disabled />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">User</label>
          <input type="text" name="" value={user} disabled />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Book Cost</label>
          <input type="text" name="" value={book_cost} disabled />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Charge Amount</label>
          <input
            type="number"
            name=""
            onChange={(e) => setChargeAmount(e.target.value)}
            value={chargeAmount}
            min="1"
          />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenChargeUser(false)}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    chargeLostBook: (userId, body, resetForm) =>
      dispatch(charge_lost_book(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LostBookChargeUser);
