// import installed packages

// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const SchoolAdminLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to="#"
        className="nav__link"
        onClick={() => toggleSubmenu("Academic")}
      >
        <i className="bx bxs-school"></i>
        <span className="nav__name">Academic ...</span>
      </Link>
      {/* academic sub menus */}
      {openSubMenu === "Academic" && (
        <>
          <Link
            to="/school-admin/class-streams/"
            className={
              `${pathname}` === "/school-admin/class-streams/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Class Streams</span>
          </Link>
          <Link
            to="/school-admin/offline-exams/"
            className={
              `${pathname}` === "/school-admin/offline-exams/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Exams - Offline</span>
          </Link>
          <Link
            to="/school-admin/school-terms/"
            className={
              `${pathname}` === "/school-admin/school-terms/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; School Terms</span>
          </Link>
          <Link
            to="/school-admin/subjects/"
            className={
              `${pathname}` === "/school-admin/subjects/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Subjects</span>
          </Link>
          <Link
            to="/school-admin/timetables/"
            className={
              `${pathname}` === "/school-admin/timetables/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; TimeTables</span>
          </Link>
        </>
      )}
      <Link to="#" className="nav__link" onClick={() => toggleSubmenu("Users")}>
        <i className="bx bxs-group"></i>
        <span className="nav__name">Users ...</span>
      </Link>
      {/* USERS SUB MENUS */}
      {openSubMenu === "Users" && (
        <>
          <Link
            to="/school-admin/teachers/"
            className={
              `${pathname}` === "/school-admin/teachers/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Teachers</span>
          </Link>
          <Link
            to="/school-admin/students/"
            className={
              `${pathname}` === "/school-admin/students/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Students</span>
          </Link>
          <Link
            to="/school-admin/parents/"
            className={
              `${pathname}` === "/school-admin/parents/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Parents</span>
          </Link>
          <Link
            to="/school-admin/other-staff/"
            className={
              `${pathname}` === "/school-admin/other-staff/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Other Staff</span>
          </Link>
          <Link
            to="/school-admin/deactivated-users/"
            className={
              `${pathname}` === "/school-admin/deactivated-users/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Deactivated Users</span>
          </Link>
        </>
      )}
    </>
  );
};

export default SchoolAdminLinks;
