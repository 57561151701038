import React, { useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { remove_class_stream_subject_teacher } from "../../../../../redux/actions/course";
import { START_LOADING } from "../../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import AllotSubjects from "./AllotSubjects";

const SubjectsAlloted = (props) => {
  const {
    alloted_subjects,
    openViewAllotedSubjects,
    currentTeacher,
    userId,
    loading,
  } = props;
  const {
    setOpenViewAllotedSubjects,
    removeClassStreamSubjectTeacher,
    startLoading,
  } = props;

  const [openAllotSubjects, setOpenAllotSubjects] = useState(false);

  const handleRemoveClassStreamSubjectTeacher = (e, classStreamSubjectId) => {
    e.preventDefault();
    if (
      window.confirm(
        "Are you sure you want to remove this teacher from this subject?"
      )
    ) {
      startLoading();
      removeClassStreamSubjectTeacher(userId, classStreamSubjectId);
    }
  };

  return (
    <>
      <MediumDialog isOpen={openViewAllotedSubjects} maxWidth="1000px">
        <div className="dialog" id={loading ? "formSubmitting" : ""}>
          <h3>
            Alloted subjects for {currentTeacher?.first_name}{" "}
            {currentTeacher?.last_name}
          </h3>
          {alloted_subjects?.length > 0 ? (
            <>
              <table className="table__listing">
                <tr className="table__listingHeader">
                  <th>Subject</th>
                  <th>Class stream</th>
                  <th className="tc">Remove</th>
                </tr>
                {loading && (
                  <CircularProgress
                    style={{ position: "absolute", marginLeft: "45%" }}
                  />
                )}
                {alloted_subjects?.map((subject) => (
                  <tr className="table__listingItem" key={subject?.id}>
                    <td>{subject?.subject_name}</td>
                    <td>{subject?.stream_name}</td>
                    <td className="tc">
                      <i
                        className="bx bxs-trash-alt red button__sp"
                        onClick={(e) =>
                          handleRemoveClassStreamSubjectTeacher(e, subject?.id)
                        }
                      ></i>
                    </td>
                  </tr>
                ))}
              </table>
            </>
          ) : (
            <h4 className="not__available">No subjects alloted yet</h4>
          )}
          <div className="form__Buttons">
            <button onClick={() => setOpenViewAllotedSubjects(false)}>
              Close
            </button>
            <button type="button" onClick={() => setOpenAllotSubjects(true)}>
              Allot Subject
            </button>
          </div>
        </div>
      </MediumDialog>
      {/* child components */}
      {openAllotSubjects && (
        <AllotSubjects
          openAllotSubjects={openAllotSubjects}
          setOpenAllotSubjects={setOpenAllotSubjects}
          currentTeacherId={currentTeacher?.id}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeClassStreamSubjectTeacher: (userId, classStreamSubjectId) =>
      dispatch(
        remove_class_stream_subject_teacher(userId, classStreamSubjectId)
      ),
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubjectsAlloted);
