import React, { useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../components/common/MediumDialog";
import { post_stock_invoice } from "../../../../redux/actions/finance";
import { START_LOADING } from "../../../../redux/actions/types";
import { ifEmpty, maxDate } from "../../../../shared/sharedFunctions";

const PostStockInvoice = (props) => {
  const {
    openPostStockInvoice,
    loading,
    supplier_ledgers,
    stock_ledgers,
    userId,
    other_ledgers,
  } = props;
  const { setOpenPostStockInvoice, startLoading, postStockInvoice } = props;

  const [currentStockItem, setCurrentStockItem] = useState({
    item_name: "",
    item_cost: "",
  });
  const [currentLedgerItem, setCurrentLedgerItem] = useState({
    name: "",
    amount: "",
  });

  const [invoiceDetails, setInvoiceDetails] = useState({
    supplier: "",
    transaction_date: "",
  });

  // we initialize the total amount for this invoice
  const [totalAmount, setTotalAmount] = useState(0);

  const [stockItems, setStockItems] = useState([]);
  const [ledgerItems, setLedgerItems] = useState([]);

  const handleChange = (e) => {
    setInvoiceDetails({ ...invoiceDetails, [e.target.name]: e.target.value });
  };

  const handleLedgerItemChange = (e) => {
    setCurrentLedgerItem({
      ...currentLedgerItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleStockItemChange = (e) => {
    setCurrentStockItem({
      ...currentStockItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddStockItem = () => {
    if (ifEmpty(currentStockItem)) {
      return window.alert("Please select item and indicate the item cost");
    }
    if (
      stockItems?.find(
        (item) => item?.item_name === currentStockItem?.item_name
      )
    ) {
      return window.alert("Item already added to list");
    }
    setStockItems([...stockItems, currentStockItem]);

    // we increment the total amount
    setTotalAmount(Number(totalAmount) + Number(currentStockItem?.item_cost));
    setCurrentStockItem({
      item_name: "",
      item_cost: "",
    });
  };
  const handleRemoveStockItem = (stock_item) => {
    setStockItems(
      stockItems?.filter((item) => item?.item_name !== stock_item?.item_name)
    );

    // we reduce the total amount
    setTotalAmount(Number(totalAmount) - Number(stock_item?.item_cost));
  };

  const handleAddLedgerItem = () => {
    if (ifEmpty(currentLedgerItem)) {
      return window.alert("Please select ledger item and indicate the amount");
    }
    if (ledgerItems?.find((item) => item?.name === currentLedgerItem?.name)) {
      return window.alert("Ledger already added to list");
    }
    setLedgerItems([...ledgerItems, currentLedgerItem]);

    // we increment the total invoice amount
    setTotalAmount(Number(totalAmount) + Number(currentLedgerItem?.amount));
    setCurrentLedgerItem({
      name: "",
      amount: "",
    });
  };
  const handleRemoveLedgerItem = (ledger) => {
    setLedgerItems(ledgerItems?.filter((item) => item?.name !== ledger.name));

    // we reduce the total amount
    setTotalAmount(Number(totalAmount) - Number(ledger?.amount));
  };
  const resetForm = () => {
    setCurrentStockItem({
      item_name: "",
      item_cost: "",
    });
    setCurrentLedgerItem({
      name: "",
      amount: "",
    });
    setInvoiceDetails({
      supplier: "",
      transaction_date: "",
    });
    setStockItems([]);
    setLedgerItems([]);
    setTotalAmount(0);
  };

  const handleClose = () => {
    resetForm();
    setOpenPostStockInvoice(false);
  };

  const handleSubmit = (e) => {
    if (ifEmpty(invoiceDetails)) {
      return window.alert("Indicate the supplier and transaction date");
    }
    if (stockItems?.length < 0) {
      return window.alert("You must select the stock items to be updated");
    }
    startLoading();
    postStockInvoice(
      userId,
      { ...invoiceDetails, stockItems, ledgerItems, totalAmount },
      resetForm
    );
  };

  return (
    <MediumDialog isOpen={openPostStockInvoice}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Stock invoice details</h3>
        <div class="dialog__row">
          <span>
            <label for="">Supplier</label>
            <select
              name="supplier"
              onChange={handleChange}
              value={invoiceDetails?.supplier}
            >
              <option value="">--select supplier--</option>
              {supplier_ledgers?.length > 0 ? (
                <>
                  {supplier_ledgers?.map((ledger) => (
                    <option value={ledger?.id} key={ledger?.id}>
                      {ledger?.name}
                    </option>
                  ))}
                </>
              ) : (
                <option value="" disabled>
                  No suppliers available
                </option>
              )}
            </select>
          </span>
          <span>
            <label for="">Transaction Date</label>
            <input
              type="date"
              name="transaction_date"
              onChange={handleChange}
              value={invoiceDetails?.transaction_date}
              max={maxDate()}
            />
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Select stock item</label>
            <select
              name="item_name"
              onChange={handleStockItemChange}
              value={currentStockItem?.item_name}
            >
              <option value="">--select stock item--</option>
              {stock_ledgers?.length > 0 ? (
                <>
                  {stock_ledgers?.map((item) => (
                    <option value={item?.name} key={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </>
              ) : (
                <option value="" disabled>
                  No stock items available
                </option>
              )}
            </select>
          </span>
          <span>
            <label for="">Stock Item Total Cost</label>
            <input
              type="number"
              name="item_cost"
              value={currentStockItem?.item_cost}
              onChange={handleStockItemChange}
              min="1"
            />
          </span>
        </div>
        <div class="dialog__row">
          <span style={{ maxWidth: "250px" }}>
            <button
              type="button"
              className="add__button"
              onClick={handleAddStockItem}
            >
              Add item to stock items list below
            </button>
          </span>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Stock items added to list</label>
          {stockItems?.length > 0 ? (
            <table class="table__listing" border="1" rules="rows">
              <thead>
                <tr className="table__listingHeader">
                  <th>Item Name</th>
                  <th>Cost</th>
                  <th className="tc">Remove</th>
                </tr>
              </thead>
              <tbody>
                {stockItems?.map((item) => (
                  <tr class="table__listingItem" key={item?.item_name}>
                    <td>{item?.item_name}</td>
                    <td>{item?.item_cost}</td>
                    <td className="tc">
                      <i
                        className="bx bxs-trash-alt red button__sp"
                        onClick={() => handleRemoveStockItem(item)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="red">No stock items added to list yet</h4>
          )}
        </div>
        <hr />
        <div class="dialog__rowSingleItem">
          <label for="">Optional - Other ledgers affected e.g VAT</label>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Select ledger</label>
            <select
              name="name"
              onChange={handleLedgerItemChange}
              value={currentLedgerItem?.name}
            >
              <option value="">--select ledger--</option>
              {other_ledgers?.map((ledger) => (
                <option value={ledger?.name} key={ledger?.id}>
                  {ledger?.name}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label for="">Amount</label>
            <input
              type="number"
              name="amount"
              onChange={handleLedgerItemChange}
              value={currentLedgerItem?.amount}
              min="1"
            />
          </span>
        </div>
        <div class="dialog__row">
          <span style={{ maxWidth: "250px" }}>
            <button
              type="button"
              className="add__button"
              onClick={handleAddLedgerItem}
            >
              Add ledger to list below
            </button>
          </span>
        </div>
        {/* other ledger items */}
        <div class="dialog__rowSingleItem">
          <label for="">Ledger items added to ledger list</label>
          {ledgerItems?.length > 0 ? (
            <table class="table__listing" border="1" rules="rows">
              <thead>
                <tr className="table__listingHeader">
                  <th>Name</th>
                  <th>Amount</th>
                  <th className="tc">Remove</th>
                </tr>
              </thead>
              <tbody>
                {ledgerItems?.map((ledger) => (
                  <tr class="table__listingItem" key={ledger?.name}>
                    <td>{ledger?.name}</td>
                    <td>{ledger?.amount}</td>
                    <td className="tc">
                      <i
                        className="bx bxs-trash-alt red button__sp"
                        onClick={() => handleRemoveLedgerItem(ledger)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="red">No ledger items added to list yet</h4>
          )}
        </div>
        <hr />
        <div class="dialog__rowSingleItem">
          <label for="">Total Invoice Amount</label>
          <input type="number" value={totalAmount} disabled />
        </div>

        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    supplier_ledgers: state?.finance?.ledgers?.filter(
      (ledger) => ledger?.is_stock_supplier
    ),
    other_ledgers: state?.finance?.ledgers?.filter(
      (ledger) =>
        ledger?.is_stock_supplier === false &&
        ledger?.is_stock_item === false &&
        ledger?.is_system_ledger === false
    ),
    stock_ledgers: state?.finance?.ledgers?.filter(
      (ledger) => ledger.is_stock_item === true
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    postStockInvoice: (userId, body, resetForm) =>
      dispatch(post_stock_invoice(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostStockInvoice);
