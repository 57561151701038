import React, { useEffect } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { admin_update_user } from "../../../../../redux/actions/auth";
import { START_LOADING } from "../../../../../redux/actions/types";
import { maxDate } from "../../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import { admin_get_class_streams } from "../../../../../redux/actions/course";

const EditStudent = (props) => {
  const {
    openEditStudent,
    currentStudent,
    loading,
    userId,
    class_streams,
    active_dorms,
  } = props;
  const {
    setOpenEditStudent,
    startLoading,
    adminUpdateUser,
    setCurrentStudent,
    adminGetClassStreams,
  } = props;

  const {
    first_name,
    last_name,
    surname,
    date_of_birth,
    admission_number,
    guardian_phone,
    class_stream,
    gender,
    is_suspended,
    is_deactivated,
    dorm,
  } = currentStudent;

  useEffect(() => {
    if (class_streams?.length === 0 && userId) {
      startLoading();
      adminGetClassStreams(userId);
    }
  }, [class_streams?.length, adminGetClassStreams, userId, startLoading]);

  const handleChange = (e) => {
    setCurrentStudent({ ...currentStudent, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setCurrentStudent({
      ...currentStudent,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    startLoading();
    const body = {
      ...currentStudent,
      userType: "Student",
    };
    adminUpdateUser(userId, body);
  };

  return (
    <MediumDialog isOpen={openEditStudent}>
      <form
        className="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Enter student details</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={first_name}
            />
          </span>
          <span>
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={last_name}
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Surname</label>
            <input
              type="text"
              name="surname"
              onChange={handleChange}
              value={surname}
            />
          </span>
          <span>
            <label htmlFor="">Gender</label>
            <select name="gender" onChange={handleChange} value={gender}>
              <option value="" selected disabled>
                Select gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <span>
            <label htmlFor="">Date of Birth</label>
            <input
              type="date"
              name="date_of_birth"
              onChange={handleChange}
              value={date_of_birth}
              max={maxDate()}
            />
          </span>
          <span>
            <label htmlFor="">Admission Number</label>
            <input type="text" value={admission_number} disabled />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Parent/Guardian Phone</label>
            <input
              type="text"
              name="guardian_phone"
              onChange={handleChange}
              value={guardian_phone}
            />
          </span>
          <span>
            <label htmlFor="">Class Stream</label>
            <select
              name="class_stream"
              onChange={handleChange}
              value={class_stream}
            >
              <option value="">Select class stream</option>
              {class_streams?.map((class_stream) => (
                <option value={class_stream?.name} key={class_stream?.id}>
                  {class_stream?.name}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Dorm (optional)</label>
            <select name="dorm" onChange={handleChange} value={dorm}>
              <option value="">--no dorm selected--</option>
              {active_dorms?.length > 0 ? (
                <>
                  {active_dorms?.map((dorm) => (
                    <option value={dorm?.name} key={dorm}>
                      {dorm?.name}
                    </option>
                  ))}
                </>
              ) : (
                <option value="" disabled>
                  No active dorms available
                </option>
              )}
            </select>
          </span>
          <span></span>
        </div>
        <div class="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_suspended"
            checked={is_suspended}
            onChange={handleCheckboxChange}
          />
          <label for="" style={{ color: "red" }}>
            Tick this to disable the student account login/access
          </label>
        </div>
        <div class="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_deactivated"
            checked={is_deactivated}
            onChange={handleCheckboxChange}
          />
          <label for="" style={{ color: "red" }}>
            Tick this to deactivate the student account PERMANENTLY
          </label>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenEditStudent(false)}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    class_streams: state.course?.class_streams,
    active_dorms: state?.accomodation?.active_dorms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    adminUpdateUser: (userId, body) =>
      dispatch(admin_update_user(userId, body)),
    adminGetClassStreams: (userId) => dispatch(admin_get_class_streams(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStudent);
