import { updateObject } from "../utility";
import * as actionTypes from "../actions/types";

const initialState = {
  parents: [],
  staff: [],
  class_levels: [],
  class_streams: [],
};

const set_phone_numbers = (state, payload) => {
  return updateObject(state, {
    parents: payload?.parents,
    staff: payload?.staff,
    class_levels: payload?.class_levels,
    class_streams: payload?.class_streams,
  });
};

const communicationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_PHONE_NUMBERS:
      return set_phone_numbers(state, payload);
    default:
      return state;
  }
};

export default communicationReducer;
