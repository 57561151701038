// import installed packages
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const LibrarianLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to="#"
        onClick={() => toggleSubmenu("Library")}
        className={`${pathname}` === "" ? "nav__link active" : "nav__link"}
      >
        <i className="bx bx-library"></i>
        <span className="nav__name">Library</span>
      </Link>
      {openSubMenu === "Library" && (
        <>
          <Link
            to="/library/books/"
            className={
              `${pathname}` === "/library/books/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Books</span>
          </Link>
          <Link
            to="/library/book-borrows/"
            className={
              `${pathname}` === "/library/book-borrows/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Book Borrows</span>
          </Link>
          <Link
            to="/library/books-lost/"
            className={
              `${pathname}` === "/library/books-lost/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Lost Books</span>
          </Link>
          {/* <Link
            to="/library/lost-book-charges/"
            className={
              `${pathname}` === "/library/lost-book-charges/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Lost Book Charges</span>
          </Link> */}
        </>
      )}
    </>
  );
};

export default LibrarianLinks;
