import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  stock_items: [],
};

const createStockItem = (state, payload) => {
  return updateObject(state, {
    stock_items: [...state.stock_items, payload],
  });
};

const editStockItem = (state, payload) => {
  return updateObject(state, {
    stock_items: state?.stock_items?.map((item) =>
      item?.id === payload?.id ? payload : item
    ),
  });
};

const setStockItems = (state, payload) => {
  return updateObject(state, {
    stock_items: payload,
  });
};

const kitchenReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.NEW_STOCK_ITEM:
      return createStockItem(state, payload);
    case actionTypes.EDIT_STOCK_ITEM:
      return editStockItem(state, payload);
    case actionTypes.SET_STOCK_ITEMS:
      return setStockItems(state, payload);
    default:
      return state;
  }
};

export default kitchenReducer;
