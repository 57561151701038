import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { edit_salary_deduction } from "../../../../redux/actions/staff";
import { START_LOADING } from "../../../../redux/actions/types";

import CircularProgress from "@mui/material/CircularProgress";

const EditSalaryDeduction = (props) => {
  const { openEditSalaryDeduction, currentSalaryDeduction, loading, userId } =
    props;
  const {
    setOpenEditSalaryDeduction,
    setCurrentSalaryDeduction,
    editSalaryDeduction,
    startLoading,
  } = props;

  const {
    name,
    deduction_type,
    amount,
    is_asset,
    is_active,
    tax_allowable_status,
    max_deductable,
    deductable_percent,
  } = currentSalaryDeduction;

  const handleChange = (e) => {
    setCurrentSalaryDeduction({
      ...currentSalaryDeduction,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setCurrentSalaryDeduction({
      ...currentSalaryDeduction,
      [e.target.name]: e.target.checked,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      deduction_type?.trim() === "" ||
      deduction_type === null ||
      name?.trim() === "" ||
      tax_allowable_status.trim() === "" ||
      tax_allowable_status === null
    ) {
      return window.alert("Fields marked with * are required");
    }
    if (deduction_type === "fixed" && (amount === null || amount === "")) {
      return window.alert("Fixed deduction must have an amount");
    }
    // for tax deductible items, max_deductable and deductable percent must be specified
    if (tax_allowable_status === "tax_deductible") {
      // confirm values exist
      if (
        max_deductable === null ||
        max_deductable === "" ||
        deductable_percent === null ||
        deductable_percent === ""
      ) {
        return window.alert("Fields marked with * are required");
      }
      // ensure deductable_percent is between 0.1 and 100
      if (deductable_percent < 0.1 || deductable_percent > 100) {
        return window.alert("Amount deductible must be between 0.1% and 100%");
      }
    }
    startLoading();
    editSalaryDeduction(userId, currentSalaryDeduction);
  };

  return (
    <MinDialog isOpen={openEditSalaryDeduction}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit deduction details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Name</label>
          <input type="text" name="name" value={name} onChange={handleChange} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Deduction Type</label>
          <select
            name="deduction_type"
            onChange={handleChange}
            value={deduction_type}
          >
            <option value="">--select type--</option>
            <option value="fixed">Fixed</option>
            <option value="variable">Variable (Set at staff level)</option>
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Amount</label>
          <input
            type="number"
            name="amount"
            onChange={handleChange}
            value={amount}
            disabled={deduction_type !== "fixed"}
          />
        </div>
        <div className="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_asset"
            onChange={handleCheckboxChange}
            checked={is_asset}
          />
          <label for="">Tick this if asset item (e.g salary advance)</label>
        </div>
        <div class="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_active"
            checked={is_active}
            onChange={handleCheckboxChange}
          />
          <label for="">Tick this to make the earning type active</label>
        </div>
        <br />
        <div class="dialog__rowSingleItem">
          <label for="" style={{ color: "red", textDecoration: "underline" }}>
            For tax computation purposes
          </label>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Tax Deductible Status*</label>
          <select
            name="tax_allowable_status"
            onChange={handleChange}
            value={tax_allowable_status}
          >
            <option value="">--select status--</option>
            <option value="not_deductible">Not Deductible</option>
            <option value="tax_deductible">Tax Deductible</option>
          </select>
        </div>
        {/* for tax deductible we show field for amount deductible and maximum deductible */}
        {tax_allowable_status === "tax_deductible" && (
          <>
            <div class="dialog__rowSingleItem">
              <label for="">Amount Deductable* (0.1-100%)</label>
              <input
                type="number"
                name="deductable_percent"
                onChange={handleChange}
                value={deductable_percent}
                max="100"
              />
            </div>
            <div class="dialog__rowSingleItem">
              <label for="">Maximum Deductable* (number)</label>
              <input
                type="number"
                name="max_deductable"
                onChange={handleChange}
                value={max_deductable}
                min="1"
              />
            </div>
          </>
        )}

        <div class="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenEditSalaryDeduction(false)}
          >
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editSalaryDeduction: (userId, body) =>
      dispatch(edit_salary_deduction(userId, body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSalaryDeduction);
