import React, { useEffect, useState } from "react";
import MaxDialog from "../../../components/common/MaxDialog";

import { connect } from "react-redux";
import API from "../../../shared/axios";
import { showError } from "../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";
import moment from "moment";

const ExamResultsReport = (props) => {
  const { openExamResultsReport, userId } = props;
  const { setOpenExamResultsReport, startLoading, stopLoading } = props;
  const [examResults, setExamResults] = useState([]);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchExamResults = async () => {
        await API.get(
          `/api/exam/parent/get-children-exam-reports/${userId}/`
        ).then((res) => {
          setExamResults(res?.data?.exam_results_data);
        });
      };
      fetchExamResults()
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  }, [startLoading, stopLoading, userId]);
  return (
    <MaxDialog
      isOpen={openExamResultsReport}
      maxWidth="1200px"
      maxHeight="85vh"
    >
      <div className="dialog">
        <h3>Access your children's exam results</h3>
        {examResults?.length > 0 ? (
          <table className="table__listing">
            <tr className="table__listingHeader">
              <th>Student</th>
              <th>Class Stream</th>
              <th>Exam</th>
              <th>Subject</th>
              <th>Marks Scored (%)</th>
              <th>Status</th>
              <th>Date Completed</th>
            </tr>
            {examResults?.map((exam) => (
              <tr className="table__listingItem" key={exam?.id}>
                <td>{exam?.student}</td>
                <td>{exam?.class_stream}</td>
                <td>{exam?.exam_title}</td>
                <td>{exam?.subject}</td>
                <td>{exam?.marks_scored}</td>
                <td>{exam?.status}</td>
                <td>{moment(exam?.date_done).format("LLL")}</td>
              </tr>
            ))}
          </table>
        ) : (
          <h4 className="not__available">No exam results available</h4>
        )}
        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenExamResultsReport(false)}>
            Close
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamResultsReport);
