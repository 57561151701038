import { useEffect, useState } from "react";
import { connect } from "react-redux";

import "./Home.scss";

import { OPEN_LOGIN } from "../../redux/actions/types";

import student from "../../assets/images/student.jpg";
import parent from "../../assets/images/parent.jpg";
import teacher from "../../assets/images/teacher.jpg";
import finance from "../../assets/images/finance.png";
import human_resources from "../../assets/images/human_resources.png";
import school_system from "../../assets/images/school_system.jpg";
import AccessDemo from "./components/AccessDemo";

const Home = (props) => {
  const { loggedIn } = props;
  const { openLogin } = props;

  const [openAccessDemo, setOpenAccessDemo] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="home">
        <div className="banner">
          <div className="banner__left">
            <h2>School management with a click.</h2>
            <p>
              {" "}
              A system to serve school management, teachers, students, parents
              and school staff.
            </p>
            {/* {!loggedIn && (
              <button type="button" onClick={openLogin}>
                Login Now
              </button>
            )} */}
            {!loggedIn && (
              <button type="button" onClick={setOpenAccessDemo}>
                View Demo
              </button>
            )}
          </div>
          <div className="banner__right">
            <img src={school_system} alt="" />
          </div>
        </div>
        <div className="benefits tutor">
          <img src={human_resources} alt="" />
          <div className="description">
            <h4>For Management</h4>
            <ul>
              <li>Maintain users i.e students, teachers, parents and staff</li>
              <li>Maintain staff leaves online</li>
              <li>Payroll Processing &amp; Reports with a single click</li>
              <li>Staff attendance monitoring</li>
              <li>Library Management</li>
              <li>Accomodation Management</li>
              <li>Inbuilt PAYE Calculator</li>
              <li>
                We offer any other customization you need to fit your school
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="benefits tutor">
          <img src={finance} alt="" />
          <div className="description">
            <h4>For Finance</h4>
            <ul>
              <li>Invoice students</li>
              <li>Receive student fees</li>
              <li>Maitain accounting records</li>
              <li>Generate financial reports</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="benefits student">
          <img src={student} alt="" />
          <div className="description">
            <h4>As a student</h4>
            <ul>
              <li>Attend online classes</li>
              <li>Do online tests</li>
              <li>Access exam results online</li>
              <li>Access assignments online</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="benefits parent">
          <img src={parent} alt="" />
          <div className="description">
            <h4>As a Parent</h4>
            <ul>
              <li>Access children fees statements</li>
              <li>Access children exam results</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="benefits tutor">
          <img src={teacher} alt="" />
          <div className="description">
            <h4>As a Teacher</h4>
            <ul>
              <li>Teach online classes</li>
              <li>Mark attendance online</li>
              <li>Set online tests</li>
              <li>Post students exam results online</li>
              <li>Apply leave online</li>
              <li>Post assignments for students to access online</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="benefits tutor">
          <img src={teacher} alt="" />
          <div className="description">
            <h4>For Other School Staff</h4>
            <ul>
              <li>Apply leave online</li>
            </ul>
          </div>
        </div>

        <hr />
      </div>
      {/* child components */}
      {openAccessDemo && (
        <AccessDemo
          openAccessDemo={openAccessDemo}
          setOpenAccessDemo={setOpenAccessDemo}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch({ type: OPEN_LOGIN }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
