import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { START_LOADING } from "../../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import { create_offline_exam } from "../../../../../redux/actions/exam";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";

const AddOfflineExam = (props) => {
  const { openAddOfflineExam, school_terms, userId, loading } = props;
  const { setOpenAddOfflineExam, startLoading, createOfflineExam } = props;

  const [examDetails, setExamDetails] = useState({
    name: "",
    school_term: "",
    exam_type: "",
  });

  const { name, school_term, exam_type } = examDetails;

  const resetForm = () => {
    resetFormValues(examDetails);
  };

  const handleClose = () => {
    setOpenAddOfflineExam(false);
  };

  const handleChange = (e) => {
    setExamDetails({ ...examDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(examDetails)) {
      return window.alert("Please fill in all fields");
    }
    startLoading();
    createOfflineExam(userId, { ...examDetails }, resetForm);
  };

  return (
    <MinDialog isOpen={openAddOfflineExam}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Exam to enable teachers to post offline exams results</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Exam Name</label>
          <input type="text" name="name" value={name} onChange={handleChange} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">School Term</label>
          <select
            name="school_term"
            onChange={handleChange}
            value={school_term}
          >
            <option value="" disabled selected>
              --select--
            </option>
            {school_terms?.map((term) => (
              <option value={term?.id} key={term?.id}>
                {term?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Exam Type</label>
          <select name="exam_type" onChange={handleChange} value={exam_type}>
            <option value="" disabled selected>
              --select--
            </option>
            <option value="end_year">end year exam</option>
            <option value="intermediate_exam">intermediate exam</option>
          </select>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    school_terms: state.course?.school_terms,
    userId: state.auth.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createOfflineExam: (userId, body, resetForm) =>
      dispatch(create_offline_exam(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOfflineExam);
