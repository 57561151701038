import React, { useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import MediumDialog from "../../../components/common/MediumDialog";
import ReportHeader from "../../../shared/ReportHeader/ReportHeader";

const StudentAttendanceReport = (props) => {
  const { attendanceData, openAttendanceReport, start_date, end_date } = props;
  const { setOpenAttendanceReport } = props;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Students Attendance Report",
  });

  return (
    <MediumDialog isOpen={openAttendanceReport}>
      <div class="dialog">
        <table
          className="report__statement"
          border="1"
          rules="rows"
          ref={printArea}
        >
          <ReportHeader />
          <tbody>
            <tr>
              <td className="report__title" colSpan="100%">
                {attendanceData?.student}:- student attendance report from{" "}
                {moment(start_date).format("LL")} to{" "}
                {moment(end_date).format("LL")}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="table__listingHeader">
              <th>Date</th>
              <th className="tc">Present</th>
            </tr>
            {attendanceData?.dates?.map((item) => (
              <tr class="table__listingItem" key={item?.id}>
                <td>{moment(item?.date).format("LL")}</td>
                <td className="tc">
                  <input type="checkbox" checked={item?.is_present} readOnly />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenAttendanceReport(false)}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentAttendanceReport);
