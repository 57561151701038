import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../components/common/MinDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { START_LOADING } from "../../../redux/actions/types";
import { edit_stock_item } from "../../../redux/actions/stores";
import { ifEmpty } from "../../../shared/sharedFunctions";

const EditStockItem = (props) => {
  const {
    openEditStockItem,
    currentStockItem,
    expensesLedgers,
    loading,
    userId,
  } = props;
  const {
    setOpenEditStockItem,
    setCurrentStockItem,
    editStockItem,
    startLoading,
  } = props;

  const { name, unit_of_measure, ledger, reorder_level, is_active } =
    currentStockItem;

  const handleChange = (e) => {
    if (e.target.name === "is_active") {
      setCurrentStockItem({
        ...currentStockItem,
        is_active: e.target.checked,
      });
    } else {
      setCurrentStockItem({
        ...currentStockItem,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    if (ifEmpty(currentStockItem)) {
      return window.alert("Fill all fields");
    }
    startLoading();
    const body = {
      ...currentStockItem,
    };
    editStockItem(userId, body);
  };

  return (
    <MinDialog isOpen={openEditStockItem}>
      <div class="dialog">
        <h3>Edit stock item</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Stock Item Name</label>
          <input type="text" name="name" onChange={handleChange} value={name} />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Unit of Measure</label>
          <select
            name="unit_of_measure"
            onChange={handleChange}
            value={unit_of_measure}
          >
            <option value="" selected disabled>
              --select--
            </option>
            <option value="grams">grams</option>
            <option value="kg">kg</option>
            <option value="litres">litres</option>
            <option value="meters">meters</option>
            <option value="packets">packets</option>
            <option value="pieces">pieces</option>
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Expenses Ledger when stock is consumed</label>
          <select name="ledger" onChange={handleChange} value={ledger}>
            <option value="" disabled selected>
              --select--
            </option>
            {expensesLedgers?.length > 0 ? (
              <>
                {expensesLedgers?.map((ledger) => (
                  <option value={ledger?.id} key={ledger?.id}>
                    {ledger?.name}
                  </option>
                ))}
              </>
            ) : (
              <option value="" disabled>
                No expenses ledger created yet
              </option>
            )}
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Reorder Level</label>
          <input
            type="number"
            name="reorder_level"
            onChange={handleChange}
            value={reorder_level}
            min="1"
          />
        </div>
        <br />
        <div class="dialog__rowSingleItem">
          <p style={{ color: "green", fontWeight: "bold", fontSize: "0.9rem" }}>
            <input
              type="checkbox"
              name="is_active"
              onChange={handleChange}
              checked={is_active}
            />{" "}
            Activate or deactivate stock item
          </p>
        </div>

        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditStockItem(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editStockItem: (userId, body) => dispatch(edit_stock_item(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStockItem);
