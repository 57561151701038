// import installed packages
import { useState } from "react";
import { connect } from "react-redux";
// import styles
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import globals from "../../../../shared/globals";
import { ifEmpty, resetFormValues } from "../../../../shared/sharedFunctions";
// import components/pages
import MinDialog from "../../../../components/common/MinDialog";
// import redux API
import { START_LOADING } from "../../../../redux/actions/types";
import { create_new_school_service } from "../../../../redux/actions/finance";

const AddSchoolService = (props) => {
  const { openAddSchoolService, loading, userId } = props; // get state from props
  const { setOpenAddSchoolService, startLoading, createNewSchoolService } =
    props; // get dispatch actions from props

  const [newService, setNewService] = useState({
    name: "",
    price: "",
  });

  // destructure
  const { name, price } = newService;
  const fillFields = globals;

  const resetForm = () => {
    resetFormValues(newService);
  };

  const handleClose = () => {
    resetForm();
    setOpenAddSchoolService(false);
  };

  const handleChange = (e) => {
    setNewService({ ...newService, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(newService)) {
      return alert(fillFields);
    }
    startLoading();
    createNewSchoolService(newService, userId, resetForm);
  };

  return (
    <MinDialog isOpen={openAddSchoolService}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Add new school service</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Service Name</label>
          <input type="text" name="name" value={name} onChange={handleChange} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "42%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Price</label>
          <input
            type="number"
            name="price"
            min="0"
            value={price}
            onChange={handleChange}
          />
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    userId: state.auth.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createNewSchoolService: (newService, userId, resetForm) =>
      dispatch(create_new_school_service(newService, userId, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSchoolService);
