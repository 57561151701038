import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MaxDialog from "../../../../../components/common/MaxDialog";
import { showError } from "../../../../../redux/actions/shared";
import {
  START_LOADING,
  STOP_LOADING,
} from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import globals from "../../../../../shared/globals";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

const AssignmentSubmissions = (props) => {
  const { openAssignmentSubmissions, userId, currentAssignment, loading } =
    props;
  const { setOpenAssignmentSubmissions, startLoading, stopLoading } = props;

  const { liveProduction, devHome } = globals;

  const [submissionsData, setSubmissionsData] = useState([]);
  const [marksAwarded, setMarksAwarded] = useState("");

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchAssignmentSubmissions = async () => {
        await API.get(
          `/api/exam/teacher/maintain-assignment-submissions/${userId}/${currentAssignment?.id}/`
        ).then((res) => {
          setSubmissionsData(res?.data?.assignment_submissions_data);
        });
      };
      fetchAssignmentSubmissions()
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  }, [startLoading, userId, stopLoading, currentAssignment?.id]);

  const updateAssignmentSubmission = (payload) => {
    setSubmissionsData(
      submissionsData?.map((submission) =>
        submission.id === payload.id ? payload : submission
      )
    );
  };

  const handleSubmit = (e, assignmentSubmissionId) => {
    e.preventDefault();
    if (marksAwarded === "") {
      return window.alert("Please indicate marks awarded");
    }
    startLoading();
    const body = {
      assignmentSubmissionId,
      marksAwarded,
    };
    const gradeAssignmentSubmission = async () => {
      await API.post(
        `/api/exam/teacher/grade-assignment-submission/${userId}/`,
        body
      ).then((res) => {
        updateAssignmentSubmission(res.data?.updated_assignment_submission);
        window.alert(res?.data?.detail);
      });
    };
    gradeAssignmentSubmission()
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MaxDialog
      isOpen={openAssignmentSubmissions}
      maxWidth="1200px"
      maxHeight="85vh"
    >
      <div className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Assignment submissions for {currentAssignment?.title}</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {submissionsData?.length > 0 ? (
          <table className="table__listing">
            <thead>
              <tr className="table__listingHeader">
                <th>Student</th>
                <th>Open</th>
                <th>Submission Date</th>
                <th>Marks</th>
              </tr>
              {submissionsData?.map((submission) => (
                <tr className="table__listingItem" key={submission?.id}>
                  <td>{submission?.student}</td>
                  <td>
                    {liveProduction ? (
                      <Link
                        className="dodgerblue bd tc"
                        to={`${submission?.attachment}`}
                        target="__blank"
                      >
                        Open
                      </Link>
                    ) : (
                      <a
                        className="dodgerblue bd button__sp"
                        href={`${devHome}${submission?.attachment}`}
                        target="__blank"
                      >
                        Open
                      </a>
                    )}
                  </td>
                  <td>{moment(submission?.submission_date).format("LLL")}</td>
                  <td>
                    {submission?.marks_scored ? (
                      <span>{submission?.marks_scored}</span>
                    ) : (
                      <form style={{ display: "flex" }}>
                        <input
                          type="number"
                          onChange={(e) => setMarksAwarded(e.target.value)}
                          value={marksAwarded}
                          style={{ maxWidth: "60px", marginRight: "10px" }}
                        />
                        <input
                          type="button"
                          className="button__sp add__button"
                          value="Award"
                          onClick={(e) => handleSubmit(e, submission?.id)}
                        />
                      </form>
                    )}
                  </td>
                </tr>
              ))}
            </thead>
          </table>
        ) : (
          <h4 className="not__available">
            No assignment submissions received yet
          </h4>
        )}
        <div className="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenAssignmentSubmissions(false)}
          >
            Close
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentSubmissions);
