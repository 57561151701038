import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { create_class_level } from "../../../../../redux/actions/course";
import { START_LOADING } from "../../../../../redux/actions/types";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";

import CircularProgress from "@mui/material/CircularProgress";

const AddClassLevel = (props) => {
  const { openAddClassLevel, userId, loading } = props;
  const { setOpenAddClassLevel, startLoading, createClassLevel } = props;

  const [newLevel, setNewLevel] = useState({
    name: "",
  });

  const { name } = newLevel;

  const handleChange = (e) => {
    setNewLevel({ ...newLevel, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    resetFormValues(newLevel);
  };

  const handleClose = () => {
    setOpenAddClassLevel(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(newLevel)) {
      return window.alert("Please enter valid data");
    }
    startLoading();

    createClassLevel(userId, { ...newLevel }, resetForm);
  };

  return (
    <MinDialog isOpen={openAddClassLevel}>
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>Class level details</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Level Name/Number</label>
          <input type="text" name="name" onChange={handleChange} value={name} />
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createClassLevel: (userId, body, resetForm) =>
      dispatch(create_class_level(userId, body, resetForm)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddClassLevel);
