import React, { useState } from "react";
import MaxDialog from "../../../../components/common/MaxDialog";
import { connect } from "react-redux";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import { teacher_mark_attendance } from "../../../../redux/actions/course";
import CircularProgress from "@mui/material/CircularProgress";
import { maxDate } from "../../../../shared/sharedFunctions";

const MarkAttendance = (props) => {
  const { openMarkAttendance, class_streams, userId, loading } = props;
  const {
    setOpenMarkAttendance,
    startLoading,
    stopLoading,
    teacherMarkAttendance,
  } = props;
  const [attendanceFilters, setAttendanceFilters] = useState({
    classStreamId: "",
    date: "",
  });
  const [streamStudents, setStreamStudents] = useState([]);

  const { classStreamId, date } = attendanceFilters;

  const handleChange = (e) => {
    setAttendanceFilters({
      ...attendanceFilters,
      [e.target.name]: e.target.value,
    });
  };

  const handleGetStudents = async (e) => {
    e.preventDefault();
    await API.get(
      `/api/course/teacher/get-class-stream-students/${userId}/${classStreamId}/`
    )
      .then((res) => {
        setStreamStudents(res.data?.stream_students);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleStudentsTicking = (e, studentIndex) => {
    const arrayToMutate = [...streamStudents];

    arrayToMutate[studentIndex] = {
      ...arrayToMutate[studentIndex],
      is_present: e.target.checked,
    };

    setStreamStudents(arrayToMutate);
  };

  const handleClose = () => {
    setOpenMarkAttendance(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (date?.trim() === "") {
      return window.alert("Please indicate the attendance date");
    }
    startLoading();
    teacherMarkAttendance(userId, {
      streamStudents,
      classStreamId,
      date,
      attendanceType: "newAttendance",
    });
  };

  return (
    <MaxDialog isOpen={openMarkAttendance} maxWidth="1200px" maxHeight="90vh">
      <div className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Mark attendance - choose class stream</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Class Stream</label>
            <select
              name="classStreamId"
              onChange={handleChange}
              value={classStreamId}
            >
              <option value="" selected disabled>
                --select--
              </option>
              {class_streams?.map((stream) => (
                <option value={stream?.id} key={stream?.id}>
                  {stream?.name}
                </option>
              ))}
            </select>
          </span>
          {streamStudents?.length > 0 && (
            <span>
              <label htmlFor="">Date</label>
              <input
                type="date"
                name="date"
                onChange={handleChange}
                value={date}
                max={maxDate()}
              />
            </span>
          )}
        </div>
        <button
          type="button"
          className="add__button"
          style={{ maxWidth: "200px", justifySelf: "center" }}
          onClick={handleGetStudents}
        >
          Get Students
        </button>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        {streamStudents?.length > 0 && (
          <table className="table__listing">
            <thead>
              <tr className="table__listingHeader">
                <th>Admission Number</th>
                <th>Name</th>
                <th className="tc">Present</th>
              </tr>
            </thead>
            {streamStudents?.map((student, index) => (
              <tbody>
                <tr className="table__listingItem">
                  <td>{student?.admission_number}</td>
                  <td>{student?.name}</td>
                  <td className="tc">
                    <input
                      type="checkbox"
                      name=""
                      onClick={(e) => handleStudentsTicking(e, index)}
                      value={student?.id}
                      checked={student?.is_present}
                    />
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        )}
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    class_streams: state?.auth?.user?.class_streams,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    teacherMarkAttendance: (userId, body) =>
      dispatch(teacher_mark_attendance(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarkAttendance);
