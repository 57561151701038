import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_teacher_subjects } from "../../../redux/actions/course";
import { showError } from "../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";
import API from "../../../shared/axios";
import ClassStreamSubjectStudents from "./components/ClassStreamSubjectStudents";

const TeacherSubjects = (props) => {
  const { teacher_subjects, userId, loading } = props;
  const { startLoading, getTeacherSubjects, stopLoading } = props;

  const [openSubjectStudents, setOpenSubjectStudents] = useState(false);
  const [currentClassStreamSubject, setCurrentClassStreamSubject] = useState(
    {}
  );
  const [subjectStudentsList, setSubjectStudentsList] = useState([]);

  useEffect(() => {
    if (teacher_subjects?.length === 0 && userId) {
      startLoading();
      getTeacherSubjects(userId);
    }
  }, [teacher_subjects?.length, userId, getTeacherSubjects, startLoading]);

  const handleGetStudents = async (e, teacher_subject) => {
    e.preventDefault();
    setCurrentClassStreamSubject(teacher_subject);
    startLoading();
    await API.get(
      `/api/course/teacher/maintain-subject-students/${userId}/${teacher_subject?.id}/`
    )
      .then((res) => {
        setSubjectStudentsList(res?.data?.students_listing);
        setOpenSubjectStudents(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <h3>Your currently allocated subjects</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {teacher_subjects?.length > 0 ? (
          <table class="table__listing" rules="all" border="1">
            <thead>
              <tr class="table__listingHeader">
                <th>Subject/Class Stream</th>
                <th>Students</th>
              </tr>
            </thead>

            <tbody>
              {teacher_subjects?.map((subject) => (
                <tr class="table__listingItem" key={subject?.id}>
                  <td>{subject?.subject_stream}</td>
                  <td
                    className="dodgerblue bd button__sp"
                    onClick={(e) => handleGetStudents(e, subject)}
                  >
                    view
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No currently allocated subjects</h4>
        )}
      </div>
      {/* child components */}
      {openSubjectStudents && (
        <ClassStreamSubjectStudents
          openSubjectStudents={openSubjectStudents}
          setOpenSubjectStudents={setOpenSubjectStudents}
          currentClassStreamSubject={currentClassStreamSubject}
          subjectStudentsList={subjectStudentsList}
          setSubjectStudentsList={setSubjectStudentsList}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    teacher_subjects: state.course?.teacher_subjects,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeacherSubjects: (userId) => dispatch(get_teacher_subjects(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherSubjects);
