import React, { useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { delete_exam_question } from "../../../../../redux/actions/exam";
import { START_LOADING } from "../../../../../redux/actions/types";

import EditQuestion from "./EditQuestion";
import AddQuestion from "./AddQuestion";

const QuestionsListing = (props) => {
  const {
    openQuestionsListing,
    currentExamTitle,
    currentExamId,
    currentExamStatus,
    currentExamQuestions,
    userId,
  } = props;
  const { setOpenQuestionsListing, startLoading, deleteExamQuestion } = props;

  const [openAddQuestion, setOpenAddQuestion] = useState(false);
  const [openEditQuestion, setOpenEditQuestion] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});

  const handleOpenEditExamQuestion = (question) => {
    setOpenEditQuestion(true);
    setCurrentQuestion(question);
  };

  const handleDeleteQuestion = (questionId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this question, this is not reversible"
      )
    ) {
      startLoading();
      deleteExamQuestion(userId, questionId);
    }
  };

  return (
    <>
      <MediumDialog isOpen={openQuestionsListing} maxWidth="1100px">
        <div className="dialog">
          <h3>Exam questions for {currentExamTitle}</h3>
          {currentExamQuestions?.length > 0 ? (
            <>
              <table className="table__listing">
                <tr className="table__listingHeader">
                  <th>No</th>
                  <th>Marks</th>
                  <th>Edit</th>
                  <th className="tc">Delete</th>
                </tr>

                {currentExamQuestions?.map((question, index) => (
                  <tr className="table__listingItem" key={question?.id}>
                    <td>Question {index + 1}</td>
                    <td>{question?.marks}</td>
                    <td className="button__sp dodgerblue bd">
                      {currentExamStatus === "draft" ? (
                        <span
                          onClick={() => handleOpenEditExamQuestion(question)}
                        >
                          edit
                        </span>
                      ) : (
                        <span>n/a</span>
                      )}
                    </td>
                    <td className="tc">
                      {currentExamStatus === "draft" ? (
                        <i
                          className="bx bxs-trash-alt button__sp red"
                          onClick={() => handleDeleteQuestion(question.id)}
                        ></i>
                      ) : (
                        <span>n/a</span>
                      )}
                    </td>
                  </tr>
                ))}
              </table>
            </>
          ) : (
            <h4 className="not__available">No questions added yet</h4>
          )}
          <div className="form__Buttons">
            <button
              type="button"
              onClick={() => setOpenQuestionsListing(false)}
            >
              Close
            </button>
            <button type="submit" onClick={() => setOpenAddQuestion(true)}>
              Add Question
            </button>
          </div>
        </div>
      </MediumDialog>

      {openAddQuestion && (
        <AddQuestion
          openAddQuestion={openAddQuestion}
          setOpenAddQuestion={setOpenAddQuestion}
          currentExamId={currentExamId}
        />
      )}
      {openEditQuestion && (
        <EditQuestion
          setOpenEditQuestion={setOpenEditQuestion}
          openEditQuestion={openEditQuestion}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    deleteExamQuestion: (userId, questionId) =>
      dispatch(delete_exam_question(userId, questionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsListing);
