import React, { useState } from "react";
import { connect } from "react-redux";
import { ifEmpty } from "../../../../shared/sharedFunctions";
import MediumDialog from "../../../../components/common/MediumDialog";
import { START_LOADING } from "../../../../redux/actions/types";
import { create_payroll } from "../../../../redux/actions/staff";
import CircularProgress from "@mui/material/CircularProgress";

const CreatePayroll = (props) => {
  const { openCreatePayroll, userId, loading } = props;
  const { setOpenCreatePayroll, startLoading, createPayroll } = props;

  const [payrollDates, setPayrollDates] = useState({
    start_date: "",
    end_date: "",
  });
  const { start_date, end_date } = payrollDates;

  const handleChange = (e) => {
    setPayrollDates({
      ...payrollDates,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setPayrollDates({
      start_date: "",
      end_date: "",
    });
  };

  const handleClose = () => {
    resetForm();
    setOpenCreatePayroll(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(payrollDates)) {
      return window.alert("You must indicate the start and end date");
    }
    // validate end date is greater than start date
    if (start_date >= end_date) {
      return window.alert("Start date must be less than end date");
    }
    // to be able to validate the dates, we convert the date strings into date objects
    const start_date_obj = new Date(start_date);
    const end_date_obj = new Date(end_date);

    // confirm the dates are within the same month, note that getMonth starts from 0, thus any month will give a number less by 1
    if (start_date_obj.getMonth() !== end_date_obj?.getMonth()) {
      return window.alert(
        "Start date and end date must be within the same month"
      );
    }
    // validate start date, i.e must be 1
    if (start_date_obj.getDate() !== 1) {
      return window.alert(
        "Error, start date must be the beginning of the month"
      );
    }
    // now we get the last date for the selected month
    const month_end_date = new Date(
      start_date_obj.getFullYear(),
      start_date_obj.getMonth() + 1,
      0
    ).getDate();
    if (end_date_obj.getDate() !== month_end_date) {
      return window.alert(
        `The end date must be the last day of the month i.e ${month_end_date}`
      );
    }
    // inform the user to ensure they have allocated staff earnings and deductions and activated them
    if (
      window.confirm(
        "Ensure you have allocated all the active staff their earnings and deductions and activated them,\n",
        "and deactivated the inactive deductions/earnings for respective staff. Are you sure you want to continue?"
      )
    ) {
      startLoading();
      // we define the month names here and use getMonth index to get the month name
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month_name = months[start_date_obj.getMonth()];
      const body = {
        ...payrollDates,
        month_year: `${month_name}-${start_date_obj.getFullYear()}`,
      };
      createPayroll(userId, body, resetForm);
    }
  };

  return (
    <MediumDialog isOpen={openCreatePayroll} maxWidth="600px">
      <form class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Select payroll dates (Must be the same month)</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__row">
          <span>
            <label for="">Start Date</label>
            <input
              type="date"
              name="start_date"
              onChange={handleChange}
              value={start_date}
            />
          </span>
          <span>
            <label for="">End date</label>
            <input
              type="date"
              name="end_date"
              onChange={handleChange}
              value={end_date}
            />
          </span>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Process
          </button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createPayroll: (userId, body, resetForm) =>
      dispatch(create_payroll(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePayroll);
