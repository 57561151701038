import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { parent_get_children } from "../../redux/actions/auth";
import { START_LOADING } from "../../redux/actions/types";

import OnlineExamResultsReport from "./components/OnlineExamResultsReport";
import FeesReport from "./components/FeesReport";
import OfflineExamResultsReport from "./components/OfflineExamResultsReport";
import StudentAttendance from "./components/StudentAttendance";

const ParentReports = (props) => {
  const { userId, parentsChildren } = props;
  const { parentGetChildren, startLoading } = props;
  const [openFeesReport, setOpenFeesReport] = useState(false);
  const [openExamResultsReport, setOpenExamResultsReport] = useState(false);
  const [openOfflineExamResultsReport, setOpenOfflineExamResultsReport] =
    useState(false);
  const [openStudentAttendance, setOpenStudentAttendance] = useState(false);

  useEffect(() => {
    if (userId && parentsChildren?.length === 0) {
      startLoading();
      parentGetChildren(userId);
    }
  }, [userId, parentGetChildren, startLoading, parentsChildren?.length]);

  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <h3>Access reports here</h3>
        </div>
        <table className="table__listing">
          <tr className="table__listingHeader">
            <th>Report</th>
            <th>Action</th>
          </tr>
          <tr className="table__listingItem">
            <td>Fees report</td>
            <td
              className="dodgerblue bd button__sp"
              onClick={() => setOpenFeesReport(true)}
            >
              Open
            </td>
          </tr>
          <tr className="table__listingItem">
            <td>Online tests exam results</td>
            <td
              className="dodgerblue bd button__sp"
              onClick={() => setOpenExamResultsReport(true)}
            >
              Open
            </td>
          </tr>
          <tr className="table__listingItem">
            <td>Offline exams results</td>
            <td
              className="dodgerblue bd button__sp"
              onClick={() => setOpenOfflineExamResultsReport(true)}
            >
              Open
            </td>
          </tr>
          <tr className="table__listingItem">
            <td>Student Attendance</td>
            <td
              className="button__sp dodgerblue bd"
              onClick={() => setOpenStudentAttendance(true)}
            >
              Open
            </td>
          </tr>
        </table>
      </div>
      {/* child components */}

      {openFeesReport && (
        <FeesReport
          openFeesReport={openFeesReport}
          setOpenFeesReport={setOpenFeesReport}
        />
      )}
      {openExamResultsReport && (
        <OnlineExamResultsReport
          openExamResultsReport={openExamResultsReport}
          setOpenExamResultsReport={setOpenExamResultsReport}
        />
      )}
      {openOfflineExamResultsReport && (
        <OfflineExamResultsReport
          openOfflineExamResultsReport={openOfflineExamResultsReport}
          setOpenOfflineExamResultsReport={setOpenOfflineExamResultsReport}
        />
      )}
      {openStudentAttendance && (
        <StudentAttendance
          openStudentAttendance={openStudentAttendance}
          setOpenStudentAttendance={setOpenStudentAttendance}
        />
      )}
    </>
  );
};

const napStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    parentsChildren: state?.auth?.parentsChildren,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    parentGetChildren: (userId) => dispatch(parent_get_children(userId)),
  };
};

export default connect(napStateToProps, mapDispatchToProps)(ParentReports);
