import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  teacherExams: [],
  teacherExamQuestions: [],
  studentExams: [],
  isExamPage: false,
  offlineExams: [],
  teacherAssignments: [],
};

const createExam = (state, payload) => {
  return updateObject(state, {
    teacherExams: [...state.teacherExams, payload],
  });
};

const setTeacherExams = (state, payload) => {
  return updateObject(state, {
    teacherExams: payload?.exams,
    teacherExamQuestions: payload.exam_questions,
  });
};

const updateExam = (state, payload) => {
  return updateObject(state, {
    teacherExams: state.teacherExams?.map((exam) =>
      exam?.id === payload?.id ? payload : exam
    ),
  });
};

const createExamQuestion = (state, payload) => {
  return updateObject(state, {
    teacherExamQuestions: [...state.teacherExamQuestions, payload],
  });
};

const updateExamQuestion = (state, payload) => {
  return updateObject(state, {
    teacherExamQuestions: state.teacherExamQuestions.map((question) =>
      question?.id === payload?.id ? payload : question
    ),
  });
};

const deleteExam = (state, payload) => {
  return updateObject(state, {
    teacherExams: state.teacherExams?.filter((exam) => exam.id !== payload),
  });
};

const deleteExamQuestion = (state, payload) => {
  return updateObject(state, {
    teacherExamQuestions: state.teacherExamQuestions?.filter(
      (question) => question.id !== payload
    ),
  });
};

const studentGetExams = (state, payload) => {
  return updateObject(state, {
    studentExams: payload,
  });
};

// set is exam page so as to hide sidebar and have custom header

const setExamPage = (state, payload) => {
  return updateObject(state, {
    isExamPage: payload,
  });
};

const setOfflineExams = (state, payload) => {
  return updateObject(state, {
    offlineExams: payload,
  });
};

const createOfflineExam = (state, payload) => {
  return updateObject(state, {
    offlineExams: [...state.offlineExams, payload],
  });
};

const editOfflineExam = (state, payload) => {
  return updateObject(state, {
    offlineExams: state?.offlineExams?.map((offline_exam) =>
      offline_exam?.id === payload?.id ? payload : offline_exam
    ),
  });
};

const teacherGetAssignments = (state, payload) => {
  return updateObject(state, {
    teacherAssignments: payload,
  });
};

const teacherCreateAssignment = (state, payload) => {
  return updateObject(state, {
    teacherAssignments: [...state.teacherAssignments, payload],
  });
};
const teacherEditAssignment = (state, payload) => {
  return updateObject(state, {
    teacherAssignments: state?.teacherAssignments?.map((assignment) =>
      assignment?.id === payload?.id ? payload : assignment
    ),
  });
};
const teacherDeleteAssignment = (state, payload) => {
  return updateObject(state, {
    teacherAssignments: state.teacherAssignments?.filter(
      (assignment) => assignment?.id !== payload
    ),
  });
};

const examReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.NEW_EXAM:
      return createExam(state, payload);
    case actionTypes.SET_EXAMS:
      return setTeacherExams(state, payload);
    case actionTypes.UPDATE_EXAM:
      return updateExam(state, payload);
    case actionTypes.NEW_EXAM_QUESTION:
      return createExamQuestion(state, payload);
    case actionTypes.UPDATE_EXAM_QUESTION:
      return updateExamQuestion(state, payload);
    case actionTypes.DELETE_EXAM:
      return deleteExam(state, payload);
    case actionTypes.DELETE_EXAM_QUESTION:
      return deleteExamQuestion(state, payload);
    case actionTypes.SET_STUDENT_EXAMS:
      return studentGetExams(state, payload);
    case actionTypes.SET_EXAM_PAGE:
      return setExamPage(state, payload);
    case actionTypes.SET_OFFLINE_EXAMS:
      return setOfflineExams(state, payload);
    case actionTypes.NEW_OFFLINE_EXAM:
      return createOfflineExam(state, payload);
    case actionTypes.UPDATE_OFFLINE_EXAM:
      return editOfflineExam(state, payload);
    case actionTypes.SET_ASSIGNMENTS:
      return teacherGetAssignments(state, payload);
    case actionTypes.NEW_ASSIGNMENT:
      return teacherCreateAssignment(state, payload);
    case actionTypes.EDIT_ASSIGNMENT:
      return teacherEditAssignment(state, payload);
    case actionTypes.DELETE_ASSIGNMENT:
      return teacherDeleteAssignment(state, payload);
    default:
      return state;
  }
};

export default examReducer;
