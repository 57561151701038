import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { student_get_live_classes } from "../../../redux/actions/course";
import { START_LOADING } from "../../../redux/actions/types";
import { Link } from "react-router-dom";

const StudentLiveClasses = (props) => {
  const { userId, loading, student_live_classes } = props;
  const { startLoading, studentGetLiveClasses } = props;

  useEffect(() => {
    if (userId) {
      startLoading();
      studentGetLiveClasses(userId);
    }
  }, [startLoading, studentGetLiveClasses, userId]);

  return (
    <div
      className="table__parent"
      id={loading ? "pageSubmitting" : ""}
      style={{ maxWidth: "800px" }}
    >
      <div class="table__parentHeader">
        <h3>Scheduled live classes</h3>
      </div>
      {student_live_classes?.length > 0 ? (
        <table class="table__listing">
          <thead>
            <tr class="table__listingHeader">
              <th>Topic</th>
              <th>Start Time</th>
              <th>Duration</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {student_live_classes?.map((live_class) => (
              <tr class="table__listingItem" key={live_class?.id}>
                <td>{live_class?.topic}</td>
                <td>{moment(live_class?.start_time).format("LLL")}</td>
                <td>{live_class?.duration} Minutes</td>
                <td>
                  {live_class.status === "join" ? (
                    <Link
                      to={`/live-class/${live_class?.id}/`}
                      className="dodgerblue bd button__sp"
                    >
                      Join Class
                    </Link>
                  ) : (
                    <span>Not Started</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h4 class="not__available">No scheduled live classes</h4>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    student_live_classes: state?.course?.student_live_classes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    studentGetLiveClasses: (userId) =>
      dispatch(student_get_live_classes(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentLiveClasses);
