import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { add_overall_grade } from "../../../../../redux/actions/course";
import { START_LOADING } from "../../../../../redux/actions/types";

const AddOverallGrade = (props) => {
  const { openAddOverallGrade, loading, userId } = props;
  const { setOpenAddOverallGrade, startLoading, addOverallGrade } = props;

  const [overallGradeDetails, setOverallGradeDetails] = useState({
    grade: "",
    points: "",
  });

  const { grade, points } = overallGradeDetails;

  const resetForm = () => {
    setOverallGradeDetails({
      grade: "",
      points: "",
    });
  };

  const handleChange = (e) => {
    setOverallGradeDetails({
      ...overallGradeDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    const body = {
      ...overallGradeDetails,
      grade: grade?.toUpperCase(),
    };
    addOverallGrade(userId, body, resetForm);
  };

  return (
    <MediumDialog isOpen={openAddOverallGrade} maxWidth="400px">
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>New Overall Grade</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Grade</label>
          <input
            type="text"
            name="grade"
            onChange={handleChange}
            value={grade}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Points (0 - 12)</label>
          <input
            type="number"
            name="points"
            onChange={handleChange}
            value={points}
            required
          />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenAddOverallGrade(false)}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    addOverallGrade: (userId, body, resetForm) =>
      dispatch(add_overall_grade(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOverallGrade);
