import API from "../shared/axios";

// get existing groups
export const getGroups = (userId) => API.get(`/api/user/get-groups/${userId}/`);

// get user data
export const getUser = () => API.get("/api/user/get-user-data/");

// function to get created school terms
export const getSchoolTerms = (userId) =>
  API.get(`/api/course/maintain-school-terms/${userId}/`);

// API to get valid license
export const getValidLicense = (userId) =>
  API.get(`/api/core/get-valid-license/${userId}/`);

// API to get report types
export const getReportTypes = (userId) =>
  API.get(`/api/finance/maintain-report-types/${userId}/`);

// API to get users
export const getUsers = (userId, userType) =>
  API.get(`/api/user/get-users/${userId}/${userType}/`);

// GET REQUEST API to get classes and class streams
export const adminGetClassStreams = (userId) =>
  API.get(`/api/course/maintain-class-stream/${userId}/`);

// GET REQUEST API to get school services
export const getSchoolServices = (userId) =>
  API.get(`/api/finance/maintain-school-services/${userId}/`);

// API to get subjects
export const getSubjects = (userId) =>
  API.get(`/api/course/maintain-subjects/${userId}/`);

// API to get teacher subjects and class streams
export const getTeacherSubjects = (userId) =>
  API.get(`/api/course/teacher/get-subjects-and-streams/${userId}/`);

// GET REQUEST to get exams created by teacher
export const getTeacherExams = (userId) =>
  API.get(`/api/exam/teacher/maintain-exams/${userId}/`);

// GET REQUEST to get scheduled live classes
export const teacherGetLiveClasses = (userId) =>
  API.get(
    `/api/course/teacher/teacher-maintain-live-classes-listing/${userId}/`
  );

// GET REQUEST to get parent ledger groups, ledger groups and ledgers
export const getLedgers = (userId) =>
  API.get(`/api/finance/maintain-ledgers/${userId}/`);

// GET REQUEST for student to get the exams
export const studentGetExams = (userId) =>
  API.get(`/api/exam/student/student-maintain-exams/${userId}/`);

// GET REQUEST for staff to get all leave applications
export const staffGetLeaveApplications = (userId) =>
  API.get(`/api/staff/staff/maintain-leave-applications/${userId}/`);

// GET REQUEST for human resource to get all staff
export const humanResourceGetStaff = (userId) =>
  API.get(`/api/staff/staff/human-resource-maintain-staff/${userId}/`);

// GET REQUEST for human resource to get salary deductions
export const getSalaryDeductions = (userId) =>
  API.get(`/api/staff/staff/maintain-salary-deductions/${userId}/`);

// GET REQUEST to get the created offline exams
export const getOfflineExams = (userId) =>
  API.get(`/api/exam/maintain-offline-exams/${userId}/`);

// GET REQUEST for parent/gurdian to get children
export const parentGetChildren = (userId) =>
  API.get(`/api/user/parent/maintain-parents-children/${userId}/`);

// GET REQUEST for teacher to create assignment
export const teacherGetAssignments = (userId) =>
  API.get(`/api/exam/teacher/maintain-assignments/${userId}/`);

// GET REQUEST for users such as teachers to get stream classes
export const getClassStreams = (userId) =>
  API.get(`/api/course/get-class-streams/${userId}/`);

// GET REQUEST for student to get live classes
export const studentGetLiveClasses = (userId) =>
  API.get(`/api/course/student/maintain-live-classes/${userId}/`);

//  GET REQUEST to get dorms
export const getDorms = (userId) =>
  API.get(`/api/accomodation/admin/maintain-dorms/${userId}/`);

// GET REQUEST to get active dorms
export const getActiveDorms = (userId) =>
  API.get(`/api/accomodation/get-active-dorms/${userId}/`);

// GET REQUEST to get all stock items
export const getStockItems = (userId, body) =>
  API.get(`/api/store/admin/maintain-stock-items/${userId}/`, body);

// GET REQUEST to get all books
export const getBooks = (userId) =>
  API.get(`/api/library/admin/maintain-books/${userId}/`);

// GET REQUEST to get book borrows
export const getBookBorrows = (userId) =>
  API.get(`/api/library/admin/maintain-book-borrows/${userId}/`);

// GET REQUEST to get lost books
export const getLostBooks = (userId) =>
  API.get(`/api/library/admin/maintain-lost-books/${userId}/`);

// GET REQUEST to get lost book charges
export const getLostBookCharges = (userId) =>
  API.get(`/api/library/admin/maintain-lost-book-charges/${userId}/`);

// GET REQUEST to get all salary earnings
export const getSalaryEarnings = (userId) =>
  API.get(`/api/staff/staff/maintain-salary-earnings/${userId}/`);

// GET REQUEST to get all PAYE brackets
export const getPAYEBrackets = (userId) =>
  API.get(`/api/staff/staff/maintain-paye-brackets/${userId}/`);

// GET REQUEST to get all payrolls
export const getPayrolls = (userId) =>
  API.get(`/api/staff/staff/maintain-payrolls/${userId}/`);

// GET REQUEST to get parents, staff, class_levels and class streams parents phone numbers
export const adminGetStaffParentPhoneNumbers = (userId) =>
  API.get(
    `/api/communication/school-admin/get-staff-parents-phone-numbers/${userId}/`
  );
