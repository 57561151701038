import API from "../shared/axios";

// function to create new term
export const createNewTerm = (newTerm, userId) =>
  API.post(`/api/course/maintain-school-terms/${userId}/`, newTerm);

// create new user group
export const createGroup = (newGroup, userId) =>
  API.post(`/api/user/create-group/${userId}/`, newGroup);
// POST REQUEST function to create a new class stream
export const createNewClassStream = (newClassStream, userId) =>
  API.post(`/api/course/maintain-class-stream/${userId}/`, newClassStream);

// create new user
export const createUser = (newUser, userId) =>
  API.post(`/api/user/create-user/${userId}/`, newUser);

// signup
export const signupUser = (newUser) => API.post("/api/user/signup/", newUser);

// activate user account
export const activateAccount = (activation_token) =>
  API.post("/api/user/activate-user-account/", { activation_token });

// resend account activation email
export const resendActivation = (email) =>
  API.post("/api/user/resend-account-activation-link/", { email });

// sign in user
export const signIn = (loginData) => API.post("/api/user/login/", loginData);

// function to create class level
export const createClassLevel = (userId, body) =>
  API.post(`/api/course/maintain-class-levels/${userId}/`, body);

// POST REQUEST to create a school service
export const createNewSchoolService = (newService, userId) =>
  API.post(`/api/finance/maintain-school-services/${userId}/`, newService);

// POST REQUEST API to create a students invoice
export const createStudentsInvoice = (body, userId) =>
  API.post(`/api/finance/invoice-students/${userId}/`, body);

// send password reset email
export const resetPassword = (email) =>
  API.post("/api/user/user-request-password-reset/", { email });

// set new password using reset link sent from above
export const setPassword = (newPasswords, password_token) =>
  API.post("/api/user/user-set-new-password/", {
    ...newPasswords,
    password_token,
  });

// POST REQUEST API to create a new ledger group
export const createNewLedgerGroup = (userId, body) =>
  API.post(`/api/finance/maintain-ledger-groups/${userId}/`, body);

// POST REQUEST API to create a new ledger
export const createNewLedger = (userId, body) =>
  API.post(`/api/finance/maintain-ledgers/${userId}/`, body);

// user change password
export const changePassword = (passwords, userId) =>
  API.post(`/api/user/change-user-password/${userId}/`, passwords);

// POST REQUEST to create new subject
export const createSubject = (userId, body) =>
  API.post(`/api/course/maintain-subjects/${userId}/`, body);

// POST REQUEST to allocate teacher to subject
export const allotSubject = (userId, body) =>
  API.post(`/api/course/maintain-subject-allotments/${userId}/`, body);

// POST REQUEST to add a new overall grade
export const addOverallGrade = (userId, body) =>
  API.post(`/api/course/maintain-overall-grade/${userId}/`, body);
// POST REQUEST to create a new exam
export const createExam = (userId, body) =>
  API.post(`/api/exam/teacher/maintain-exams/${userId}/`, body);

// POST REQUEST to create exam question
export const createExamQuestion = (userId, body) =>
  API.post(`/api/exam/teacher/maintain-exam-questions/${userId}/`, body);

// POST REQUEST to create a new student/parent/teacher/other staff
export const createNewUser = (userId, body) =>
  API.post(`/api/user/maintain-users/${userId}/`, body);

// POST REQUEST to create scheduled live classes
export const createLiveClass = (userId, body) =>
  API.post(
    `/api/course/teacher/teacher-maintain-live-classes-listing/${userId}/`,
    body
  );

// POST REQUEST TO POST A TRANSACTION ENTRY
export const postNewTransaction = (userId, body) =>
  API.post(`/api/finance/maintain-transactions/${userId}/`, body);

// POST student submit exam
export const studentSubmitExam = (userId, examId, body) =>
  API.post(
    `/api/exam/student/student-maintain-current-exam/${userId}/${examId}/`,
    body
  );

// POST REQUEST for teacher to mark attendance
export const teacherMarkAttendance = (userId, body) =>
  API.post(`/api/course/teacher/maintain-attendance/${userId}/`, body);

// POST REQUEST for staff to apply a new leave
export const staffApplyLeave = (userId, body) =>
  API.post(`/api/staff/staff/maintain-leave-applications/${userId}/`, body);

// POST REQUEST to create the created offline exams
export const createOfflineExam = (userId, body) =>
  API.post(`/api/exam/maintain-offline-exams/${userId}/`, body);

// POST REQUEST to create new assignment
export const teacherCreateAssignment = (userId, body) =>
  API.post(`/api/exam/teacher/maintain-assignments/${userId}/`, body);

// POST REQUEST to create a dorm
export const createNewDorm = (userId, body) =>
  API.post(`/api/accomodation/admin/maintain-dorms/${userId}/`, body);

// POST REQUEST to add a new stock item
export const createStockItem = (userId, body) =>
  API.post(`/api/store/admin/maintain-stock-items/${userId}/`, body);

// POST REQUEST to add a new book
export const addNewBook = (userId, body) =>
  API.post(`/api/library/admin/maintain-books/${userId}/`, body);

// POST REQUEST to add stock for existing book
export const stockExistingBook = (userId, body) =>
  API.post(`/api/library/admin/maintain-book-stocks/${userId}/`, body);

// POST REQUEST to record book borrows
export const recordBookBorrow = (userId, body) =>
  API.post(`/api/library/admin/maintain-book-borrows/${userId}/`, body);

// POST REQUEST to charge a lost book
export const chargeLostBook = (userId, body) =>
  API.post(`/api/library/admin/maintain-lost-book-charges/${userId}/`, body);

// POST REQUEST to create a new deduction
export const createSalaryDeduction = (userId, body) =>
  API.post(`/api/staff/staff/maintain-salary-deductions/${userId}/`, body);

// POST REQUEST to create an earning
export const createSalaryEarning = (userId, body) =>
  API.post(`/api/staff/staff/maintain-salary-earnings/${userId}/`, body);

// POST REQUEST to create staff salary earning for specific staff
export const createStaffSalaryEarning = (userId, body) =>
  API.post(`/api/staff/staff/maintain-staff-salary-earning/${userId}/`, body);

// POST REQUEST to create staff salary deduction for specific staff
export const createStaffSalaryDeduction = (userId, body) =>
  API.post(`/api/staff/staff/maintain-staff-salary-deduction/${userId}/`, body);

// POST REQUEST to create a PAYE bracket
export const createPAYEBracket = (userId, body) =>
  API.post(`/api/staff/staff/maintain-paye-brackets/${userId}/`, body);

// POST REQUEST to run a payroll
export const createPayroll = (userId, body) =>
  API.post(`/api/staff/staff/maintain-payrolls/${userId}/`, body);

// POST REQUEST to create a tax relief
export const createTaxRelief = (userId, body) =>
  API.post(`/api/staff/staff/maintain-tax-relief/${userId}/`, body);

// POST REQUEST to post a stock invoice
export const postStockInvoice = (userId, body) =>
  API.post(`/api/finance/maintain-stock-invoices/${userId}/`, body);
