import { CircularProgress } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import MaxDialog from "../../../../../components/common/MaxDialog";
import ReportHeader from "../../../../../shared/ReportHeader/ReportHeader";

const SubjectResultsMeritList = (props) => {
  const {
    openMeritList,
    studentsSubjectResults,
    loading,
    currentSubjectStream,
  } = props;
  const { setOpenMeritList } = props;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "Results Report",
  });

  return (
    <MaxDialog isOpen={openMeritList} maxWidth="1200px" maxHeight="90vh">
      <div class="dialog">
        {studentsSubjectResults?.length > 0 ? (
          <table
            ref={printArea}
            className="report__statement"
            border="1"
            rules="all"
            id="subject_results_merit_list"
          >
            <ReportHeader />
            <tr>
              <th colSpan="100%" style={{ padding: "5px", color: "purple" }}>
                {currentSubjectStream?.subject_stream} results merit list
              </th>
            </tr>
            <tr className="table__listingHeader">
              <th className="tc">Position</th>
              <th>Admission</th>
              <th>Student Name</th>
              <th className="tc">Marks Scored</th>
              <th className="tc">Grade</th>
              <th>Comments</th>
            </tr>
            {loading && (
              <CircularProgress
                style={{ position: "absolute", marginLeft: "45%" }}
              />
            )}
            {studentsSubjectResults?.map((student, index) => (
              <tr className="table__listingItem" key={student?.id}>
                <td className="tc">{index + 1}</td>
                <td>{student?.admission_number}</td>
                <td>{student?.name}</td>
                <td className="tc">{student?.marks_scored}</td>
                <td className="tc">{student?.grade}</td>
                <td>{student?.comments}</td>
              </tr>
            ))}
          </table>
        ) : (
          <h4 class="not__available">
            No students for this class stream and subject
          </h4>
        )}
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenMeritList(false)}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print/Save
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

export default SubjectResultsMeritList;
