// import installed packages

// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const CommunicationLinks = ({
  Link,
  pathname,
  toggleSubmenu,
  openSubMenu,
  users_groups,
}) => {
  return (
    <>
      {["School Admin"]?.some((allowed_group) =>
        users_groups?.includes(allowed_group)
      ) && (
        <>
          <Link
            to="#"
            className="nav__link"
            onClick={() => toggleSubmenu("Communication")}
          >
            <i className="bx bxs-message"></i>
            <span className="nav__name">Communication</span>
          </Link>
          {openSubMenu === "Communication" && (
            <>
              <Link
                to="/communication/sms/"
                className={
                  `${pathname}` === "/communication/sms/"
                    ? "nav__link active sidebar__submenu"
                    : "nav__link sidebar__submenu"
                }
              >
                <span className="nav__name">&bull; SMS</span>
              </Link>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CommunicationLinks;
