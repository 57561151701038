import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../../components/common/MinDialog";
import { get_teacher_subjects } from "../../../../../redux/actions/course";
import { showError } from "../../../../../redux/actions/shared";
import {
  START_LOADING,
  STOP_LOADING,
} from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import SubjectResultsMeritList from "./SubjectResultsMeritList";

import UpdateSubjectResults from "./UpdateSubjectResults";

const SubjectResultsAction = (props) => {
  const { openResultsAction, currentExam, teacher_subjects, userId, loading } =
    props;
  const {
    setOpenResultsAction,
    startLoading,
    getTeacherSubjects,
    stopLoading,
  } = props;

  const [openUpdateSubjectResults, setOpenUpdateSubjectResults] =
    useState(false);
  const [openMeritList, setOpenMeritList] = useState(false);

  const [actionType, setActionType] = useState("");
  const [currentSubjectAllotmentId, setCurrentSubjectAllotmentId] =
    useState("");
  const [studentsSubjectResults, setStudentsSubjectResults] = useState([]);
  const [currentSubjectStream, setCurrentSubjectStream] = useState({});

  // useEffect to get teacher subjects

  useEffect(() => {
    if (teacher_subjects?.length === 0 && userId) {
      startLoading();
      getTeacherSubjects(userId);
    }
  }, [teacher_subjects?.length, userId, getTeacherSubjects, startLoading]);

  const handleChange = (e) => {
    setActionType(e.target.value);
  };

  // this is called when a user clicks the continue button
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCurrentSubjectStream(
      teacher_subjects?.find(
        (subject) => subject?.id === currentSubjectAllotmentId
      )
    );
    startLoading();
    // we get
    await API.get(
      `/api/exam/teacher/maintain-offline-exam-subject-results/${userId}/${currentSubjectAllotmentId}/${currentExam?.id}/`
    )
      .then((res) => {
        setStudentsSubjectResults(res?.data?.students_subject_results_data);
        if (actionType === "update_results") {
          setOpenUpdateSubjectResults(true);
        } else if (actionType === "merit_list") {
          setOpenMeritList(true);
        }
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog isOpen={openResultsAction}>
        <form
          class="dialog"
          onSubmit={handleSubmit}
          id={loading ? "formSubmitting" : ""}
        >
          <h3>{currentExam?.name} - choose to view or update results</h3>
          <div class="dialog__rowSingleItem">
            <label for="">Update results or view results merit list</label>
            <select name="" onChange={handleChange} required>
              <option value="">---select--</option>
              <option value="update_results">Update Results</option>
              <option value="merit_list">Merit List</option>
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <div class="dialog__rowSingleItem">
            <label htmlFor="">Class Stream and Subject</label>
            <select
              name=""
              onChange={(e) => setCurrentSubjectAllotmentId(e.target.value)}
              required
            >
              <option value="" disabled selected>
                --select--
              </option>
              {teacher_subjects?.map((subject) => (
                <option value={subject?.id} key={subject?.id}>
                  {subject?.subject_stream}
                </option>
              ))}
            </select>
          </div>
          <div class="form__Buttons">
            <button type="button" onClick={() => setOpenResultsAction(false)}>
              Close
            </button>
            <button type="submit">Continue</button>
          </div>
        </form>
      </MinDialog>
      {/* child components */}
      {openUpdateSubjectResults && (
        <UpdateSubjectResults
          openUpdateSubjectResults={openUpdateSubjectResults}
          setOpenUpdateSubjectResults={setOpenUpdateSubjectResults}
          studentsSubjectResults={studentsSubjectResults}
          currentSubjectStream={currentSubjectStream}
          setStudentsSubjectResults={setStudentsSubjectResults}
          currentExam={currentExam}
        />
      )}
      {openMeritList && (
        <SubjectResultsMeritList
          openMeritList={openMeritList}
          setOpenMeritList={setOpenMeritList}
          studentsSubjectResults={studentsSubjectResults}
          loading={loading}
          currentSubjectStream={currentSubjectStream}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    teacher_subjects: state.course?.teacher_subjects,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeacherSubjects: (userId) => dispatch(get_teacher_subjects(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectResultsAction);
