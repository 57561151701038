import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../components/common/MediumDialog";
import API from "../../../../shared/axios";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import { showError } from "../../../../redux/actions/shared";
import CircularProgress from "@mui/material/CircularProgress";

const ExamsReport = (props) => {
  const { openExamsReport, userId, loading } = props;
  const { setOpenExamsReport, startLoading, stopLoading } = props;

  const [examResults, setExamResults] = useState([]);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchExamResults = async () => {
        await API.get(`/api/exam/student/get-exam-results/${userId}/`).then(
          (res) => {
            setExamResults(res?.data?.exam_results_data);
          }
        );
      };
      fetchExamResults()
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  }, [userId, startLoading, stopLoading]);

  return (
    <MediumDialog isOpen={openExamsReport}>
      <div className="dialog">
        <h3>Your exam results</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", margibLeft: "45%" }}
          />
        )}

        {examResults?.length > 0 ? (
          <table className="table__listing">
            <tr className="table__listingHeader">
              <th>Exam</th>
              <th>Subject</th>
              <th>Class Stream</th>
              <th>Marks scored (%)</th>
              <th>Status</th>
            </tr>
            {examResults?.map((exam) => (
              <tr className="table__listingItem" key={exam?.id}>
                <td>{exam?.exam_title}</td>
                <td>{exam?.subject}</td>
                <td>{exam?.class_stream}</td>
                <td>{exam?.marks_scored}</td>
                <td>{exam?.status}</td>
              </tr>
            ))}
          </table>
        ) : (
          <h4 className="not__available">No exam results available</h4>
        )}

        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenExamsReport(false)}>
            Close
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamsReport);
