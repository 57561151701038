import { combineReducers } from "redux";

import auth from "./auth";
import shared from "./shared";
import course from "./course";
import finance from "./finance";
import exam from "./exam";
import staff from "./staff";
import accomodation from "./accomodation";
import stores from "./stores";
import library from "./library";
import communication from "./communication";

export default combineReducers({
  auth,
  shared,
  course,
  finance,
  exam,
  staff,
  accomodation,
  stores,
  library,
  communication,
});
