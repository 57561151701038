import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { START_LOADING } from "../../../../redux/actions/types";
import { create_paye_bracket } from "../../../../redux/actions/staff";
import { ifEmpty } from "../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const AddPAYEBracket = (props) => {
  const { openAddPAYEBracket, userId, loading } = props;
  const { setOpenAddPAYEBracket, startLoading, createPAYEBracket } = props;

  const [bracketdetails, setBracketDetails] = useState({
    name: "",
    order: "",
    start_amount: "",
    end_amount: "",
    rate: "",
    is_last_bracket: false,
  });
  const { name, order, start_amount, end_amount, rate, is_last_bracket } =
    bracketdetails;

  const handleChange = (e) => {
    setBracketDetails({ ...bracketdetails, [e.target.name]: e.target.value });
  };
  const handleCheckboxChange = (e) => {
    setBracketDetails({ ...bracketdetails, [e.target.name]: e.target.checked });
  };

  const resetForm = () => {
    //   do not use resetFormValues since is_last_bracket gets reset to '' which can cause a bug
    setBracketDetails({
      name: "",
      order: "",
      start_amount: "",
      end_amount: "",
      rate: "",
      is_last_bracket: false,
    });
  };
  const handleClose = () => {
    resetForm();
    setOpenAddPAYEBracket(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!is_last_bracket && ifEmpty(bracketdetails)) {
      return window.alert("Fields marked with * are required");
    } else if (
      is_last_bracket &&
      (name?.trim() === "" ||
        order === "" ||
        start_amount === "" ||
        rate === "")
    ) {
      return window.alert(
        "For last bracket other fields are required except end amount"
      );
    }
    if (!is_last_bracket && Number(start_amount) >= Number(end_amount)) {
      return window.alert("Start amount must be less than end amount");
    }
    startLoading();
    createPAYEBracket(userId, bracketdetails, resetForm);
  };

  return (
    <MinDialog isOpen={openAddPAYEBracket}>
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>Enter PAYE bracket details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">
            Bracket Name <span className="red">*</span>
          </label>
          <input type="text" onChange={handleChange} value={name} name="name" />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">
            Order<span className="red">*</span>(1-10)
          </label>
          <input
            type="number"
            name="order"
            min="1"
            max="10"
            onChange={handleChange}
            value={order}
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">
            Start Amount<span className="red">*</span>
          </label>
          <input
            type="number"
            name="start_amount"
            onChange={handleChange}
            value={start_amount}
            min="0"
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">
            End Amount<span className="red">*</span>
          </label>
          <input
            type="number"
            name="end_amount"
            onChange={handleChange}
            value={end_amount}
            min="0"
            disabled={is_last_bracket}
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">
            PAYE Rate<span className="red">*</span>(0-100)
          </label>
          <input
            type="number"
            name="rate"
            onChange={handleChange}
            value={rate}
            min="0"
            max="100"
          />
        </div>
        <div class="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_last_bracket"
            onChange={handleCheckboxChange}
            checked={is_last_bracket}
          />
          <label>Tick this if last bracket</label>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createPAYEBracket: (userId, body, resetForm) =>
      dispatch(create_paye_bracket(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPAYEBracket);
