// import installed packages
import { useState } from "react";
import { connect } from "react-redux";

// import styles
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import { resetFormValues } from "../../../../shared/sharedFunctions";
// import components/pages
import MinDialog from "../../../../components/common/MinDialog";
// import redux API
import { START_LOADING } from "../../../../redux/actions/types";
import { create_new_ledger } from "../../../../redux/actions/finance";

const AddLedger = (props) => {
  const { openAddLedger, loading, ledger_groups, userId } = props; //get state from props
  const { setOpenAddLedger, startLoading, createNewLedger } = props; //get dispatch actions from props
  const [newLedger, setNewLedger] = useState({
    ledger_group: "",
    name: "",
  });

  // destructure
  const { ledger_group, name } = newLedger;

  const resetForm = () => {
    resetFormValues(newLedger);
  };

  const handleClose = () => {
    resetForm();
    setOpenAddLedger(false);
  };

  const handleChange = (e) => {
    setNewLedger({ ...newLedger, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    createNewLedger(userId, newLedger, resetForm);
  };

  return (
    <MinDialog isOpen={openAddLedger}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter ledger details</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Ledger Group</label>
          <select
            name="ledger_group"
            onChange={handleChange}
            value={ledger_group}
          >
            <option value="" selected disabled>
              Select ledger group
            </option>
            {ledger_groups?.map((item) => (
              <option value={item?.id} key={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "42%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Ledger Name</label>
          <input type="text" name="name" onChange={handleChange} value={name} />
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    ledger_groups: state.finance?.ledger_groups?.filter(
      (group) => group?.is_stock_item === false
    ),
    userId: state.auth.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createNewLedger: (userId, newLedger, resetForm) =>
      dispatch(create_new_ledger(userId, newLedger, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLedger);
