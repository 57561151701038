import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import { START_LOADING } from "../../../../redux/actions/types";
import { get_users } from "../../../../redux/actions/auth";

import AddStudent from "./components/AddStudent";
import EditStudent from "./components/EditStudent";
import StudentServices from "./components/StudentServices";
import { admin_get_class_streams } from "../../../../redux/actions/course";
import { get_active_dorms } from "../../../../redux/actions/accomodation";

const Students = (props) => {
  const { loading, students, userId, class_streams } = props;
  const { startLoading, getStudents, adminGetClassStreams, getActiveDorms } =
    props;

  const [openAddStudent, setOpenAddStudent] = useState(false);
  const [openEditStudent, setOpenEditStudent] = useState(false);
  const [currentStudent, setCurrentStudent] = useState({});
  const [openStudentServices, setOpenStudentServices] = useState(false);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filterBy, setFilterBy] = useState("");

  useEffect(() => {
    if (userId) {
      startLoading();
      getStudents(userId, "Student");
    }
  }, [getStudents, userId, startLoading]);

  useEffect(() => {
    setFilteredStudents(students);
  }, [students]);

  useEffect(() => {
    if (userId) {
      startLoading();
      adminGetClassStreams(userId);
    }
  }, [adminGetClassStreams, userId, startLoading]);

  useEffect(() => {
    if (userId) {
      startLoading();
      getActiveDorms(userId);
    }
  }, [getActiveDorms, startLoading, userId]);

  const handleOpenEditStudent = (student) => {
    setCurrentStudent(student);
    setOpenEditStudent(true);
  };

  const handleOpenStudentServices = (student) => {
    setCurrentStudent(student);
    setOpenStudentServices(true);
  };

  const handleFIlterByChange = (e) => {
    // if all students
    if (e.target.value === "all") {
      setFilteredStudents(students);
    }
    setFilterBy(e.target.value);
  };
  const handleOnClassStreamChange = (e) => {
    // if all streams
    if (e.target.value === "all") {
      setFilteredStudents(students);
    } else {
      setFilteredStudents(
        students?.filter((student) => student?.class_stream === e.target.value)
      );
    }
  };

  const handleOnAdmissionNumberChange = (e) => {
    if (e.target.value?.trim() !== "") {
      setFilteredStudents(
        students?.filter((student) =>
          student?.admission_number
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase())
        )
      );
    } else {
      setFilteredStudents(students);
    }
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__parentHeader">
          <button
            type="button"
            className="add__button white"
            onClick={() => setOpenAddStudent(true)}
          >
            Add Student
          </button>
          <h3 className="dodgerblue bd ud">Maintain students</h3>
          <div
            class="dialog__row"
            style={{ maxWidth: "800px", margin: "auto" }}
          >
            <span>
              <label for="">Select option to filter students</label>
              <select name="" onChange={handleFIlterByChange}>
                <option value="all">--all students--</option>
                <option value="admission">Filter by Admission</option>
                <option value="class_stream">Filter by Class Stream</option>
              </select>
            </span>
            {filterBy?.trim() === "class_stream" && (
              <span>
                <label for="">Filter students by class stream</label>
                <select name="" onChange={handleOnClassStreamChange}>
                  <option value="all">--all streams--</option>
                  {class_streams?.map((stream) => (
                    <option value={stream?.name} key={stream?.id}>
                      {stream?.name}
                    </option>
                  ))}
                </select>
              </span>
            )}
            {filterBy?.trim() === "admission" && (
              <span>
                <label for="">Search by admission</label>
                <input type="text" onChange={handleOnAdmissionNumberChange} />
              </span>
            )}
          </div>
        </div>
        <br />
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}

        <table className="table__listing">
          {filteredStudents?.length > 0 ? (
            <>
              <tr className="table__listingHeader">
                <th>Admission Number</th>
                <th>Name</th>
                <th>Class Stream</th>
                <th>Login Status</th>
                <th>Edit</th>
                <th>Services</th>
              </tr>
              {filteredStudents?.map((student) => (
                <tr className="table__listingItem" key={student?.id}>
                  <td>{student?.admission_number}</td>
                  <td>
                    {student?.first_name} {student?.last_name}
                  </td>
                  <td>{student?.class_stream}</td>
                  <td>
                    {student?.is_suspended ? (
                      <span className="red bd">Disabled</span>
                    ) : (
                      <span className="green bd">Enabled</span>
                    )}
                  </td>
                  <td
                    className="green bd button__sp"
                    onClick={() => handleOpenEditStudent(student)}
                  >
                    Edit
                  </td>
                  <td
                    class="dodgerblue bd button__sp"
                    onClick={() => handleOpenStudentServices(student)}
                  >
                    View
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <h4 className="not__available">No students available</h4>
          )}
        </table>
      </div>

      {openAddStudent && (
        <AddStudent
          openAddStudent={openAddStudent}
          setOpenAddStudent={setOpenAddStudent}
        />
      )}
      {openEditStudent && (
        <EditStudent
          openEditStudent={openEditStudent}
          setOpenEditStudent={setOpenEditStudent}
          currentStudent={currentStudent}
          setCurrentStudent={setCurrentStudent}
        />
      )}
      {openStudentServices && (
        <StudentServices
          openStudentServices={openStudentServices}
          setOpenStudentServices={setOpenStudentServices}
          currentStudent={currentStudent}
          setCurrentStudent={setCurrentStudent}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    students: state?.auth?.students,
    userId: state.auth?.user?.id,
    class_streams: state.course?.class_streams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminGetClassStreams: (userId) => dispatch(admin_get_class_streams(userId)),
    getStudents: (userId, userType) => dispatch(get_users(userId, userType)),
    startLoading: () => dispatch({ type: START_LOADING }),
    getActiveDorms: (userId) => dispatch(get_active_dorms(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Students);
