import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { START_LOADING } from "../../../redux/actions/types";
import parse from "html-react-parser";

import { student_submit_exam } from "../../../redux/actions/exam";
import CircularProgress from "@mui/material/CircularProgress";

import "./ExamPageContent.scss";

const ExamPageContent = (props) => {
  const navigate = useNavigate();
  const { userId, loading, examId, examDetails, studentAnswers } = props;
  const { startLoading, studentSubmitExam } = props;

  const { questions } = examDetails;

  // function for multiple answer questions
  const userMultipleExamChoices = (e, currentQuestionId) => {
    if (
      studentAnswers?.find(
        (question_data) => question_data.id === currentQuestionId
      )
    ) {
      const question = studentAnswers?.find(
        (question_data) => question_data.id === currentQuestionId
      );
      const answer_index = question.answersChosen?.findIndex(
        (answer) => answer === e.target.value
      );
      if (e.target.checked) {
        question.answersChosen?.push(e.target.value);
      } else if (!e.target.checked) {
        question.answersChosen?.splice(answer_index, 1);
      }
    }
  };

  // function for single answer questions
  const userExamChoices = (e, currentQuestionId) => {
    //if a choice exists for given question, replace with new choice
    if (
      studentAnswers?.find(
        (question_data) => question_data.id === currentQuestionId
      )
    ) {
      // get the current question
      const question = studentAnswers?.find(
        (question_data) => question_data.id === currentQuestionId
      );
      // remove the existing answer to allow selection of new answer
      question.answersChosen = [e.target.value];
      // subsection.answersChosen?.push(e.target.value);
    }
  };
  // function to check if a student had selected a given answer
  // function used when student is resuming an exam, or after an interim save and page reload
  // const checkAnswer = (currentQuestion, option) => {
  //   try {
  //     const filteredAnswer = studentAnswers?.find(
  //       (question) => question.id === currentQuestion.id
  //     );
  //     // for single answer questions
  //     if (
  //       !currentQuestion.has_multiple_answers &&
  //       option === filteredAnswer.answersChosen
  //     ) {
  //       return true;
  //     }
  //     // for multiple answer questions
  //     else if (currentQuestion.has_multiple_answers) {
  //       if (
  //         filteredAnswer.answersChosen?.find(
  //           (interimAnswer) => interimAnswer === option
  //         )
  //       ) {
  //         // if you find a match return true, else false
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     } else {
  //       return false;
  //     }
  //   } catch (err) {
  //     return false;
  //   }
  // };

  const submitExam = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Ensure you have completed all the questions. Click ok to submit exam"
      )
    ) {
      startLoading();
      studentSubmitExam(
        userId,
        examId,
        { studentAnswers, studentExamId: examDetails?.studentExamId },
        navigate
      );
    }
  };

  return (
    <form className="exam__content" id={loading ? "pageSubmitting" : ""}>
      {questions?.length > 0 ? (
        <>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {questions?.map((question, index) => (
            <>
              <h4 className="question__number">
                Question {index + 1}{" "}
                <span className="question__marks">
                  ({question.marks} marks)
                </span>
              </h4>
              <p className="question__title">{parse(question?.description)}</p>
              <h4 className="question__options">Options</h4>
              {question.has_multiple_answers && (
                <>
                  {question?.answerOptions?.map((option, index) => (
                    <p key={index} className="question__singleOption">
                      <input
                        type="checkbox"
                        value={option}
                        name={option}
                        onClick={(e) =>
                          userMultipleExamChoices(e, question?.id)
                        }
                        // checked={checkAnswer(question, option)} // for exam save progress, currently not working
                      />
                      <label htmlFor="">{option}</label>
                    </p>
                  ))}
                </>
              )}
              {!question.has_multiple_answers && (
                <>
                  {question?.answerOptions?.map((option, index) => (
                    <p key={index} className="question__singleOption">
                      <input
                        type="radio"
                        value={option}
                        id={option}
                        name={question?.id}
                        // checked={checkAnswer(question, option)} // for exam save progress, currently not working
                        onClick={(e) => userExamChoices(e, question?.id)}
                      />
                      <label htmlFor={option}>{option}</label>
                    </p>
                  ))}
                </>
              )}
            </>
          ))}
          <div className="submit__button">
            <button type="submit" onClick={submitExam}>
              Submit Exam
            </button>
          </div>
        </>
      ) : (
        <h4 className="not__available">No questions in this exam</h4>
      )}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    studentSubmitExam: (userId, examId, body, navigate) =>
      dispatch(student_submit_exam(userId, examId, body, navigate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamPageContent);
