import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { START_LOADING } from "../../../../redux/actions/types";
import { edit_salary_earning } from "../../../../redux/actions/staff";

const EditSalaryEarning = (props) => {
  const { loading, userId, currentSalaryEarning, openEditSalaryEarning } =
    props;
  const {
    startLoading,
    setCurrentSalaryEarning,
    setOpenEditSalaryEarning,
    editSalaryEarning,
  } = props;

  const {
    name,
    amount,
    earning_type,
    is_active,
    taxable_status,
    taxable_percent,
  } = currentSalaryEarning;

  const handleChange = (e) => {
    setCurrentSalaryEarning({
      ...currentSalaryEarning,
      [e.target.name]: e.target.value,
    });
  };

  const handleIsActiveChange = (e) => {
    setCurrentSalaryEarning({
      ...currentSalaryEarning,
      is_active: e.target.checked,
    });
  };

  const handleClose = () => {
    setCurrentSalaryEarning({});
    setOpenEditSalaryEarning(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      name?.trim() === "" ||
      earning_type?.trim() === "" ||
      earning_type === null ||
      taxable_status?.trim() === "" ||
      taxable_status === null
    ) {
      return window.alert("Fields marked with * are required");
    }
    if (earning_type === "fixed" && (amount === "" || amount === null)) {
      return window.alert("Fixed earnings must have an amount");
    }
    // we confirm if taxable and verify taxable percent
    if (taxable_status === "taxable") {
      if (taxable_percent === "" || taxable_percent === null) {
        return window.alert("Fields marked with * are required");
      }
      if (taxable_percent < 0 || taxable_percent > 100) {
        return window.alert("Amount taxable must be between 0% and 100%");
      }
    }
    startLoading();
    editSalaryEarning(userId, currentSalaryEarning);
  };

  return (
    <MinDialog isOpen={openEditSalaryEarning}>
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Edit earning type details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Earnings Name</label>
          <input type="text" name="name" onChange={handleChange} value={name} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Earning Type</label>
          <select
            name="earning_type"
            onChange={handleChange}
            value={earning_type}
          >
            <option value="">--select type--</option>
            <option value="fixed">Fixed</option>
            <option value="variable">Variable (set at staff level)</option>
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Amount</label>
          <input
            type="number"
            name="amount"
            onChange={handleChange}
            value={amount}
            disabled={earning_type !== "fixed"}
          />
        </div>
        <div class="dialog__rowCheckbox">
          <input
            type="checkbox"
            name=""
            checked={is_active}
            onChange={handleIsActiveChange}
          />
          <label for="">Tick this to make the earning active</label>
        </div>
        <br />
        <div class="dialog__rowSingleItem">
          <label for="" style={{ color: "red", textDecoration: "underline" }}>
            For tax computation purposes
          </label>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Taxable Status*</label>
          <select
            name="taxable_status"
            onChange={handleChange}
            value={taxable_status}
          >
            <option value="">--select taxable status--</option>
            <option value="taxable">Taxable</option>
            <option value="non_taxable">Non Taxable</option>
          </select>
        </div>
        {/* for taxable we show field for amount taxable */}
        {taxable_status === "taxable" && (
          <>
            <div class="dialog__rowSingleItem">
              <label for="">Amount Taxable* (0-100%)</label>
              <input
                type="number"
                name="taxable_percent"
                onChange={handleChange}
                value={taxable_percent}
                max="100"
                min="0"
              />
            </div>
          </>
        )}
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editSalaryEarning: (userId, body) =>
      dispatch(edit_salary_earning(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSalaryEarning);
