import React from "react";
import { connect, useDispatch } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import {
  START_LOADING,
  STOP_LOADING,
  UPDATE_SUBJECT,
} from "../../../../../redux/actions/types";
import { ifEmpty } from "../../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../../shared/axios";
import { showError } from "../../../../../redux/actions/shared";

const EditSubjectGrade = (props) => {
  const dispatch = useDispatch();
  const {
    openEditSubjectGrade,
    currentSubjectGrade,
    loading,
    userId,
    currentSubject,
  } = props;
  const {
    setOpenEditSubjectGrade,
    setCurrentSubjectGrade,
    startLoading,
    stopLoading,
    setCurrentSubject,
  } = props;

  const { start_marks, end_marks, points, grade } = currentSubjectGrade;

  const handleChange = (e) => {
    setCurrentSubjectGrade({
      ...currentSubjectGrade,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Number(end_marks) < Number(start_marks)) {
      return window.alert("End marks must be greater than start marks");
    }
    if (ifEmpty(currentSubjectGrade)) {
      return window.alert("Fill in all fields");
    }
    const body = {
      ...currentSubjectGrade,
      grade: grade?.toUpperCase(),
    };
    startLoading();
    await API.patch(`/api/course/maintain-subject-grade/${userId}/`, body)
      .then((res) => {
        dispatch({
          type: UPDATE_SUBJECT,
          payload: res?.data?.updated_subject,
        });
        setCurrentSubject(res?.data?.updated_subject);
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MediumDialog isOpen={openEditSubjectGrade} maxWidth="600px">
      <form
        class="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>Edit Grade for {currentSubject?.name}</h3>
        <div class="dialog__row">
          <span>
            <label for="">Start Marks</label>
            <input
              type="number"
              name="start_marks"
              min="0"
              max="100"
              onChange={handleChange}
              value={start_marks}
              required
            />
          </span>
          <span>
            <label for="">End Marks</label>
            <input
              type="number"
              name="end_marks"
              min="0"
              max="100"
              onChange={handleChange}
              value={end_marks}
              required
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__row">
          <span>
            <label for="">Points (0 - 12)</label>
            <input
              type="number"
              name="points"
              min="0"
              max="12"
              value={points}
              onChange={handleChange}
              required
            />
          </span>
          <span>
            <label for="">Grade</label>
            <input
              type="text"
              name="grade"
              value={grade}
              onChange={handleChange}
              required
            />
          </span>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditSubjectGrade(false)}>
            Close
          </button>
          <button type="submit">Update</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSubjectGrade);
