// import installed packages
import { useState } from "react";
import { connect } from "react-redux";

// import styles

// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import { ifEmpty, resetFormValues } from "../../shared/sharedFunctions";
// import components/pages
import MinDialog from "../common/MinDialog";

// import redux API
import {
  CLOSE_LOGIN,
  OPEN_FORGOT_PASSWORD,
  OPEN_RESEND_ACTIVATION,
  OPEN_SIGNUP,
  START_LOADING,
} from "../../redux/actions/types";
import { login } from "../../redux/actions/auth";

const Login = (props) => {
  const { loading, loginForm } = props; // extract state from props
  const {
    startLoading,
    loginUser,
    closeLogin,
    openPasswordReset,
    openResendActivation,
  } = props; // extract dispatch action from props
  const [loginData, setLoginData] = useState({
    loginType: "",
    email: "",
    password: "",
  });

  // destructuring
  const { email, password, loginType } = loginData;

  // reset form values
  const resetForm = () => {
    resetFormValues(loginData);
  };

  // function to close login form
  const closeLoginForm = () => {
    closeLogin();
    resetForm();
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (ifEmpty(loginData)) {
      return window.alert("Email and password required");
    }
    startLoading();
    // call the signup action creator
    loginUser(loginData, resetForm);
  };

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  // open password reset form
  const openPasswordResetForm = () => {
    openPasswordReset();
    closeLogin();
  };

  // open resend activation
  const openResendActivationForm = () => {
    openResendActivation();
    closeLogin();
  };

  return (
    <MinDialog isOpen={loginForm}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Login here</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Login As</label>
          <select name="loginType" onChange={handleChange} value={loginType}>
            <option value="" selected disabled>
              Login as...
            </option>
            <option value="student">Student</option>
            <option value="parent">Parent</option>
            <option value="staff">Staff</option>
            <option value="teacher">Teacher</option>
          </select>
        </div>
        {loginType !== "" && (
          <>
            {loginType === "student" ? (
              <div className="dialog__rowSingleItem">
                <label htmlFor="">Admission number</label>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={email}
                />
              </div>
            ) : (
              <div className="dialog__rowSingleItem">
                <label htmlFor="">Email</label>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={email}
                />
              </div>
            )}

            {loading && (
              <CircularProgress
                style={{ position: "absolute", marginLeft: "40%" }}
              />
            )}
            <div className="dialog__rowSingleItem">
              <label htmlFor="">Password</label>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                value={password}
              />
            </div>
          </>
        )}
        <div className="form__Buttons">
          <button type="button" onClick={closeLoginForm}>
            Close
          </button>
          <button type="submit" onClick={handleLogin}>
            Login
          </button>
        </div>

        <div className="extra__formButtons">
          <label htmlFor="" className="button" onClick={openPasswordResetForm}>
            Forgot Password
          </label>
          <label
            htmlFor=""
            className="button"
            onClick={openResendActivationForm}
          >
            Resend Activation
          </label>
        </div>
        <div className="extra__formButtons">
          {/* <label
              htmlFor=""
              className="button"
              style={{ justifySelf: "end" }}
              onClick={openSignupForm}
            >
              Create Account
            </label> */}
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    loginForm: state.auth.loginForm,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    loginUser: (loginData, resetForm) => dispatch(login(loginData, resetForm)),
    closeLogin: () => dispatch({ type: CLOSE_LOGIN }),
    openPasswordReset: () => dispatch({ type: OPEN_FORGOT_PASSWORD }),
    openSignup: () => dispatch({ type: OPEN_SIGNUP }),
    openResendActivation: () => dispatch({ type: OPEN_RESEND_ACTIVATION }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
