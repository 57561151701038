import React from "react";
import { connect } from "react-redux";
import { START_LOADING } from "../../../../../redux/actions/types";
import MinDialog from "../../../../../components/common/MinDialog";
import { edit_subject } from "../../../../../redux/actions/course";
import CircularProgress from "@mui/material/CircularProgress";

const EditSubject = (props) => {
  const { openEditSubject, currentSubject, userId, loading, subject_groups } =
    props;
  const { setOpenEditSubject, setCurrentSubject, editSubject, startLoading } =
    props;

  const { name, short_name, subject_group } = currentSubject;

  const handleChange = (e) => {
    setCurrentSubject({ ...currentSubject, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    const body = {
      ...currentSubject,
    };
    editSubject(userId, body);
  };

  return (
    <MinDialog isOpen={openEditSubject}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit subject details</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Subject Name</label>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={name}
            required
          />
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Short Name</label>
          <input
            type="text"
            name="short_name"
            onChange={handleChange}
            value={short_name}
            required
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Subject Group</label>
          <select
            name="subject_group"
            onChange={handleChange}
            value={subject_group}
            required
          >
            <option value="">--select--</option>
            {subject_groups?.map((group) => (
              <option value={group?.id} key={group?.id}>
                {group?.name}
              </option>
            ))}
          </select>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditSubject(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    subject_groups: state?.course?.subject_groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editSubject: (userId, body) => dispatch(edit_subject(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSubject);
