import React, { useState } from "react";
import MinDialog from "../../../components/common/MinDialog";
import API from "../../../shared/axios";
import { showError } from "../../../redux/actions/shared";
import { useDispatch, useSelector } from "react-redux";
import { get_user } from "../../../redux/actions/auth";
import { CircularProgress } from "@mui/material";
import { TOGGLE_LOADING } from "../../../redux/actions/types";

const AccessDemo = (props) => {
  const [loginAsUser, setLoginAsUser] = useState("");

  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.shared?.loading);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: TOGGLE_LOADING, payload: true });
    await API.post("/api/user/access-demo/", { loginAsUser })
      .then((res) => {
        localStorage.setItem("session_cookie", res.data?.access);
        dispatch(get_user());
        props?.setOpenAccessDemo(false);
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => {
        dispatch({ type: TOGGLE_LOADING, payload: false });
      });
  };

  return (
    <MinDialog isOpen={props?.openAccessDemo}>
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading && "formSubmitting"}
      >
        <h3>Select user type to access demo</h3>
        <br />
        {/* choose to login as school admin, parent, librarian, accountant */}
        <div className="dialog__rowSingleItem">
          <select
            name=""
            onChange={(e) => setLoginAsUser(e.target.value)}
            value={loginAsUser}
            required
          >
            <option value="" disabled>
              --select--
            </option>
            <option value="Accountant">Accountant</option>
            <option value="Accomodation">Accomodation</option>
            <option value="Human Resource">Human Resource</option>
            <option value="Librarian">Librarian</option>
            <option value="Parent">Parent</option>
            <option value="School Admin">School Admin</option>
            <option value="Store">Store</option>
            <option value="Teacher">Teacher</option>
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div className="form__Buttons">
          <button type="button" onClick={() => props.setOpenAccessDemo(false)}>
            Close
          </button>
          <button>Continue</button>
        </div>
      </form>
    </MinDialog>
  );
};

export default AccessDemo;
