import React, { useEffect, useState } from "react";

import "./ExamPageHeader.scss";

const ExamPageHeader = (props) => {
  const { examTitle, examRemainingTime, examSubject } = props;

  const [timerHours, setTimerHours] = useState(0);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);

  useEffect(() => {
    let exam_time = examRemainingTime * 60 * 1000;
    if (exam_time > 0) {
      const timer = setInterval(() => {
        const hours = Math.floor(exam_time / (1000 * 60 * 60));
        const minutes = Math.floor(
          (exam_time % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((exam_time % (1000 * 60)) / 1000);
        // update the timer
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);

        exam_time -= 1000;
        localStorage.setItem("sessionTime", exam_time);
      }, 1000);
      return () => {
        clearInterval(timer);
        localStorage.removeItem("sessionTime");
      };
    }
  }, [examRemainingTime]);

  return (
    <div className="exam__pageHeader">
      <h3>
        {examSubject} - {examTitle}
      </h3>
      <p>
        Time: {timerHours}:{timerMinutes}:{timerSeconds}
      </p>
    </div>
  );
};

export default ExamPageHeader;
