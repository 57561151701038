import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { create_tax_relief } from "../../../../redux/actions/staff";
import { START_LOADING } from "../../../../redux/actions/types";
import { ifEmpty } from "../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const AddTaxRelief = (props) => {
  const { openAddTaxRelief, loading, userId, salaryDeductions } = props;
  const { setOpenAddTaxRelief, startLoading, createTaxRelief } = props;

  const [taxReliefDetails, setTaxReliefDetails] = useState({
    name: "",
    relief_type: "",
    max_relief_amount: 0,
    relief_amount: 0,
    relief_percent: 0,
  });
  const {
    name,
    relief_type,
    max_relief_amount,
    relief_amount,
    relief_percent,
  } = taxReliefDetails;
  const [associatedDeductions, setAssociatedDeductions] = useState([]);

  const handleChange = (e) => {
    setTaxReliefDetails({
      ...taxReliefDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddDeduction = (e) => {
    // only add adeduction that has not been added yet
    if (
      !associatedDeductions?.find(
        (deduction) => deduction?.id === e.target.value
      )
    ) {
      setAssociatedDeductions([
        ...associatedDeductions,
        salaryDeductions.find((item) => item?.id === e.target.value),
      ]);
    }
  };

  const handleRemoveDeduction = (deductionId) => {
    setAssociatedDeductions(
      associatedDeductions?.filter((deduction) => deduction?.id !== deductionId)
    );
  };

  const resetForm = () => {
    setTaxReliefDetails({
      name: "",
      relief_type: "",
      max_relief_amount: 0,
      relief_amount: 0,
      relief_percent: 0,
    });
    setAssociatedDeductions([]);
  };

  const handleClose = () => {
    resetForm();
    setOpenAddTaxRelief(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(taxReliefDetails)) {
      return window.alert("Fields marked with * are required");
    }
    if (associatedDeductions?.length === 0) {
      return window.alert("You must select the associated deductions");
    }
    if (
      relief_type === "fixed" &&
      (relief_amount === 0 || relief_amount === "")
    ) {
      return window.alert("You must enter the relief amount");
    } else if (
      relief_type === "variable" &&
      (relief_percent === 0 || relief_percent === "")
    ) {
      return window.alert("You must indicate the relief percent");
    }
    startLoading();
    const body = {
      ...taxReliefDetails,
      associatedDeductions: associatedDeductions?.map(
        (deduction) => deduction?.id
      ),
    };
    createTaxRelief(userId, body, resetForm);
  };

  return (
    <MinDialog isOpen={openAddTaxRelief}>
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Enter tax relief details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">
            Name<span className="red">*</span>
          </label>
          <input type="text" name="name" onChange={handleChange} value={name} />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">
            Type<span className="red">*</span>
          </label>
          <select
            name="relief_type"
            onChange={handleChange}
            value={relief_type}
          >
            <option value="">--select relief type--</option>
            <option value="fixed">Fixed</option>
            <option value="variable">Variable</option>
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {/* for fixed relief show amount field */}
        {relief_type === "fixed" && (
          <div class="dialog__rowSingleItem">
            <label for="">
              Relief Amount<span className="red">*</span>
            </label>
            <input
              type="number"
              name="relief_amount"
              onChange={handleChange}
              value={relief_amount}
            />
          </div>
        )}
        {relief_type === "variable" && (
          <div class="dialog__rowSingleItem">
            <label for="">
              Relief Percent(%)<span className="red">*</span>
            </label>
            <input
              type="number"
              name="relief_percent"
              onChange={handleChange}
              value={relief_percent}
            />
          </div>
        )}
        <div class="dialog__rowSingleItem">
          <label for="">
            Maximum Relief<span className="red">*</span>
          </label>
          <input
            type="number"
            name="max_relief_amount"
            onChange={handleChange}
            value={max_relief_amount}
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">
            Select Associated Deductions<span className="red">*</span>
          </label>
          <select name="" onChange={handleAddDeduction}>
            <option value="" disabled selected>
              --select--
            </option>
            {salaryDeductions?.map((deduction) => (
              <option value={deduction?.id} key={deduction?.id}>
                {deduction?.name}
              </option>
            ))}
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Associated deductions</label>
          {associatedDeductions?.length > 0 ? (
            <table class="table__listing">
              <thead>
                <tr class="table__listingHeader">
                  <th>Name</th>
                  <th className="tc">Delete</th>
                </tr>
              </thead>
              <tbody>
                {associatedDeductions?.map((deduction) => (
                  <tr class="table__listingItem" key={deduction?.id}>
                    <td>{deduction?.name}</td>
                    <td className="tc">
                      <i
                        className="bx bxs-trash-alt red bd button__sp"
                        onClick={() => handleRemoveDeduction(deduction?.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="not__available">No associated deductions selected</h4>
          )}
        </div>

        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    salaryDeductions: state?.staff?.salaryDeductions?.filter(
      (deduction) =>
        deduction?.is_active === true && deduction?.is_system_item === false
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createTaxRelief: (userId, body, resetForm) =>
      dispatch(create_tax_relief(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTaxRelief);
