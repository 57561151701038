import React, { useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../../components/common/MaxDialog";
import Monday from "./sections/Monday";
import TimeSlots from "./sections/TimeSlots";
import classes from "./sections/TimeTableClassesList";
import API from "../../../../../shared/axios";
import { showError } from "../../../../../redux/actions/shared";

import "./ManualTimeTable.scss";
import {
  START_LOADING,
  STOP_LOADING,
} from "../../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import Thursday from "./sections/Thursday";
import Friday from "./sections/Friday";
import Wednesday from "./sections/Wednesday";
import Tuesday from "./sections/Tuesday";

const AddManualTimeTable = (props) => {
  const { openAddManualTimeTable, userId, loading, timeTables } = props;
  const {
    setOpenAddManualTimeTable,
    startLoading,
    stopLoading,
    setTimeTables,
  } = props;

  const [timeTableClasses, setTimeTableClasses] = useState(classes);
  const [timeTableName, setTimeTableName] = useState("");

  const handleChange = (e) => {
    setTimeTableClasses({
      ...timeTableClasses,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    const body = {
      timeTableName,
      timeTableClasses,
    };
    await API.post(`/api/timetable/maintain-manual-timetable/${userId}/`, body)
      .then((res) => {
        setTimeTables([...timeTables, res?.data?.new_timetable]);
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MaxDialog
      isOpen={openAddManualTimeTable}
      maxWidth="1200px"
      maxHeight="90vh"
    >
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <table className="timetable_template" rules="all" border="1">
          <tr>
            <th colSpan="100%">Funzo Letu</th>
          </tr>
          <tr>
            <th colSpan="8">TimeTable Name</th>
            <th colSpan="8">
              <input
                type="text"
                value={timeTableName}
                onChange={(e) => setTimeTableName(e.target.value)}
              />
            </th>
          </tr>
          <TimeSlots />

          <Monday
            timeTableClasses={timeTableClasses}
            handleChange={handleChange}
          />
          <Tuesday
            timeTableClasses={timeTableClasses}
            handleChange={handleChange}
          />
          <Wednesday
            timeTableClasses={timeTableClasses}
            handleChange={handleChange}
          />
          <Thursday
            timeTableClasses={timeTableClasses}
            handleChange={handleChange}
          />
          <Friday
            timeTableClasses={timeTableClasses}
            handleChange={handleChange}
          />
        </table>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenAddManualTimeTable(false)}
          >
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddManualTimeTable);
