// import installed packages
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
// import styles
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
// import components/pages
// import redux API
import { START_LOADING } from "../../../../redux/actions/types";
import { get_school_terms } from "../../../../redux/actions/course";

import AddTerm from "./components/AddTerm";
import EditTerm from "./components/EditTerm";

const SchoolTerms = (props) => {
  const { loading, school_terms, userId } = props; // get state from props
  const { startLoading, getSchoolTerms } = props; // get dispatch from props
  const [openTermForm, setOpenTermForm] = useState(false);
  const [openEditTerm, setOpenEditTerm] = useState(false);
  const [currentTerm, setCurrentTerm] = useState({});

  // useEffect to get created terms
  useEffect(() => {
    if (userId) {
      startLoading();
      getSchoolTerms(userId);
    }
  }, [getSchoolTerms, userId, startLoading]);

  const handleOpenTermEdit = (term) => {
    setCurrentTerm(term);
    setOpenEditTerm(true);
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__parentHeader">
          <button
            type="button"
            className="add__button white"
            onClick={() => setOpenTermForm(true)}
          >
            Add Term
          </button>
          <h3>Study terms</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "40%" }}
            />
          )}
        </div>
        <table className="table__listing">
          {school_terms?.length > 0 ? (
            <>
              <tbody>
                <tr className="table__listingHeader">
                  <th>No:</th>
                  <th>Name</th>
                  <th>Starting</th>
                  <th>Ending</th>
                  <th>Status</th>
                  <th>Edit</th>
                </tr>
                {school_terms?.map((term, index) => (
                  <tr className="table__listingItem" key={term?.id}>
                    <td>{index + 1}</td>
                    <td>{term?.name}</td>
                    <td>{moment(term?.starting_date).format("LL")}</td>
                    <td>{moment(term?.ending_date).format("LL")}</td>
                    <td>
                      {term?.is_active ? (
                        <span className="green bd">Active</span>
                      ) : (
                        <span className="red bd">Expired</span>
                      )}
                    </td>
                    <td
                      class="dodgerblue bd button__sp"
                      onClick={() => handleOpenTermEdit(term)}
                    >
                      edit
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <h4 className="not__available">No terms available</h4>
          )}
        </table>
      </div>
      {/* child components */}

      {openTermForm && (
        <AddTerm
          openTermForm={openTermForm}
          setOpenTermForm={setOpenTermForm}
        />
      )}
      {openEditTerm && (
        <EditTerm
          openEditTerm={openEditTerm}
          setOpenEditTerm={setOpenEditTerm}
          currentTerm={currentTerm}
          setCurrentTerm={setCurrentTerm}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    school_terms: state.course?.school_terms,
    userId: state.auth.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getSchoolTerms: (userId) => dispatch(get_school_terms(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolTerms);
