import * as actionTypes from "./types";
import { showError, stopLoading } from "./shared";
import * as api from "../../api";

// action for admin to create a new stock item
export const create_stock_item =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createStockItem(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_STOCK_ITEM,
          payload: res?.data?.new_stock_item,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action for admin to edit a stock item
export const edit_stock_item = (userId, body) => async (dispatch) => {
  await api
    .editStockItem(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_STOCK_ITEM,
        payload: res?.data?.updated_stock_item,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action for admin to get all stock items
export const admin_get_stock_items = (userId) => async (dispatch) => {
  await api
    .getStockItems(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_STOCK_ITEMS,
        payload: res?.data?.stock_items_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};
