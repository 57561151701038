import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import QuestionsListing from "./components/QuestionsListing";
import { START_LOADING } from "../../../../redux/actions/types";
import {
  delete_exam,
  get_teacher_exams,
  update_exam,
} from "../../../../redux/actions/exam";
import EditExam from "./components/EditExam";

import AddExam from "./components/AddExam";

const OnlineExams = (props) => {
  const { loading, teacherExams, userId, teacherExamQuestions } = props;
  const { startLoading, getTeacherExams, updateExam, deleteExam } = props;

  const [openAddExam, setOpenAddExam] = useState(false);
  const [openEditExam, setOpenEditExam] = useState(false);
  const [openQuestionsListing, setOpenQuestionsListing] = useState(false);
  const [currentExam, setCurrentExam] = useState({});

  const handleOpenQuestionsListing = (exam) => {
    setCurrentExam(exam);
    setOpenQuestionsListing(true);
  };

  const handleOpenEditExam = (thisExam) => {
    setCurrentExam(thisExam);
    setOpenEditExam(true);
  };

  useEffect(() => {
    if (teacherExams?.length === 0 && userId) {
      startLoading();
      getTeacherExams(userId);
    }
  }, [userId, teacherExams?.length, startLoading, getTeacherExams]);

  const handleExamAction = (examId, actionType) => {
    // if user clicks cancel on prompt the return function will terminate the process
    if (actionType === "activate") {
      if (
        !window.confirm(
          "This will make this exam available to the students to take. Are you sure you want to continue"
        )
      ) {
        return;
      }
    } else if (actionType === "deactivate") {
      if (
        !window.confirm(
          "This will make the exam unavailable for students. Are you sure you want to continue"
        )
      ) {
        return;
      }
    }
    startLoading();
    updateExam(userId, { actionType, id: examId });
  };

  const handleDeleteExam = (examId) => {
    if (
      !window.confirm(
        "Are you sure you want to delete this exam, this process is not reversible"
      )
    ) {
      return;
    }
    startLoading();
    deleteExam(userId, examId);
  };

  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <button
            type="button"
            className="add__button white"
            onClick={() => setOpenAddExam(true)}
          >
            Add Exam
          </button>
          <h3 className="dodgerblue bd ud"> Maintain Exams</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "0%" }}
            />
          )}
        </div>
        <table className="table__listing">
          {teacherExams?.length > 0 ? (
            <>
              <tr className="table__listingHeader">
                <th>Title</th>
                <th>Deadline</th>
                <th>Status</th>
                <th>Subject/ Stream</th>
                <th>Edit</th>
                <th>Questions</th>
                <th>Action</th>
                <th className="tc">Delete</th>
              </tr>
              {teacherExams?.map((exam) => (
                <tr className="table__listingItem" key={exam?.id}>
                  <td>{exam?.title}</td>
                  <td>{moment(exam?.deadline).format("LLL")}</td>
                  <td>{exam?.exam_status}</td>
                  <td>{exam?.class_stream_subject}</td>
                  <td>
                    {exam?.exam_status === "draft" ? (
                      <span
                        onClick={() => handleOpenEditExam(exam)}
                        className="dodgerblue bd button__sp"
                      >
                        edit
                      </span>
                    ) : (
                      <span>n/a</span>
                    )}
                  </td>
                  <td
                    onClick={() => handleOpenQuestionsListing(exam)}
                    className="button__sp dodgerblue bd"
                  >
                    view
                  </td>
                  <td className="button__sp">
                    {exam?.exam_status === "draft" && (
                      <span
                        onClick={() => handleExamAction(exam?.id, "activate")}
                        className="green"
                      >
                        activate
                      </span>
                    )}
                    {exam?.exam_status === "available" && (
                      <span
                        onClick={() => handleExamAction(exam?.id, "deactivate")}
                        className="red"
                      >
                        deactivate
                      </span>
                    )}
                    {exam?.exam_status === "expired" && (
                      <span className="red">expired</span>
                    )}
                  </td>
                  <td className="tc">
                    {exam?.exam_status === "draft" ? (
                      <i
                        className="bx bxs-trash-alt button__sp red"
                        onClick={() => handleDeleteExam(exam?.id)}
                      ></i>
                    ) : (
                      <span>n/a</span>
                    )}
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <h4 className="not__available">No exams set yet</h4>
          )}
        </table>
      </div>
      {/* child components */}

      {openAddExam && (
        <AddExam openAddExam={openAddExam} setOpenAddExam={setOpenAddExam} />
      )}
      {openQuestionsListing && (
        <QuestionsListing
          openQuestionsListing={openQuestionsListing}
          setOpenQuestionsListing={setOpenQuestionsListing}
          currentExamTitle={currentExam?.title}
          currentExamId={currentExam?.id}
          currentExamStatus={currentExam?.exam_status}
          currentExamQuestions={teacherExamQuestions?.filter(
            (question) => question?.exam === currentExam?.id
          )}
        />
      )}
      {openEditExam && (
        <EditExam
          openEditExam={openEditExam}
          setOpenEditExam={setOpenEditExam}
          currentExam={currentExam}
          setCurrentExam={setCurrentExam}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    teacherExams: state.exam?.teacherExams,
    teacherExamQuestions: state.exam?.teacherExamQuestions,
    userId: state.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getTeacherExams: (userId) => dispatch(get_teacher_exams(userId)),
    updateExam: (userId, body) => dispatch(update_exam(userId, body)),
    deleteExam: (userId, examId) => dispatch(delete_exam(userId, examId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineExams);
