import React, { useRef } from "react";
import { connect } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MaxDialog from "../../../../../components/common/MaxDialog";
import {
  START_LOADING,
  STOP_LOADING,
} from "../../../../../redux/actions/types";
import TimeSlots from "./sections/TimeSlots";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../../shared/axios";
import Monday from "./sections/Monday";
import { showError } from "../../../../../redux/actions/shared";
import Tuesday from "./sections/Tuesday";
import Wednesday from "./sections/Wednesday";
import Thursday from "./sections/Thursday";
import Friday from "./sections/Friday";
import { useReactToPrint } from "react-to-print";

const EditManualTimeTable = (props) => {
  const {
    openEditManualTimeTable,
    currentTimeTable,
    loading,
    userId,
    timeTables,
  } = props;
  const {
    setOpenEditManualTimeTable,
    setCurrentTimeTable,
    startLoading,
    stopLoading,
    setTimeTables,
  } = props;

  const { name, timeTableClasses } = currentTimeTable;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: "TimeTable",
  });

  const handleChange = (e) => {
    setCurrentTimeTable({
      ...currentTimeTable,
      timeTableClasses: {
        ...timeTableClasses,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      timeTableName: currentTimeTable?.name,
      timetableId: currentTimeTable?.id,
      timeTableClasses,
    };
    startLoading();
    await API.patch(`/api/timetable/maintain-manual-timetable/${userId}/`, body)
      .then((res) => {
        setTimeTables(
          timeTables?.map((timetable) =>
            timetable?.id === res?.data?.updated_timetable?.id
              ? res?.data?.updated_timetable
              : timetable
          )
        );
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MaxDialog
      isOpen={openEditManualTimeTable}
      maxWidth="1200px"
      maxHeight="90vh"
    >
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <table
          className="timetable_template"
          rules="all"
          border="1"
          ref={printArea}
          id="timetable"
        >
          <tr>
            <th colSpan="100%">Funzo Letu</th>
          </tr>
          <tr>
            <th colSpan="8">TimeTable Name</th>
            <th colSpan="8">
              <input
                type="text"
                value={name}
                onChange={(e) =>
                  setCurrentTimeTable({
                    ...currentTimeTable,
                    name: e.target.value,
                  })
                }
              />
            </th>
          </tr>
          <TimeSlots />

          <Monday
            timeTableClasses={timeTableClasses}
            handleChange={handleChange}
          />
          <Tuesday
            timeTableClasses={timeTableClasses}
            handleChange={handleChange}
          />
          <Wednesday
            timeTableClasses={timeTableClasses}
            handleChange={handleChange}
          />
          <Thursday
            timeTableClasses={timeTableClasses}
            handleChange={handleChange}
          />
          <Friday
            timeTableClasses={timeTableClasses}
            handleChange={handleChange}
          />
        </table>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenEditManualTimeTable(false)}
          >
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Update
          </button>
        </div>
        <div class="form__Buttons">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="timetable"
            filename="TimeTable"
            sheet="timetable"
            buttonText="Export To Excel"
          />
          <button type="button" onClick={handlePrint}>
            Print/Save
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditManualTimeTable);
