import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { student_get_exams } from "../../../redux/actions/exam";
import { START_LOADING } from "../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

const StudentExams = (props) => {
  const { userId, studentExams, loading } = props;
  const { startLoading, studentGetExams } = props;

  useEffect(() => {
    if (userId) {
      startLoading();
      studentGetExams(userId);
    }
  }, [startLoading, studentGetExams, userId]);

  return (
    <div className="table__parent">
      <div className="table__parentHeader">
        <h3>Upcoming exams</h3>
      </div>
      {loading && (
        <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />
      )}
      {studentExams?.length > 0 ? (
        <table className="table__listing">
          <tr className="table__listingHeader">
            <th>Subject</th>
            <th>Title</th>
            <th>Deadline</th>
            <th>Duration (minutes)</th>
            <th>Action</th>
          </tr>
          {studentExams?.map((exam) => (
            <tr className="table__listingItem" key={exam?.id}>
              <td>{exam?.subject}</td>
              <td>{exam?.title}</td>
              <td>{moment(exam?.deadline).format("LLL")}</td>
              <td>{exam?.duration}</td>
              <td>
                <Link
                  to={`/student/online-exam/${exam?.id}/`}
                  className="dodgerblue bd button__sp"
                >
                  Start Exam
                </Link>
              </td>
            </tr>
          ))}
        </table>
      ) : (
        <h4 className="not__available">No upcoming exams</h4>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    studentExams: state?.exam?.studentExams,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    studentGetExams: (userId) => dispatch(student_get_exams(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentExams);
