// import installed packages
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const ParentLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to="/parent/reports/"
        className={`${pathname}` === "" ? "nav__link active" : "nav__link"}
      >
        <i className="bx bxs-report"></i>
        <span className="nav__name">Reports</span>
      </Link>
    </>
  );
};

export default ParentLinks;
