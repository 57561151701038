// import installed packages
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const AccountantLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to="#"
        className="nav__link"
        onClick={() => toggleSubmenu("Finance")}
      >
        <i className="bx bxs-wallet-alt"></i>
        <span className="nav__name">Finance</span>
      </Link>
      {openSubMenu === "Finance" && (
        <>
          <Link
            to="/finance/school-fees/"
            className={
              `${pathname}` === "/finance/school-fees/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Fees</span>
          </Link>
          <Link
            to="/finance/accounting/"
            className={
              `${pathname}` === "/finance/accounting/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Accounting</span>
          </Link>
          <Link
            to="/finance/reports/"
            className={
              `${pathname}` === "/finance/reports/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Reports</span>
          </Link>
          <Link
            to="/finance/transactions/"
            className={
              `${pathname}` === "/finance/transactions/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Transactions</span>
          </Link>
        </>
      )}
    </>
  );
};

export default AccountantLinks;
