import React, { useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MaxDialog from "../../../../../components/common/MaxDialog";
import ReportHeader from "../../../../../shared/ReportHeader/ReportHeader";
import { formatWithCommas } from "../../../../../shared/sharedFunctions";

const StaffLedgerStatement = (props) => {
  const { openReportTemplate, start_date, reportData, currentStaff, end_date } =
    props;
  const { setOpenReportTemplate } = props;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: `${currentStaff?.name} Staff Ledger Statement`,
  });

  return (
    <MaxDialog isOpen={openReportTemplate} maxWidth="1200px" maxHeight="85vh">
      <div class="dialog">
        <table
          ref={printArea}
          className="report__statement"
          border="1"
          rules="rows"
          id="staff_ledger_statement"
        >
          <ReportHeader />
          <tbody>
            <tr>
              <td className="report__title" colSpan="100%">
                {currentStaff?.name} staff ledger statement between{" "}
                {moment(start_date).format("LL")} and{" "}
                {moment(end_date).format("LL")}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th className="bbottom purple tl">Date</th>
              <th className="bbottom purple tl">Memo</th>
              <th className="bbottom purple">Debit</th>
              <th className="bbottom purple">Credit</th>
            </tr>
          </tbody>

          <tbody>
            <tr>
              <th>{moment(start_date).format("LL")}</th>
              <th>Opening Balance</th>
              <th>
                {reportData?.opening_balance_obj[0] === "Debit" &&
                  formatWithCommas(reportData?.opening_balance_obj[1], 2)}
              </th>
              <th>
                {reportData?.opening_balance_obj[0] === "Credit" &&
                  formatWithCommas(reportData?.opening_balance_obj[1], 2)}
              </th>
            </tr>
            {reportData?.transactions_data?.map((item) => (
              <tr>
                <td>{moment(item?.transaction_date).format("LL")}</td>
                <td>{item?.details}</td>
                <td>
                  {item?.entry_type === "Debit" &&
                    formatWithCommas(item?.amount, 2)}
                </td>
                <td>
                  {item?.entry_type === "Credit" &&
                    formatWithCommas(item?.amount, 2)}
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
            </tr>
            <tr>
              <th>{moment(end_date).format("LL")}</th>
              <th>Closing Balance</th>
              <th>
                {reportData?.closing_balance_obj[0] === "Debit" &&
                  formatWithCommas(reportData?.closing_balance_obj[1], 2)}
              </th>
              <th>
                {reportData?.closing_balance_obj[0] === "Credit" &&
                  formatWithCommas(reportData?.closing_balance_obj[1], 2)}
              </th>
            </tr>
          </tbody>
        </table>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenReportTemplate(false)}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print/Save
          </button>
        </div>
        <div class="export_to_excel_button">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="staff_ledger_statement"
            filename="staff_ledger_statement"
            sheet="staff_ledger_statement"
            buttonText="Export To Excel"
          />
        </div>
      </div>
    </MaxDialog>
  );
};

export default StaffLedgerStatement;
