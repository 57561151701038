import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../components/common/MinDialog";
import { edit_dorm } from "../../../redux/actions/accomodation";
import { START_LOADING } from "../../../redux/actions/types";
import { ifEmpty } from "../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const EditDorm = (props) => {
  const { currentDorm, openEditDorm, userId, loading } = props;
  const { setOpenEditDorm, setCurrentDorm, editDorm, startLoading } = props;

  const { name, capacity } = currentDorm;

  const handleChange = (e) => {
    setCurrentDorm({ ...currentDorm, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(currentDorm)) {
      return window.alert("All fields are required");
    }
    startLoading();

    editDorm(userId, {
      ...currentDorm,
    });
  };

  return (
    <MinDialog isOpen={openEditDorm}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit dorm details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Dorm Name</label>
          <input type="text" onChange={handleChange} name="name" value={name} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Capacity</label>
          <input
            type="number"
            name="capacity"
            onChange={handleChange}
            value={capacity}
          />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditDorm(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editDorm: (userId, body) => dispatch(edit_dorm(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDorm);
