import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import MediumDialog from "../../../../components/common/MediumDialog";
import {
  create_live_class,
  get_teacher_subjects,
} from "../../../../redux/actions/course";
import { START_LOADING } from "../../../../redux/actions/types";
import { ifEmpty, resetFormValues } from "../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const AddLiveClass = (props) => {
  const { openAddLiveClass, userId, teacher_subjects, loading } = props;
  const {
    setOpenAddLiveClass,
    getTeacherSubjects,
    createLiveClass,
    startLoading,
  } = props;

  const [liveClassDetails, setLiveClassDetails] = useState({
    teacher_subject: "",
    topic: "",
    start_date: "",
    start_time: "",
    duration: "",
  });

  const { teacher_subject, topic, start_date, start_time, duration } =
    liveClassDetails;

  useEffect(() => {
    if (teacher_subjects?.length === 0 && userId) {
      startLoading();
      getTeacherSubjects(userId);
    }
  }, [startLoading, getTeacherSubjects, teacher_subjects?.length, userId]);

  const resetForm = () => {
    resetFormValues(liveClassDetails);
  };

  const handleClose = () => {
    resetForm();
    setOpenAddLiveClass(false);
  };

  const handleChange = (e) => {
    setLiveClassDetails({
      ...liveClassDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(liveClassDetails)) {
      return window.alert("Please fill in all details");
    }
    startLoading();
    const body = {
      ...liveClassDetails,
    };
    createLiveClass(userId, body, resetForm);
  };

  return (
    <MediumDialog isOpen={openAddLiveClass}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter live class details</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Subject &amp; class stream</label>
            <select
              name="teacher_subject"
              onChange={handleChange}
              value={teacher_subject}
            >
              <option value="" disabled selected>
                Select subject..
              </option>
              {teacher_subjects?.map((subject) => (
                <option value={subject?.id} key={subject?.id}>
                  {subject?.subject_stream}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label htmlFor="">Topic</label>
            <input
              type="text"
              name="topic"
              onChange={handleChange}
              value={topic}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <span>
            <label htmlFor="">Start Date</label>
            <input
              type="date"
              name="start_date"
              onChange={handleChange}
              value={start_date}
            />
          </span>
          <span>
            <label for="">Start Time</label>
            <input
              type="time"
              name="start_time"
              onChange={handleChange}
              value={start_time}
            />
          </span>
          <span>
            <label htmlFor="">Duration (Minutes)</label>
            <input
              type="number"
              name="duration"
              onChange={handleChange}
              value={duration}
              min="1"
            />
          </span>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    teacher_subjects: state.course?.teacher_subjects,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeacherSubjects: (userId) => dispatch(get_teacher_subjects(userId)),
    createLiveClass: (userId, body, resetForm) =>
      dispatch(create_live_class(userId, body, resetForm)),
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLiveClass);
