import React, { useState } from "react";
import MediumDialog from "../../../../components/common/MediumDialog";
import API from "../../../../shared/axios";
import { connect } from "react-redux";
import "./PAYECalculator.scss";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import { formatWithCommas } from "../../../../shared/sharedFunctions";
import { showError } from "../../../../redux/actions/shared";
import CircularProgress from "@mui/material/CircularProgress";

const PAYECalculator = (props) => {
  const { openPAYECalculator, userId, loading } = props;
  const { setOpenPAYECalculator, startLoading, stopLoading } = props;

  const [calculationDetails, setCalculationDetails] = useState({
    gross_pay: 0,
    nssf: 200,
    nhif: 0,
  });
  const { gross_pay, nssf, nhif } = calculationDetails;
  const [payeDetails, setPayeDetails] = useState({
    taxable_pay: 0,
    gross_salary: 0,
    allowable_nssf: 0,
    gross_paye: 0,
    net_paye: 0,
    personal_relief: 0,
    nhif_relief: 0,
  });
  const {
    gross_paye,
    net_paye,
    personal_relief,
    nhif_relief,
    taxable_pay,
    gross_salary,
    allowable_nssf,
  } = payeDetails;
  const [calculationComplete, setCalculationComplete] = useState(false);

  const handleClose = () => {
    setCalculationDetails({
      gross_pay: 0,
      nssf: 200,
      nhif: 0,
    });
    setOpenPAYECalculator(false);
  };

  const handleChange = (e) => {
    setCalculationDetails({
      ...calculationDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (gross_pay === 0 || gross_pay === "") {
      return window.alert("Gross pay is required");
    }
    startLoading();
    const body = {
      taxable_pay: Number(gross_pay) - Number(nssf),
      gross_pay,
      nssf,
      nhif,
    };
    await API.post(`/api/staff/staff/calculate-paye-amount/${userId}/`, body)
      .then((res) => {
        setPayeDetails(res?.data?.paye_details);
        setCalculationComplete(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MediumDialog isOpen={openPAYECalculator} maxWidth="800px">
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>Simple PAYE Calculator</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__row">
          <span>
            <label for="">Gross Amount</label>
            <input
              type="number"
              name="gross_pay"
              value={gross_pay}
              onChange={handleChange}
              min="1"
            />
          </span>
          <span>
            <label for="">NSSF</label>
            <input
              type="number"
              name="nssf"
              onChange={handleChange}
              value={nssf}
              min="0"
            />
          </span>
          <span>
            <label for="">NHIF</label>
            <input
              type="number"
              name="nhif"
              onChange={handleChange}
              value={nhif}
              min="0"
            />
          </span>
        </div>
        {/* show this section when calculation is complete */}
        {calculationComplete && (
          <div className="paye__results">
            <h4>PAYE Calculation Results</h4>
            <table>
              <tbody>
                <tr>
                  <th>Gross Salary</th>
                  <th>{formatWithCommas(gross_salary)}</th>
                </tr>
                <tr>
                  <th>Less Deductions</th>
                </tr>
                <tr>
                  <td>Nssf:</td>
                  <td>{allowable_nssf}</td>
                </tr>
                <tr>
                  <th>Taxable Pay</th>
                  <th>{formatWithCommas(taxable_pay)}</th>
                </tr>
                <tr>
                  <td>Tax before relief:</td>
                  <td> {formatWithCommas(gross_paye, 2)}</td>
                </tr>
                <tr>
                  <th>Reliefs</th>
                </tr>
                <tr>
                  <td>Personal Relief: </td>
                  <td>({formatWithCommas(personal_relief)})</td>
                </tr>
                <tr>
                  <td>NHIF 15% relief </td>
                  <td>({nhif_relief})</td>
                </tr>
                <tr>
                  <th>PAYE tax: </th>
                  <th>{formatWithCommas(net_paye, 2)}</th>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Calculate</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PAYECalculator);
