import React, { useState } from "react";
import { connect } from "react-redux";

import MediumDialog from "../../../../../components/common/MediumDialog";
import { create_new_user } from "../../../../../redux/actions/auth";
import { START_LOADING } from "../../../../../redux/actions/types";
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const AddParent = (props) => {
  const { openAddParent, userId, loading } = props;
  const { setOpenAddParent, createNewUser, startLoading } = props;
  const [parentDetails, setParentDetails] = useState({
    first_name: "",
    last_name: "",
    surname: "",
    email: "",
    gender: "",
    phone_number: "",
  });

  const { first_name, last_name, surname, email, gender, phone_number } =
    parentDetails;

  const resetForm = () => {
    resetFormValues(parentDetails);
  };

  const handleClose = () => {
    setOpenAddParent(false);
    resetForm();
  };

  const handleChange = (e) => {
    setParentDetails({ ...parentDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(parentDetails)) {
      return window.alert("Please fill in the fields");
    }
    startLoading();
    const body = {
      ...parentDetails,
      userType: "Parent",
    };
    createNewUser(userId, body, resetForm);
  };

  return (
    <MediumDialog isOpen={openAddParent} maxWidth="1100px">
      <form
        className="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Enter parent details</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={first_name}
            />
          </span>
          <span>
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={last_name}
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <span>
            <label htmlFor="">Surname</label>
            <input
              type="text"
              name="surname"
              onChange={handleChange}
              value={surname}
            />
          </span>
          <span>
            <label htmlFor="">Email</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={email}
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Phone Number</label>
            <input
              type="text"
              name="phone_number"
              onChange={handleChange}
              value={phone_number}
            />
          </span>
          <span>
            <label htmlFor="">Gender</label>
            <select name="gender" onChange={handleChange} value={gender}>
              <option value="" selected disabled>
                Select gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </span>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createNewUser: (userId, body, resetForm) =>
      dispatch(create_new_user(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddParent);
