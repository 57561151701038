// import installed packages
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const ReceptionistLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to=""
        className={
          `${pathname}` === ""
            ? "nav__link active sidebar__submenu"
            : "nav__link sidebar__submenu"
        }
      >
        <span className="nav__name">Reports</span>
      </Link>
    </>
  );
};

export default ReceptionistLinks;
