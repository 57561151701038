import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { ifEmpty } from "../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";

const SendSMS = (props) => {
  const {
    openSendSMS,
    parents,
    staff,
    loading,
    userId,
    class_levels,
    class_streams,
  } = props;
  const { setOpenSendSMS, startLoading, stopLoading } = props;

  const [smsDetails, setSmsDetails] = useState({
    userType: "",
    message: "",
  });
  const { userType, message } = smsDetails;
  const [recepient, setRecepient] = useState("");

  const handleChange = (e) => {
    setSmsDetails({ ...smsDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (ifEmpty(smsDetails)) {
      return window.alert("You must indicate user type and message");
    }
    if (
      (userType === "single_parent" ||
        userType === "single_staff" ||
        userType === "class_level_parents" ||
        userType === "class_stream_parents") &&
      recepient?.trim() === ""
    ) {
      return window.alert("You must choose the recepient");
    }
    startLoading();
    const body = {
      recepient,
      ...smsDetails,
    };
    await API.post(`/api/communication/send-bulk-sms/${userId}/`, body)
      .then((res) => {
        window.alert(res?.data?.detail);
        setSmsDetails({
          userType: "",
          message: "",
        });
        setRecepient("");
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openSendSMS}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>SMS Details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Select user type</label>
          <select name="userType" onChange={handleChange} value={userType}>
            <option value="">--select--</option>
            <option value="all_parents">All Parents</option>
            <option value="all_staff">All Staff</option>
            <option value="all_teachers">All Teachers</option>
            <option value="class_level_parents">
              Specific Class Level Parents
            </option>
            <option value="class_stream_parents">
              Specific Class Stream Parents
            </option>
            <option value="single_parent">Specific Single Parent</option>
            <option value="single_staff">Specific Single Staff</option>
          </select>
        </div>
        {/* if class level parents is selected, show this */}
        {userType === "class_level_parents" && (
          <div class="dialog__rowSingleItem">
            <label for="">Select Class Level</label>
            <select
              name="recepient"
              onChange={(e) => setRecepient(e.target.value)}
              value={recepient}
            >
              <option value="">--select--</option>
              {class_levels?.map((class_level) => (
                <option key={class_level?.id} value={class_level?.id}>
                  {class_level.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* if class stream parents is selected show this */}
        {userType === "class_stream_parents" && (
          <div class="dialog__rowSingleItem">
            <label for="">Select Class Stream</label>
            <select
              name="recepient"
              onChange={(e) => setRecepient(e.target.value)}
              value={recepient}
            >
              <option value="">--select--</option>
              {class_streams?.map((class_stream) => (
                <option key={class_stream?.id} value={class_stream?.id}>
                  {class_stream.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* if single parent is selected show this */}
        {userType === "single_parent" && (
          <div class="dialog__rowSingleItem">
            <label for="">Select Recepient</label>
            <select
              name="recepient"
              onChange={(e) => setRecepient(e.target.value)}
              value={recepient}
            >
              <option value="">--select--</option>
              {parents?.map((parent) => (
                <option key={parent?.id} value={parent?.phone_number}>
                  {parent.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {/* if single staff is selected show this */}
        {userType === "single_staff" && (
          <div class="dialog__rowSingleItem">
            <label for="">Select Recepient</label>
            <select
              name="recepient"
              onChange={(e) => setRecepient(e.target.value)}
              value={recepient}
            >
              <option value="">--select--</option>
              {staff?.map((staff_item) => (
                <option key={staff_item?.id} value={staff_item?.phone_number}>
                  {staff_item.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Message</label>
          <textarea name="message" onChange={handleChange} value={message} />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenSendSMS(false)}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Send
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    parents: state?.communication?.parents,
    staff: state?.communication?.staff,
    class_levels: state?.communication?.class_levels,
    class_streams: state?.communication?.class_streams,
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendSMS);
