import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../../components/common/MaxDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { update_exam } from "../../../../../redux/actions/exam";
import { START_LOADING } from "../../../../../redux/actions/types";
import Markdown from "../../../../../shared/Markdown/Markdown";
import { ifEmpty } from "../../../../../shared/sharedFunctions";

const EditExam = (props) => {
  const { openEditExam, currentExam, loading, userId } = props;
  const { setOpenEditExam, setCurrentExam, startLoading, updateExam } = props;

  const {
    title,
    class_stream_subject,
    pass_mark,
    deadline_date,
    deadline_time,
    duration,
  } = currentExam;

  const [instructions, setInstructions] = useState("");

  useEffect(() => {
    setInstructions(currentExam?.instructions);
  }, [currentExam?.instructions]);

  const handleChange = (e) => {
    setCurrentExam({ ...currentExam, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(currentExam) || instructions.trim() === "") {
      return window.alert("Fill in all fields");
    }
    startLoading();
    updateExam(userId, { ...currentExam, actionType: "edit", instructions });
  };

  return (
    <MaxDialog isOpen={openEditExam} maxWidth="1000px" maxHeight="80vh">
      <form
        className="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Edit exam details</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Title</label>
            <input
              type="text"
              name="title"
              onChange={handleChange}
              value={title}
            />
          </span>
          <span>
            <label htmlFor="">Subject &amp; Stream</label>
            <input type="text" value={class_stream_subject} disabled readOnly />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Pass Mark (%)</label>
            <input
              type="number"
              name="pass_mark"
              onChange={handleChange}
              value={pass_mark}
              min="1"
            />
          </span>
          <span>
            <label htmlFor="">Deadline - Date</label>
            <input
              type="date"
              name="deadline_date"
              onChange={handleChange}
              value={deadline_date}
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Deadline - Time</label>
            <input
              type="time"
              name="deadline_time"
              onChange={handleChange}
              value={deadline_time}
            />
          </span>
          <span>
            <label htmlFor="">Duration (minutes)</label>
            <input
              type="number"
              name="duration"
              onChange={handleChange}
              value={duration}
              min="1"
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Exam Instructions</label>
          <Markdown content={instructions} setContent={setInstructions} />
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenEditExam(false)}>
            Close
          </button>
          <button type="submit">Update</button>
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth?.user?.id,
    loading: state.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    updateExam: (userId, body) => dispatch(update_exam(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditExam);
