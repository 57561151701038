// import installed packages
// import styles
// import material ui items
// import shared/global items
// import components/pages
// import redux API

const StoreLinks = ({ Link, pathname, toggleSubmenu, openSubMenu }) => {
  return (
    <>
      <Link
        to="#"
        className={`${pathname}` === "" ? "nav__link active" : "nav__link"}
        onClick={() => toggleSubmenu("Store")}
      >
        <i className="bx bxs-store"></i>
        <span className="nav__name">Store</span>
      </Link>
      {openSubMenu === "Store" && (
        <>
          <Link
            to="/store/stocks/"
            className={
              `${pathname}` === "/store/stocks/"
                ? "nav__link active sidebar__submenu"
                : "nav__link sidebar__submenu"
            }
          >
            <span className="nav__name">&bull; Stocks</span>
          </Link>
        </>
      )}
    </>
  );
};

export default StoreLinks;
