const classes = {
  // MONDAY FORM 1
  mon__1N__8_00__8_40: "",
  mon__1N__8_40__9_20: "",
  mon__1N__9_30__10_10: "",
  mon__1N__10_10__10_50: "",
  mon__1N__11_15__11_55: "",
  mon__1N__11_55__12_35: "",
  mon__1N__2_00__2_40: "",
  mon__1N__2_40__3_20: "",
  mon__1N__3_20__4_00: "",
  mon__1E__8_00__8_40: "",
  mon__1E__8_40__9_20: "",
  mon__1E__9_30__10_10: "",
  mon__1E__10_10__10_50: "",
  mon__1E__11_15__11_55: "",
  mon__1E__11_55__12_35: "",
  mon__1E__2_00__2_40: "",
  mon__1E__2_40__3_20: "",
  mon__1E__3_20__4_00: "",
  mon__1W__8_00__8_40: "",
  mon__1W__8_40__9_20: "",
  mon__1W__9_30__10_10: "",
  mon__1W__10_10__10_50: "",
  mon__1W__11_15__11_55: "",
  mon__1W__11_55__12_35: "",
  mon__1W__2_00__2_40: "",
  mon__1W__2_40__3_20: "",
  mon__1W__3_20__4_00: "",
  mon__1S__8_00__8_40: "",
  mon__1S__8_40__9_20: "",
  mon__1S__9_30__10_10: "",
  mon__1S__10_10__10_50: "",
  mon__1S__11_15__11_55: "",
  mon__1S__11_55__12_35: "",
  mon__1S__2_00__2_40: "",
  mon__1S__2_40__3_20: "",
  mon__1S__3_20__4_00: "",
  // MONDAY FORM 2
  mon__2N__8_00__8_40: "",
  mon__2N__8_40__9_20: "",
  mon__2N__9_30__10_10: "",
  mon__2N__10_10__10_50: "",
  mon__2N__11_15__11_55: "",
  mon__2N__11_55__12_35: "",
  mon__2N__2_00__2_40: "",
  mon__2N__2_40__3_20: "",
  mon__2N__3_20__4_00: "",
  mon__2E__8_00__8_40: "",
  mon__2E__8_40__9_20: "",
  mon__2E__9_30__10_10: "",
  mon__2E__10_10__10_50: "",
  mon__2E__11_15__11_55: "",
  mon__2E__11_55__12_35: "",
  mon__2E__2_00__2_40: "",
  mon__2E__2_40__3_20: "",
  mon__2E__3_20__4_00: "",
  mon__2W__8_00__8_40: "",
  mon__2W__8_40__9_20: "",
  mon__2W__9_30__10_10: "",
  mon__2W__10_10__10_50: "",
  mon__2W__11_15__11_55: "",
  mon__2W__11_55__12_35: "",
  mon__2W__2_00__2_40: "",
  mon__2W__2_40__3_20: "",
  mon__2W__3_20__4_00: "",
  mon__2S__8_00__8_40: "",
  mon__2S__8_40__9_20: "",
  mon__2S__9_30__10_10: "",
  mon__2S__10_10__10_50: "",
  mon__2S__11_15__11_55: "",
  mon__2S__11_55__12_35: "",
  mon__2S__2_00__2_40: "",
  mon__2S__2_40__3_20: "",
  mon__2S__3_20__4_00: "",
  // MONDAY FORM 3
  mon__3N__8_00__8_40: "",
  mon__3N__8_40__9_20: "",
  mon__3N__9_30__10_10: "",
  mon__3N__10_10__10_50: "",
  mon__3N__11_15__11_55: "",
  mon__3N__11_55__12_35: "",
  mon__3N__2_00__2_40: "",
  mon__3N__2_40__3_20: "",
  mon__3N__3_20__4_00: "",
  mon__3E__8_00__8_40: "",
  mon__3E__8_40__9_20: "",
  mon__3E__9_30__10_10: "",
  mon__3E__10_10__10_50: "",
  mon__3E__11_15__11_55: "",
  mon__3E__11_55__12_35: "",
  mon__3E__2_00__2_40: "",
  mon__3E__2_40__3_20: "",
  mon__3E__3_20__4_00: "",
  mon__3W__8_00__8_40: "",
  mon__3W__8_40__9_20: "",
  mon__3W__9_30__10_10: "",
  mon__3W__10_10__10_50: "",
  mon__3W__11_15__11_55: "",
  mon__3W__11_55__12_35: "",
  mon__3W__2_00__2_40: "",
  mon__3W__2_40__3_20: "",
  mon__3W__3_20__4_00: "",
  mon__3S__8_00__8_40: "",
  mon__3S__8_40__9_20: "",
  mon__3S__9_30__10_10: "",
  mon__3S__10_10__10_50: "",
  mon__3S__11_15__11_55: "",
  mon__3S__11_55__12_35: "",
  mon__3S__2_00__2_40: "",
  mon__3S__2_40__3_20: "",
  mon__3S__3_20__4_00: "",
  // MONDAY FORM 4
  mon__4N__8_00__8_40: "",
  mon__4N__8_40__9_20: "",
  mon__4N__9_30__10_10: "",
  mon__4N__10_10__10_50: "",
  mon__4N__11_15__11_55: "",
  mon__4N__11_55__12_35: "",
  mon__4N__2_00__2_40: "",
  mon__4N__2_40__3_20: "",
  mon__4N__3_20__4_00: "",
  mon__4E__8_00__8_40: "",
  mon__4E__8_40__9_20: "",
  mon__4E__9_30__10_10: "",
  mon__4E__10_10__10_50: "",
  mon__4E__11_15__11_55: "",
  mon__4E__11_55__12_35: "",
  mon__4E__2_00__2_40: "",
  mon__4E__2_40__3_20: "",
  mon__4E__3_20__4_00: "",
  mon__4W__8_00__8_40: "",
  mon__4W__8_40__9_20: "",
  mon__4W__9_30__10_10: "",
  mon__4W__10_10__10_50: "",
  mon__4W__11_15__11_55: "",
  mon__4W__11_55__12_35: "",
  mon__4W__2_00__2_40: "",
  mon__4W__2_40__3_20: "",
  mon__4W__3_20__4_00: "",
  mon__4S__8_00__8_40: "",
  mon__4S__8_40__9_20: "",
  mon__4S__9_30__10_10: "",
  mon__4S__10_10__10_50: "",
  mon__4S__11_15__11_55: "",
  mon__4S__11_55__12_35: "",
  mon__4S__2_00__2_40: "",
  mon__4S__2_40__3_20: "",
  mon__4S__3_20__4_00: "",
  // TUESDAY FORM 1
  tue__1N__8_00__8_40: "",
  tue__1N__8_40__9_20: "",
  tue__1N__9_30__10_10: "",
  tue__1N__10_10__10_50: "",
  tue__1N__11_15__11_55: "",
  tue__1N__11_55__12_35: "",
  tue__1N__2_00__2_40: "",
  tue__1N__2_40__3_20: "",
  tue__1N__3_20__4_00: "",
  tue__1E__8_00__8_40: "",
  tue__1E__8_40__9_20: "",
  tue__1E__9_30__10_10: "",
  tue__1E__10_10__10_50: "",
  tue__1E__11_15__11_55: "",
  tue__1E__11_55__12_35: "",
  tue__1E__2_00__2_40: "",
  tue__1E__2_40__3_20: "",
  tue__1E__3_20__4_00: "",
  tue__1W__8_00__8_40: "",
  tue__1W__8_40__9_20: "",
  tue__1W__9_30__10_10: "",
  tue__1W__10_10__10_50: "",
  tue__1W__11_15__11_55: "",
  tue__1W__11_55__12_35: "",
  tue__1W__2_00__2_40: "",
  tue__1W__2_40__3_20: "",
  tue__1W__3_20__4_00: "",
  tue__1S__8_00__8_40: "",
  tue__1S__8_40__9_20: "",
  tue__1S__9_30__10_10: "",
  tue__1S__10_10__10_50: "",
  tue__1S__11_15__11_55: "",
  tue__1S__11_55__12_35: "",
  tue__1S__2_00__2_40: "",
  tue__1S__2_40__3_20: "",
  tue__1S__3_20__4_00: "",
  // TUESDAY FORM 2
  tue__2N__8_00__8_40: "",
  tue__2N__8_40__9_20: "",
  tue__2N__9_30__10_10: "",
  tue__2N__10_10__10_50: "",
  tue__2N__11_15__11_55: "",
  tue__2N__11_55__12_35: "",
  tue__2N__2_00__2_40: "",
  tue__2N__2_40__3_20: "",
  tue__2N__3_20__4_00: "",
  tue__2E__8_00__8_40: "",
  tue__2E__8_40__9_20: "",
  tue__2E__9_30__10_10: "",
  tue__2E__10_10__10_50: "",
  tue__2E__11_15__11_55: "",
  tue__2E__11_55__12_35: "",
  tue__2E__2_00__2_40: "",
  tue__2E__2_40__3_20: "",
  tue__2E__3_20__4_00: "",
  tue__2W__8_00__8_40: "",
  tue__2W__8_40__9_20: "",
  tue__2W__9_30__10_10: "",
  tue__2W__10_10__10_50: "",
  tue__2W__11_15__11_55: "",
  tue__2W__11_55__12_35: "",
  tue__2W__2_00__2_40: "",
  tue__2W__2_40__3_20: "",
  tue__2W__3_20__4_00: "",
  tue__2S__8_00__8_40: "",
  tue__2S__8_40__9_20: "",
  tue__2S__9_30__10_10: "",
  tue__2S__10_10__10_50: "",
  tue__2S__11_15__11_55: "",
  tue__2S__11_55__12_35: "",
  tue__2S__2_00__2_40: "",
  tue__2S__2_40__3_20: "",
  tue__2S__3_20__4_00: "",
  // TUESDAY FORM 3
  tue__3N__8_00__8_40: "",
  tue__3N__8_40__9_20: "",
  tue__3N__9_30__10_10: "",
  tue__3N__10_10__10_50: "",
  tue__3N__11_15__11_55: "",
  tue__3N__11_55__12_35: "",
  tue__3N__2_00__2_40: "",
  tue__3N__2_40__3_20: "",
  tue__3N__3_20__4_00: "",
  tue__3E__8_00__8_40: "",
  tue__3E__8_40__9_20: "",
  tue__3E__9_30__10_10: "",
  tue__3E__10_10__10_50: "",
  tue__3E__11_15__11_55: "",
  tue__3E__11_55__12_35: "",
  tue__3E__2_00__2_40: "",
  tue__3E__2_40__3_20: "",
  tue__3E__3_20__4_00: "",
  tue__3W__8_00__8_40: "",
  tue__3W__8_40__9_20: "",
  tue__3W__9_30__10_10: "",
  tue__3W__10_10__10_50: "",
  tue__3W__11_15__11_55: "",
  tue__3W__11_55__12_35: "",
  tue__3W__2_00__2_40: "",
  tue__3W__2_40__3_20: "",
  tue__3W__3_20__4_00: "",
  tue__3S__8_00__8_40: "",
  tue__3S__8_40__9_20: "",
  tue__3S__9_30__10_10: "",
  tue__3S__10_10__10_50: "",
  tue__3S__11_15__11_55: "",
  tue__3S__11_55__12_35: "",
  tue__3S__2_00__2_40: "",
  tue__3S__2_40__3_20: "",
  tue__3S__3_20__4_00: "",
  // TUESDAY FORM 4
  tue__4N__8_00__8_40: "",
  tue__4N__8_40__9_20: "",
  tue__4N__9_30__10_10: "",
  tue__4N__10_10__10_50: "",
  tue__4N__11_15__11_55: "",
  tue__4N__11_55__12_35: "",
  tue__4N__2_00__2_40: "",
  tue__4N__2_40__3_20: "",
  tue__4N__3_20__4_00: "",
  tue__4E__8_00__8_40: "",
  tue__4E__8_40__9_20: "",
  tue__4E__9_30__10_10: "",
  tue__4E__10_10__10_50: "",
  tue__4E__11_15__11_55: "",
  tue__4E__11_55__12_35: "",
  tue__4E__2_00__2_40: "",
  tue__4E__2_40__3_20: "",
  tue__4E__3_20__4_00: "",
  tue__4W__8_00__8_40: "",
  tue__4W__8_40__9_20: "",
  tue__4W__9_30__10_10: "",
  tue__4W__10_10__10_50: "",
  tue__4W__11_15__11_55: "",
  tue__4W__11_55__12_35: "",
  tue__4W__2_00__2_40: "",
  tue__4W__2_40__3_20: "",
  tue__4W__3_20__4_00: "",
  tue__4S__8_00__8_40: "",
  tue__4S__8_40__9_20: "",
  tue__4S__9_30__10_10: "",
  tue__4S__10_10__10_50: "",
  tue__4S__11_15__11_55: "",
  tue__4S__11_55__12_35: "",
  tue__4S__2_00__2_40: "",
  tue__4S__2_40__3_20: "",
  tue__4S__3_20__4_00: "",
  // WEDNESDAY FORM 1
  wed__1N__8_00__8_40: "",
  wed__1N__8_40__9_20: "",
  wed__1N__9_30__10_10: "",
  wed__1N__10_10__10_50: "",
  wed__1N__11_15__11_55: "",
  wed__1N__11_55__12_35: "",
  wed__1N__2_00__2_40: "",
  wed__1N__2_40__3_20: "",
  wed__1N__3_20__4_00: "",
  wed__1E__8_00__8_40: "",
  wed__1E__8_40__9_20: "",
  wed__1E__9_30__10_10: "",
  wed__1E__10_10__10_50: "",
  wed__1E__11_15__11_55: "",
  wed__1E__11_55__12_35: "",
  wed__1E__2_00__2_40: "",
  wed__1E__2_40__3_20: "",
  wed__1E__3_20__4_00: "",
  wed__1W__8_00__8_40: "",
  wed__1W__8_40__9_20: "",
  wed__1W__9_30__10_10: "",
  wed__1W__10_10__10_50: "",
  wed__1W__11_15__11_55: "",
  wed__1W__11_55__12_35: "",
  wed__1W__2_00__2_40: "",
  wed__1W__2_40__3_20: "",
  wed__1W__3_20__4_00: "",
  wed__1S__8_00__8_40: "",
  wed__1S__8_40__9_20: "",
  wed__1S__9_30__10_10: "",
  wed__1S__10_10__10_50: "",
  wed__1S__11_15__11_55: "",
  wed__1S__11_55__12_35: "",
  wed__1S__2_00__2_40: "",
  wed__1S__2_40__3_20: "",
  wed__1S__3_20__4_00: "",
  // WEDNESDAY FORM 2
  wed__2N__8_00__8_40: "",
  wed__2N__8_40__9_20: "",
  wed__2N__9_30__10_10: "",
  wed__2N__10_10__10_50: "",
  wed__2N__11_15__11_55: "",
  wed__2N__11_55__12_35: "",
  wed__2N__2_00__2_40: "",
  wed__2N__2_40__3_20: "",
  wed__2N__3_20__4_00: "",
  wed__2E__8_00__8_40: "",
  wed__2E__8_40__9_20: "",
  wed__2E__9_30__10_10: "",
  wed__2E__10_10__10_50: "",
  wed__2E__11_15__11_55: "",
  wed__2E__11_55__12_35: "",
  wed__2E__2_00__2_40: "",
  wed__2E__2_40__3_20: "",
  wed__2E__3_20__4_00: "",
  wed__2W__8_00__8_40: "",
  wed__2W__8_40__9_20: "",
  wed__2W__9_30__10_10: "",
  wed__2W__10_10__10_50: "",
  wed__2W__11_15__11_55: "",
  wed__2W__11_55__12_35: "",
  wed__2W__2_00__2_40: "",
  wed__2W__2_40__3_20: "",
  wed__2W__3_20__4_00: "",
  wed__2S__8_00__8_40: "",
  wed__2S__8_40__9_20: "",
  wed__2S__9_30__10_10: "",
  wed__2S__10_10__10_50: "",
  wed__2S__11_15__11_55: "",
  wed__2S__11_55__12_35: "",
  wed__2S__2_00__2_40: "",
  wed__2S__2_40__3_20: "",
  wed__2S__3_20__4_00: "",
  // WEDNESDAY FORM 3
  wed__3N__8_00__8_40: "",
  wed__3N__8_40__9_20: "",
  wed__3N__9_30__10_10: "",
  wed__3N__10_10__10_50: "",
  wed__3N__11_15__11_55: "",
  wed__3N__11_55__12_35: "",
  wed__3N__2_00__2_40: "",
  wed__3N__2_40__3_20: "",
  wed__3N__3_20__4_00: "",
  wed__3E__8_00__8_40: "",
  wed__3E__8_40__9_20: "",
  wed__3E__9_30__10_10: "",
  wed__3E__10_10__10_50: "",
  wed__3E__11_15__11_55: "",
  wed__3E__11_55__12_35: "",
  wed__3E__2_00__2_40: "",
  wed__3E__2_40__3_20: "",
  wed__3E__3_20__4_00: "",
  wed__3W__8_00__8_40: "",
  wed__3W__8_40__9_20: "",
  wed__3W__9_30__10_10: "",
  wed__3W__10_10__10_50: "",
  wed__3W__11_15__11_55: "",
  wed__3W__11_55__12_35: "",
  wed__3W__2_00__2_40: "",
  wed__3W__2_40__3_20: "",
  wed__3W__3_20__4_00: "",
  wed__3S__8_00__8_40: "",
  wed__3S__8_40__9_20: "",
  wed__3S__9_30__10_10: "",
  wed__3S__10_10__10_50: "",
  wed__3S__11_15__11_55: "",
  wed__3S__11_55__12_35: "",
  wed__3S__2_00__2_40: "",
  wed__3S__2_40__3_20: "",
  wed__3S__3_20__4_00: "",
  // WEDNESDAY FORM 4
  wed__4N__8_00__8_40: "",
  wed__4N__8_40__9_20: "",
  wed__4N__9_30__10_10: "",
  wed__4N__10_10__10_50: "",
  wed__4N__11_15__11_55: "",
  wed__4N__11_55__12_35: "",
  wed__4N__2_00__2_40: "",
  wed__4N__2_40__3_20: "",
  wed__4N__3_20__4_00: "",
  wed__4E__8_00__8_40: "",
  wed__4E__8_40__9_20: "",
  wed__4E__9_30__10_10: "",
  wed__4E__10_10__10_50: "",
  wed__4E__11_15__11_55: "",
  wed__4E__11_55__12_35: "",
  wed__4E__2_00__2_40: "",
  wed__4E__2_40__3_20: "",
  wed__4E__3_20__4_00: "",
  wed__4W__8_00__8_40: "",
  wed__4W__8_40__9_20: "",
  wed__4W__9_30__10_10: "",
  wed__4W__10_10__10_50: "",
  wed__4W__11_15__11_55: "",
  wed__4W__11_55__12_35: "",
  wed__4W__2_00__2_40: "",
  wed__4W__2_40__3_20: "",
  wed__4W__3_20__4_00: "",
  wed__4S__8_00__8_40: "",
  wed__4S__8_40__9_20: "",
  wed__4S__9_30__10_10: "",
  wed__4S__10_10__10_50: "",
  wed__4S__11_15__11_55: "",
  wed__4S__11_55__12_35: "",
  wed__4S__2_00__2_40: "",
  wed__4S__2_40__3_20: "",
  wed__4S__3_20__4_00: "",
  // THURSDAY FORM 1
  thur__1N__8_00__8_40: "",
  thur__1N__8_40__9_20: "",
  thur__1N__9_30__10_10: "",
  thur__1N__10_10__10_50: "",
  thur__1N__11_15__11_55: "",
  thur__1N__11_55__12_35: "",
  thur__1N__2_00__2_40: "",
  thur__1N__2_40__3_20: "",
  thur__1N__3_20__4_00: "",
  thur__1E__8_00__8_40: "",
  thur__1E__8_40__9_20: "",
  thur__1E__9_30__10_10: "",
  thur__1E__10_10__10_50: "",
  thur__1E__11_15__11_55: "",
  thur__1E__11_55__12_35: "",
  thur__1E__2_00__2_40: "",
  thur__1E__2_40__3_20: "",
  thur__1E__3_20__4_00: "",
  thur__1W__8_00__8_40: "",
  thur__1W__8_40__9_20: "",
  thur__1W__9_30__10_10: "",
  thur__1W__10_10__10_50: "",
  thur__1W__11_15__11_55: "",
  thur__1W__11_55__12_35: "",
  thur__1W__2_00__2_40: "",
  thur__1W__2_40__3_20: "",
  thur__1W__3_20__4_00: "",
  thur__1S__8_00__8_40: "",
  thur__1S__8_40__9_20: "",
  thur__1S__9_30__10_10: "",
  thur__1S__10_10__10_50: "",
  thur__1S__11_15__11_55: "",
  thur__1S__11_55__12_35: "",
  thur__1S__2_00__2_40: "",
  thur__1S__2_40__3_20: "",
  thur__1S__3_20__4_00: "",
  // THURSDAY FORM 2
  thur__2N__8_00__8_40: "",
  thur__2N__8_40__9_20: "",
  thur__2N__9_30__10_10: "",
  thur__2N__10_10__10_50: "",
  thur__2N__11_15__11_55: "",
  thur__2N__11_55__12_35: "",
  thur__2N__2_00__2_40: "",
  thur__2N__2_40__3_20: "",
  thur__2N__3_20__4_00: "",
  thur__2E__8_00__8_40: "",
  thur__2E__8_40__9_20: "",
  thur__2E__9_30__10_10: "",
  thur__2E__10_10__10_50: "",
  thur__2E__11_15__11_55: "",
  thur__2E__11_55__12_35: "",
  thur__2E__2_00__2_40: "",
  thur__2E__2_40__3_20: "",
  thur__2E__3_20__4_00: "",
  thur__2W__8_00__8_40: "",
  thur__2W__8_40__9_20: "",
  thur__2W__9_30__10_10: "",
  thur__2W__10_10__10_50: "",
  thur__2W__11_15__11_55: "",
  thur__2W__11_55__12_35: "",
  thur__2W__2_00__2_40: "",
  thur__2W__2_40__3_20: "",
  thur__2W__3_20__4_00: "",
  thur__2S__8_00__8_40: "",
  thur__2S__8_40__9_20: "",
  thur__2S__9_30__10_10: "",
  thur__2S__10_10__10_50: "",
  thur__2S__11_15__11_55: "",
  thur__2S__11_55__12_35: "",
  thur__2S__2_00__2_40: "",
  thur__2S__2_40__3_20: "",
  thur__2S__3_20__4_00: "",
  // THURSDAY FORM 3
  thur__3N__8_00__8_40: "",
  thur__3N__8_40__9_20: "",
  thur__3N__9_30__10_10: "",
  thur__3N__10_10__10_50: "",
  thur__3N__11_15__11_55: "",
  thur__3N__11_55__12_35: "",
  thur__3N__2_00__2_40: "",
  thur__3N__2_40__3_20: "",
  thur__3N__3_20__4_00: "",
  thur__3E__8_00__8_40: "",
  thur__3E__8_40__9_20: "",
  thur__3E__9_30__10_10: "",
  thur__3E__10_10__10_50: "",
  thur__3E__11_15__11_55: "",
  thur__3E__11_55__12_35: "",
  thur__3E__2_00__2_40: "",
  thur__3E__2_40__3_20: "",
  thur__3E__3_20__4_00: "",
  thur__3W__8_00__8_40: "",
  thur__3W__8_40__9_20: "",
  thur__3W__9_30__10_10: "",
  thur__3W__10_10__10_50: "",
  thur__3W__11_15__11_55: "",
  thur__3W__11_55__12_35: "",
  thur__3W__2_00__2_40: "",
  thur__3W__2_40__3_20: "",
  thur__3W__3_20__4_00: "",
  thur__3S__8_00__8_40: "",
  thur__3S__8_40__9_20: "",
  thur__3S__9_30__10_10: "",
  thur__3S__10_10__10_50: "",
  thur__3S__11_15__11_55: "",
  thur__3S__11_55__12_35: "",
  thur__3S__2_00__2_40: "",
  thur__3S__2_40__3_20: "",
  thur__3S__3_20__4_00: "",
  // THURSDAY FORM 4
  thur__4N__8_00__8_40: "",
  thur__4N__8_40__9_20: "",
  thur__4N__9_30__10_10: "",
  thur__4N__10_10__10_50: "",
  thur__4N__11_15__11_55: "",
  thur__4N__11_55__12_35: "",
  thur__4N__2_00__2_40: "",
  thur__4N__2_40__3_20: "",
  thur__4N__3_20__4_00: "",
  thur__4E__8_00__8_40: "",
  thur__4E__8_40__9_20: "",
  thur__4E__9_30__10_10: "",
  thur__4E__10_10__10_50: "",
  thur__4E__11_15__11_55: "",
  thur__4E__11_55__12_35: "",
  thur__4E__2_00__2_40: "",
  thur__4E__2_40__3_20: "",
  thur__4E__3_20__4_00: "",
  thur__4W__8_00__8_40: "",
  thur__4W__8_40__9_20: "",
  thur__4W__9_30__10_10: "",
  thur__4W__10_10__10_50: "",
  thur__4W__11_15__11_55: "",
  thur__4W__11_55__12_35: "",
  thur__4W__2_00__2_40: "",
  thur__4W__2_40__3_20: "",
  thur__4W__3_20__4_00: "",
  thur__4S__8_00__8_40: "",
  thur__4S__8_40__9_20: "",
  thur__4S__9_30__10_10: "",
  thur__4S__10_10__10_50: "",
  thur__4S__11_15__11_55: "",
  thur__4S__11_55__12_35: "",
  thur__4S__2_00__2_40: "",
  thur__4S__2_40__3_20: "",
  thur__4S__3_20__4_00: "",
  // FRIDAY FORM 1
  fri__1N__8_00__8_40: "",
  fri__1N__8_40__9_20: "",
  fri__1N__9_30__10_10: "",
  fri__1N__10_10__10_50: "",
  fri__1N__11_15__11_55: "",
  fri__1N__11_55__12_35: "",
  fri__1N__2_00__2_40: "",
  fri__1N__2_40__3_20: "",
  fri__1N__3_20__4_00: "",
  fri__1E__8_00__8_40: "",
  fri__1E__8_40__9_20: "",
  fri__1E__9_30__10_10: "",
  fri__1E__10_10__10_50: "",
  fri__1E__11_15__11_55: "",
  fri__1E__11_55__12_35: "",
  fri__1E__2_00__2_40: "",
  fri__1E__2_40__3_20: "",
  fri__1E__3_20__4_00: "",
  fri__1W__8_00__8_40: "",
  fri__1W__8_40__9_20: "",
  fri__1W__9_30__10_10: "",
  fri__1W__10_10__10_50: "",
  fri__1W__11_15__11_55: "",
  fri__1W__11_55__12_35: "",
  fri__1W__2_00__2_40: "",
  fri__1W__2_40__3_20: "",
  fri__1W__3_20__4_00: "",
  fri__1S__8_00__8_40: "",
  fri__1S__8_40__9_20: "",
  fri__1S__9_30__10_10: "",
  fri__1S__10_10__10_50: "",
  fri__1S__11_15__11_55: "",
  fri__1S__11_55__12_35: "",
  fri__1S__2_00__2_40: "",
  fri__1S__2_40__3_20: "",
  fri__1S__3_20__4_00: "",
  // FRIDAY FORM 2
  fri__2N__8_00__8_40: "",
  fri__2N__8_40__9_20: "",
  fri__2N__9_30__10_10: "",
  fri__2N__10_10__10_50: "",
  fri__2N__11_15__11_55: "",
  fri__2N__11_55__12_35: "",
  fri__2N__2_00__2_40: "",
  fri__2N__2_40__3_20: "",
  fri__2N__3_20__4_00: "",
  fri__2E__8_00__8_40: "",
  fri__2E__8_40__9_20: "",
  fri__2E__9_30__10_10: "",
  fri__2E__10_10__10_50: "",
  fri__2E__11_15__11_55: "",
  fri__2E__11_55__12_35: "",
  fri__2E__2_00__2_40: "",
  fri__2E__2_40__3_20: "",
  fri__2E__3_20__4_00: "",
  fri__2W__8_00__8_40: "",
  fri__2W__8_40__9_20: "",
  fri__2W__9_30__10_10: "",
  fri__2W__10_10__10_50: "",
  fri__2W__11_15__11_55: "",
  fri__2W__11_55__12_35: "",
  fri__2W__2_00__2_40: "",
  fri__2W__2_40__3_20: "",
  fri__2W__3_20__4_00: "",
  fri__2S__8_00__8_40: "",
  fri__2S__8_40__9_20: "",
  fri__2S__9_30__10_10: "",
  fri__2S__10_10__10_50: "",
  fri__2S__11_15__11_55: "",
  fri__2S__11_55__12_35: "",
  fri__2S__2_00__2_40: "",
  fri__2S__2_40__3_20: "",
  fri__2S__3_20__4_00: "",
  // FRIDAY FORM 3
  fri__3N__8_00__8_40: "",
  fri__3N__8_40__9_20: "",
  fri__3N__9_30__10_10: "",
  fri__3N__10_10__10_50: "",
  fri__3N__11_15__11_55: "",
  fri__3N__11_55__12_35: "",
  fri__3N__2_00__2_40: "",
  fri__3N__2_40__3_20: "",
  fri__3N__3_20__4_00: "",
  fri__3E__8_00__8_40: "",
  fri__3E__8_40__9_20: "",
  fri__3E__9_30__10_10: "",
  fri__3E__10_10__10_50: "",
  fri__3E__11_15__11_55: "",
  fri__3E__11_55__12_35: "",
  fri__3E__2_00__2_40: "",
  fri__3E__2_40__3_20: "",
  fri__3E__3_20__4_00: "",
  fri__3W__8_00__8_40: "",
  fri__3W__8_40__9_20: "",
  fri__3W__9_30__10_10: "",
  fri__3W__10_10__10_50: "",
  fri__3W__11_15__11_55: "",
  fri__3W__11_55__12_35: "",
  fri__3W__2_00__2_40: "",
  fri__3W__2_40__3_20: "",
  fri__3W__3_20__4_00: "",
  fri__3S__8_00__8_40: "",
  fri__3S__8_40__9_20: "",
  fri__3S__9_30__10_10: "",
  fri__3S__10_10__10_50: "",
  fri__3S__11_15__11_55: "",
  fri__3S__11_55__12_35: "",
  fri__3S__2_00__2_40: "",
  fri__3S__2_40__3_20: "",
  fri__3S__3_20__4_00: "",
  // FRIDAY FORM 4
  fri__4N__8_00__8_40: "",
  fri__4N__8_40__9_20: "",
  fri__4N__9_30__10_10: "",
  fri__4N__10_10__10_50: "",
  fri__4N__11_15__11_55: "",
  fri__4N__11_55__12_35: "",
  fri__4N__2_00__2_40: "",
  fri__4N__2_40__3_20: "",
  fri__4N__3_20__4_00: "",
  fri__4E__8_00__8_40: "",
  fri__4E__8_40__9_20: "",
  fri__4E__9_30__10_10: "",
  fri__4E__10_10__10_50: "",
  fri__4E__11_15__11_55: "",
  fri__4E__11_55__12_35: "",
  fri__4E__2_00__2_40: "",
  fri__4E__2_40__3_20: "",
  fri__4E__3_20__4_00: "",
  fri__4W__8_00__8_40: "",
  fri__4W__8_40__9_20: "",
  fri__4W__9_30__10_10: "",
  fri__4W__10_10__10_50: "",
  fri__4W__11_15__11_55: "",
  fri__4W__11_55__12_35: "",
  fri__4W__2_00__2_40: "",
  fri__4W__2_40__3_20: "",
  fri__4W__3_20__4_00: "",
  fri__4S__8_00__8_40: "",
  fri__4S__8_40__9_20: "",
  fri__4S__9_30__10_10: "",
  fri__4S__10_10__10_50: "",
  fri__4S__11_15__11_55: "",
  fri__4S__11_55__12_35: "",
  fri__4S__2_00__2_40: "",
  fri__4S__2_40__3_20: "",
  fri__4S__3_20__4_00: "",
};

export default classes;
