import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { create_staff_salary_earning } from "../../../../redux/actions/staff";
import CircularProgress from "@mui/material/CircularProgress";
import { START_LOADING } from "../../../../redux/actions/types";
import { ifEmpty } from "../../../../shared/sharedFunctions";

const AddStaffSalary = (props) => {
  const { openAddStaffSalary, currentStaff, salaryEarnings, loading } = props;
  const {
    setOpenAddStaffSalary,
    setCurrentStaff,
    userId,
    startLoading,
    createStaffSalaryEarning,
  } = props;

  const [staffSalaryDetails, setStaffSalaryDetails] = useState({
    salary_earning: "",
    amount: 0,
    is_active: true,
  });

  const { salary_earning, amount, is_active } = staffSalaryDetails;
  const [earningType, setEarningType] = useState("variable");

  // useEffect to set earning type to either variable or fixed
  useEffect(() => {
    if (salary_earning !== "") {
      return setEarningType(
        salaryEarnings?.find((earning) => earning?.id === salary_earning)[
          "earning_type"
        ]
      );
    }
  }, [salaryEarnings, salary_earning]);

  const handleChange = (e) => {
    setStaffSalaryDetails({
      ...staffSalaryDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setStaffSalaryDetails({
      ...staffSalaryDetails,
      [e.target.name]: e.target.checked,
    });
  };

  const resetForm = () => {
    setStaffSalaryDetails({
      salary_earning: "",
      amount: 0,
      is_active: true,
    });
  };

  const handleClose = () => {
    resetForm();
    setOpenAddStaffSalary(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(staffSalaryDetails)) {
      return window.alert("All fields must be filled");
    }
    startLoading();
    const body = {
      ...staffSalaryDetails,
      staff: currentStaff?.id,
    };
    createStaffSalaryEarning(userId, body, resetForm, setCurrentStaff);
  };

  return (
    <MinDialog isOpen={openAddStaffSalary}>
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading ? "formSubmitting" : ""}
      >
        <h3>Enter staff earning details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Staff Name</label>
          <input type="text" disabled value={currentStaff?.name} />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Payroll Number</label>
          <input type="text" value={currentStaff?.payroll_number} disabled />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Earning type</label>
          <select
            name="salary_earning"
            onChange={handleChange}
            value={salary_earning}
          >
            {salaryEarnings?.length > 0 ? (
              <>
                <option value="">--select earning type--</option>
                {salaryEarnings?.map((item) => (
                  <option value={item?.id} key={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </>
            ) : (
              <option value="" disabled>
                No active salary type earnings
              </option>
            )}
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Amount</label>
          <input
            type="number"
            name="amount"
            onChange={handleChange}
            value={amount}
            min="1"
            // expression returns true or false, disable this if earning is fixed
            disabled={earningType === "fixed"}
          />
        </div>
        <div class="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_active"
            checked={is_active}
            onChange={handleCheckboxChange}
          />
          <label for="">Tick this to make the staff earning active</label>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    salaryEarnings: state?.staff?.salaryEarnings?.filter(
      (item) => item?.is_active === true
    ),
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createStaffSalaryEarning: (userId, body, resetForm, setCurrentStaff) =>
      dispatch(
        create_staff_salary_earning(userId, body, resetForm, setCurrentStaff)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStaffSalary);
