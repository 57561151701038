import { useState } from "react";
import { connect } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import MinDialog from "../../../../../components/common/MinDialog";
import { showError } from "../../../../../redux/actions/shared";
import {
  START_LOADING,
  STOP_LOADING,
} from "../../../../../redux/actions/types";
import API from "../../../../../shared/axios";
import OverallResultsMeritList from "../../../../common/Exams/OverallResultsMeritList";

const OverallResultsAction = (props) => {
  const {
    openOverallResultsAction,
    currentExam,
    class_streams,
    userId,
    loading,
  } = props;
  const { setOpenOverallResultsAction, startLoading, stopLoading } = props;

  const [studentsOverallResults, setStudentsOverallResults] = useState([]);
  const [classStreamSubjects, setClassStreamSubjects] = useState([]);
  const [openOverallResultsMeritList, setOpenOverallResultsMeritList] =
    useState(false);

  const [meritListOptions, setMeritListOptions] = useState({
    optionType: "",
    currentClassStreamId: "",
  });
  const { optionType, currentClassStreamId } = meritListOptions;

  const handleChange = (e) => {
    setMeritListOptions({
      ...meritListOptions,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (optionType === "merit_list") {
      handleGetResultsMeritList();
    }
  };

  const handleGetResultsMeritList = async () => {
    startLoading();
    await API.get(
      `/api/exam/teacher/maintain-offline-exam-results/${userId}/${currentClassStreamId}/${currentExam?.id}/`
    )
      .then((res) => {
        setClassStreamSubjects(res?.data?.class_stream_subjects);
        setStudentsOverallResults(res?.data?.students_overall_results_data);
        setOpenOverallResultsMeritList(true);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog isOpen={openOverallResultsAction}>
        <form
          class="dialog"
          onSubmit={handleSubmit}
          id={loading ? "formSubmitting" : ""}
        >
          <h3>{currentExam?.name} merit list</h3>
          <div class="dialog__rowSingleItem">
            <label for="">Select Type</label>
            <select
              name="optionType"
              onChange={handleChange}
              value={optionType}
              required
            >
              <option value="">--select--</option>
              <option value="merit_list">Merit List</option>
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "40%" }}
            />
          )}
          <div class="dialog__rowSingleItem">
            <label for="">Class Stream</label>
            <select
              name="currentClassStreamId"
              onChange={handleChange}
              value={currentClassStreamId}
              required
            >
              <option value="">--select--</option>
              {class_streams?.map((stream) => (
                <option value={stream?.id} key={stream?.id}>
                  {stream?.name}
                </option>
              ))}
            </select>
          </div>
          <div class="form__Buttons">
            <button
              type="button"
              onClick={() => setOpenOverallResultsAction(false)}
            >
              Close
            </button>
            <button type="submit">Generate</button>
          </div>
        </form>
      </MinDialog>
      {/* child components */}
      {openOverallResultsMeritList && (
        <OverallResultsMeritList
          openOverallResultsMeritList={openOverallResultsMeritList}
          setOpenOverallResultsMeritList={setOpenOverallResultsMeritList}
          studentsOverallResults={studentsOverallResults}
          currentClassStream={class_streams?.find(
            (class_stream) => class_stream?.id === currentClassStreamId
          )}
          currentExam={currentExam}
          classStreamSubjects={classStreamSubjects}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    class_streams: state?.auth?.user?.class_streams,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverallResultsAction);
