import * as actionTypes from "../actions/types";

import * as api from "../../api";
import { showError, stopLoading } from "./shared";

export const staff_apply_leave =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .staffApplyLeave(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_LEAVE_APPLICATION,
          payload: res?.data?.new_leave_application_data,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action for staff to get all leave applications
export const staff_get_leave_applications = (userId) => async (dispatch) => {
  await api
    .staffGetLeaveApplications(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_LEAVE_APPLICATIONS,
        payload: {
          leave_applications_data: res?.data?.leave_applications_data,
          pending_leave_applications_data:
            res?.data?.pending_leave_applications_data,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action for school admin to approve or reject staff leave application
export const approve_or_reject_staff_leave_application =
  (userId, body) => async (dispatch) => {
    await api
      .approveOrRejectStaffLeaveApplication(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.APPROVE_OR_REJECT_LEAVE,
          payload: res?.data?.leaveApplicationId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action for human resource to get staff
export const human_resource_get_staff = (userId) => async (dispatch) => {
  await api
    .humanResourceGetStaff(userId)
    .then((res) => {
      dispatch({ type: actionTypes.SET_STAFF, payload: res?.data?.staff_data });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to get salary deductions
export const get_salary_deductions = (userId) => async (dispatch) => {
  await api
    .getSalaryDeductions(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_SALARY_DEDUCTIONS,
        payload: {
          salary_deductions_data: res?.data?.salary_deductions_data,
          tax_reliefs_data: res?.data?.tax_reliefs_data,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to update a salary deduction
export const edit_salary_deduction = (userId, body) => async (dispatch) => {
  await api
    .editSalaryDeduction(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_SALARY_DEDUCTION,
        payload: res?.data?.updated_salary_deduction_data,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to create a salary deduction
export const create_salary_deduction =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createSalaryDeduction(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_SALARY_DEDUCTION,
          payload: res?.data?.new_salary_deduction_data,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to create a salary earning
export const create_salary_earning =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createSalaryEarning(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_SALARY_EARNING,
          payload: res?.data?.new_salary_earning_data,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to update a salary earning
export const edit_salary_earning = (userId, body) => async (dispatch) => {
  await api
    .editSalaryEarning(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_SALARY_EARNING,
        payload: res?.data?.updated_salary_earning_data,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to get all salary earnings
export const get_salary_earnings = (userId) => async (dispatch) => {
  await api
    .getSalaryEarnings(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_SALARY_EARNINGS,
        payload: res?.data?.salary_earnings_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to create staff salary earning for a single staff
export const create_staff_salary_earning =
  (userId, body, resetForm, setCurrentStaff) => async (dispatch) => {
    await api
      .createStaffSalaryEarning(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_STAFF_DETAILS,
          payload: res?.data?.updated_staff_details,
        });
        resetForm();
        setCurrentStaff(res?.data?.updated_staff_details);
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to edit staff salary earning for a single staff
export const edit_staff_salary_earning =
  (userId, body, setCurrentStaff) => async (dispatch) => {
    await api
      .editStaffSalaryEarning(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_STAFF_DETAILS,
          payload: res?.data?.updated_staff_details,
        });
        setCurrentStaff(res?.data?.updated_staff_details);
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to create staff salary deduction for a single staff
export const create_staff_salary_deduction =
  (userId, body, resetForm, setCurrentStaff) => async (dispatch) => {
    await api
      .createStaffSalaryDeduction(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_STAFF_DETAILS,
          payload: res?.data?.updated_staff_details,
        });
        setCurrentStaff(res?.data?.updated_staff_details);
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to edit staff salary deduction for a single staff
export const edit_staff_salary_deduction =
  (userId, body, setCurrentStaff) => async (dispatch) => {
    await api
      .editStaffSalaryDeduction(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_STAFF_DETAILS,
          payload: res?.data?.updated_staff_details,
        });
        setCurrentStaff(res?.data?.updated_staff_details);
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to delete salary deduction id
export const delete_salary_deduction =
  (userId, salaryDeductionId) => async (dispatch) => {
    await api
      .deleteSalaryDeduction(userId, salaryDeductionId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_SALARY_DEDUCTION,
          payload: salaryDeductionId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to delete salary earning id
export const delete_salary_earning =
  (userId, salaryEarningId) => async (dispatch) => {
    await api
      .deleteSalaryEarning(userId, salaryEarningId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_SALARY_EARNING,
          payload: salaryEarningId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to create a new paye bracket
export const create_paye_bracket =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createPAYEBracket(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_PAYE_BRACKET,
          payload: res?.data?.new_paye_bracket_data,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to get all PAYE brackets
export const get_paye_brackets = (userId) => async (dispatch) => {
  await api
    .getPAYEBrackets(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_PAYE_BRACKETS,
        payload: res?.data?.paye_brackets_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to delete PAYE bracket
export const delete_paye_bracket =
  (userId, PAYEBracketId) => async (dispatch) => {
    await api
      .deletePAYEBracket(userId, PAYEBracketId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_PAYE_BRACKET,
          payload: PAYEBracketId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to create a new payroll
export const create_payroll = (userId, body, resetForm) => async (dispatch) => {
  await api
    .createPayroll(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.NEW_PAYROLL,
        payload: res?.data?.new_payroll_data,
      });
      resetForm();
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to edit a payroll
export const edit_payroll = (userId, body) => async (dispatch) => {
  await api
    .editPayroll(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_PAYROLL,
        payload: res?.data?.updated_payroll_data,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to get all payrolls
export const get_payrolls = (userId) => async (dispatch) => {
  await api
    .getPayrolls(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_PAYROLLS,
        payload: res?.data?.payrolls_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to create a new tax relief
export const create_tax_relief =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createTaxRelief(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_TAX_RELIEF,
          payload: res?.data?.new_tax_relief_data,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to edit a tax relief
export const edit_tax_relief = (userId, body) => async (dispatch) => {
  await api
    .editTaxRelief(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_TAX_RELIEF,
        payload: res?.data?.updated_tax_relief_data,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to delete tax relief
export const delete_tax_relief = (userId, taxReliefId) => async (dispatch) => {
  await api
    .deleteTaxRelief(userId, taxReliefId)
    .then((res) => {
      dispatch({ type: actionTypes.DELETE_TAX_RELIEF, payload: taxReliefId });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to approve payroll
export const approve_payroll = (userId, body) => async (dispatch) => {
  await api
    .approvePayroll(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_PAYROLL,
        payload: res?.data?.approved_payroll,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};
