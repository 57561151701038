import React, { useState } from "react";
import API from "../../../../shared/axios";
import { connect } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../../../redux/actions/types";
import { showError } from "../../../../redux/actions/shared";
import CircularProgress from "@mui/material/CircularProgress";

const DeactivatedUsers = (props) => {
  const { loading, userId } = props;
  const { startLoading, stopLoading } = props;

  const [userType, setUserType] = useState("");
  const [deactivatedUsersList, setDeactivatedUsersList] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userType?.trim() === "") {
      return window.alert("You must select the user type");
    }
    startLoading();
    await API.get(`/api/user/maintain-deactivated-users/${userId}/${userType}/`)
      .then((res) => {
        setDeactivatedUsersList(res?.data?.deactivated_users_data);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleActivateUser = async (userToActivateId) => {
    if (window.confirm("Are you sure you want to reactivate this user?")) {
      startLoading();
      await API.patch(
        `/api/user/maintain-deactivated-users/${userId}/${userType}/`,
        { userToActivateId }
      )
        .then((res) => {
          setDeactivatedUsersList(
            deactivatedUsersList?.filter(
              (user) => user?.id !== userToActivateId
            )
          );
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
      <div class="table__parentHeader">
        <h3>View a list of deactivated users</h3>
      </div>
      {loading && (
        <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />
      )}
      <form
        class="dialog__row"
        style={{ maxWidth: "500px" }}
        onSubmit={handleSubmit}
      >
        <span>
          <label for="">Select user type</label>
          <select
            name=""
            required
            onChange={(e) => setUserType(e.target.value)}
            value={userType}
          >
            <option value="">--select--</option>
            <option value="Student">Students</option>
            <option value="Teacher">Teachers</option>
            <option value="Parent">Parents</option>
            <option value="Other_Staff">Other Staff</option>
          </select>
        </span>
        <span>
          <label for="" style={{ visibility: "hidden" }}>
            action
          </label>
          <button
            type="submit"
            className="add__button"
            style={{ maxWidth: "200px" }}
          >
            View
          </button>
        </span>
      </form>
      {deactivatedUsersList?.length > 0 ? (
        <table class="table__listing">
          <thead>
            <tr class="table__listingHeader">
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {deactivatedUsersList?.map((user) => (
              <tr class="table__listingItem" key={user?.id}>
                <td>{user?.name}</td>
                <td
                  className="dodgerblue bd button__sp"
                  onClick={() => handleActivateUser(user?.id)}
                >
                  Activate
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h4 class="not__available">Select user type to view</h4>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeactivatedUsers);
