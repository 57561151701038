import * as actionTypes from "./types";

import globals from "../../shared/globals";

const { unknown_error } = globals;

// START OF reusable functions
// show error function
export const showError = (err) => {
  if (err.response?.status === 400) {
    console.log(err?.response?.data);
    // we confirm if error is available, if so show it
    if (err?.response?.data?.detail) {
      window.alert(err?.response?.data?.detail);
    } else {
      // here we assume if error is not available in if above, we assume it's a serializer error and then show it as below
      // this may change if more information comes up on how to handle this
      window.alert(Object.values(err.response?.data)[0][0]);
    }
  } else if (err?.response?.status === 401) {
    window.alert(err?.response?.data?.detail);
  } else {
    window.alert(unknown_error);
  }
};

export const stopLoading = (dispatch) => {
  dispatch({ type: actionTypes.STOP_LOADING });
};

// END OF REUSABLE FUNCTIONS
