// import installed packages
import { useState, useEffect } from "react";
import { connect } from "react-redux";

// import styles
// import material ui items
// import shared/global items
// import components/pages
import { START_LOADING } from "../../redux/actions/types";
// import redux API
import { get_groups } from "../../redux/actions/auth";

import AddGroup from "../../components/SystemAdmin/AddGroup";

const UserGroups = (props) => {
  const { groups, userId } = props; // get state from props
  const { getGroups, startLoading } = props; // get dispatch actions from props
  const [openAddGroup, setOpenAddGroup] = useState(false);

  useEffect(() => {
    if (groups?.length === 0 && userId) {
      startLoading();
      getGroups(userId);
    }
  }, [getGroups, groups?.length, startLoading, userId]);

  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <button
            type="button"
            className="add__button white"
            onClick={() => setOpenAddGroup(true)}
          >
            Add Group
          </button>
          <h3>Maintain user groups</h3>
        </div>
        <table className="table__listing">
          {groups?.length > 0 ? (
            <>
              <tr className="table__listingHeader">
                <th>No:</th>
                <th>Name</th>
                <th>Status</th>
                <th>View</th>
              </tr>
              {groups?.map((group, index) => (
                <tr className="table__listingItem" key={group?.name}>
                  <td>{index + 1}</td>
                  <td>{group.name}</td>
                  <td>{group.is_active ? "Active" : "Inactive"}</td>
                  <td className="button dodgerblue bd">View</td>
                </tr>
              ))}
            </>
          ) : (
            <h4 className="not__available">No groups available</h4>
          )}
        </table>
      </div>
      {/* child components */}

      {openAddGroup && (
        <AddGroup
          openAddGroup={openAddGroup}
          setOpenAddGroup={setOpenAddGroup}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    groups: state.auth.groups,
    userId: state.auth.user.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getGroups: (userId) => dispatch(get_groups(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroups);
