import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ApproveDenyStaffLeave from "./components/ApproveDenyStaffLeave";

const PendingLeaveApplications = (props) => {
  const { pendingLeaveApplications } = props;

  const [openApproveDenyStaffLeave, setOpenApproveDenyStaffLeave] =
    useState(false);
  const [currentStaffLeave, setCurrentStaffLeave] = useState({});

  const handleOpen = (staff_leave) => {
    setOpenApproveDenyStaffLeave(true);
    setCurrentStaffLeave(staff_leave);
  };

  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <h3>Leave applications awaiting approval</h3>
        </div>
        {pendingLeaveApplications?.length > 0 ? (
          <table className="table__listing">
            <thead>
              <tr className="table__listingHeader">
                <th>Staff</th>
                <th>Staff Type</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Action</th>
              </tr>
              {pendingLeaveApplications?.map((staff_leave) => (
                <tr className="table__listingItem">
                  <td>{staff_leave?.staff}</td>
                  <td>{staff_leave?.staff_type}</td>
                  <td>{moment(staff_leave?.start_date).format("LL")}</td>
                  <td>{moment(staff_leave?.end_date).format("LL")}</td>
                  <td
                    className="dodgerblue bd button__sp"
                    onClick={() => handleOpen(staff_leave)}
                  >
                    Update
                  </td>
                </tr>
              ))}
            </thead>
          </table>
        ) : (
          <h4 className="not__available">
            No leave applications awaiting approval
          </h4>
        )}
      </div>
      {/* child components */}

      <ApproveDenyStaffLeave
        openApproveDenyStaffLeave={openApproveDenyStaffLeave}
        setOpenApproveDenyStaffLeave={setOpenApproveDenyStaffLeave}
        currentStaffLeave={currentStaffLeave}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pendingLeaveApplications: state?.staff?.pendingLeaveApplications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingLeaveApplications);
