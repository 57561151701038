import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { admin_get_staff_parent_phone_numbers } from "../../../redux/actions/communication";
import SendSMS from "./components/SendSMS";

const SMS = (props) => {
  const { userId } = props;
  const { adminGetStaffParentPhoneNumbers } = props;
  const [openSendSMS, setOpenSendSMS] = useState(false);

  useEffect(() => {
    if (userId) {
      adminGetStaffParentPhoneNumbers(userId);
    }
  }, [adminGetStaffParentPhoneNumbers, userId]);

  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <button type="button" onClick={() => setOpenSendSMS(true)}>
            Send SMS
          </button>
        </div>
      </div>
      {/* child components */}
      {openSendSMS && (
        <SendSMS openSendSMS={openSendSMS} setOpenSendSMS={setOpenSendSMS} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminGetStaffParentPhoneNumbers: (userId) =>
      dispatch(admin_get_staff_parent_phone_numbers(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SMS);
