import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import API from "../../../shared/axios";
import { showError } from "../../../redux/actions/shared";
import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";

import "./LiveMeeting.scss";

const LiveMeeting = (props) => {
  const { liveClassId } = useParams();
  const { userId, loading } = props;
  const { startLoading, stopLoading } = props;
  const [liveClassDetails, setLiveClassDetails] = useState({});

  useEffect(() => {
    if (userId) {
      const fetchMeetingDetails = async () => {
        await API.get(
          `/api/course/maintain-live-meeting/${userId}/${liveClassId}/`
        ).then((res) => {
          setLiveClassDetails(res?.data?.live_class_details);
        });
      };
      fetchMeetingDetails()
        .catch((err) => showError(err))
        .finally(() => {});
    }
  }, [startLoading, stopLoading, userId, liveClassId]);

  return (
    <div className="live__meeting">
      {/* {liveClassDetails?.liveClassId && liveClassDetails?.token_value ? (
        <iframe
          title={`${liveClassDetails?.id}`}
          src={`https://meeting.azwgroup.com/${liveClassDetails?.liveClassId}?jwt=${liveClassDetails?.token_value}`}
          allow="camera;microphone;fullscreen;display-capture"
        />
      ) : (
        <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />
      )} */}
      {loading && (
        <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />
      )}
      {liveClassDetails?.live_class_url && (
        <iframe
          title={`${liveClassDetails?.id}`}
          src={`${liveClassDetails?.live_class_url}`}
          allow="camera;microphone;fullscreen;display-capture"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveMeeting);
