import { CircularProgress } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { edit_school_service } from "../../../../redux/actions/finance";
import { START_LOADING } from "../../../../redux/actions/types";
import { ifEmpty } from "../../../../shared/sharedFunctions";

const EditSchoolService = (props) => {
  const { openEditSchoolService, currentSchoolService, loading, userId } =
    props;
  const {
    setOpenEditSchoolService,
    setCurrentSchoolService,
    startLoading,
    editSchoolService,
  } = props;

  const { name, price, is_active } = currentSchoolService;

  const handleChange = (e) => {
    setCurrentSchoolService({
      ...currentSchoolService,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setCurrentSchoolService({
      ...currentSchoolService,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(currentSchoolService)) {
      return window.alert("You must fill in all fields");
    }
    startLoading();
    editSchoolService(userId, { ...currentSchoolService });
  };

  return (
    <MinDialog isOpen={openEditSchoolService}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit school service</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Service Name</label>
          <input type="text" onChange={handleChange} name="name" value={name} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "42%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Price</label>
          <input
            type="number"
            name="price"
            min="1"
            onChange={handleChange}
            value={price}
          />
        </div>
        <div class="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_active"
            checked={is_active}
            onChange={handleCheckboxChange}
          />
          <label for="">Tick this to make the school service active</label>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditSchoolService(false)}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editSchoolService: (userId, body) =>
      dispatch(edit_school_service(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSchoolService);
