import API from "../shared/axios";

// DELETE request teacher to delete exam
export const deleteExam = (userId, examId) =>
  API.delete(`/api/exam/teacher/delete-exam/${userId}/${examId}/`);

// DELETE request teacher to delete exam question
export const deleteExamQuestion = (userId, questionId) =>
  API.delete(`/api/exam/teacher/delete-exam-question/${userId}/${questionId}/`);

// DELETE REQUEST to delete an assignment
export const teacherDeleteAssignment = (userId, assignmentId) =>
  API.delete(`/api/exam/teacher/delete-assignment/${userId}/${assignmentId}/`);

// DELETE REQUEST TO delete a ledger or ledger group
export const deleteLedgerOrGroup = (userId, itemId, ledgerOrGroup) =>
  API.delete(
    `/api/finance/delete-ledger-or-group/${userId}/${itemId}/${ledgerOrGroup}/`
  );

// DELETE REQUEST TO delete a salary deduction
export const deleteSalaryDeduction = (userId, salaryDeductionId) =>
  API.delete(
    `/api/staff/staff/delete-salary-deduction/${userId}/${salaryDeductionId}/`
  );
// DELETE REQUEST TO delete a salary earning
export const deleteSalaryEarning = (userId, salaryEarningId) =>
  API.delete(
    `/api/staff/staff/delete-salary-earning/${userId}/${salaryEarningId}/`
  );

// DELETE REQUEST to delete PAYE bracket
export const deletePAYEBracket = (userId, PAYEBracketId) =>
  API.delete(
    `/api/staff/staff/delete-paye-bracket/${userId}/${PAYEBracketId}/`
  );

// DELETE REQUEST to delete tax relief
export const deleteTaxRelief = (userId, taxReliefId) =>
  API.delete(`/api/staff/staff/delete-tax-relief/${userId}/${taxReliefId}/`);

// DELETE SCHOOL SERVICE
export const deleteSchoolService = (userId, schoolServiceId) =>
  API.delete(
    `/api/finance/delete-school-service/${userId}/${schoolServiceId}/`
  );

// remove teacher from subject allocated
export const removeClassStreamSubjectTeacher = (userId, classStreamSubjectId) =>
  API.delete(
    `/api/course/remove-class-stream-subject-teacher/${userId}/${classStreamSubjectId}/`
  );

// delete overall grade

export const deleteOverallGrade = (userId, overallGradeId) =>
  API.delete(`/api/course/delete-overall-grade/${userId}/${overallGradeId}/`);
