import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../components/common/MinDialog";
import { create_stock_item } from "../../../redux/actions/stores";
import { START_LOADING } from "../../../redux/actions/types";
import { ifEmpty, resetFormValues } from "../../../shared/sharedFunctions";

const AddStockItem = (props) => {
  const { openAddStockItem, loading, userId, expensesLedgers } = props;
  const { setOpenAddStockItem, createStockItem, startLoading } = props;

  const [stockItemDetails, setStockItemDetails] = useState({
    name: "",
    unit_of_measure: "",
    reorder_level: "",
    ledger: "",
  });

  const { name, unit_of_measure, reorder_level, ledger } = stockItemDetails;

  const resetForm = () => {
    resetFormValues(stockItemDetails);
  };
  const handleClose = () => {
    resetForm();
    setOpenAddStockItem(false);
  };

  const handleChange = (e) => {
    setStockItemDetails({
      ...stockItemDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(stockItemDetails)) {
      return window.alert("Fill in all fields");
    }
    startLoading();
    const body = {
      ...stockItemDetails,
    };
    createStockItem(userId, body, resetForm);
  };

  return (
    <MinDialog isOpen={openAddStockItem}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter stock item details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Stock Item Name</label>
          <input type="text" name="name" onChange={handleChange} value={name} />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Unit of Measure</label>
          <select
            name="unit_of_measure"
            onChange={handleChange}
            value={unit_of_measure}
          >
            <option value="" selected disabled>
              --select--
            </option>
            <option value="grams">grams</option>
            <option value="kg">kg</option>
            <option value="litres">litres</option>
            <option value="meters">meters</option>
            <option value="packets">packets</option>
            <option value="pieces">pieces</option>
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Expenses Ledger when stock is consumed</label>
          <select name="ledger" onChange={handleChange} value={ledger}>
            <option value="" disabled selected>
              --select--
            </option>
            {expensesLedgers?.length > 0 ? (
              <>
                {expensesLedgers?.map((ledger) => (
                  <option value={ledger?.id} key={ledger?.id}>
                    {ledger?.name}
                  </option>
                ))}
              </>
            ) : (
              <option value="" disabled>
                No expenses ledger created yet
              </option>
            )}
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Reorder Level</label>
          <input
            type="number"
            name="reorder_level"
            onChange={handleChange}
            value={reorder_level}
            min="1"
          />
        </div>

        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createStockItem: (userId, body, resetForm) =>
      dispatch(create_stock_item(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStockItem);
