import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";

import { get_users } from "../../../../redux/actions/auth";
import { START_LOADING } from "../../../../redux/actions/types";
import GroupsAllocated from "../Shared/GroupsAllocated";
import EditTeacher from "./components/EditTeacher";
import AddTeacher from "./components/AddTeacher";
import SubjectsAlloted from "./components/SubjectsAlloted";

const Teachers = (props) => {
  const { loading, teachers, userId, alloted_subjects } = props;
  const { getTeachers, startLoading } = props;

  const [currentTeacher, setCurrentTeacher] = useState({});
  const [openViewAllotedSubjects, setOpenViewAllotedSubjects] = useState(false);
  const [openAddTeacher, setOpenAddTeacher] = useState(false);
  const [openGroupsAllocated, setOpenGroupsAllocated] = useState(false);
  const [currentStaff, setCurrentStaff] = useState({});
  const [openEditTeacher, setOpenEditTeacher] = useState(false);
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [filterBy, setFilterBy] = useState("");

  useEffect(() => {
    if (userId) {
      startLoading();
      getTeachers(userId, "Teacher");
    }
  }, [getTeachers, userId, startLoading]);

  useEffect(() => {
    setFilteredTeachers(teachers);
  }, [teachers]);

  const handleOpenViewSubjectsAllotment = (teacher) => {
    setOpenViewAllotedSubjects(true);
    setCurrentTeacher(teacher);
  };

  const handleOpenGroups = (staff) => {
    setOpenGroupsAllocated(true);
    setCurrentStaff(staff);
  };

  const handleOpenEditTeacher = (teacher) => {
    setCurrentTeacher(teacher);
    setOpenEditTeacher(true);
  };

  const handleFilterByChange = (e) => {
    if (e.target.value === "all") {
      setFilteredTeachers(teachers);
    }
    setFilterBy(e.target.value);
  };

  const onFilterValueChange = (e) => {
    if (filterBy === "phone_number") {
      setFilteredTeachers(
        teachers?.filter((teacher) =>
          teacher?.phone_number?.includes(e.target.value)
        )
      );
    } else if (filterBy === "first_name") {
      setFilteredTeachers(
        teachers?.filter((teacher) =>
          teacher?.first_name
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase())
        )
      );
    } else if (filterBy === "last_name") {
      setFilteredTeachers(
        teachers?.filter((teacher) =>
          teacher?.last_name
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase())
        )
      );
    } else if (filterBy === "surname") {
      setFilteredTeachers(
        teachers?.filter((teacher) =>
          teacher?.surname
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase())
        )
      );
    }
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div className="table__parentHeader">
          <button
            type="button"
            className="add__button white"
            onClick={() => setOpenAddTeacher(true)}
          >
            Add Teacher
          </button>
          <h3 className="dodgerblue bd ud">Maintain teachers</h3>
          <div
            class="dialog__row"
            style={{ maxWidth: "800px", margin: "auto" }}
          >
            <span>
              <label for="">Select option to filter teachers</label>
              <select name="" onChange={handleFilterByChange}>
                <option value="all">--all teachers--</option>
                <option value="phone_number">Filter by Phone Number</option>
                <option value="first_name">Filter by First Name</option>
                <option value="last_name">Filter by Last Name</option>
                <option value="surname">Filter by Surname</option>
              </select>
            </span>
            {filterBy === "phone_number" && (
              <span>
                <label for="">Teacher phone number</label>
                <input type="number" onChange={onFilterValueChange} min="0" />
              </span>
            )}
            {filterBy === "first_name" && (
              <span>
                <label for="">Teacher First Name</label>
                <input type="text" onChange={onFilterValueChange} />
              </span>
            )}
            {filterBy === "last_name" && (
              <span>
                <label for="">Teacher Last Name</label>
                <input type="text" onChange={onFilterValueChange} />
              </span>
            )}
            {filterBy === "surname" && (
              <span>
                <label for="">Teacher Surname</label>
                <input type="text" onChange={onFilterValueChange} />
              </span>
            )}
          </div>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <table className="table__listing">
          {filteredTeachers?.length > 0 ? (
            <>
              <tr className="table__listingHeader">
                <th>Name</th>
                <th>Phone Number</th>
                <th>Login Status</th>
                <th>Subjects alloted</th>
                <th>Groups</th>
                <th>Edit</th>
              </tr>
              {filteredTeachers?.map((teacher) => (
                <tr className="table__listingItem" key={teacher?.id}>
                  <td>
                    {teacher?.first_name} {teacher?.last_name}{" "}
                    {teacher?.surname}
                  </td>
                  <td>{teacher?.phone_number}</td>
                  <td>
                    {teacher?.is_suspended ? (
                      <span className="red bd">Disabled</span>
                    ) : (
                      <span className="green bd">Enabled</span>
                    )}
                  </td>

                  <td
                    className="button__sp dodgerblue bd"
                    onClick={() => handleOpenViewSubjectsAllotment(teacher)}
                  >
                    view
                  </td>
                  <td
                    onClick={() => handleOpenGroups(teacher)}
                    className="dodgerblue bd button__sp"
                  >
                    Groups
                  </td>
                  <td
                    className="green bd button__sp"
                    onClick={() => handleOpenEditTeacher(teacher)}
                  >
                    Edit
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <h4 className="not__available">No teachers added yet</h4>
          )}
        </table>
      </div>
      {/* child components */}

      {openViewAllotedSubjects && (
        <SubjectsAlloted
          openViewAllotedSubjects={openViewAllotedSubjects}
          setOpenViewAllotedSubjects={setOpenViewAllotedSubjects}
          alloted_subjects={alloted_subjects?.filter(
            (subject) => subject?.teacher_id === currentTeacher?.id
          )}
          currentTeacher={currentTeacher}
        />
      )}
      {openAddTeacher && (
        <AddTeacher
          openAddTeacher={openAddTeacher}
          setOpenAddTeacher={setOpenAddTeacher}
        />
      )}
      {openGroupsAllocated && (
        <GroupsAllocated
          openGroupsAllocated={openGroupsAllocated}
          setOpenGroupsAllocated={setOpenGroupsAllocated}
          currentStaff={currentStaff}
          userType="Teacher"
        />
      )}
      {openEditTeacher && (
        <EditTeacher
          openEditTeacher={openEditTeacher}
          setOpenEditTeacher={setOpenEditTeacher}
          currentTeacher={currentTeacher}
          setCurrentTeacher={setCurrentTeacher}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    teachers: state.auth?.teachers,
    userId: state?.auth?.user?.id,
    alloted_subjects: state.course?.alloted_subjects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeachers: (userId, userType) => dispatch(get_users(userId, userType)),
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);
