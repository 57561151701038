import * as api from "../../api";
import * as actionTypes from "./types";

import { showError, stopLoading } from "./shared";

// POST request to create new exam

export const create_exam = (userId, body, resetForm) => async (dispatch) => {
  await api
    .createExam(userId, body)
    .then((res) => {
      dispatch({ type: actionTypes.NEW_EXAM, payload: res?.data?.new_exam });
      window.alert(res?.data?.detail);
      resetForm();
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// GET REQUEST to get teacher created exams
export const get_teacher_exams = (userId) => async (dispatch) => {
  await api
    .getTeacherExams(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_EXAMS,
        payload: {
          exams: res.data?.exams,
          exam_questions: res.data?.exam_questions,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// POST REQUEST TO create exam question
export const create_exam_question =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createExamQuestion(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_EXAM_QUESTION,
          payload: res.data?.new_exam_question,
        });
        resetForm();
        window.alert(res.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// PATCH REQUEST to update exam question
export const update_exam_question = (userId, body) => async (dispatch) => {
  await api
    .updateExamQuestion(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_EXAM_QUESTION,
        payload: res?.data?.updated_exam_question,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// PATCH REQUEST TO UPDATE EXAM
export const update_exam = (userId, body) => async (dispatch) => {
  await api
    .updateExam(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_EXAM,
        payload: res?.data?.updated_exam,
      });
      window.alert(res.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// DELETE REQUEST TO DELETE EXAM
export const delete_exam = (userId, examId) => async (dispatch) => {
  await api
    .deleteExam(userId, examId)
    .then((res) => {
      dispatch({
        type: actionTypes.DELETE_EXAM,
        payload: examId,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};
// DELETE REQUEST TO DELETE EXAM QUESTION
export const delete_exam_question =
  (userId, questionId) => async (dispatch) => {
    await api
      .deleteExamQuestion(userId, questionId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_EXAM_QUESTION,
          payload: questionId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// GET REQUEST for student to get exams
export const student_get_exams = (userId) => async (dispatch) => {
  await api
    .studentGetExams(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_STUDENT_EXAMS,
        payload: res.data?.student_exams_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// POST student dispatch action to submit exam
export const student_submit_exam =
  (userId, examId, body, navigate) => async (dispatch) => {
    await api
      .studentSubmitExam(userId, examId, body)
      .then((res) => {
        alert(res.data?.detail);
        navigate(`/student/reports/`, {
          replace: true,
        });
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// GET offline exams
export const get_offline_exams = (userId) => async (dispatch) => {
  await api
    .getOfflineExams(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_OFFLINE_EXAMS,
        payload: res?.data?.offline_exams_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// POST to create offline exam
export const create_offline_exam =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .createOfflineExam(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_OFFLINE_EXAM,
          payload: res?.data?.new_offline_exam,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// PATCH to edit offline exam
export const edit_offline_exam = (userId, body) => async (dispatch) => {
  await api
    .editOfflineExam(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.UPDATE_OFFLINE_EXAM,
        payload: res?.data?.updated_offline_exam,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to get assignments
export const teacher_get_assignments = (userId) => async (dispatch) => {
  await api
    .teacherGetAssignments(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_ASSIGNMENTS,
        payload: res?.data?.assignments_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to create new assignment
export const teacher_create_assignment =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .teacherCreateAssignment(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_ASSIGNMENT,
          payload: res?.data?.new_assignment,
        });
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to edit assignment
export const teacher_edit_assignment = (userId, body) => async (dispatch) => {
  await api
    .teacherEditAssignment(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_ASSIGNMENT,
        payload: res?.data?.updated_assignment,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to delete assignment
export const teacher_delete_assignment =
  (userId, assignmentId) => async (dispatch) => {
    await api
      .teacherDeleteAssignment(userId, assignmentId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_ASSIGNMENT,
          payload: assignmentId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };
