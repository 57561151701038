import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { stock_existing_book } from "../../../../redux/actions/library";
import { START_LOADING } from "../../../../redux/actions/types";
import { ifEmpty, resetFormValues } from "../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const StockExistingBook = (props) => {
  const { userId, all_books, openStockExistingBook, loading } = props;
  const { setOpenStockExistingBook, stockExistingBook, startLoading } = props;

  const [bookDetails, setBookDetails] = useState({
    book_id: "",
    quantity: "",
    unit_cost: "",
  });

  const { book_id, quantity, unit_cost } = bookDetails;

  const handleChange = (e) => {
    setBookDetails({ ...bookDetails, [e.target.name]: e.target.value });
  };
  const handleSelectionChange = (obj_item) => {};

  const resetForm = () => {
    resetFormValues(bookDetails);
  };
  const handleClose = () => {
    resetForm();
    setOpenStockExistingBook(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(bookDetails)) {
      return window.alert("Please fill in all fields");
    }
    startLoading();
    stockExistingBook(userId, bookDetails, resetForm);
  };

  return (
    <MinDialog isOpen={openStockExistingBook}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Add stock for existing book</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Select Book</label>
          <select name="book_id" onChange={handleChange} value={book_id}>
            <option value="" selected disabled>
              --select book--
            </option>
            {all_books?.map((book) => (
              <option value={book?.id} key={book?.id}>
                {book?.name}
              </option>
            ))}
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Quantity</label>
          <input
            type="number"
            name="quantity"
            onChange={handleChange}
            value={quantity}
            min="1"
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Unit Cost</label>
          <input
            type="number"
            name="unit_cost"
            onChange={handleChange}
            value={unit_cost}
            min="1"
          />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    all_books: state?.library?.all_books,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stockExistingBook: (userId, body, resetForm) =>
      dispatch(stock_existing_book(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockExistingBook);
