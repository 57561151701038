import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { edit_tax_relief } from "../../../../redux/actions/staff";
import { START_LOADING } from "../../../../redux/actions/types";
import { ifEmpty } from "../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const EditTaxRelief = (props) => {
  const {
    openEditTaxRelief,
    currentTaxRelief,
    loading,
    userId,
    salaryDeductions,
  } = props;
  const {
    setOpenEditTaxRelief,
    setCurrentTaxRelief,
    startLoading,
    editTaxRelief,
  } = props;

  const {
    name,
    relief_type,
    relief_amount,
    relief_percent,
    is_system_item,
    associatedDeductions,
    max_relief_amount,
  } = currentTaxRelief;

  const handleChange = (e) => {
    setCurrentTaxRelief({
      ...currentTaxRelief,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddDeduction = (e) => {
    // only add adeduction that has not been added yet
    if (
      !associatedDeductions?.find(
        (deduction) => deduction?.id === e.target.value
      )
    ) {
      setCurrentTaxRelief({
        ...currentTaxRelief,
        associatedDeductions: [
          ...currentTaxRelief?.associatedDeductions,
          salaryDeductions.find((item) => item?.id === e.target.value),
        ],
      });
    }
  };

  const handleRemoveDeduction = (deductionId) => {
    setCurrentTaxRelief({
      ...currentTaxRelief,
      associatedDeductions: associatedDeductions?.filter(
        (deduction) => deduction?.id !== deductionId
      ),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!is_system_item && associatedDeductions?.length === 0) {
      return window.alert("You must select the associated deductions");
    }
    if (!is_system_item && ifEmpty(currentTaxRelief)) {
      return window.alert("Fields marked with * are required");
    }

    if (
      relief_type === "fixed" &&
      (relief_amount === 0 || relief_amount === "")
    ) {
      return window.alert("You must enter the relief amount");
    } else if (
      relief_type === "variable" &&
      (relief_percent === 0 || relief_percent === "")
    ) {
      return window.alert("You must indicate the relief percent");
    }
    startLoading();
    const body = {
      ...currentTaxRelief,
      associatedDeductions: associatedDeductions?.map(
        (deduction) => deduction?.id
      ),
    };
    editTaxRelief(userId, body);
  };

  return (
    <MinDialog isOpen={openEditTaxRelief}>
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Edit tax relief details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">
            Name<span className="red">*</span>
          </label>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={name}
            disabled={is_system_item}
          />
        </div>
        {!is_system_item && (
          <div class="dialog__rowSingleItem">
            <label for="">
              Type<span className="red">*</span>
            </label>
            <select
              name="relief_type"
              onChange={handleChange}
              value={relief_type}
            >
              <option value="">--select relief type--</option>
              <option value="fixed">Fixed</option>
              <option value="variable">Variable</option>
            </select>
          </div>
        )}

        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {/* for fixed relief show amount field */}
        {relief_type === "fixed" && (
          <div class="dialog__rowSingleItem">
            <label for="">
              Relief Amount<span className="red">*</span>
            </label>
            <input
              type="number"
              name="relief_amount"
              onChange={handleChange}
              value={relief_amount}
            />
          </div>
        )}
        {relief_type === "variable" && (
          <div class="dialog__rowSingleItem">
            <label for="">
              Relief Percent(%)<span className="red">*</span>
            </label>
            <input
              type="number"
              name="relief_percent"
              onChange={handleChange}
              value={relief_percent}
            />
          </div>
        )}
        <div class="dialog__rowSingleItem">
          <label for="">
            Maximum Relief<span className="red">*</span>
          </label>
          <input
            type="number"
            name="max_relief_amount"
            onChange={handleChange}
            value={max_relief_amount}
          />
        </div>
        {!is_system_item && (
          <>
            <div class="dialog__rowSingleItem">
              <label for="">
                Select Associated Deductions<span className="red">*</span>
              </label>
              <select name="" onChange={handleAddDeduction}>
                <option value="" disabled selected>
                  --select--
                </option>
                {salaryDeductions?.map((deduction) => (
                  <option value={deduction?.id} key={deduction?.id}>
                    {deduction?.name}
                  </option>
                ))}
              </select>
            </div>
            <div class="dialog__rowSingleItem">
              <label for="">Associated deductions</label>
              {associatedDeductions?.length > 0 ? (
                <table class="table__listing">
                  <thead>
                    <tr class="table__listingHeader">
                      <th>Name</th>
                      <th className="tc">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {associatedDeductions?.map((deduction) => (
                      <tr class="table__listingItem" key={deduction?.id}>
                        <td>{deduction?.name}</td>
                        <td className="tc">
                          <i
                            className="bx bxs-trash-alt red bd button__sp"
                            onClick={() => handleRemoveDeduction(deduction?.id)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h4 class="not__available">
                  No associated deductions selected
                </h4>
              )}
            </div>
          </>
        )}
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditTaxRelief(false)}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    salaryDeductions: state?.staff?.salaryDeductions?.filter(
      (deduction) =>
        deduction?.is_active === true && deduction?.is_system_item === false
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editTaxRelief: (userId, body) => dispatch(edit_tax_relief(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTaxRelief);
