import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { START_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import { ifEmpty } from "../../../../shared/sharedFunctions";
import { edit_staff_salary_deduction } from "../../../../redux/actions/staff";

const EditStaffDeduction = (props) => {
  const {
    currentStaff,
    loading,
    userId,
    openEditStaffDeduction,
    currentStaffDeduction,
    salaryDeductions,
  } = props;
  const {
    setCurrentStaffDeduction,
    setOpenEditStaffDeduction,
    startLoading,
    editStaffSalaryDeduction,
    setCurrentStaff,
  } = props;

  const { salary_deduction, amount, is_active } = currentStaffDeduction;

  const [deductionType, setDeductionType] = useState("");

  // useEffect to set earning type to either variable or fixed
  useEffect(() => {
    if (salary_deduction !== "" && salaryDeductions?.length !== 0) {
      setDeductionType(
        salaryDeductions?.find((earning) => earning?.id === salary_deduction)[
          "deduction_type"
        ]
      );
    }
  }, [salaryDeductions, salary_deduction]);

  const handleChange = (e) => {
    setCurrentStaffDeduction({
      ...currentStaffDeduction,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setCurrentStaffDeduction({
      ...currentStaffDeduction,
      [e.target.name]: e.target.checked,
    });
  };

  const handleClose = () => {
    setOpenEditStaffDeduction(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(currentStaffDeduction)) {
      return window.alert("Fields marked with a * are required");
    }
    startLoading();
    const body = {
      ...currentStaffDeduction,
      staff: currentStaff?.id,
    };
    editStaffSalaryDeduction(userId, body, setCurrentStaff);
  };

  return (
    <MinDialog isOpen={openEditStaffDeduction}>
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Add staff deduction</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Staff Name</label>
          <input type="text" value={currentStaff?.name} disabled />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Payroll Number</label>
          <input type="text" value={currentStaff?.payroll_number} disabled />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">
            Deduction Type<span className="red">*</span>
          </label>
          <select
            name="salary_deduction"
            onChange={handleChange}
            value={salary_deduction}
          >
            {salaryDeductions?.length > 0 ? (
              <>
                <option value="">--select deduction type--</option>
                {salaryDeductions?.map((deduction) => (
                  <option value={deduction?.id} key={deduction?.id}>
                    {deduction?.name}
                  </option>
                ))}
              </>
            ) : (
              <option value="">No active deduction types found</option>
            )}
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">
            Amount<span className="red">*</span>
            {deductionType === "fixed" && <span>(This is a fixed amount)</span>}
          </label>
          <input
            type="number"
            name="amount"
            onChange={handleChange}
            value={amount}
            min="1"
            disabled={deductionType === "fixed"}
          />
        </div>
        <div class="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_active"
            checked={is_active}
            onChange={handleCheckboxChange}
          />
          <label for="">Tick this to make the staff deduction active</label>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    // we only pick the active deductions which are not system items, i.e we drop paye, relief etc, because these apply to all employees
    salaryDeductions: state?.staff?.salaryDeductions?.filter(
      (deduction) =>
        deduction?.is_active === true && deduction?.is_system_item === false
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editStaffSalaryDeduction: (userId, body, setCurrentStaff) =>
      dispatch(edit_staff_salary_deduction(userId, body, setCurrentStaff)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStaffDeduction);
