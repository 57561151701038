import React, { useRef } from "react";
import moment from "moment";
import MaxDialog from "../../../../../components/common/MaxDialog";
import globals from "../../../../../shared/globals";
import { useReactToPrint } from "react-to-print";

const StockReportTemplate = (props) => {
  const { openStockReportTemplate, report_type, report_date, reportData } =
    props;
  const { setOpenStockReportTemplate } = props;

  const { schoolName } = globals;

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: { report_type },
  });

  return (
    <MaxDialog
      isOpen={openStockReportTemplate}
      maxWidth="1200px"
      maxHeight="85vh"
    >
      <div class="dialog">
        <table
          className="table__listing report__statement"
          ref={printArea}
          border="1"
          rules="rows"
        >
          <thead>
            <tr>
              <th colSpan="7" className="dodgerblue">
                {schoolName}
              </th>
            </tr>
            <tr>
              <th colSpan="7" className="dodgerblue tc">
                {report_type}
              </th>
            </tr>
            <tr>
              <th colSpan="7" className="dodgerblue">
                On {moment(report_date).format("LL")}
              </th>
            </tr>

            <tr>
              <th className="purple tl">Stock Item</th>
              <th className="purple">Quantity</th>
              <th className="purple">Unit Cost</th>
            </tr>
          </thead>
          <tbody>
            {reportData?.stock_item_transactions_data?.map((item) => (
              <tr class="table__listingItem" key={item?.id}>
                <td>{item?.stock_item_name}</td>
                <td className="tc">{item?.quantity}</td>
                <td className="tc">{item?.unit_cost}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div class="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenStockReportTemplate(false)}
          >
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print/Save
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

export default StockReportTemplate;
