import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import MediumDialog from "../../../../components/common/MediumDialog";
import {
  ifEmpty,
  minDate,
  resetFormValues,
} from "../../../../shared/sharedFunctions";
import { START_LOADING } from "../../../../redux/actions/types";
import { CircularProgress } from "@mui/material";
import { record_book_borrow } from "../../../../redux/actions/library";

const NewBookBorrow = (props) => {
  const {
    openNewBookBorrow,
    available_books,
    teachers,
    students,
    otherstaff,
    loading,
    userId,
  } = props;
  const { setOpenNewBookBorrow, startLoading, recordBookBorrow } = props;

  const [currentChosenBook, setCurrentChosenBook] = useState({
    text_book: "", // this represents the book id, but to align to backend serializers, we use text_book, instead of book_id
    book_name: "",
    due_date: "",
    due_time: "",
  });
  const [borrowedBooks, setBorrowedBooks] = useState([]);
  const [borrowingUserId, setBorrowingUserId] = useState("");
  const [currentUserType, setCurrentUserType] = useState("");

  const resetForm = () => {
    resetFormValues(currentChosenBook);
    setBorrowingUserId("");
    setBorrowedBooks([]);
    setCurrentUserType("");
  };

  const handleClose = () => {
    resetForm();
    setOpenNewBookBorrow(false);
  };

  const handleUserChange = (e) => {
    setBorrowingUserId("");
    setCurrentUserType(e.target.value);
  };

  const handleChange = (e) => {
    if (e.target.name === "text_book") {
      const selectedBook = available_books[e.target.value];
      setCurrentChosenBook({
        ...currentChosenBook,
        text_book: selectedBook["id"],
        book_name: selectedBook["name"],
        book_code: selectedBook["book_code"],
        index: e.target.value,
      });
    } else {
      setCurrentChosenBook({
        ...currentChosenBook,
        [e.target.name]: e.target.value,
      });
    }
  };

  // function to add new book to array
  const handleAddNewBook = () => {
    if (ifEmpty(currentChosenBook)) {
      return window.alert("Indicate the chosen book and the due date and time");
    }
    const bookExists = borrowedBooks?.find(
      (book) => book?.text_book === currentChosenBook?.text_book
    );
    if (!bookExists) {
      // note we need to combine date and time to form a date time object
      setBorrowedBooks([
        ...borrowedBooks,
        {
          ...currentChosenBook,
          due_date: `${currentChosenBook?.due_date}T${currentChosenBook?.due_time}:00`,
        },
      ]);
      // note using resetFormvalues here is causing bugs, thus we use setstate
      setCurrentChosenBook({
        text_book: "",
        book_name: "",
        due_date: "",
        due_time: "",
        book_code: "",
        index: "",
      });
    } else {
      return window.alert("That book is already added to selected books");
    }
  };

  const handleRemoveBook = (bookId) => {
    setBorrowedBooks(
      borrowedBooks?.filter((book) => book?.text_book !== bookId)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (borrowingUserId?.trim() === "" || borrowedBooks?.length === 0) {
      return window.alert("Ensure you select a user and at least one book");
    }
    startLoading();
    const body = {
      borrowingUserId,
      borrowedBooks,
    };
    recordBookBorrow(userId, body, resetForm);
  };

  return (
    <MediumDialog isOpen={openNewBookBorrow} maxWidth="1000px">
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Record new book borrow</h3>
        <div class="dialog__row">
          <span>
            <label for="">User Type</label>
            <select name="" onChange={handleUserChange} value={currentUserType}>
              <option value="" disabled selected>
                --select user type--
              </option>
              <option value="otherstaff">Other Staff</option>
              <option value="students">Students</option>
              <option value="teachers">Teachers</option>
            </select>
          </span>
          <span>
            <label for="">Select user</label>
            <select
              name=""
              onChange={(e) => setBorrowingUserId(e.target.value)}
              value={borrowingUserId}
            >
              {currentUserType === "otherstaff" && (
                <>
                  <option value="" selected>
                    --choose user--
                  </option>
                  {otherstaff?.map((staff) => (
                    <option value={staff?.id} key={staff?.id}>
                      {staff?.first_name} {staff?.last_name} {staff?.surname}
                    </option>
                  ))}
                </>
              )}
              {currentUserType === "students" && (
                <>
                  <option value="" selected>
                    --choose user--
                  </option>
                  {students?.map((student) => (
                    <option value={student?.id} key={student?.id}>
                      {student?.admission_number} - {student?.first_name}{" "}
                      {student?.last_name} {student?.surname}
                    </option>
                  ))}
                </>
              )}

              {currentUserType === "teachers" && (
                <>
                  <option value="" selected>
                    --choose user--
                  </option>
                  {teachers?.map((teacher) => (
                    <option value={teacher?.id} key={teacher?.id}>
                      {teacher?.first_name} {teacher?.last_name}{" "}
                      {teacher?.surname}
                    </option>
                  ))}
                </>
              )}
            </select>
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Choose book</label>
            <select
              name="text_book"
              onChange={handleChange}
              value={currentChosenBook?.index}
            >
              <option value="" disabled selected>
                --choose book--
              </option>
              {available_books?.map((book, index) => (
                <option value={index} key={book?.id}>
                  {book?.name} - Code: {book?.book_code}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label for="">Due Date</label>
            <input
              type="date"
              name="due_date"
              onChange={handleChange}
              min={minDate()}
              value={currentChosenBook?.due_date}
            />
          </span>
          <span>
            <label for="">Due Time</label>
            <input
              type="time"
              name="due_time"
              onChange={handleChange}
              value={currentChosenBook?.due_time}
            />
          </span>
        </div>
        <button
          type="button"
          className="add__button"
          style={{ marginLeft: "10px" }}
          onClick={handleAddNewBook}
        >
          Click to select chosen book
        </button>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Books currently selected</label>
          {borrowedBooks?.length > 0 ? (
            <table class="table__listing">
              <thead>
                <tr class="table__listingHeader">
                  <th>Book Name</th>
                  <th>Due Date</th>
                  <th className="tc">Remove</th>
                </tr>
              </thead>
              <tbody>
                {borrowedBooks?.map((book) => (
                  <tr class="table__listingItem" key={book?.text_book}>
                    <td>
                      {book?.book_name} - Code: {book?.book_code}
                    </td>
                    <td>{moment(book?.due_date).format("LLL")}</td>
                    <td className="tc">
                      <i
                        className="bx bxs-trash-alt red button__sp"
                        onClick={() => handleRemoveBook(book?.text_book)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 class="red">No books currently selected</h4>
          )}
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    available_books: state?.library?.all_books?.filter(
      (book) => book?.quantity > 0
    ),
    teachers: state?.auth?.teachers,
    students: state?.auth?.students,
    otherstaff: state?.auth?.otherstaff,
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    recordBookBorrow: (userId, body, resetForm) =>
      dispatch(record_book_borrow(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBookBorrow);
