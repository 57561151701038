import React, { useState } from "react";
import { connect } from "react-redux";

import MediumDialog from "../../../../../components/common/MediumDialog";
import { create_new_user } from "../../../../../redux/actions/auth";
import { START_LOADING } from "../../../../../redux/actions/types";

import {
  ifEmpty,
  maxDate,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";

const AddStudent = (props) => {
  const { openAddStudent, class_streams, loading, userId, active_dorms } =
    props;
  const { setOpenAddStudent, startLoading, createNewUser } = props;

  const [studentDetails, setStudentDetails] = useState({
    first_name: "",
    last_name: "",
    surname: "",
    gender: "",
    date_of_birth: "",
    admission_number: "",
    guardian_phone: "",
    class_stream: "",
  });
  const [dorm, setDorm] = useState("");

  const {
    first_name,
    last_name,
    surname,
    gender,
    date_of_birth,
    admission_number,
    guardian_phone,
    class_stream,
  } = studentDetails;

  const handleChange = (e) => {
    setStudentDetails({ ...studentDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    resetFormValues(studentDetails);
    setDorm("");
  };

  const handleClose = () => {
    setOpenAddStudent(false);
    resetForm();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(studentDetails)) {
      return window.alert("Please fill in all fields");
    }
    startLoading();
    const body = {
      ...studentDetails,
      dorm,
      userType: "Student",
    };
    createNewUser(userId, body, resetForm);
  };

  return (
    <MediumDialog isOpen={openAddStudent} maxWidth="1100px">
      <form
        className="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Enter student details</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={first_name}
            />
          </span>
          <span>
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={last_name}
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Surname</label>
            <input
              type="text"
              name="surname"
              onChange={handleChange}
              value={surname}
            />
          </span>
          <span>
            <label htmlFor="">Gender</label>
            <select name="gender" onChange={handleChange} value={gender}>
              <option value="" selected disabled>
                Select gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <span>
            <label htmlFor="">Date of Birth</label>
            <input
              type="date"
              name="date_of_birth"
              onChange={handleChange}
              value={date_of_birth}
              max={maxDate()}
            />
          </span>
          <span>
            <label htmlFor="">Admission Number</label>
            <input
              type="text"
              name="admission_number"
              onChange={handleChange}
              value={admission_number}
            />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Parent/Guardian Phone</label>
            <input
              type="text"
              name="guardian_phone"
              onChange={handleChange}
              value={guardian_phone}
            />
          </span>
          <span>
            <label htmlFor="">Class Stream</label>
            <select
              name="class_stream"
              onChange={handleChange}
              value={class_stream}
            >
              <option value="">Select class stream</option>
              {class_streams?.map((class_stream) => (
                <option value={class_stream?.id} key={class_stream?.id}>
                  {class_stream?.name}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div class="dialog__row">
          <span>
            <label for="">Dorm (optional)</label>
            <select
              name=""
              onChange={(e) => setDorm(e.target.value)}
              value={dorm}
            >
              <option value="" disabled selected>
                --select dorm--
              </option>
              {active_dorms?.length > 0 ? (
                <>
                  {active_dorms?.map((dorm) => (
                    <option value={dorm?.name} key={dorm?.id}>
                      {dorm?.name}
                    </option>
                  ))}
                </>
              ) : (
                <option value="" disabled>
                  No active dorms available
                </option>
              )}
            </select>
          </span>
          <span></span>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    class_streams: state.course?.class_streams,
    loading: state?.shared?.loading,
    userId: state.auth?.user?.id,
    active_dorms: state?.accomodation?.active_dorms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createNewUser: (userId, body, resetForm) =>
      dispatch(create_new_user(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStudent);
