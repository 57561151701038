import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  school_services: [],
  parent_ledger_groups: [],
  ledger_groups: [],
  ledgers: [],
  reportTypes: [],
  specificReports: [],
};

// set report types and specific reports
const setReports = (state, payload) => {
  return updateObject(state, {
    reportTypes: payload.reportTypes,
    specificReports: payload.specificReports,
  });
};

const newSchoolService = (state, payload) => {
  return updateObject(state, {
    school_services: [...state.school_services, payload],
  });
};

const editSchoolService = (state, payload) => {
  return updateObject(state, {
    school_services: state?.school_services?.map((service) =>
      service?.id === payload?.id ? payload : service
    ),
  });
};

const setSchoolServices = (state, payload) => {
  return updateObject(state, {
    school_services: payload,
  });
};

const setLedgers = (state, payload) => {
  return updateObject(state, {
    ledgers: payload.ledgers,
    ledger_groups: payload.ledger_groups,
    parent_ledger_groups: payload.parent_ledger_groups,
  });
};

const newLedgerGroup = (state, payload) => {
  return updateObject(state, {
    ledger_groups: [...state.ledger_groups, payload],
  });
};

const newLedger = (state, payload) => {
  return updateObject(state, {
    ledgers: [...state.ledgers, payload],
  });
};

const editLedger = (state, payload) => {
  return updateObject(state, {
    ledgers: state?.ledgers?.map((ledger) =>
      ledger?.id === payload?.id ? payload : ledger
    ),
  });
};
const editLedgerGroup = (state, payload) => {
  return updateObject(state, {
    ledger_groups: state?.ledger_groups?.map((ledger_group) =>
      ledger_group?.id === payload?.id ? payload : ledger_group
    ),
  });
};

const deleteLedgerOrGroup = (state, payload) => {
  if (payload?.ledgerOrGroup === "ledger") {
    return updateObject(state, {
      ledgers: state?.ledgers?.filter(
        (ledger) => ledger?.id !== payload?.itemId
      ),
    });
  } else if (payload?.ledgerOrGroup === "group") {
    return updateObject(state, {
      ledger_groups: state?.ledger_groups?.filter(
        (ledger_group) => ledger_group?.id !== payload?.itemId
      ),
    });
  }
};

const deleteSchoolService = (state, payload) => {
  return updateObject(state, {
    school_services: state?.school_services?.filter(
      (item) => item?.id !== payload
    ),
  });
};

const financeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.NEW_SCHOOL_SERVICE:
      return newSchoolService(state, payload);
    case actionTypes.SET_SCHOOL_SERVICES:
      return setSchoolServices(state, payload);
    case actionTypes.SET_LEDGERS:
      return setLedgers(state, payload);
    case actionTypes.NEW_LEDGER_GROUP:
      return newLedgerGroup(state, payload);
    case actionTypes.NEW_LEDGER:
      return newLedger(state, payload);
    case actionTypes.EDIT_LEDGER:
      return editLedger(state, payload);
    case actionTypes.EDIT_LEDGER_GROUP:
      return editLedgerGroup(state, payload);
    case actionTypes.SET_REPORTS:
      return setReports(state, payload);
    case actionTypes.EDIT_SCHOOL_SERVICE:
      return editSchoolService(state, payload);
    case actionTypes.DELETE_LEDGER_OR_GROUP:
      return deleteLedgerOrGroup(state, payload);
    case actionTypes.DELETE_SCHOOL_SERVICE:
      return deleteSchoolService(state, payload);
    default:
      return state;
  }
};

export default financeReducer;
