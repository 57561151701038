import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { edit_offline_exam } from "../../../../../redux/actions/exam";
import { START_LOADING } from "../../../../../redux/actions/types";

const EditOfflineExam = (props) => {
  const {
    openEditOfflineExam,
    currentOfflineExam,
    loading,
    school_terms,
    userId,
  } = props;
  const {
    setOpenEditOfflineExam,
    setCurrentOfflineExam,
    startLoading,
    editOfflineExam,
  } = props;

  const { school_term, exam_type, name } = currentOfflineExam;

  const handleChange = (e) => {
    setCurrentOfflineExam({
      ...currentOfflineExam,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setOpenEditOfflineExam(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    editOfflineExam(userId, currentOfflineExam);
  };

  return (
    <MediumDialog isOpen={openEditOfflineExam} maxWidth="600px">
      <form class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Edit offline exam</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Exam Name</label>
          <input type="text" name="name" value={name} onChange={handleChange} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">School Term</label>
          <select
            name="school_term"
            onChange={handleChange}
            value={school_term}
          >
            <option value="" disabled selected>
              --select--
            </option>
            {school_terms?.map((term) => (
              <option value={term?.id} key={term?.id}>
                {term?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Exam Type</label>
          <select name="exam_type" onChange={handleChange} value={exam_type}>
            <option value="" disabled selected>
              --select--
            </option>
            <option value="end_year">end year exam</option>
            <option value="intermediate_exam">intermediate exam</option>
          </select>
        </div>
        <br />

        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    school_terms: state.course?.school_terms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editOfflineExam: (userId, body) =>
      dispatch(edit_offline_exam(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOfflineExam);
