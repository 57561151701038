import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  delete_salary_earning,
  get_salary_earnings,
} from "../../../redux/actions/staff";
import { START_LOADING } from "../../../redux/actions/types";
import { formatWithCommas } from "../../../shared/sharedFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import AddSalaryEarning from "./components/AddSalaryEarning";
import EditSalaryEarning from "./components/EditSalaryEarning";

const SalaryEarnings = (props) => {
  const { userId, loading, salaryEarnings } = props;
  const { startLoading, getSalaryEarnings, deleteSalaryEarning } = props;

  const [openAddSalaryEarning, setOpenAddSalaryEarning] = useState(false);
  const [openEditSalaryEarning, setOpenEditSalaryEarning] = useState(false);
  const [currentSalaryEarning, setCurrentSalaryEarning] = useState({});

  useEffect(() => {
    if (userId) {
      startLoading();
      getSalaryEarnings(userId);
    }
  }, [getSalaryEarnings, userId, salaryEarnings?.length, startLoading]);

  const handleOpenEditSalaryEarning = (earning) => {
    setCurrentSalaryEarning(earning);
    setOpenEditSalaryEarning(true);
  };

  const handleDeleteSalaryEarning = (e, salaryEarningId) => {
    e.preventDefault();
    if (
      window.confirm(
        "This action is not reversible. Are you sure you want to delete this salary earning"
      )
    ) {
      startLoading();
      deleteSalaryEarning(userId, salaryEarningId);
    }
  };

  return (
    <>
      <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
        <div class="table__parentHeader">
          <button type="button" onClick={() => setOpenAddSalaryEarning(true)}>
            Add Earning Type
          </button>
          <h3>Maintain salary earnings types</h3>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {salaryEarnings?.length > 0 ? (
          <table class="table__listing">
            <thead>
              <tr class="table__listingHeader">
                <th>Name</th>
                <th>Type</th>
                <th className="tc">Amount</th>
                <th>Tax Status</th>
                <th className="tc">Status</th>
                <th className="tc">Edit</th>
                <th class="tc">Delete</th>
              </tr>
            </thead>
            <tbody>
              {salaryEarnings?.map((item) => (
                <tr class="table__listingItem" key={item?.id}>
                  <td>{item?.name}</td>
                  <td>{item?.earning_type}</td>
                  <td className="tc">
                    {item?.earning_type === "fixed" ? (
                      <span>{formatWithCommas(item?.amount)}</span>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                  <td>{item?.taxable_status}</td>
                  <td className="tc">
                    {item?.is_active ? (
                      <span className="green bd">Active</span>
                    ) : (
                      <span className="red bd">Inactive</span>
                    )}
                  </td>
                  <td
                    className="tc button__sp bd dodgerblue"
                    onClick={() => handleOpenEditSalaryEarning(item)}
                  >
                    Edit
                  </td>
                  <td className="tc">
                    <i
                      className="bx bxs-trash-alt red button__sp"
                      onClick={(e) => handleDeleteSalaryEarning(e, item?.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 class="not__available">No salary earning types added</h4>
        )}
      </div>
      {/* child components */}
      {openAddSalaryEarning && (
        <AddSalaryEarning
          openAddSalaryEarning={openAddSalaryEarning}
          setOpenAddSalaryEarning={setOpenAddSalaryEarning}
        />
      )}
      {openEditSalaryEarning && (
        <EditSalaryEarning
          openEditSalaryEarning={openEditSalaryEarning}
          setOpenEditSalaryEarning={setOpenEditSalaryEarning}
          currentSalaryEarning={currentSalaryEarning}
          setCurrentSalaryEarning={setCurrentSalaryEarning}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    salaryEarnings: state?.staff?.salaryEarnings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getSalaryEarnings: (userId) => dispatch(get_salary_earnings(userId)),
    deleteSalaryEarning: (userId, salaryEarningId) =>
      dispatch(delete_salary_earning(userId, salaryEarningId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalaryEarnings);
