import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";
import { edit_overall_grade } from "../../../../../redux/actions/course";
import { START_LOADING } from "../../../../../redux/actions/types";

const EditOverallGrade = (props) => {
  const { loading, currentOverallGrade, openEditOverallGrade, userId } = props;
  const {
    setCurrentOverallGrade,
    setOpenEditOverallGrade,
    editOverallGrade,
    startLoading,
  } = props;

  const { grade, points } = currentOverallGrade;

  const handleChange = (e) => {
    setCurrentOverallGrade({
      ...currentOverallGrade,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    editOverallGrade(userId, currentOverallGrade);
  };

  return (
    <MediumDialog isOpen={openEditOverallGrade}>
      <form
        class="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>New Overall Grade</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Grade</label>
          <input
            type="text"
            name="grade"
            onChange={handleChange}
            value={grade}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Points (0 - 12)</label>
          <input
            type="number"
            name="points"
            onChange={handleChange}
            value={points}
            required
          />
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenEditOverallGrade(false)}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    editOverallGrade: (userId, body) =>
      dispatch(edit_overall_grade(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOverallGrade);
