// import installed packages
import { useState } from "react";
import { connect } from "react-redux";
// import styles
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
import globals from "../../../../../shared/globals";
// import components/pages
import MinDialog from "../../../../../components/common/MinDialog";
// import redux API
import { START_LOADING } from "../../../../../redux/actions/types";
import { create_new_term } from "../../../../../redux/actions/course";

const AddTerm = (props) => {
  const { loading, openTermForm, userId } = props; // get state from props
  const { startLoading, setOpenTermForm, createNewTerm } = props; // get dispatch actions from props

  const { fillFields } = globals;

  const [newTerm, setNewTerm] = useState({
    starting_date: "",
    ending_date: "",
    name: "",
  });

  // destructure
  const { starting_date, ending_date, name } = newTerm;

  const handleChange = (e) => {
    e.preventDefault();
    setNewTerm({ ...newTerm, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    resetFormValues(newTerm);
  };

  const handleFormClose = () => {
    setOpenTermForm(false);
    resetForm();
  };

  // function to handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(newTerm)) {
      return alert(fillFields);
    }
    if (starting_date > ending_date) {
      return alert("Ending date must be bigger than starting date");
    }
    startLoading();
    createNewTerm(newTerm, userId, resetForm);
  };

  return (
    <MinDialog isOpen={openTermForm}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter term details</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Name</label>
          <input type="text" name="name" value={name} onChange={handleChange} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Start date</label>
          <input
            type="date"
            name="starting_date"
            value={starting_date}
            onChange={handleChange}
          />
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Ending date</label>
          <input
            type="date"
            name="ending_date"
            value={ending_date}
            onChange={handleChange}
          />
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleFormClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    userId: state.auth.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createNewTerm: (newTerm, userId, resetForm) =>
      dispatch(create_new_term(newTerm, userId, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTerm);
