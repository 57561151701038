import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/common/MinDialog";
import { START_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import { create_salary_deduction } from "../../../../redux/actions/staff";

const AddSalaryDeduction = (props) => {
  const { openAddSalaryDeduction, loading, userId } = props;
  const { setOpenAddSalaryDedution, startLoading, createSalaryDeduction } =
    props;

  const [deductionDetails, setDeductionDetails] = useState({
    name: "",
    deduction_type: "",
    is_asset: false,
    is_active: true,
    tax_allowable_status: "",
    deductable_percent: 0,
    max_deductable: 0,
    amount: 0, // we initialize this to 0 to avoid errors in case the deduction_type is variable
  });

  const {
    name,
    deduction_type,
    is_asset,
    is_active,
    tax_allowable_status,
    amount,
    deductable_percent,
    max_deductable,
  } = deductionDetails;

  const resetForm = () => {
    setDeductionDetails({
      name: "",
      deduction_type: "",
      is_asset: false,
      is_active: false,
      tax_allowable_status: "",
      deductable_percent: 0,
      max_deductable: 0,
      amount: 0,
    });
  };

  const handleClose = () => {
    resetForm();
    setOpenAddSalaryDedution(false);
  };

  const handleChange = (e) => {
    setDeductionDetails({
      ...deductionDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handleCheckboxChange = (e) => {
    setDeductionDetails({
      ...deductionDetails,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      deduction_type?.trim() === "" ||
      name?.trim() === "" ||
      tax_allowable_status.trim() === ""
    ) {
      return window.alert("Fields marked with * are required");
    }

    // for tax deductible items, max_deductable and deductable percent must be specified
    if (tax_allowable_status === "tax_deductible") {
      // confirm values exist
      if (max_deductable === "" || deductable_percent === "") {
        return window.alert("Fields marked with * are required");
      }
      // ensure deductable_percent is between 0.1 and 100
      if (deductable_percent < 0.1 || deductable_percent > 100) {
        return window.alert("Amount deductible must be between 0.1% and 100%");
      }
    }
    // for fixed deduction ensure amount is set
    if (deduction_type === "fixed" && (amount === null || amount === "")) {
      return window.alert("Fixed deduction must have an amount");
    }
    startLoading();
    const body = {
      ...deductionDetails,
    };
    createSalaryDeduction(userId, body, resetForm);
  };

  return (
    <MinDialog isOpen={openAddSalaryDeduction}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Enter deduction details</h3>
        <div class="dialog__rowSingleItem">
          <label for="">Name*</label>
          <input type="text" name="name" value={name} onChange={handleChange} />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__rowSingleItem">
          <label for="">Deduction Type*</label>
          <select
            name="deduction_type"
            onChange={handleChange}
            value={deduction_type}
          >
            <option value="">--select type--</option>
            <option value="fixed">Fixed</option>
            <option value="variable">Variable (Set at staff level)</option>
          </select>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Amount*</label>
          <input
            type="number"
            name="amount"
            onChange={handleChange}
            value={amount}
            disabled={deduction_type !== "fixed"}
          />
        </div>
        <div className="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_asset"
            onChange={handleCheckboxChange}
            checked={is_asset}
          />
          <label for="">Tick this if asset item (e.g salary advance)</label>
        </div>
        <div class="dialog__rowCheckbox">
          <input
            type="checkbox"
            name="is_active"
            checked={is_active}
            onChange={handleCheckboxChange}
          />
          <label for="">Tick this to make the earning type active</label>
        </div>
        <br />
        <div class="dialog__rowSingleItem">
          <label for="" style={{ color: "red", textDecoration: "underline" }}>
            For tax computation purposes
          </label>
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Tax Deductible Status*</label>
          <select
            name="tax_allowable_status"
            onChange={handleChange}
            value={tax_allowable_status}
          >
            <option value="">--select status--</option>
            <option value="not_deductible">Not Deductible</option>
            <option value="tax_deductible">Tax Deductible</option>
          </select>
        </div>
        {/* for tax deductible we show field for amount deductible and maximum deductible */}
        {tax_allowable_status === "tax_deductible" && (
          <>
            <div class="dialog__rowSingleItem">
              <label for="">Amount Deductable* (1-100%)</label>
              <input
                type="number"
                name="deductable_percent"
                onChange={handleChange}
                value={deductable_percent}
                max="100"
              />
            </div>
            <div class="dialog__rowSingleItem">
              <label for="">Maximum Deductable* (number)</label>
              <input
                type="number"
                name="max_deductable"
                onChange={handleChange}
                value={max_deductable}
                min="1"
              />
            </div>
          </>
        )}

        <div class="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createSalaryDeduction: (userId, body, resetForm) =>
      dispatch(create_salary_deduction(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSalaryDeduction);
