import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import { get_users } from "../../../../redux/actions/auth";
import { START_LOADING } from "../../../../redux/actions/types";
import AddParent from "./components/AddParent";
import EditParent from "./components/EditParent";

const Parents = (props) => {
  const { loading, parents, userId } = props;
  const { startLoading, getParents } = props;
  const [openAddParent, setOpenAddParent] = useState(false);
  const [currentParent, setCurrentParent] = useState({});
  const [openEditParent, setOpenEditParent] = useState(false);
  const [filteredParents, setFilteredParents] = useState([]);
  const [filterBy, setFilterBy] = useState("");

  useEffect(() => {
    if (userId) {
      startLoading();
      getParents(userId, "Parent");
    }
  }, [getParents, userId, startLoading]);

  useEffect(() => {
    setFilteredParents(parents);
  }, [parents]);

  const handleOpenEditParent = (parent) => {
    setCurrentParent(parent);
    setOpenEditParent(true);
  };

  const handleFilterByChange = (e) => {
    if (e.target.value === "all") {
      setFilteredParents(parents);
    }
    setFilterBy(e.target.value);
  };

  const onFilterValueChange = (e) => {
    if (filterBy === "phone_number") {
      setFilteredParents(
        parents?.filter((parent) =>
          parent?.phone_number?.includes(e.target.value)
        )
      );
    } else if (filterBy === "first_name") {
      setFilteredParents(
        parents?.filter((parent) =>
          parent?.first_name
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase())
        )
      );
    } else if (filterBy === "last_name") {
      setFilteredParents(
        parents?.filter((parent) =>
          parent?.last_name
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase())
        )
      );
    } else if (filterBy === "surname") {
      setFilteredParents(
        parents?.filter((parent) =>
          parent?.surname
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase())
        )
      );
    }
  };

  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <button
            type="button"
            className="add__button white"
            onClick={() => setOpenAddParent(true)}
          >
            Add Parent
          </button>
          <h3 className="dodgerblue bd ud">Maintain parents</h3>
          <div
            class="dialog__row"
            style={{ maxWidth: "800px", margin: "auto" }}
          >
            <span>
              <label for="">Select option to filter parents</label>
              <select name="" onChange={handleFilterByChange}>
                <option value="all">--all parents--</option>
                <option value="phone_number">Filter by Phone Number</option>
                <option value="first_name">Filter by First Name</option>
                <option value="last_name">Filter by Last Name</option>
                <option value="surname">Filter by Surname</option>
              </select>
            </span>
            {filterBy === "phone_number" && (
              <span>
                <label for="">Parent phone number</label>
                <input type="number" onChange={onFilterValueChange} min="0" />
              </span>
            )}
            {filterBy === "first_name" && (
              <span>
                <label for="">Parent First Name</label>
                <input type="text" onChange={onFilterValueChange} />
              </span>
            )}
            {filterBy === "last_name" && (
              <span>
                <label for="">Parent Last Name</label>
                <input type="text" onChange={onFilterValueChange} />
              </span>
            )}
            {filterBy === "surname" && (
              <span>
                <label for="">Parent Surname</label>
                <input type="text" onChange={onFilterValueChange} />
              </span>
            )}
          </div>
        </div>
        <br />
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <table className="table__listing">
          {filteredParents?.length > 0 ? (
            <>
              <tr className="table__listingHeader">
                <th>Name</th>
                <th>Phone</th>
                <th>Status</th>
                <th>Edit</th>
              </tr>
              {filteredParents?.map((parent) => (
                <tr className="table__listingItem" key={parent?.id}>
                  <td>
                    {parent?.first_name} {parent?.last_name} {parent?.surname}
                  </td>
                  <td>{parent?.phone_number}</td>
                  <td>
                    {parent?.is_suspended ? (
                      <span className="red bd">Disabled</span>
                    ) : (
                      <span className="green bd">Enabled</span>
                    )}
                  </td>
                  <td
                    className="green bd button__sp"
                    onClick={() => handleOpenEditParent(parent)}
                  >
                    Edit
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <h4 className="not__available">No parents available</h4>
          )}
        </table>
      </div>
      {/* child components */}

      {openAddParent && (
        <AddParent
          openAddParent={openAddParent}
          setOpenAddParent={setOpenAddParent}
        />
      )}
      {openEditParent && (
        <EditParent
          openEditParent={openEditParent}
          setOpenEditParent={setOpenEditParent}
          currentParent={currentParent}
          setCurrentParent={setCurrentParent}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    parents: state?.auth?.parents,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getParents: (userId, userType) => dispatch(get_users(userId, userType)),
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Parents);
