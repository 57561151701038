import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import MediumDialog from "../../../../components/common/MediumDialog";
import { update_book_borrow } from "../../../../redux/actions/library";
import { START_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";

const UpdateBookBorrow = (props) => {
  const { openUpdateBookBorrow, currentBookBorrow, userId, loading } = props;
  const {
    setOpenUpdateBookBorrow,
    setCurrentBookBorrow,
    updateBookBorrow,
    startLoading,
  } = props;

  const { book_name, book_code, due_date, status } = currentBookBorrow;

  const handleChange = (e) => {
    setCurrentBookBorrow({
      ...currentBookBorrow,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setOpenUpdateBookBorrow(false);
  };

  const handleSubmit = (e) => {
    if (status !== "borrowed") {
      startLoading();
      updateBookBorrow(userId, { ...currentBookBorrow }, resetForm);
    } else {
      return window.alert("You have not made any status change");
    }
  };

  return (
    <MediumDialog isOpen={openUpdateBookBorrow}>
      <div class="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Update book borrow</h3>
        <div class="dialog__row">
          <span>
            <label for="">Book Name</label>
            <input type="text" value={book_name} disabled />
          </span>
          <span>
            <label for="">Book Code</label>
            <input type="text" value={book_code} disabled />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div class="dialog__row">
          <span>
            <label for="">Due Date</label>
            <input
              type="text"
              value={moment(due_date).format("LLL")}
              disabled
            />
          </span>
          <span>
            <label for="">Change Status</label>
            <select name="status" onChange={handleChange} value={status}>
              <option value="borrowed">Borrowed</option>
              <option value="lost">Lost</option>
              <option value="returned">Returned</option>
            </select>
          </span>
        </div>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenUpdateBookBorrow(false)}>
            Close
          </button>
          <button type="button" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBookBorrow: (userId, body, resetForm) =>
      dispatch(update_book_borrow(userId, body, resetForm)),
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBookBorrow);
