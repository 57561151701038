import React, { useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../../components/common/MediumDialog";

import {
  ifEmpty,
  resetFormValues,
} from "../../../../../shared/sharedFunctions";
import { START_LOADING } from "../../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import { create_subject } from "../../../../../redux/actions/course";

const AddSubject = (props) => {
  const { openAddSubject, loading, userId, subject_groups } = props;
  const { setOpenAddSubject, startLoading, createSubject } = props;

  const [subjectDetails, setSubjectDetails] = useState({
    name: "",
    short_name: "",
    subject_group: "",
  });

  const { name, short_name, subject_group } = subjectDetails;

  const handleChange = (e) => {
    setSubjectDetails({ ...subjectDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    resetFormValues(subjectDetails);
  };

  const handleClose = () => {
    resetForm();
    setOpenAddSubject(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(subjectDetails)) {
      return window.alert("Fill in all fields");
    }
    startLoading();
    createSubject(userId, { ...subjectDetails }, resetForm);
  };

  return (
    <MediumDialog isOpen={openAddSubject} maxWidth="500px">
      <form
        className="dialog"
        id={loading ? "formSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Enter subject details</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Subject Name</label>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={name}
            placeholder="e.g English"
            required
          />
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Short Name</label>
          <input
            type="text"
            name="short_name"
            onChange={handleChange}
            value={short_name}
            placeholder="e.g Eng"
            required
          />
        </div>
        <div class="dialog__rowSingleItem">
          <label for="">Subject Group</label>
          <select
            name="subject_group"
            onChange={handleChange}
            value={subject_group}
            required
          >
            <option value="">--select--</option>
            {subject_groups?.map((group) => (
              <option value={group?.id} key={group?.id}>
                {group?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state.auth?.user?.id,
    subject_groups: state?.course?.subject_groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    createSubject: (userId, body, resetForm) =>
      dispatch(create_subject(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSubject);
