import React, { useEffect } from "react";
import { connect } from "react-redux";
import { get_lost_book_charges } from "../../../redux/actions/library";
import { START_LOADING } from "../../../redux/actions/types";

const LostBookCharges = (props) => {
  const { loading, userId, lost_book_charges } = props;
  const { startLoading, getLostBookCharges } = props;

  useEffect(() => {
    if (userId) {
      startLoading();
      getLostBookCharges(userId);
    }
  }, [userId, startLoading, getLostBookCharges]);

  return (
    <div className="table__parent" id={loading ? "pageSubmitting" : ""}>
      <div class="table__parentHeader">
        <h3>Lost book charges</h3>
      </div>
      {lost_book_charges?.length > 0 ? (
        <table class="table__listing">
          <thead>
            <tr class="table__listingHeader">
              <th>Book</th>
              <th>User</th>
              <th>Amount Charged</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {lost_book_charges?.map((charge) => (
              <tr class="table__listingItem" key={charge?.id}>
                <td>{charge?.book}</td>
                <td>{charge?.user}</td>
                <td>{charge?.amount}</td>
                <td>{charge?.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h4 class="not__available">No lost book charges</h4>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    lost_book_charges: state?.library?.lost_book_charges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getLostBookCharges: (userId) => dispatch(get_lost_book_charges(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LostBookCharges);
