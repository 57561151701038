// import installed packages
import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import styles
import "./Reports.scss";
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import globals from "../../../shared/globals";
import API from "../../../shared/axios";
import { ifEmpty } from "../../../shared/sharedFunctions";
// import components/pages

// import redux API
import { get_report_types } from "../../../redux/actions/finance";
import { START_LOADING, STOP_LOADING } from "../../../redux/actions/types";

import TrialBalanceTemplate from "./components/TrialBalanceTemplate/TrialBalanceTemplate";
import ProfitLoss from "./components/ProfitLoss/ProfitLoss";
import BalanceSheet from "./components/BalanceSheet/BalanceSheet";
import LedgerReport from "./components/LedgerReport/LedgerReport";
import { get_class_streams } from "../../../redux/actions/course";
import { showError } from "../../../redux/actions/shared";
import StudentBalances from "./components/StudentBalances/StudentBalances";
import StaffNetPayBalances from "./components/StaffNetPayBalances/StaffNetPayBalances";
import { human_resource_get_staff } from "../../../redux/actions/staff";
import StaffLedgerStatement from "./components/StaffLedgerStatement/StaffLedgerStatement";

const Reports = (props) => {
  const {
    loading,
    userId,
    reportTypes,
    specificReports,
    class_streams,
    allStaff,
  } = props; // get state from props
  const {
    startLoading,
    getReportTypes,
    stopLoading,
    getClassStreams,
    getAllStaff,
  } = props; // get dispatch actions from props

  const { schoolName } = globals;

  const [openReportTemplate, setOpenReportTemplate] = useState(false);
  const [reportData, setReportData] = useState({});

  const [currentReport, setCurrentReport] = useState({
    currentReportType: "", // report type
    currentSpecificReport: "", // specific report
    start_date: "",
    end_date: "",
  });

  const [classStream, setClassStream] = useState("all_streams");
  const [currentStaff, setCurrentStaff] = useState({});

  // destructure

  const { currentReportType, currentSpecificReport, start_date, end_date } =
    currentReport;

  // useeffect to get all staff
  useEffect(() => {
    if (userId) {
      startLoading();
      getAllStaff(userId);
    }
  }, [startLoading, userId, getAllStaff]);

  // useEffect to get report types listing
  useEffect(() => {
    if (userId) {
      startLoading();
      getReportTypes(userId);
    }
  }, [userId, startLoading, getReportTypes]);

  // useEffect to get classes and class streams in case they are not in the redux state
  useEffect(() => {
    if (userId) {
      startLoading();
      getClassStreams(userId);
    }
  }, [userId, startLoading, getClassStreams]);

  const handleChange = (e) => {
    setCurrentReport({ ...currentReport, [e.target.name]: e.target.value });
  };

  const handleStaffChange = (e) => {
    // since we are doing strict equality, we must convert e.target.value to number since it's originally a string
    setCurrentStaff(
      allStaff?.find((staff) => staff?.id === Number(e.target.value))
    );
  };

  // function to submit generate report request
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      currentSpecificReport !== "Balance Sheet" &&
      currentSpecificReport !== "Student Balances" &&
      currentSpecificReport !== "Staff Net Pay Balances" &&
      ifEmpty(currentReport)
    ) {
      return alert("Please fill in all fields");
    } else if (
      (currentSpecificReport === "Balance Sheet" ||
        currentSpecificReport === "Student Balances" ||
        currentSpecificReport === "Staff Net Pay Balances") &&
      end_date === ""
    ) {
      return alert("Please fill in all fields");
    }
    if (end_date < start_date) {
      return alert("End date must be greater than start date.");
    }
    // for staff ledger statement, we confirm staff is selected
    if (
      currentSpecificReport === "Staff Ledger Statement" &&
      currentStaff?.id === undefined
    ) {
      return window.alert("You must select the staff to generate report for");
    }
    startLoading();
    let body;
    let url;
    if (currentReportType === "Student Financial Reports") {
      body = { ...currentReport, classStream, currentSpecificReport };
      url = `/api/finance/generate-student-financial-reports/${userId}/`;
    } else if (currentReportType === "Salary Reports") {
      body = { ...currentReport, currentStaffId: currentStaff.id };
      url = `/api/finance/generate-staff-salary-reports/${userId}/`;
    } else {
      body = { ...currentReport };
      url = `/api/finance/maintain-specific-report/${userId}/`;
    }

    const fetchReport = async () => {
      const res = await API.post(url, body);
      setReportData(res.data?.report_data);
      // open the report template here
      setOpenReportTemplate(true);
    };
    fetchReport()
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <form
        className="reports dialog"
        id={loading ? "pageSubmitting" : ""}
        onSubmit={handleSubmit}
      >
        <h3>Reports Center</h3>
        <h4>Fill sections below to generate report</h4>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Report type</label>
            <select
              name="currentReportType"
              onChange={handleChange}
              value={currentReportType}
              required
            >
              <option value="" disabled selected>
                Choose report type
              </option>
              {reportTypes?.map((item) => (
                <option value={item?.name} key={item?.name}>
                  {item?.name}
                </option>
              ))}
            </select>
          </span>
          <span>
            <label htmlFor="">Report</label>
            <select
              name="currentSpecificReport"
              onChange={handleChange}
              value={currentSpecificReport}
              required
            >
              <option value="">Choose report</option>
              {/* we filter specific reports based on report type */}
              {specificReports
                ?.filter((item) => item.reportType === currentReportType)
                .map((item) => (
                  <option value={item?.name}>{item?.name}</option>
                ))}
            </select>
          </span>
          {currentReportType === "Student Financial Reports" && (
            <span>
              <label for="">Class Stream</label>
              <select
                name="classStream"
                onChange={(e) => setClassStream(e.target.value)}
                value={classStream}
                required
              >
                <option value="all_streams">all streams</option>
                {class_streams?.map((stream) => (
                  <option value={stream?.name} key={stream?.name}>
                    {stream?.name}
                  </option>
                ))}
              </select>
            </span>
          )}

          {/* for staff ledger statement, we show option to select the staff */}
          {currentSpecificReport === "Staff Ledger Statement" && (
            <span>
              <label for="">Select Staff</label>
              <select name="" onChange={handleStaffChange}>
                <option value="">--select staff--</option>
                {allStaff?.map((staff) => (
                  <option value={staff?.id} key={staff?.id}>
                    {staff?.name}
                  </option>
                ))}
              </select>
            </span>
          )}
          {/* don't show start date if report is balance sheet or student balances*/}
          {currentSpecificReport !== "Balance Sheet" &&
            currentSpecificReport !== "Student Balances" &&
            currentSpecificReport !== "Staff Net Pay Balances" && (
              <span>
                <label htmlFor="">Start Date</label>
                <input
                  type="date"
                  name="start_date"
                  onChange={handleChange}
                  value={start_date}
                  required
                />
              </span>
            )}

          <span>
            <label htmlFor="">End Date</label>
            <input
              type="date"
              name="end_date"
              onChange={handleChange}
              value={end_date}
              required
            />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "2%" }}
          />
        )}
        <button type="submit" className="add__button">
          View Report
        </button>
      </form>

      {/* child components */}
      {/* trial balance */}

      {openReportTemplate && (
        <>
          {currentSpecificReport === "Trial Balance" && (
            <TrialBalanceTemplate
              openReportTemplate={openReportTemplate}
              setOpenReportTemplate={setOpenReportTemplate}
              reportData={reportData}
              start_date={start_date}
              end_date={end_date}
              schoolName={schoolName}
            />
          )}
          {/* profit and loss */}
          {currentSpecificReport === "Profit & Loss" && (
            <ProfitLoss
              openReportTemplate={openReportTemplate}
              setOpenReportTemplate={setOpenReportTemplate}
              reportData={reportData}
              start_date={start_date}
              end_date={end_date}
              schoolName={schoolName}
            />
          )}
          {/* balance sheet */}
          {currentSpecificReport === "Balance Sheet" && (
            <BalanceSheet
              openReportTemplate={openReportTemplate}
              setOpenReportTemplate={setOpenReportTemplate}
              reportData={reportData}
              end_date={end_date}
              schoolName={schoolName}
            />
          )}
          {/* ledger report if user wants a general ledger item report */}
          {currentReportType === "General Ledger" && (
            <LedgerReport
              openReportTemplate={openReportTemplate}
              setOpenReportTemplate={setOpenReportTemplate}
              reportData={reportData}
              start_date={start_date}
              end_date={end_date}
              schoolName={schoolName}
            />
          )}
          {currentSpecificReport === "Student Balances" && (
            <StudentBalances
              openReportTemplate={openReportTemplate}
              setOpenReportTemplate={setOpenReportTemplate}
              reportData={reportData}
              end_date={end_date}
              schoolName={schoolName}
            />
          )}
          {currentSpecificReport === "Staff Net Pay Balances" && (
            <StaffNetPayBalances
              openReportTemplate={openReportTemplate}
              setOpenReportTemplate={setOpenReportTemplate}
              reportData={reportData}
              end_date={end_date}
              schoolName={schoolName}
            />
          )}
          {currentSpecificReport === "Staff Ledger Statement" && (
            <StaffLedgerStatement
              openReportTemplate={openReportTemplate}
              setOpenReportTemplate={setOpenReportTemplate}
              reportData={reportData}
              start_date={start_date}
              end_date={end_date}
              currentStaff={currentStaff}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user?.id,
    loading: state.shared?.loading,
    reportTypes: state.finance?.reportTypes,
    specificReports: state.finance?.specificReports,
    class_streams: state.course?.class_streams,
    allStaff: state?.staff?.allStaff,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    getReportTypes: (userId) => dispatch(get_report_types(userId)),
    getClassStreams: (userId) => dispatch(get_class_streams(userId)),
    getAllStaff: (userId) => dispatch(human_resource_get_staff(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
