// import installed packages
import { connect } from "react-redux";

// import styles
import "./Profile.scss";
// import material ui items
// import shared/global items
// import components/pages
// import redux API
import { OPEN_CHANGE_PASSWORD } from "../../redux/actions/types";

import ChangePassword from "../../components/users/ChangePassword";

const Profile = (props) => {
  // to avoid messing up with ledger names, for now we disable user editing their profile, only change password
  const { first_name, last_name, surname, email } = props; // extract state from props
  const { openChangePassword } = props; // extract dispatch actions from props

  return (
    <div className="dialog profile">
      <h3>Profile Details</h3>
      <p>
        <strong>Note:</strong>{" "}
        <span>Editing profile can only be done by admin</span>
      </p>
      <div className="dialog__row">
        <span>
          <label htmlFor="">First Name</label>
          <input type="text" value={first_name} readOnly />
        </span>
        <span>
          <label htmlFor="">Last Name</label>
          <input type="text" value={last_name} readOnly />
        </span>
      </div>

      <div className="dialog__row">
        <span>
          <label htmlFor="">Surname</label>
          <input type="text" value={surname} readOnly />
        </span>
        <span>
          <label htmlFor="">Email</label>
          <input type="email" value={email} readOnly />
        </span>
      </div>
      <div class="buttons">
        <button
          type="button"
          className="add__button"
          onClick={openChangePassword}
        >
          Change Password
        </button>
      </div>
      {/* linked components */}

      {openChangePassword && <ChangePassword />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    first_name: state.auth.user?.first_name,
    last_name: state.auth.user?.last_name,
    surname: state.auth.user?.surname,
    email: state.auth.user?.email,
    userId: state.auth.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openChangePassword: () => dispatch({ type: OPEN_CHANGE_PASSWORD }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
