// import installed packages
import { useState, useEffect } from "react";
import { connect } from "react-redux";
// import styles
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
import { ifEmpty, resetFormValues } from "../../../../shared/sharedFunctions";
import globals from "../../../../shared/globals";
// import components/pages
import MinDialog from "../../../../components/common/MinDialog";
// import redux API
import { START_LOADING } from "../../../../redux/actions/types";
import {
  get_class_streams,
  get_school_terms,
} from "../../../../redux/actions/course";
import {
  get_school_services,
  create_students_invoice,
} from "../../../../redux/actions/finance";

const InvoiceStudents = (props) => {
  const { openInvoiceStudents, class_streams, userId, school_terms, loading } =
    props; // get state from props
  const {
    setOpenInvoiceStudents,
    getClassStreams,
    startLoading,
    getSchoolServices,
    createStudentsInvoice,
    getSchoolTerms,
  } = props; // get dispatch actions from props
  const [invoiceStudent, setInvoiceStudent] = useState({
    invoice_type: "",
    admission_or_stream: "",
    school_term: "",
  });

  const { invoice_type, admission_or_stream, school_term } = invoiceStudent;
  const { fillFields } = globals;

  // useEffect to get class streams in case they are not already in redux state
  useEffect(() => {
    if (userId && openInvoiceStudents) {
      startLoading();
      getClassStreams(userId);
    }
  }, [userId, startLoading, getClassStreams, openInvoiceStudents]);

  // useEffect to get school services if they are not in state
  useEffect(() => {
    if (userId && openInvoiceStudents) {
      startLoading();
      getSchoolServices(userId);
    }
  }, [userId, openInvoiceStudents, startLoading, getSchoolServices]);

  // useEffect to get created terms if they are not in redux state
  useEffect(() => {
    if (userId) {
      startLoading();
      getSchoolTerms(userId);
    }
  }, [getSchoolTerms, userId, school_terms?.length, startLoading]);

  const handleChange = (e) => {
    setInvoiceStudent({ ...invoiceStudent, [e.target.name]: e.target.value });
  };

  // function to reset form values
  const resetForm = () => {
    resetFormValues(invoiceStudent);
  };

  // function to close form
  const handleClose = () => {
    resetForm();
    setOpenInvoiceStudents(false);
  };

  // function to handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (ifEmpty(invoiceStudent)) {
      return alert(fillFields);
    }
    startLoading();
    createStudentsInvoice({ ...invoiceStudent }, userId, resetForm);
  };

  return (
    <MinDialog isOpen={openInvoiceStudents}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Choose Student(s) to invoice</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Select type</label>
          <select
            name="invoice_type"
            onChange={handleChange}
            value={invoice_type}
          >
            <option value="" disabled selected>
              Select student or stream
            </option>
            <option value="single_student">Single Student</option>
            <option value="stream_students">Stream Students</option>
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "42%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">School term</label>
          <select
            name="school_term"
            value={school_term}
            onChange={handleChange}
          >
            <option value="" disabled selected>
              Select term
            </option>
            {school_terms?.length > 0 ? (
              <>
                {school_terms?.map((term) => (
                  <option value={term?.id} key={term?.id}>
                    {term?.name}
                  </option>
                ))}
              </>
            ) : (
              <option value="" disabled selected>
                No active school terms
              </option>
            )}
          </select>
        </div>
        {invoice_type === "single_student" && (
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Admission Number</label>
            <input
              type="text"
              name="admission_or_stream"
              onChange={handleChange}
              value={admission_or_stream}
            />
          </div>
        )}
        {invoice_type === "stream_students" && (
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Stream Name</label>
            <select
              name="admission_or_stream"
              onChange={handleChange}
              value={admission_or_stream}
            >
              <option value="" disabled selected>
                Select stream
              </option>
              {class_streams?.map((stream) => (
                <option value={stream?.name} key={stream?.name}>
                  {stream?.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div class="dialog__rowSingleItem">
          <label for="" style={{ fontSize: "1rem", color: "green" }}>
            Students will be invoiced based on the services they consume
          </label>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    class_streams: state.course?.class_streams?.filter(
      (stream) => Number(stream?.students_number) > 0
    ),
    userId: state.auth.user?.id,
    school_terms: state.course?.school_terms?.filter(
      (term) => term?.is_active === true
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    getClassStreams: (userId) => dispatch(get_class_streams(userId)),
    getSchoolServices: (userId) => dispatch(get_school_services(userId)),
    createStudentsInvoice: (body, userId, resetForm) =>
      dispatch(create_students_invoice(body, userId, resetForm)),
    getSchoolTerms: (userId) => dispatch(get_school_terms(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceStudents);
