import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { teacher_get_live_classes } from "../../../redux/actions/course";
import { START_LOADING } from "../../../redux/actions/types";

import AddLiveClass from "./components/AddLiveClass";

const TeacherLiveClasses = (props) => {
  const { userId, live_classes } = props;
  const { startLoading, teacherGetLiveClasses } = props;

  const [openAddLiveClass, setOpenAddLiveClass] = useState(false);

  useEffect(() => {
    if (live_classes?.length === 0 && userId) {
      startLoading();
      teacherGetLiveClasses(userId);
    }
  }, [live_classes?.length, userId, startLoading, teacherGetLiveClasses]);

  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <button
            className="add__button"
            onClick={() => setOpenAddLiveClass(true)}
          >
            Schedule Live Class
          </button>
        </div>
        <table className="table__listing">
          {live_classes?.length > 0 ? (
            <>
              <tr className="table__listingHeader">
                <th>Topic</th>
                <th>Class stream</th>
                <th>Status</th>
              </tr>
              {live_classes?.map((live_class) => (
                <tr className="table__listingItem">
                  <td>{live_class?.topic}</td>
                  <td>{live_class?.class_stream}</td>
                  <td>
                    {live_class.status === "start now" ? (
                      <Link
                        to={`/live-class/${live_class?.id}/`}
                        className="dodgerblue bd button__sp"
                      >
                        Start Class
                      </Link>
                    ) : (
                      <span>Not Started</span>
                    )}
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <h4 className="not__available">No scheduled live classes</h4>
          )}
        </table>
      </div>
      {/* child components */}

      {openAddLiveClass && (
        <AddLiveClass
          openAddLiveClass={openAddLiveClass}
          setOpenAddLiveClass={setOpenAddLiveClass}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    live_classes: state?.course?.live_classes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    teacherGetLiveClasses: (userId) =>
      dispatch(teacher_get_live_classes(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherLiveClasses);
