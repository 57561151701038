import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_offline_exams } from "../../../../redux/actions/exam";
import { START_LOADING } from "../../../../redux/actions/types";
import OverallResultsAction from "./components/OverallResultsAction";

import SubjectResultsAction from "./components/SubjectResultsAction";

const OfflineExams = (props) => {
  const { offlineExams, loading, userId, class_streams } = props;
  const { startLoading, getOfflineExams } = props;

  const [currentExam, setCurrentExam] = useState({});
  const [openResultsAction, setOpenResultsAction] = useState("");
  const [openOverallResultsAction, setOpenOverallResultsAction] =
    useState(false);

  useEffect(() => {
    if (userId && offlineExams?.length === 0) {
      startLoading();
      getOfflineExams(userId);
    }
  }, [startLoading, userId, getOfflineExams, offlineExams?.length]);

  const handleOpen = (exam) => {
    setCurrentExam(exam);
    setOpenResultsAction(true);
  };

  const handleOpenOveralResultsAction = (exam) => {
    setCurrentExam(exam);
    setOpenOverallResultsAction(true);
  };

  return (
    <>
      <div className="table__parent">
        <div className="table__parentHeader">
          <h3>Offline exams subject results</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "0%" }}
            />
          )}
        </div>
        {offlineExams?.length > 0 ? (
          <table className="table__listing">
            <tr className="table__listingHeader">
              <th>Exam</th>
              <th>School Term</th>
              <th>Exam Type</th>
              <th>Results Status</th>
              <th>Subject Results</th>
            </tr>
            {offlineExams?.map((exam) => (
              <tr className="table__listingItem" key={exam?.id}>
                <td>{exam?.name}</td>
                <td>{exam?.school_term_name}</td>
                <td>{exam?.exam_type}</td>
                <td>
                  {exam?.update_complete ? (
                    <span className="purple bd">Completed</span>
                  ) : (
                    <span className="green bd">Update ongoing</span>
                  )}
                </td>
                <td className="dodgerblue bd button__sp">
                  <span onClick={() => handleOpen(exam)}>Open</span>
                </td>
              </tr>
            ))}
          </table>
        ) : (
          <h4 className="not__available">No offline exams available</h4>
        )}
        {/* WE ONLY SHOW THIS OVERALL RESULTS IF THIS TEACHER IS A CLASS TEACHER OF AT LEAST ONE CLASS */}
        {class_streams?.length > 0 && (
          <>
            <div class="table__parentHeader">
              <h3>Offline exams overall results</h3>
            </div>
            {/* table for the overall results */}
            {offlineExams?.length > 0 ? (
              <table className="table__listing">
                <thead>
                  <tr class="table__listingHeader">
                    <th>Exam</th>
                    <th>School Term</th>
                    <th>Exam Type</th>
                    <th>Results Status</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {offlineExams?.map((exam) => (
                    <tr class="table__listingItem" key={exam?.id}>
                      <td>{exam?.name}</td>
                      <td>{exam?.school_term_name}</td>
                      <td>{exam?.exam_type}</td>
                      <td>
                        {exam?.update_complete ? (
                          <span className="purple bd">Completed</span>
                        ) : (
                          <span className="green bd">Update ongoing</span>
                        )}
                      </td>
                      <td
                        className="dodgerblue bd button__sp"
                        onClick={() => handleOpenOveralResultsAction(exam)}
                      >
                        Open
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h4 class="not__available">No offline exams available</h4>
            )}
          </>
        )}
      </div>
      {/* child components */}
      {openResultsAction && (
        <SubjectResultsAction
          openResultsAction={openResultsAction}
          setOpenResultsAction={setOpenResultsAction}
          currentExam={currentExam}
        />
      )}
      {openOverallResultsAction && (
        <OverallResultsAction
          openOverallResultsAction={openOverallResultsAction}
          setOpenOverallResultsAction={setOpenOverallResultsAction}
          currentExam={currentExam}
        />
      )}

      {/* {openOverallResults && (
        <OverallResults
          openOverallResults={openOverallResults}
          setOpenOverallResults={setOpenOverallResults}
          currentExamId={currentExam?.id}
          currentExamType={currentExam?.exam_type}
          resultsStatus={resultsStatus}
          schoolName={schoolName}
        />
      )} */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    offlineExams: state?.exam?.offlineExams,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    class_streams: state?.auth?.user?.class_streams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOfflineExams: (userId) => dispatch(get_offline_exams(userId)),
    startLoading: () => dispatch({ type: START_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfflineExams);
