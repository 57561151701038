import React, { useRef } from "react";
import { connect } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { formatWithCommas } from "../../../../shared/sharedFunctions";
import globals from "../../../../shared/globals";
import MediumDialog from "../../../../components/common/MediumDialog";

import "./PrintPaySlip.scss";

const PrintPaySlip = (props) => {
  const {
    openPrintPaySlip,
    currentPaySlip,
    payslipMonth,
    school_name,
    school_logo,
  } = props;
  const { setOpenPrintPaySlip } = props;

  const { liveProduction, devHome } = globals;

  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: `${currentPaySlip?.staff_name} payslip`,
  });

  return (
    <MediumDialog isOpen={openPrintPaySlip} maxWidth="600px">
      <div className="dialog">
        <table
          className="report__statement payslip"
          border="1"
          rules="rows"
          ref={printArea}
        >
          <thead>
            <tr>
              <td className="employee_details">
                <span>
                  <strong>School: </strong> {school_name}
                </span>
                <br />
                <span>
                  <strong>Employee Name:</strong> {currentPaySlip?.staff_name}
                </span>{" "}
                <br />
                <span>
                  <strong>PIN:</strong> {currentPaySlip?.staff_pin}
                </span>
              </td>
              <td>
                <img
                  src={
                    liveProduction
                      ? `${school_logo}`
                      : `${devHome}${school_logo}`
                  }
                  alt=""
                />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colSpan="100%">{payslipMonth} paylip</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th colSpan="100%">Payslip Earnings: </th>
            </tr>
            {currentPaySlip?.staff_payslip_earnings?.map((earning) => (
              <tr key={earning?.id}>
                <td>{earning?.name}</td>
                <td>{formatWithCommas(earning?.amount, 2)}</td>
              </tr>
            ))}
            <tr>
              <th>Total Payslip Earnings</th>
              <th>
                {formatWithCommas(currentPaySlip?.total_salary_earnings, 2)}
              </th>
            </tr>
            <tr>
              <td colSpan="100%"></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>Total Allowable Deductions</th>
              <th>
                {formatWithCommas(
                  currentPaySlip?.total_allowable_deductions,
                  2
                )}
              </th>
            </tr>
            <tr>
              <td colSpan="100%"></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>Taxable Amount</th>
              <th>{formatWithCommas(currentPaySlip?.taxable_pay, 2)}</th>
            </tr>
            <tr>
              <td>Gross Tax</td>
              <td>{formatWithCommas(currentPaySlip?.gross_tax, 2)}</td>
            </tr>
            <tr>
              <th>Tax Reliefs</th>
            </tr>
            {currentPaySlip?.staff_tax_reliefs?.map((relief) => (
              <tr key={relief?.id}>
                <td>{relief?.name}</td>
                <td>{formatWithCommas(relief?.amount, 2)}</td>
              </tr>
            ))}

            <tr>
              <th>PAYE Tax</th>
              <th>{formatWithCommas(currentPaySlip?.paye_tax, 2)}</th>
            </tr>
            <tr>
              <td colSpan="100%"></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th colSpan="100%">Payslip Deductions: </th>
            </tr>
            {currentPaySlip?.staff_payslip_deductions?.map((deduction) => (
              <tr key={deduction?.id}>
                <td>{deduction?.name}</td>
                <td>{formatWithCommas(deduction?.amount, 2)}</td>
              </tr>
            ))}

            <tr>
              <th>Total Payslip Deductions</th>
              <th>
                {formatWithCommas(currentPaySlip?.total_salary_deductions, 2)}
              </th>
            </tr>
            <tr>
              <td colSpan="100%"></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>Net Pay</th>
              <th>{formatWithCommas(currentPaySlip?.net_pay, 2)}</th>
            </tr>
          </tbody>
        </table>
        <div class="form__Buttons">
          <button type="button" onClick={() => setOpenPrintPaySlip(false)}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print/Save
          </button>
        </div>
      </div>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    school_name: state?.auth?.user?.school_details?.school_name,
    school_logo: state?.auth?.user?.school_details?.school_logo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintPaySlip);
