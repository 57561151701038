import React, { useState } from "react";
import { connect } from "react-redux";
import MediumDialog from "../../../../components/common/MediumDialog";
import { START_LOADING } from "../../../../redux/actions/types";

import CircularProgress from "@mui/material/CircularProgress";
import { approve_or_reject_staff_leave_application } from "../../../../redux/actions/staff";

const ApproveDenyStaffLeave = (props) => {
  const { openApproveDenyStaffLeave, currentStaffLeave, loading, userId } =
    props;
  const {
    setOpenApproveDenyStaffLeave,
    startLoading,
    approveOrRejectStaffLeave,
  } = props;

  const { start_date, end_date, staff, staff_type } = currentStaffLeave;

  const [leaveUpdateDetails, setLeaveUpdateDetails] = useState({
    action_type: "",
    rejection_reason: "",
  });

  const { action_type, rejection_reason } = leaveUpdateDetails;

  const handleChange = (e) => {
    setLeaveUpdateDetails({
      ...leaveUpdateDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (action_type?.trim() === "") {
      return window.alert("Please choose approve or reject");
    }
    if (action_type === "rejected" && rejection_reason.trim() === "") {
      return window.alert("Please indicate the rejection reason");
    }
    startLoading();
    const body = {
      leaveApplicationId: currentStaffLeave?.id,
      action_type,
      rejection_reason,
    };
    approveOrRejectStaffLeave(userId, body);
  };

  return (
    <MediumDialog isOpen={openApproveDenyStaffLeave}>
      <form className="dialog" id={loading ? "formSubmitting" : ""}>
        <h3>Approve or reject staff leave application</h3>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Staff Name</label>
            <input type="text" value={staff} disabled />
          </span>
          <span>
            <label htmlFor="">Staff Type</label>
            <input type="text" value={staff_type} disabled />
          </span>
        </div>
        <div className="dialog__row">
          <span>
            <label htmlFor="">Start Date</label>
            <input type="text" value={start_date} disabled />
          </span>
          <span>
            <label htmlFor="">End Date</label>
            <input type="text" value={end_date} disabled />
          </span>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <span>
            <label htmlFor="">Approve or reject</label>
            <select
              name="action_type"
              value={action_type}
              onChange={handleChange}
            >
              <option value="" disabled selected>
                --select--
              </option>
              <option value="approved">Approve</option>
              <option value="rejected">Reject</option>
            </select>
          </span>
          <span></span>
        </div>
        {action_type === "rejected" && (
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Rejection reason</label>
            <input
              type="text"
              name="rejection_reason"
              onChange={handleChange}
              value={rejection_reason}
            />
          </div>
        )}
        <div className="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenApproveDenyStaffLeave(false)}
          >
            Close
          </button>
          <button type="submit" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </form>
    </MediumDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    approveOrRejectStaffLeave: (userId, body) =>
      dispatch(approve_or_reject_staff_leave_application(userId, body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveDenyStaffLeave);
