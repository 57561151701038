// import installed packages
import React, { useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import styles
// import material ui items
// import shared/global items
// import components/pages
import MediumDialog from "../../../../../components/common/MediumDialog";
// import redux API

const LedgerReport = (props) => {
  const { schoolName, openReportTemplate, reportData, start_date, end_date } =
    props; // get state from props
  const { setOpenReportTemplate } = props; // get dispatch actions from props

  // printing and saving report
  const printArea = useRef();
  const handlePrint = useReactToPrint({
    content: () => printArea.current,
    documentTitle: `${reportData?.ledgerName} Report`,
  });

  const handleClose = () => {
    setOpenReportTemplate(false);
  };
  return (
    <MediumDialog isOpen={openReportTemplate} maxWidth="1200px">
      <div className="dialog">
        <table
          className="report__statement"
          border="1"
          rules="rows"
          ref={printArea}
          id="ledger_report"
        >
          <thead>
            <tr>
              <th colSpan="8" className="dodgerblue">
                {schoolName}
              </th>
            </tr>
            <tr>
              <th colSpan="8" className="dodgerblue">
                {reportData?.ledgerName} ledger
              </th>
            </tr>
            <tr>
              <th colSpan="8" className="dodgerblue">
                From {moment(start_date).format("DD-MM-YYYY")} to{" "}
                {moment(end_date).format("DD-MM-YYYY")}
              </th>
            </tr>
            <tr>
              <th className="bbottom purple tl">Date</th>
              <th className="bbottom purple tl">Memo</th>
              <th className="bbottom purple">Debit</th>
              <th className="bbottom purple">Credit</th>
            </tr>
          </thead>
          <tbody>
            {/* show opening balance if it's a balance sheet item */}
            {reportData?.isBalanceSheetLedgerItem && (
              <>
                <tr>
                  <th className="tl" colSpan="2">
                    Opening balance
                  </th>
                  {/* show as a debit balance is a debit */}
                  <th>
                    {reportData?.openingBalanceType === "Debit" &&
                      reportData?.openingBalanceValue?.toLocaleString()}
                  </th>

                  {/* show as a debit balance is a credit */}
                  <th>
                    {reportData?.openingBalanceType === "Credit" &&
                      reportData?.openingBalanceValue?.toLocaleString()}
                  </th>
                </tr>
                <br />
              </>
            )}
            {/* loop through the various transactions */}
            {reportData?.transactions?.map((transaction, index) => (
              <tr key={index}>
                <td>{moment(transaction?.date).format("DD-MM-YYYY")}</td>
                <td>{transaction?.memo}</td>
                <td className="amount">
                  {transaction?.entry_type === "Debit" &&
                    transaction?.amount?.toLocaleString()}
                </td>
                <td className="amount">
                  {transaction?.entry_type === "Credit" &&
                    transaction?.amount?.toLocaleString()}
                </td>
              </tr>
            ))}
            {/* section for totals */}
            <br />
            <tr className="parent_ledger_group">
              <th colSpan="2" className="tl">
                Period Totals
              </th>
              <th className="amount">
                {reportData?.totalDebits?.toLocaleString()}
              </th>
              <th className="amount">
                {reportData?.totalCredits?.toLocaleString()}
              </th>
            </tr>
            {/* section for closing balance */}

            <>
              <br />
              <tr className="parent_ledger_group">
                <th colSpan="2" className="tl">
                  {reportData?.isBalanceSheetLedgerItem ? (
                    <span>Closing Balance</span>
                  ) : (
                    <span>Net Totals</span>
                  )}
                </th>
                <th className="amount">
                  {reportData?.closingBalanceType === "Debit" &&
                    reportData?.closingBalanceValue?.toLocaleString()}
                </th>
                <th className="amount">
                  {reportData?.closingBalanceType === "Credit" &&
                    reportData?.closingBalanceValue?.toLocaleString()}
                </th>
              </tr>
            </>
          </tbody>
        </table>
        <div className="form__Buttons">
          <button type="button" onClick={handleClose}>
            Close
          </button>
          <button type="button" onClick={handlePrint}>
            Print
          </button>
        </div>
        <div class="export_to_excel_button">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="ledger_report"
            filename="ledger_report"
            sheet="ledger_report"
            buttonText="Export To Excel"
          />
        </div>
      </div>
    </MediumDialog>
  );
};

export default LedgerReport;
