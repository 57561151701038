import * as api from "../../api";

import * as actionTypes from "./types";

import { showError, stopLoading } from "./shared";

// POST REQUEST function to create a new school service
export const create_new_school_service =
  (newService, userId, resetForm) => async (dispatch) => {
    await api
      .createNewSchoolService(newService, userId)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_SCHOOL_SERVICE,
          payload: res.data?.new_service,
        });
        resetForm();
        alert(res.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// PATCH REQUEST to edit school service
export const edit_school_service = (userId, body) => async (dispatch) => {
  await api
    .editSchoolService(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_SCHOOL_SERVICE,
        payload: res?.data?.updated_school_service,
      });
      window.alert("School service edited successfully");
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// POST REQUEST function to invoice student(s)
export const create_students_invoice =
  (body, userId, resetForm) => async (dispatch) => {
    await api
      .createStudentsInvoice(body, userId)
      .then((res) => {
        alert(res.data?.detail);
        resetForm();
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// GET REQUEST function to get school services
export const get_school_services = (userId) => async (dispatch) => {
  await api
    .getSchoolServices(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_SCHOOL_SERVICES,
        payload: res.data?.school_services,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// POST REQUEST function to create a new ledger group
export const create_new_ledger_group =
  (userId, newLedgerGroup, resetForm) => async (dispatch) => {
    await api
      .createNewLedgerGroup(userId, newLedgerGroup)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_LEDGER_GROUP,
          payload: res.data?.new_ledger_group,
        });
        resetForm();
        alert(res.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// POST REQUEST function to create a new ledger
export const create_new_ledger =
  (userId, newLedger, resetForm) => async (dispatch) => {
    await api
      .createNewLedger(userId, newLedger)
      .then((res) => {
        dispatch({
          type: actionTypes.NEW_LEDGER,
          payload: res.data?.new_ledger,
        });
        alert(res.data?.detail);
        resetForm();
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to edit ledger group
export const edit_ledger_group = (userId, body) => async (dispatch) => {
  await api
    .editLedgerGroup(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_LEDGER_GROUP,
        payload: res?.data?.updated_ledger_group,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// action to edit ledger
export const edit_ledger = (userId, body) => async (dispatch) => {
  await api
    .editLedger(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_LEDGER,
        payload: res?.data?.updated_ledger,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// GET REQUEST to get ledgers, ledger_groups and parent_ledger_groups
export const get_ledgers = (userId) => async (dispatch) => {
  await api
    .getLedgers(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_LEDGERS,
        payload: {
          ledgers: res.data?.ledgers,
          ledger_groups: res.data?.ledger_groups,
          parent_ledger_groups: res.data?.parent_ledger_groups,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// REQUEST to get report types
export const get_report_types = (userId) => async (dispatch) => {
  await api
    .getReportTypes(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_REPORTS,
        payload: {
          reportTypes: res.data?.report_types_listing,
          specificReports: res.data?.specific_reports_listing,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

// POST REQUEST TO post a new transaction
export const post_new_transaction =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .postNewTransaction(userId, body)
      .then((res) => {
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// PATCH REQUEST to edit services a student consumes
export const update_student_school_services =
  (userId, body) => async (dispatch) => {
    await api
      .updateStudentSchoolServices(userId, body)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_USER,
          payload: {
            userType: "Student",
            user_data: res?.data?.updated_user,
          },
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to delete a ledger or a ledger group
export const delete_ledger_or_group =
  (userId, itemId, ledgerOrGroup) => async (dispatch) => {
    await api
      .deleteLedgerOrGroup(userId, itemId, ledgerOrGroup)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_LEDGER_OR_GROUP,
          payload: { itemId, ledgerOrGroup },
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to delete school service
export const delete_school_service =
  (userId, schoolServiceId) => async (dispatch) => {
    await api
      .deleteSchoolService(userId, schoolServiceId)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_SCHOOL_SERVICE,
          payload: schoolServiceId,
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };

// action to post stock invoice
export const post_stock_invoice =
  (userId, body, resetForm) => async (dispatch) => {
    await api
      .postStockInvoice(userId, body)
      .then((res) => {
        resetForm();
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };
